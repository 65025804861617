import HeaderCell from "./HeaderCell";

const HeaderCellWrapper = (data: any) => {
  return (
    <>
      <HeaderCell
        title="Title"
        width="min-w-[16.875rem] w-[16.875rem] near_arrow"
        arrow={false}
        postKey="title"
        padding="pl-5"
      />
      <HeaderCell
        title="Type"
        width="min-w-[5rem] w-[5rem] near_arrow"
        arrow={false}
        postKey="title"
      />
      <HeaderCell
        title="Description"
        width="min-w-[11.25rem] w-[11.25rem] near_arrow"
        arrow={false}
        postKey="title"
      />
    </>
  );
};

export default HeaderCellWrapper;
