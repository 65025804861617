import _ from "lodash";
import { useContext, useRef } from "react";
import AngleSVG from "../../../../assets/media/icons/other_icons/down-angle.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { AddInventoryContext } from "../../core/AddInventoryProvider";

// type Props = {
//   title?: string;
//   arrow?: boolean;
//   order?: string;
//   event?: any;
//   postKey?: string;
//   minWidth?: any;
// };

const EventHeaderCell = ({
  title,
  arrow,
  order,
  event,
  postKey,
  minWidth,
  setList,
  mobWidth = false,
}: any) => {
  const layout = useContext(AddInventoryContext);
  let orderRef = useRef("desc");

  let isDisabledListing =
    _.size(layout?.isTableEditable) > 0 || layout.isDeleteActive;

  var sortingArray: any = {
    ticketType: {
      key: "id",
      defaultValue: "",
      type: "string",
    },
    quantity: {
      defaultValue: 0.0,
      type: "number",
    },
    maxDisplayQuantity: {
      defaultValue: 0.0,
      type: "number",
    },
    category: {
      key: "name",
      defaultValue: "",
      type: "string",
    },
    section: {
      key: "name",
      defaultValue: "",
      type: "string",
    },
    row: {
      defaultValue: 0.0,
      type: "number",
    },
    firstSeat: {
      defaultValue: 0.0,
      type: "number",
    },
    faceValue: {
      key: "value",
      defaultValue: 0.0,
      type: "number",
    },
    proceedValue: {
      key: "value",
      defaultValue: 0.0,
      type: "number",
    },
  };
  const handleSort = (id: any, postKey: any) => {
    orderRef.current = orderRef.current == "desc" ? "asc" : "desc";
    setList((events: any) => {
      return events?.map((event: any) => {
        if (event?.id === id) {
          let isData = event?.eventRecords?.some((item: any) => item[postKey]);

          if (isData) {
            // const isAscending = _.every(
            //   event?.eventRecords,
            //   (record: any, index: any) => {
            //     if (index === 0) return true; // The first element is always in ascending order
            //     let first =
            //       typeof record[postKey] === "object"
            //         ? ((record[postKey]?.value)?record[postKey]?.value:0.00)
            //         : (record[postKey]?record[postKey]:0);
            //     first = Number(first).toFixed();
            //     let second =
            //       typeof event?.eventRecords[index - 1][postKey] === "object"
            //         ? ((event?.eventRecords[index - 1][postKey]?.value)?event?.eventRecords[index - 1][postKey]?.value:0.00)
            //         : ((event?.eventRecords[index - 1][postKey])?event?.eventRecords[index - 1][postKey]:0);
            //       second = Number(second).toFixed();

            //     return parseInt(first) >= parseInt(second);
            //   }
            // );

            // if (isAscending) {
            //   sortedRecords = _.orderBy(
            //     event?.eventRecords,
            //     [
            //       (record) =>
            //         typeof record[postKey] === "object"
            //           ? parseFloat((record[postKey]?.value)?record[postKey]?.value:0.00)
            //           : parseInt(record[postKey]?record[postKey]:0),
            //     ],
            //     ["desc"]
            //   );
            // } else {
            //   sortedRecords = _.orderBy(event?.eventRecords, [
            //     (record) =>
            //       typeof record[postKey] === "object"
            //         ? parseFloat((record[postKey]?.value)?record[postKey]?.value:0.00)
            //         : parseInt(record[postKey]?record[postKey]:0),
            //   ]);
            // }
            let orderRefval: any = orderRef?.current;
            let sortedRecords;
            sortedRecords = _.orderBy(
              event?.eventRecords,
              [
                (record: any) =>
                  typeof record?.[postKey] === "object"
                    ? record?.[postKey]?.[sortingArray[postKey]["key"]]
                      ? sortingArray[postKey]["type"] === "string"
                        ? record?.[postKey]?.[sortingArray[postKey]["key"]]
                        : parseFloat(
                            record?.[postKey]?.[sortingArray[postKey]["key"]]
                          )
                      : sortingArray[postKey]["defaultValue"]
                    : record?.[postKey]
                    ? sortingArray[postKey]["type"] === "string"
                      ? record?.[postKey]
                      : parseFloat(record?.[postKey])
                    : sortingArray[postKey]["defaultValue"],
              ],
              [orderRefval]
            );
            const updatedRecords = sortedRecords?.map((record: any) => ({
              ...record,
              sortKey: postKey,
              // order: isAscending ? "asc" : "desc",
              order: orderRefval,
            }));

            return {
              ...event,
              eventRecords: updatedRecords,
            };
          } else {
            return {
              ...event,
            };
          }
        } else {
          return {
            ...event,
          };
        }
      });
    });
  };
  return (
    <div
      className={`px-1.5 py-3 font-medium whitespace-nowrap min-w-[${minWidth}] ${mobWidth} `}
      key={title}
    >
      {arrow ? (
        <div className="w-full pr-3 relative ">
          {title}
          <button
            type="button"
            disabled={isDisabledListing ? true : false}
            data-value={"asc"}
            onClick={() => {
              handleSort(event.id, postKey);
            }}
          >
            <IKTSVG
              path={AngleSVG}
              className={`${
                isDisabledListing ? "pointer-events-none" : ""
              } fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4
              ${
                event?.eventRecords[0]?.sortKey === postKey &&
                event?.eventRecords[0]?.order === "desc"
                  ? "-rotate-180 !fill-violet-500"
                  : ""
              }
            `}
              svgClassName=""
            />
          </button>
        </div>
      ) : (
        title
      )}
    </div>
  );
};

export default EventHeaderCell;
