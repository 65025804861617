import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";

export const ClearButton = (props: any) => {
  const { name, isLoading, ...rest } = props;
  return (
    <div
      className={`flex py-1 px-2.5 text-xs border rounded-full my-0.5 ${
        isLoading && "shimmer-effect"
      }`}
    >
      {name && <span className={`${isLoading && "opacity-0"}`}>{name}</span>}
      <button type="button" className="ml-2 hover:fill-indigo-500" {...rest}>
        <CrossIcon className="h-2 w-2 hover:fill-indigo-500" />
      </button>
    </div>
  );
};
