const Warning = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      {...props}
    >
      <g
        id="Group_3065"
        data-name="Group 3065"
        transform="translate(-1155.5 -302)"
      >
        <rect
          id="Rectangle_42"
          data-name="Rectangle 42"
          width="16"
          height="16"
          rx="8"
          transform="translate(1155.5 302)"
        />
        <text
          id="_"
          data-name="!"
          transform="translate(1163.5 314.5)"
          fill="#fff"
          fontSize="12"
          fontFamily="Inter-SemiBold, Inter"
          fontWeight="600"
        >
          <tspan x="-1.854" y="0">
            !
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export default Warning;
