/* eslint-disable jsx-a11y/anchor-is-valid */
import { useRef } from "react";
// import { Footer, Select } from "flowbite-react";

import Inventory from "../../assets/media/icons/menu_icons/Inventory.svg";
import Overview from "../../assets/media/icons/menu_icons/Overview.svg";
import Settings from "../../assets/media/icons/menu_icons/Settings.svg";
import faq from "../../assets/media/icons/menu_icons/faq.svg";
import salesNew from "../../assets/media/icons/menu_icons/salesNew.svg";
import technicalSupport from "../../assets/media/icons/menu_icons/technicalSupport.svg";
import txPayNew from "../../assets/media/icons/menu_icons/txPayNew.svg";

import PageTitle from "../../components/core/PageTitle";
import { IKTSVG } from "../../components/ui/IKTSVG";
import { KTSVG } from "../../components/ui/KTSVG";

const FaqSearch = () => {
  const canvasRef: any = useRef();

  // const ref = useRef(null);
  const display = () => {
    var pop_width =
      document.getElementsByClassName("buy_canvas")[0].clientWidth;
    if (canvasRef.current.classList.contains("show")) {
      // @ts-ignore
      document.getElementById(
        "EventAccordion"
      ).style.marginRight = `${pop_width}px`;
    } else if (!canvasRef.current.classList.contains("show")) {
      // @ts-ignore
      document.getElementById("EventAccordion").style.marginRight = "0px";
    }
  };

  return (
    <>
      <PageTitle title="FAQ Centre" />

      <div className="main-content-wrap tx-pay-module">
        <div className="flex flex-wrap">
          <div className="sidebar lg2:max-w-[15.0625rem] border-r p-5 w-full bg-white lg2:min-h-[calc(100vh-3.9375rem)] lg2:relative lg2:top-0 sticky top-[3.75rem] z-20">
            <div
              className="group lg2:hidden block mob_sb_menu relative  [&[aria-expanded='true'] .dd_arrow]:rotate-180"
              data-te-collapse-init
              data-te-collapse-collapsed
              data-te-target="#collapseOne5"
              aria-expanded="false"
              aria-controls="collapseOne5"
            >
              <span className="block w-full border rounded px-[.9375rem] py-2.5 text-sm13 font-medium">
                Overview
              </span>
              <span className="dd_arrow absolute right-3 top-1/2 -translate-y-1/2 group-[[data-te-collapse-collapsed]]:rotate-0 rotate-180 transition">
                <span className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400">
                  <KTSVG
                    className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400"
                    path="other_icons/down-angle.svg"
                    svgClassName="w-2.5 h-2.5"
                  />
                </span>
              </span>
            </div>
            <div
              className="sb_menus lg2:!h-auto lg2:sticky lg2:top-20 lg2:overflow-auto lg2:block hidden absolute left-0 w-full top-full lg2:p-0 px-5 z-20 bg-white"
              id="collapseOne5"
              data-te-collapse-item
              data-te-collapse-collapsed
              aria-labelledby="headingOne5"
            >
              <div className="group sb_item p-2 cursor-pointer bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500 text-indigo-500"
                  href="#"
                >
                  <div className="item_icon w-6 h-6 group-hover:bg-indigo-500 bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={Overview}
                      />
                    </span>
                  </div>
                  Overview
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="bg-indigo-500 item_icon w-6 h-6 group-hover:bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={Settings}
                      />
                    </span>
                  </div>
                  Account & settings
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="bg-indigo-500 item_icon w-6 h-6 group-hover:bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={Inventory}
                      />
                    </span>
                  </div>
                  Listings
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="bg-indigo-500 item_icon w-6 h-6 group-hover:bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={salesNew}
                      />
                    </span>
                  </div>
                  Sales
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="bg-indigo-500 item_icon w-6 h-6 group-hover:bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={txPayNew}
                      />
                    </span>
                  </div>
                  Payments
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="bg-indigo-500 item_icon w-6 h-6 group-hover:bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={technicalSupport}
                      />
                    </span>
                  </div>
                  Technical support
                </a>
              </div>

              <div className="group sb_item p-2 cursor-pointer hover:bg-indigo-500/5 rounded mb-2.5 transition focus:text-indigo-500 active:text-indigo-500">
                <a
                  className="flex flex-wrap text-sm13 font-semibold items-center group-hover:text-indigo-500"
                  href="#"
                >
                  <div className="bg-indigo-500 item_icon w-6 h-6 group-hover:bg-indigo-500 flex items-center justify-center rounded-sm mr-2.5">
                    <span className="min-w-[.8125rem] max-w-[.8125rem] flex items-center justify-center fill-white transition">
                      <IKTSVG
                        svgClassName="fill-white h-3.5 w-4 center"
                        path={faq}
                      />
                    </span>
                  </div>
                  Miscellaneous
                </a>
              </div>
            </div>
          </div>

          <div className="sb_content lg2:max-w-[calc(100%-15.0625rem)] w-full bg-no-repeat bg-cover bg-center bg-white">
            <div className="z-10 w-full md:flex-row flex flex-col flex-wrap items-center justify-between py-4 px-5 bg-setting-bg bg-white/40 relative before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-white/40 before:z-[-1]">
              <span className="mb-2 md:mb-0">Account & settings</span>
              <div className="relative flex-1 max-w-[27rem] w-full md:mb-0">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                  <button>
                    <KTSVG
                      className="fill-violet-500"
                      svgClassName="h-3.5 w-3.5"
                      path="other_icons/search-violet.svg"
                    />
                  </button>
                </div>
                <input
                  type="text"
                  id="simple-search"
                  className="border font-medium bg-white border-indigo-500/20 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-8 pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                  placeholder="Search FAQs"
                  required
                />
              </div>
            </div>
            <div className="p-5 ">
              <div id="table-shrink" className="">
                <div className="single-item bg-gray-100/50 hover:bg-indigo-500/10 transition-all rounded mb-2.5">
                  <div className="flex justify-between">
                    <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                      <h4 className="text-[15px] font-semibold mb-3">
                        Have you already got an account?
                      </h4>
                      <p className="text-[13px] text-gray-600 line-clamp-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi vel tortor ligula. Integer augue mauris, consequat
                        a justo sed, cursus hendrerit quam. Aliquam aliquet
                        tellus blandit semper sagittis. Ut id …
                      </p>
                    </div>
                    <div className="flex items-center justify-center border-l border-gray-200">
                      <button
                        className="h-full p-3 group"
                        type="button"
                        id="buy"
                        onClick={() => {
                          display();
                        }}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#FaqVideo"
                        aria-controls="FaqVideo"
                      >
                        <KTSVG
                          className="w-4 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400 group-hover:fill-violet-100"
                          path="other_icons/Eye.svg"
                          svgClassName="w-4 h-2.5"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="single-item bg-gray-100/50 hover:bg-indigo-500/10 transition-all rounded mb-2.5">
                  <div className="flex justify-between">
                    <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                      <h4 className="text-[15px] font-semibold mb-3">
                        Have you already got an account?
                      </h4>
                      <p className="text-[13px] text-gray-600 line-clamp-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi vel tortor ligula. Integer augue mauris, consequat
                        a justo sed, cursus hendrerit quam. Aliquam aliquet
                        tellus blandit semper sagittis. Ut id …
                      </p>
                    </div>
                    <div className="flex items-center justify-center border-l border-gray-200">
                      <button
                        className="h-full px-3 group"
                        type="button"
                        id="buy"
                        onClick={() => {
                          display();
                        }}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#FaqVideo"
                        aria-controls="FaqVideo"
                      >
                        <KTSVG
                          className="w-4 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400 group-hover:fill-violet-100"
                          path="other_icons/Eye.svg"
                          svgClassName="w-4 h-2.5"
                        />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="single-item bg-gray-100/50 hover:bg-indigo-500/10 transition-all rounded mb-2.5">
                  <div className="flex justify-between">
                    <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                      <h4 className="text-[15px] font-semibold mb-3">
                        Have you already got an account?
                      </h4>
                      <p className="text-[13px] text-gray-600 line-clamp-1">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Morbi vel tortor ligula. Integer augue mauris, consequat
                        a justo sed, cursus hendrerit quam. Aliquam aliquet
                        tellus blandit semper sagittis. Ut id …
                      </p>
                    </div>
                    <div className="flex items-center justify-center border-l border-gray-200">
                      <button
                        className="h-full px-3 group"
                        type="button"
                        id="buy"
                        onClick={() => {
                          display();
                        }}
                        data-bs-toggle="offcanvas"
                        data-bs-target="#FaqVideo"
                        aria-controls="FaqVideo"
                      >
                        <KTSVG
                          className="w-4 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400 group-hover:fill-violet-100"
                          path="other_icons/Eye.svg"
                          svgClassName="w-4 h-2.5"
                        />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div
        className={`offcanvas buy_canvas offcanvas-end  fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium lg:max-w-[43.125rem] max-w-[calc(100%-3.75rem)] w-full `}
        tabIndex={-1}
        id="FaqVideo"
        aria-labelledby="cc"
        data-bs-backdrop="false"
        ref={canvasRef}
      >
        <div className="relative flex flex-col h-full bg-white ml-auto">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold p-5 flex-1"
              id="offcanvasRightLabel"
            >
              How do you connect/link Tixstock to a marketplace?
            </h5>
            <button
              type="button"
              className="flex items-center justify-center w-9 border-l box-content group"
              data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => display()}
            >
              <KTSVG
                className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                path="standard_icons/cross.svg"
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>
          <div className="offcanvas-body max-h-full w-full flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:p-[1.875rem] p-5">
            <p className="text-[.8125rem] text-gray-600">
              In order to connect a new marketplace to your Tixstock account
              please follow the following steps. From the menu icons select
              settings from there click exchanges. You will now be presented
              with a list of exchanges you can connect to in order to list your
              inventory. By entering your username and password this will
              connect your Tixstock inventory to the marketplace.
            </p>
            <div className="md:flex-row flex flex-col justify-between md:items-center items-start max-w-[31.625rem] my-[1.875rem] -mx-2">
              <a
                href="#"
                className="flex items-center group hover:text-indigo-500 px-2 md:my-0 my-1 text-violet-500"
              >
                <KTSVG
                  className="fill-violet-800 group-hover:fill-indigo-500 h-4 w-5 mr-2.5 transition"
                  path="other_icons/youtube.svg"
                />
                Watch the video guide
              </a>

              <a
                href="#"
                className="flex items-center group hover:text-indigo-500 px-2 md:my-0 my-1 text-violet-500"
              >
                <KTSVG
                  className="fill-violet-800 group-hover:fill-indigo-500 h-4 w-5 mr-2.5 transition"
                  path="other_icons/pdf.svg"
                />
                Download PDF guide
              </a>
            </div>

            <div className="relative pt-[calc(360_/_630_*_100%)]">
              <div className="absolute top-0 left-0 w-full h-full bg-gray-200 rounded"></div>
            </div>
          </div>
          {/* <div className="offcanvas-footer px-5 py-3 border-t flex justify-between"></div> */}
        </div>
      </div>

      {/* <Footer /> */}
    </>
  );
};
export default FaqSearch;
