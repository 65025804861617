/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef, useState } from "react";
// import { Footer, Select } from "flowbite-react";

import React from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import SearchVioletSVG from "../../../assets/media/icons/other_icons/search-violet.svg";
import PageTitle from "../../../components/core/PageTitle";
import { KTSVG } from "../../../components/ui/KTSVG";
import faqs from "../../../local-json/faqs.json";
import { commonFaq } from "../../../local-json/utis";

import { LayoutContext } from "../../../components/core/LayoutProvider";
import SideDetail from "../widget/SideDetail";
import FAQSidebarList from "./FAQSidebarList";
import InputField from "./InputField";
import TxTradeAgreeMentComponent from "./TxTradeAgreeMentComponent";

const FAQListing = () => {
  const layout = useContext(LayoutContext);

  // STATES
  const tableRef: any = useRef();

  const { slug } = useParams();
  const location = useLocation();
  const cleanQueryString = location.search.slice(1);
  const querySearch = cleanQueryString.split("?")?.[0];
  const [onChangeFlag, setOnChangeFlag] = useState(false);
  const [onChangeFlagBelow, setOnChangeFlagBelow] = useState(false);
  const [searchFAQ, setSearchFAQ] = useState<any>();
  const [showFAQ, setShowFAQ] = useState<any>({
    show: false,
    data: "",
    index: "",
  });
  const [classAdd, setClassAdd] = useState<any>();

  function removeKey(obj: any, keyToRemove: any) {
    if (obj.hasOwnProperty(keyToRemove)) {
      delete obj[keyToRemove];
    }
    return obj;
  }

  const access = layout.allowedAccess?.allowed_access;
  const txTrade = access?.some(
    (item: any) => (item.id === "txtrade" && item.status === true) || false
  );

  let filteredFaq = commonFaq.filter(
    (obj) =>
      obj?.que
        .toLowerCase()
        .includes(decodeURIComponent(searchFAQ).toLowerCase()) ||
      obj?.ans
        .toLowerCase()
        .includes(decodeURIComponent(searchFAQ).toLowerCase())
  );

  let pageFilteredFaq = commonFaq.filter(
    (obj) =>
      obj?.que
        .toLowerCase()
        .includes(decodeURIComponent(querySearch).toLowerCase()) ||
      obj?.ans
        .toLowerCase()
        .includes(decodeURIComponent(querySearch).toLowerCase())
  );

  let filteredFaqs = faqs;
  if (!txTrade) {
    filteredFaqs = removeKey(filteredFaqs, "tx-trade");
    filteredFaq = filteredFaq?.filter((obj: any) => obj?.slug !== "tx-trade");
    pageFilteredFaq = pageFilteredFaq?.filter(
      (obj: any) => obj?.slug !== "tx-trade"
    );
  }

  // IF TX TRADE USER THEN DISABLE LISTING AND SALES
  if (layout?.isTxTradeUser === 1) {
    filteredFaqs = removeKey(filteredFaqs, "listings");
    filteredFaqs = removeKey(filteredFaqs, "sale");
    filteredFaq = filteredFaq?.filter(
      (obj: any) => obj?.slug !== "listing" && obj?.slug !== "sales"
    );
    pageFilteredFaq = pageFilteredFaq?.filter(
      (obj: any) => obj?.slug !== "listing" && obj?.slug !== "sales"
    );
  }

  const handleOnChange = (data: any) => {
    setSearchFAQ(data.target.value);
    setOnChangeFlag(true);
    if (data.target.name === "searchfaqsbelow") {
      setOnChangeFlagBelow(true);
    }
  };
  const navigate = useNavigate();

  useEffect(() => {
    if (querySearch) {
      setSearchFAQ(decodeURIComponent(querySearch));
    }
  }, [querySearch]);

  const handleEnterKeyPress = (event: any) => {
    if (searchFAQ && event.key === "Enter") {
      navigate({
        pathname: "/faq/search",
        // search: `?${createSearchParams({ q: searchFAQ })}`,
        search: searchFAQ,
      });
      setSearchFAQ("");
      setOnChangeFlagBelow(false);
    }
  };

  const wrapperRef: any = useRef();
  const wrapperRefBelow: any = useRef();

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        wrapperRef.current !== null &&
        !wrapperRef.current?.contains(event.target)
      ) {
        setOnChangeFlag(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRef]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        wrapperRefBelow.current !== null &&
        !wrapperRefBelow.current?.contains(event.target)
      ) {
        setOnChangeFlagBelow(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [wrapperRefBelow]);

  // RESET SIDESHOW STATE IF SIDENMENU CHANGE
  useEffect(() => {
    if (location?.state?.status) {
      setShowFAQ("");
    }

    function findIndexOfQue(data: any, targetQue: any) {
      const matchingIndex = data?.findIndex(
        (item: any) => item.index === targetQue
      );
      return matchingIndex;
    }
    if (location?.state?.data) {
      let faqList: any = Object.values(filteredFaqs).filter(
        (item: any, index) => item?.slug === slug
      )?.[0];

      let index = findIndexOfQue(faqList?.list, location?.state?.data?.index);

      setShowFAQ({
        show: true,
        data: location?.state?.data,
        index: index,
      });
      // display(true);
    }

    //  let index = Object.keys(faqList?.[0]?.list)
  }, [location?.state]);

  const ref: any = useRef();

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setClassAdd(!classAdd);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return (
    <>
      <PageTitle title="FAQ" />
      <div className="main-content-wrap tx-pay-module">
        <div className="flex flex-wrap">
          <div
            ref={ref}
            className="sidebar lg2:max-w-[15.0625rem] border-r p-5 w-full bg-white lg2:min-h-[calc(100vh-3.9375rem)] lg2:relative lg2:top-0 sticky top-[3.75rem] z-20"
          >
            <div
              className="group lg2:hidden block mob_sb_menu relative  [&[aria-expanded='true'] .dd_arrow]:rotate-180"
              data-te-collapse-init
              data-te-collapse-collapsed={classAdd ? true : false}
              data-te-target="#collapseOne5"
              aria-expanded="false"
              aria-controls="collapseOne5"
              onClick={() => setClassAdd(false)}
            >
              <span className="block w-full border rounded px-[.9375rem] py-2.5 text-sm13 font-medium">
                {slug === "Overview" && "Overview"}
                {slug === "account-settings" && "Account & settings"}
                {slug === "listing" && "Listing"}
                {slug === "sales" && "Sales"}
                {slug === "tx-trade-policy" && "TX Trade Policy"}
                {slug === "tx-trade" && "TX Trade"}
                {slug === "payments" && "Payments"}
                {slug === "technical-support" && "Technical support"}
                {slug === "miscellaneous" && "Miscellaneous"}
                {!slug && "Please Select"}
              </span>

              <span className="dd_arrow absolute right-3 top-1/2 -translate-y-1/2 group-[[data-te-collapse-collapsed]]:rotate-0 rotate-180 transition">
                <span className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-gray-400">
                  <KTSVG
                    className="w-2.5 h-2.5 flex items-center justify-center fill-violet-500 group-[[data-te-collapse-collapsed]]:fill-violet-500"
                    path="other_icons/down-angle.svg"
                    svgClassName="w-2.5 h-2.5"
                  />
                </span>
              </span>
            </div>
            <div
              className={`${
                classAdd ? "hidden" : ""
              } sb_menus lg2:!h-auto lg2:sticky lg2:top-20 lg2:overflow-auto lg2:block hidden absolute left-0 w-full top-full lg2:p-0 px-5 z-20 bg-white`}
              id="collapseOne5"
              data-te-collapse-item
              data-te-collapse-collapsed
              aria-labelledby="headingOne5"
            >
              {filteredFaqs &&
                Object.values(filteredFaqs).map(
                  (item: any) =>
                    (item?.slug === "tx-trade-policy" ||
                      item?.slug === "help-center") && (
                      <FAQSidebarList
                        title={item.title}
                        description={item.desc}
                        path={item.icon}
                        slug={item.slug}
                        isExternalLink={item?.externalLink}
                        currentMenu={slug}
                        classAdd={classAdd}
                        setClassAdd={setClassAdd}
                      />
                    )
                )}
            </div>
          </div>
          {filteredFaqs &&
            Object.values(filteredFaqs).map(
              (item: any, index) =>
                item.slug === slug && (
                  <div className="sb_content lg2:max-w-[calc(100%-15.0625rem)] w-full bg-no-repeat bg-cover bg-center bg-white">
                    <div
                      ref={wrapperRef}
                      className="inner-searchBox-width md:h-[4rem] z-10 w-full md:flex-row flex flex-wrap items-center justify-between py-4 px-5 bg-setting-bg bg-white/40 relative before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-white/40 before:z-[-1]"
                    >
                      <span className="mb-2 md:mb-0 text-sm15 font-semibold w-full md:w-auto">
                        {item.title}
                      </span>
                      {item.title !== "TX Trade Policy" && (
                        <div className="relative flex-1 md:max-w-[27rem] w-full md:mb-0">
                          <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <button>
                              <KTSVG
                                className="fill-violet-500"
                                svgClassName="h-3.5 w-3.5"
                                path="other_icons/search-violet.svg"
                              />
                            </button>
                          </div>
                          <InputField
                            placeholder="Search FAQs"
                            autoComplete="off"
                            id="searchfaqs"
                            className="border font-medium bg-white border-indigo-500/20 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-8 pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                            name="searchfaqs"
                            imgPath={SearchVioletSVG}
                            value={searchFAQ}
                            handleOnChange={(data: any) => handleOnChange(data)}
                            onKeyPress={handleEnterKeyPress}
                          />

                          {searchFAQ && onChangeFlag && (
                            <div className="suggestion rounded shadow-op1 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full w-full bg-white">
                              <div className="sb_content  w-full bg-no-repeat bg-cover bg-center bg-white">
                                <div className="p-5 ">
                                  <div id="table-shrink" className="">
                                    {/* <div id="EventAccordion" className=""> */}
                                    {filteredFaq?.length > 0 ? (
                                      filteredFaq?.map(
                                        (list: any, fIndex: any) => {
                                          // const matchedView = showFAQ?.index === fIndex;
                                          return (
                                            <React.Fragment key={fIndex}>
                                              <div
                                                className={`single-item bg-gray-100/50 hover:bg-indigo-500/10 transition-all rounded mb-2.5  cursor-pointer`}
                                              >
                                                {/* <Link to={`/faq/${list.slug}`}> */}
                                                <div
                                                  onClick={() =>
                                                    // navigate({
                                                    //   pathname: "/faq/search",
                                                    //   search: searchFAQ,
                                                    // })
                                                    navigate(
                                                      `/faq/${list.slug}`,
                                                      {
                                                        state: {
                                                          data: list,
                                                        },
                                                      }
                                                    )
                                                  }
                                                >
                                                  <div className="flex justify-between faq-list">
                                                    <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                                                      <h4 className="text-[15px] font-semibold mb-3">
                                                        {list.que}
                                                      </h4>
                                                      <p
                                                        className="text-[13px] text-gray-600 line-clamp-1 [&>a:hover]:text-indigo-500"
                                                        dangerouslySetInnerHTML={{
                                                          __html: list.ans,
                                                        }}
                                                      >
                                                        {/* {list.ans} */}
                                                      </p>
                                                    </div>
                                                  </div>
                                                </div>
                                                {/* </Link> */}
                                              </div>
                                            </React.Fragment>
                                          );
                                        }
                                      )
                                    ) : (
                                      <p className="text-[13px] text-gray-600 line-clamp-1">
                                        No Results
                                      </p>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    {item.title === "TX Trade Policy" && (
                      <div className="p-5">
                        <TxTradeAgreeMentComponent />
                      </div>
                    )}

                    <div className={` ${showFAQ?.show ? "p-0" : "p-5"} `}>
                      {/* <div id="table-shrink" className="" ref={tableRef}> */}
                      <div id="EventAccordion" className="" ref={tableRef}>
                        {Object.values(item.list).map(
                          (list: any, fIndex: any) =>
                            // item.slug === slug &&
                            {
                              const matchedView = showFAQ?.index === fIndex;
                              //   list?.que?.toUpperCase();;
                              // const matchedView =
                              //   showFAQ?.data?.que?.toUpperCase() ===
                              //   list?.que?.toUpperCase();

                              return (
                                <React.Fragment key={fIndex}>
                                  <div
                                    className={`single-item bg-gray-100/50  transition-all rounded mb-2.5 hover:bg-indigo-500 hover:bg-opacity-[7%] ${
                                      matchedView
                                        ? "bg-indigo-500 bg-opacity-[7%]"
                                        : ""
                                    }`}
                                  >
                                    <div className="flex justify-between">
                                      <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                                        <h4 className="text-[15px] font-semibold mb-3">
                                          {list.que}
                                        </h4>
                                        <p
                                          className="text-[13px] text-gray-600 line-clamp-1 [&>a:hover]:text-indigo-500"
                                          dangerouslySetInnerHTML={{
                                            __html: list.ans,
                                          }}
                                        >
                                          {/* {list.ans} */}
                                        </p>
                                      </div>
                                      <div className="flex items-center justify-center border-l border-gray-200">
                                        <button
                                          className="h-full p-3 group"
                                          type="button"
                                          id={`view-${fIndex}`}
                                          onClick={() => {
                                            // display(true);
                                            setShowFAQ({
                                              show: true,
                                              data: list,
                                              index: fIndex === 0 ? 0 : fIndex,
                                            });
                                          }}
                                          // data-bs-toggle="offcanvas"
                                          // data-bs-target="#FaqVideo"
                                          // aria-controls="FaqVideo"
                                        >
                                          <KTSVG
                                            className={`w-4 h-2.5 flex items-center justify-center group-[[data-te-collapse-collapsed]]:fill-gray-400 group-hover:fill-violet-100 ${
                                              matchedView
                                                ? "fill-indigo-500"
                                                : "fill-violet-500"
                                            }`}
                                            path="other_icons/Eye.svg"
                                            svgClassName="w-4 h-2.5"
                                          />
                                        </button>
                                        <Tooltip
                                          anchorId={`view-${fIndex}`}
                                          content={"View"}
                                          place="top"
                                          variant="light"
                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium mt-10 z-1000 "
                                          positionStrategy="fixed"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </React.Fragment>
                              );
                            }
                        )}
                      </div>
                    </div>
                  </div>
                )
            )}

          {filteredFaqs && querySearch && (
            <div className="sb_content lg2:max-w-[calc(100%-15.0625rem)] w-full bg-no-repeat bg-cover bg-center bg-white">
              <div
                ref={wrapperRefBelow}
                className="inner-searchBox-width z-10 w-full md:flex-row flex flex-col flex-wrap items-center justify-between py-4 px-5 bg-setting-bg bg-white/40 relative before:content-[''] before:absolute before:left-0 before:top-0 before:w-full before:h-full before:bg-white/40 before:z-[-1]"
              >
                <span className="mb-2 md:mb-0">
                  {/* {filteredFaq?.length
                    ? `Search results for "${decodeURIComponent(querySearch)}"`
                    : `No results found for "${decodeURIComponent(
                        querySearch
                      )}"`} */}

                  {`Search results for "${decodeURIComponent(querySearch)}"`}
                </span>
                <div className="relative flex-1 md:max-w-[27rem] w-full md:mb-0">
                  <div className="absolute inset-y-0 left-0 flex items-center pl-3">
                    <button>
                      <KTSVG
                        className="fill-violet-500"
                        svgClassName="h-3.5 w-3.5"
                        path="other_icons/search-violet.svg"
                      />
                    </button>
                  </div>
                  <InputField
                    placeholder="Search FAQs"
                    autoComplete="off"
                    id="searchfaqs"
                    className="border font-medium bg-white border-indigo-500/20 placeholder-gray-500 text-md rounded focus:ring-violet-700 focus:border-ring-violet-700 text-sm15 block w-full h-8 pl-9 py-1  dark:bg-gray-700 dark:border-gray-600  dark:placeholder-gray-400 dark:text-white "
                    name="searchfaqsbelow"
                    imgPath={SearchVioletSVG}
                    value={searchFAQ}
                    handleOnChange={(data: any) => handleOnChange(data)}
                    onKeyPress={handleEnterKeyPress}
                  />
                  {searchFAQ && onChangeFlagBelow && (
                    <div className="suggestion rounded shadow-op1 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full w-full bg-white">
                      <div className="sb_content  w-full bg-no-repeat bg-cover bg-center bg-white">
                        <div className={` ${showFAQ?.show ? "p-0" : "p-5"} `}>
                          {/* <div id="table-shrink" className=""> */}
                          <div id="EventAccordion" className="">
                            {filteredFaq?.length > 0 ? (
                              filteredFaq?.map((list: any, fIndex: any) => {
                                // const matchedView = showFAQ?.index === fIndex;
                                return (
                                  <React.Fragment key={fIndex}>
                                    <div
                                      className={`single-item bg-gray-100/50 hover:bg-indigo-500/10 transition-all rounded mb-2.5 cursor-pointer `}
                                    >
                                      {/* <Link to={`/faq/${list.slug}`}> */}
                                      <div
                                        onClick={() => {
                                          // navigate({
                                          //   pathname: "/faq/search",
                                          //   search: searchFAQ,
                                          // });
                                          navigate(`/faq/${list.slug}`, {
                                            state: {
                                              data: list,
                                              index: fIndex,
                                            },
                                          });
                                          setOnChangeFlagBelow(false);
                                        }}
                                      >
                                        <div className="flex justify-between faq-list">
                                          <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                                            <h4 className="text-[15px] font-semibold mb-3">
                                              {list.que}
                                            </h4>
                                            <p
                                              className="text-[13px] text-gray-600 line-clamp-1 [&>a:hover]:text-indigo-500"
                                              dangerouslySetInnerHTML={{
                                                __html: list.ans,
                                              }}
                                            >
                                              {/* {list.ans} */}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {/* </Link> */}
                                    </div>
                                  </React.Fragment>
                                );
                              })
                            ) : (
                              <p className="text-[13px] text-gray-600 line-clamp-1">
                                No Resutls
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className={` ${showFAQ?.show ? "p-0" : "p-5"} `}>
                {/* <div id="table-shrink" className="" ref={tableRef}> */}
                <div id="EventAccordion" className="" ref={tableRef}>
                  {pageFilteredFaq?.map((list: any, fIndex: any) => {
                    const matchedView = showFAQ?.index === fIndex;
                    // const matchedView =
                    //   showFAQ?.data?.que?.toUpperCase() ===
                    //   list?.que?.toUpperCase();
                    return (
                      <React.Fragment key={fIndex}>
                        <div
                          className={`single-item bg-gray-100/50  transition-all rounded mb-2.5 hover:bg-indigo-500 hover:bg-opacity-[7%] ${
                            matchedView ? "bg-indigo-500 bg-opacity-[7%]" : ""
                          }`}
                        >
                          <div className="flex justify-between">
                            <div className="accordion_wrap px-5 py-[1.125rem] flex-1">
                              <h4 className="text-[15px] font-semibold mb-3">
                                {list.que}
                              </h4>
                              <p
                                className="text-[13px] text-gray-600 line-clamp-1 [&>a:hover]:text-indigo-500"
                                dangerouslySetInnerHTML={{
                                  __html: list.ans,
                                }}
                              >
                                {/* {list.ans} */}
                              </p>
                            </div>
                            <div className="flex items-center justify-center border-l border-gray-200">
                              <button
                                className="h-full p-3 group"
                                type="button"
                                id="buy"
                                onClick={() => {
                                  // display(true);
                                  setShowFAQ({
                                    show: true,
                                    data: list,
                                    // index: fIndex,
                                    index: fIndex === 0 ? 0 : fIndex,
                                  });
                                }}
                                // data-bs-toggle="offcanvas"
                                // data-bs-target="#FaqVideo"
                                // aria-controls="FaqVideo"
                              >
                                <KTSVG
                                  className={`w-4 h-2.5 flex items-center justify-center  group-[[data-te-collapse-collapsed]]:fill-gray-400 group-hover:fill-violet-100 ${
                                    matchedView
                                      ? "fill-indigo-500"
                                      : "fill-violet-500"
                                  }`}
                                  path="other_icons/Eye.svg"
                                  svgClassName="w-4 h-2.5"
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    );
                  })}
                </div>
                {pageFilteredFaq?.length === 0 && (
                  <p className="text-[15px] font-semibold mb-3 flex items-center justify-center mt-20 ">
                    No results found for {`"${querySearch}"`}
                  </p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      {/* SIDE DETAIL PAGE */}
      {showFAQ?.show && (
        <SideDetail
          showFAQ={showFAQ}
          tableRef={tableRef}
          faqList={Object.values(filteredFaqs).filter(
            (item: any, index) => item.slug === slug
          )}
          setShowFAQ={setShowFAQ}
          filteredFaq={filteredFaq}
          handleClose={() => {
            setShowFAQ("");
            if (location?.search) {
              navigate({
                pathname: "/faq/search",
                search: searchFAQ,
              });
            } else {
              navigate(location.pathname, {});
            }
          }}
        />
      )}

      {/* <Footer /> */}
    </>
  );
};
export default FAQListing;
