import React from "react";
import PageTitle from "../../components/core/PageTitle";
import Sale from "./Sale";
import { SalesProvider } from "./core/SalesProvider";
import Footer from "./layout/Footer";

const SalesWrapper = () => {
  return (
    <>
      <SalesProvider>
        <PageTitle title="Sales" />
        <Sale />
        <Footer />
      </SalesProvider>
    </>
  );
};

export default SalesWrapper;
