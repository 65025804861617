import PageTitle from "../../../components/core/PageTitle";
import { WalletAndBankDetailsProvider } from "./core/WalletAndBankDetailsProvider";
import WalletAndBankDetails from "./WalletAndBankDetails";

const WalletAndBankDetailsWrapper = () => {
  return (
    <>
      <WalletAndBankDetailsProvider>
        <PageTitle title="Wallets & Bank details" />
        <WalletAndBankDetails />
      </WalletAndBankDetailsProvider>
    </>
  );
};

export default WalletAndBankDetailsWrapper;
