import _ from "lodash";
import axios from "../../../axois/SetupAxios";

// ACTIVITY LISTING
const getActivityListing = (data: any): Promise<any> => {
  return axios
    .post(`dashboard/event-lists`, data)
    .then((response) => response.data);
};

// SALES LISTING
const getSalesListing = (data: any): Promise<any> => {
  let payload = {
    duration: data?.optionsData,
    page: data?.page,
    ...(_.size(data?.team_id) > 0 && {
      team_id: _.map(data?.team_id, "id"),
    }),
  };
  return axios
    .post(`dashboard/sales`, payload)
    .then((response) => response.data);
};

// EVENTS LISTING
const getEventsListing = (data: any): Promise<any> => {
  return axios.post(`dashboard/events`, data).then((response) => response.data);
};

// TO BE FULFILLED LISTING
const getfulfilledListing = (data: any): Promise<any> => {
  const teamValue = data?.team_id
    ? _.map(data?.team_id, "id")
    : _.map(data?.optionsData?.team_id, "id");
  let payload = {
    duration: data?.optionsData?.id,
    page: data?.optionsData?.page,
    ...(_.size(teamValue) > 0 && {
      team_id: teamValue,
    }),
  };
  return axios
    .post(`dashboard/tobefulfilled`, payload)
    .then((response) => response.data);
};

// TX TRADE TRACKED LISTINGS DATA

const getTrackedListings = (data: any): Promise<any> => {
  const teamValue = data?.team_id
    ? _.map(data?.team_id, "id")
    : _.map(data?.optionsData?.team_id, "id");
  let payload = {
    page: data?.page,
    ...(_.size(teamValue) > 0 && {
      team_id: teamValue,
    }),
  };
  return axios
    .post(`dashboard/tracked-listings`, payload)
    .then((response) => response.data);
};

// TX TRADE TRACKED LISTINGS DATA

const getPurchaseListings = (data: any): Promise<any> => {
  const teamValue = data?.team_id
    ? _.map(data?.team_id, "id")
    : _.map(data?.optionsData?.team_id, "id");
  let payload = {
    page: data?.page,
    per_page: data?.per_page,
    ...(_.size(teamValue) > 0 && {
      team_id: teamValue,
    }),
  };
  return axios
    .post(`tx-trade/purchase`, payload)
    .then((response) => response.data);
};

// PRICE MOVE  LISTING
const getPriceListing = (data: any): Promise<any> => {
  return axios
    .post(`dashboard/pricemove`, data)
    .then((response) => response.data);
};

// Latest News Listing
const getNewsListing = (data: any): Promise<any> => {
  let payload = {
    category: data?.newsOption?.id,
    paged: data?.newsOption?.page,
  };
  return axios
    .post(`dashboard/industry-news`, payload)
    .then((response) => response.data);
};

// Latest News Listing
const getUserDetail = (): Promise<any> => {
  return axios.get(`settings/account/info`).then((response) => response.data);
};

// GET CATEGORY LIST
const getCategoryList = (data: any): Promise<any> => {
  let params = {
    duration: data,
  };
  return axios
    .get(`dashboard/event-sell`, {
      params: params,
    })
    .then((response) => response.data);
};
const getTopSellingEvents = (data: any): Promise<any> => {
  let teamMembers = _.map(data?.team_id, "id");
  let category = data?.data?.topSellingEvents?.id;
  let payload = {
    ...(category && { category_id: category }),
    ...(teamMembers && _.size(teamMembers) > 0 && { team_id: teamMembers }),
    page: data?.page,
    per_page: 50,
    duration: data?.data?.duration,
  };
  return axios
    .post(`dashboard/event-sell`, payload)
    .then((response) => response.data);
};

const userDetailSubmit = async (data: any): Promise<any> => {
  let payload = {
    withdrawal: {
      bank_name: data?.withdrawal_bank_name,
      ac_holder_name: data?.withdrawal_ac_holder_name,
      swift: data?.withdrawal_swift,
      iban: data?.withdrawal_iban,
      ac_number: data?.withdrawal_ac_number,
      branch_code: data?.withdrawal_branch_code,
      bank_ac_currency: data?.withdrawal_bank_ac_currency,
      bank_account_country_code:
        data?.withdrawal_bank_ac_country_code?.originalId,
      recipient_country_code: data?.recipient_country_code?.originalId,
      recipient_street: data?.recipient_street,
      recipient_city: data?.recipient_city,
      recipient_state: data?.recipient_state,
      recipient_postcode: data?.recipient_postcode,
    },
    ...(data?.use_as_same
      ? {
          funding: {
            bank_name: data?.withdrawal_bank_name,
            ac_holder_name: data?.withdrawal_ac_holder_name,
            swift: data?.withdrawal_swift,
            iban: data?.withdrawal_iban,
            ac_number: data?.withdrawal_ac_number,
            branch_code: data?.withdrawal_branch_code,
          },
        }
      : {
          funding: {
            bank_name: data?.funding_bank_name,
            ac_holder_name: data?.funding_ac_holder_name,
            swift: data?.funding_swift,
            iban: data?.funding_iban,
            ac_number: data?.funding_ac_number,
            branch_code: data?.funding_branch_code,
          },
        }),
    ...(data?.proofTaken === "business_proof" &&
      data?.business_proof && { business_proof: data?.business_proof }),
    ...(data?.proofTaken === "identity_proof" &&
      data?.identity_proof && {
        identity_proof: data?.identity_proof,
      }),
  };

  delete payload.withdrawal_bank_identifier;
  delete payload.funding_bank_identifier;

  const response = await axios.post(`dashboard/tx-pay-info`, payload);
  return response.data;
};

// identity_proof
const deleteServerFile = (data: any): Promise<any> => {
  let payload = {
    document_type:
      data?.value === "indentity" ? "identity_proof" : "business_proof",
    index: data?.data?.index,
  };
  return axios
    .post(`dashboard/tx-pay-info-document`, payload)
    .then((response) => response.data);
};

export {
  deleteServerFile,
  getActivityListing,
  getCategoryList,
  getEventsListing,
  getNewsListing,
  getPriceListing,
  getPurchaseListings,
  getSalesListing,
  getTopSellingEvents,
  getTrackedListings,
  getUserDetail,
  getfulfilledListing,
  userDetailSubmit,
};
