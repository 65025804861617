import { useCustomize } from "./useCustomize";

export const TabData = () => {
  const { isTabActive, contentFilter } = useCustomize();

  const stateReturn = (type: string) => {
    switch (type) {
      case "filterChip":
        return isTabActive === "content_page" ? contentFilter : "";

      default:
        break;
    }
  };

  return { stateReturn, isTabActive };
};
