const TableShimmer = ({ loader }: any) => {
  return (
    <>
      {Array.from({ length: 10 }, (v, i) => (
        <div key={i} className={`accordion font-medium !mb-[0.625rem] mx-5`}>
          <div
            key={i}
            className={`accordion-item bg-white !rounded overflow-hidden  ${
              loader && "shimmer-effect"
            }`}
            style={{ height: "40px" }}
          >
            &nbsp;
          </div>
        </div>
      ))}
    </>
  );
};

export default TableShimmer;
