import { useContext } from "react";
import { SalesContext } from "../../../core/SalesProvider";
import EventDetails from "./EventDetails";
import HighligtedFilter from "./HighligtedFilter";
import OrderNewListingFilterChip from "./OrderNewListingFilterChip";
import OrderNewListingFilters from "./OrderNewListingFilters";

interface FilterContainerNewListingInterface {
  categoryOptions?: any;
  paginateData?: any;
}

const FilterContainerNewListing: React.FC<
  FilterContainerNewListingInterface
> = ({ categoryOptions, paginateData }: any) => {
  const layout = useContext(SalesContext);
  let orderAssign = layout?.orderAssign?.data;
  
  return (
    <>
      {/* EVENT DETAILS */}
      <EventDetails data={layout?.orderAssign?.data}/>

      {/* FILTER HIGHLIGHTED  */}
      <HighligtedFilter data={orderAssign}/>

      {/* FILTERS */}
      <OrderNewListingFilters categoryOptions={categoryOptions} data={orderAssign} />

      {/* FILTER CHIPS */}
      <OrderNewListingFilterChip paginateData={paginateData} />
    </>
  );
};

export default FilterContainerNewListing;
