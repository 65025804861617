import axios from "../../../axois/SetupAxios";

const getListings = async (data: any): Promise<any> => {
  let status = data?.status;
  let query = data?.query;
  let per_page = data?.per_page;
  let page = data?.page;
  let order_by = data?.order_by;
  let sort_order = data?.sort_order;

  let payload = {
    page,
    per_page,
     status: status,
    ...(query && { query }),
    ...(order_by && sort_order && { order_by, sort_order }),
  };
  const response = await axios.get('sales/instruction-letter-template/listing', {params: payload});

  return {
    ...response.data,
    counts: response.data?.meta?.total
  };
};

// UPDATE INSTRUCTION
const updateInstruction = async (data: any) => {
  const response = await axios.post("sales/instruction-letter-template/edit", data);
  return response.data;
};

// UPDATE FORM DATA
const fetchInstructioData = async (id: any): Promise<any> => {
  const response = await axios.get(`sales/instruction-letter-template/edit/${id}`);
  return response.data;
};

// DELETE TEMPLATE
const deleteTemplate = async (id: any) => {
  const response = await axios.post(`sales/instruction-letter-template/delete/${id}`)
  return response.data; 
};

export {
  getListings,
  updateInstruction,
  fetchInstructioData,
  deleteTemplate
};

