/* eslint-disable react-hooks/exhaustive-deps */
import Pusher from "pusher-js";
import { useEffect } from "react";

import Cookies from "js-cookie";
import { AUTHDATA, CHANNEL_NEW_NOTIFICATION_EVENT_ID } from "../../helpers/const";

const PusherSetup = ({ refetch }: any) => {

    let broadcastId = AUTHDATA?.broadcast_id

    useEffect(() => {

        let getAuthData = Cookies.get('authData') as string
        let allAutData = getAuthData ? JSON.parse(getAuthData) : {}
        broadcastId = allAutData?.broadcast_id

        if (broadcastId) {
            const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY as string, {
                cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER as string,
            });

            pusher.connection.bind("error", function (err: any) {
                if (err?.error?.data?.code === 4004) {
                    console.count("Over limit!");
                }
            });

            const channel = pusher.subscribe(broadcastId);

            channel.bind(
                CHANNEL_NEW_NOTIFICATION_EVENT_ID,
                function (data: any) {
                    //refetch()
                }
            );

            channel.bind_global(function (event: any, data: any) {
            });

            return () => {
                pusher.disconnect();
                pusher.unsubscribe(broadcastId);
                channel.unbind();
            };
        }


    }, []);

    return <></>;
};

export default PusherSetup;
