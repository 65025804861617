import axios from "axios";
import _, { size } from "lodash";
import moment from "moment";

// SELLER WALLET
const getTXAccountSellerWalletListings = async (data: any): Promise<any> => {
  let team_id =
    data?.sellerWalletsFilter?.teams &&
    _.map(data?.sellerWalletsFilter?.teams, "id");
  let statuses =
    data?.sellerWalletsFilter?.statuses &&
    _.map(data?.sellerWalletsFilter?.statuses, "id");
  let sort_order = data?.sellerWalletsFilter?.sort_order;
  let order_by = data?.sellerWalletsFilter?.order_by;
  let page = data?.sellerWalletsFilter?.page;
  let per_page = data?.sellerWalletsFilter?.per_page;

  let payload = {
    ...(size(team_id) > 0 && {
      team_id,
    }),
    ...(size(statuses) > 0 && {
      statuses,
    }),
    ...(sort_order && order_by && { sort_order }),
    ...(sort_order && sort_order && { order_by }),
    page,
    per_page,
  };
  const response = await axios.post(`tx-accounts/seller-wallets`, payload);
  return response.data;
};

// BANK LISTINGS
const getTXAccountBankAccountListings = async (data: any): Promise<any> => {
  let id = data?.bankAccountFilter?.id;

  let team_id =
    data?.bankAccountFilter?.teams &&
    _.map(data?.bankAccountFilter?.teams, "id");
  let sort_order = data?.bankAccountFilter?.sort_order;
  let order_by = data?.bankAccountFilter?.order_by;
  let page = data?.bankAccountFilter?.page;
  let per_page = data?.bankAccountFilter?.per_page;
  let txpay_change_request =
    data?.bankAccountFilter?.txpay_change_request === true ? 1 : 0;
    let is_withdrawal_acc_configured = data?.bankAccountFilter?.is_withdrawal_acc_configured?.value

  let payload = {
    ...(size(team_id) > 0 && {
      team_id,
    }),
    ...(id && { id }),
    txpay_change_request: txpay_change_request,
    ...(sort_order && order_by && { sort_order }),
    ...(sort_order && sort_order && { order_by }),
    page,
    per_page,
    ...(is_withdrawal_acc_configured!==null&&is_withdrawal_acc_configured!==undefined &&{is_withdrawal_acc_configured})
  };
  const response = await axios.post(`tx-accounts/bank-accounts`, payload);
  return response.data;
};

// WITHDRAW REQUEST
const getTXAccountWithdrawRequestListings = async (data: any): Promise<any> => {
  let team_id =
    data?.withdrawRequestFilter?.teams &&
    _.map(data?.withdrawRequestFilter?.teams, "id");
  let statuses =
    data?.withdrawRequestFilter?.statuses &&
    _.map(data?.withdrawRequestFilter?.statuses, "id");
  let sort_order = data?.withdrawRequestFilter?.sort_order;
  let order_by = data?.withdrawRequestFilter?.order_by;
  let page = data?.withdrawRequestFilter?.page;
  let per_page = data?.withdrawRequestFilter?.per_page;
  let from_request_date: any =
    data?.withdrawRequestFilter?.requestedDate?.startDate &&
    moment(data?.withdrawRequestFilter?.requestedDate?.startDate).format(
      "YYYY-MM-DD"
    );
  let txpay_change_request = data?.withdrawRequestFilter?.txpay_change_request
    ? 1
    : 0;

  let to_request_date: any =
    data?.withdrawRequestFilter?.requestedDate?.endDate &&
    moment(data?.withdrawRequestFilter?.requestedDate?.endDate).format(
      "YYYY-MM-DD"
    );

  let payload = {
    txpay_change_request,
    ...(size(team_id) > 0 && {
      team_id,
    }),
    ...(size(statuses) > 0 && {
      statuses,
    }),
    ...(sort_order && order_by && { sort_order }),
    ...(sort_order && sort_order && { order_by }),
    ...(from_request_date && to_request_date && { from_request_date }),
    ...(to_request_date && from_request_date && { to_request_date }),
    page,
    per_page,
  };
  const response = await axios.post(`tx-accounts/withdraw-requests`, payload);
  return response.data;
};

// REFRESH WALLET
const addBankWallet = async (data: any, id: any): Promise<any> => {
  let payload = {
    action: data,
  };
  const response = await axios.get(`tx-accounts/add-seller-wallet/${id}`, {
    params: payload,
  });
  return response.data;
};

// ADD BANK ACCOUNT
const addBankAccount = async (data: any): Promise<any> => {
  let payload = {
    ...data,
  };
  const response = await axios.post(`tx-accounts/add-bank-account`, payload);
  return response.data;
};

// APPROVE REQUEST
const approveWithdrawalRequest = async (data: any): Promise<any> => {
  const response = await axios.get(`tx-accounts/process-withdrawal`, {
    params: { withdraw_request_id: data },
  });
  return response.data;
};

// CHANGE REQUEST
const changeRequest = async (data: any): Promise<any> => {
  const response = await axios.post(`tx-accounts/change-request-action`, data);
  return response.data;
};

// ADD KYC FILES
const uploadKycFiles = async (data: any): Promise<any> => {
  const response  = await axios.post(`subscribers/${data?.team_id}/kyc-docs/add`, {
    kyc_docs: data?.fileData
  })
  return response.data
}

// DELETE KYC FILE
const deleteKycFile = async (data: any): Promise<any> => {
  const response = await axios.post(`subscribers/${data?.team_id}/kyc-docs/remove`,{
    key: data?.key?.split("/").pop(),
    file_name: data?.name
  })
  return response.data
}

export {
  addBankAccount,
  addBankWallet,
  approveWithdrawalRequest,
  changeRequest, deleteKycFile, getTXAccountBankAccountListings,
  getTXAccountSellerWalletListings,
  getTXAccountWithdrawRequestListings,
  uploadKycFiles
};

