const SignUpRadio = ({
  title,
  id,
  value,
  handleSeller,
  name,
  postKey,
  error,
  labelPad = false,
  mobileHalfWidth = false,
}: any) => {
  // const[checked, setChecked]= useState<boolean>()

  return (
    <>
      <div
        className={`type  ${
          mobileHalfWidth
            ? "w-1/2 px-[.4688rem] md:px-2.5"
            : "w-full md:w-1/2 px-2.5"
        }  md:mb-2.5 mb-[.9375rem]`}
      >
        <label
          htmlFor={id}
          className={` rounded flex  circle_checkbox cursor-pointer relative ${
            labelPad ? "!px-0" : ""
          }`}
        >
          <input
            className="form peer mr-4 h-5 w-5 rounded-full checked:bg-transparent p-1 transition cursor-pointer border checked:border-indigo-500 border-gray-300 hover:border-indigo-500 focus:ring-0 focus:ring-offset-0 absolute top-1/2 -translate-y-1/2 left-[.9375rem]"
            id={id}
            type="radio"
            name={name}
            checked={value === postKey ? true : false}
            onChange={(e: any) => {
              handleSeller({
                name: name,
                value: postKey,
              });
            }}
          />
          <span className="peer-checked:opacity-100 check w-3 h-3 rounded-full bg-indigo-500 absolute top-1/2 -translate-y-1/2 left-[1.1875rem]"></span>
          <h4
            className={`border text-sm15 font-basier_med leading-5 px-[.9375rem] py-[.5625rem] pl-11 peer-checked:border-indigo-500 w-full ${
              error ? "border-rose-500" : "peer-checked:bg-indigo-500"
            } peer-checked:bg-opacity-[7%] rounded bg-gray-100/50`}
          >
            {title}
          </h4>
        </label>
      </div>
    </>
  );
};

export default SignUpRadio;
