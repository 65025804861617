import React from "react";

interface MobileViewListingBlockInterface {
  id?: string;
  className: string;
  value: any;
  placeholder: string;
  "data-sybmol-attribute"?: string;
  isError?: string;
}

const MobileViewListingBlock: React.FC<MobileViewListingBlockInterface> = ({
  id,
  className,
  value,
  placeholder,
  "data-sybmol-attribute": dataSymbolAttribute  = "",
  isError,
}: any) => {
  return (
    <div
      id={id}
      className={`${className} ${
        isError ? "text-rose-500" : value ? "" : "text-gray-400"
      }`}
      data-sybmol-attribute={dataSymbolAttribute}
    >
      {value ? value : placeholder}
    </div>
  );
};

export default MobileViewListingBlock;
