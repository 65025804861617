export const RefreshIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      id="refresh"
      d="M5.763,5.762A6,6,0,1,1,4.2,11.5h1.56A4.5,4.5,0,1,0,10,5.5,4.436,4.436,0,0,0,6.835,6.835L9.25,9.25H4V4Z"
      transform="translate(-4 -4)"
      fill=""
    />
  </svg>
);
