import { useContext, useEffect, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SVG from "react-inlinesvg";
import { Tooltip } from "react-tooltip";
import DragSVG from "../../../assets/media/icons/other_icons/dragg.svg";
import RefreshSVG from "../../../assets/media/icons/other_icons/refresh.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { ADD_INVENTORY_KEYBOARD_ITEMS } from "../../../helpers/const";
import { AddInventoryContext } from "../core/AddInventoryProvider";

const KeyboardFilterDrag = ({ refetch }: any) => {
  const layout = useContext(AddInventoryContext);
  const keyboardField = layout.keyboardField;
  let keyboardItemsLength: any;
  keyboardItemsLength = layout.keyboardItems.length * 30;
  const columnRef: any = useRef<any>();

  //Function For Handling Keyboard Items Search
  const handleKeyboardItemSearch = (e: any) => {
    layout.setKeyboardItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return {
              ...item,
              searched: true,
            };
          } else {
            return {
              ...item,
              searched: false,
            };
          }
        });
      return updatedArray;
    });
  };

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items: any = reorder(
        layout.keyboardItems,
        source.index,
        destination.index
      );
      layout.setKeyboardItems([...items]);
      layout.setDraggedItems(items);
      setTimeout(() => {
        refetch();
      }, 1);
    }
  };

  // Save Draggable Keyboard Items Data to Local Storage
  const saveKeyboardItems = () => {
    localStorage.setItem(
      "saveKeyboardItems",
      JSON.stringify(layout.keyboardItems)
    );
  };

  useEffect(() => {
    // saveKeyboardItems();
  }, [layout.keyboardItems]);

  const handleResetKeyboardItems = () => {
    columnRef.current.value = "";
    layout.setKeyboardItems(ADD_INVENTORY_KEYBOARD_ITEMS);
    layout.setDraggedItems([]);
    setTimeout(() => {
      refetch();
    }, 1);
  };
  const allSearchedTrue = layout.keyboardItems.every(
    (item) => item.searched === false
  );
  // Dropdown position when right side
  const ddMenu = () => {
    const elements = document.querySelectorAll(".dd_large");
    elements.forEach((element: any) => {
      const parentWidth = window.innerWidth;
      const offsetRight =
        parentWidth - (element.offsetLeft + element.offsetWidth);
      if (offsetRight < 16) {
        element.classList.add("rightDD");
      } else {
        element.classList.remove("rightDD");
      }
    });
  };

  useEffect(() => {
    ddMenu();
    window.addEventListener("click", ddMenu);
    return () => {
      window.removeEventListener("click", ddMenu);
    };
  }, []);

  return (
    <>
      <div className="ba_search p-2.5 border-b">
        <div className="relative">
          <input
            type="text"
            placeholder="Search keyboard items"
            id="search"
            ref={columnRef}
            className="rounded  bg-violet-100/5 border-violet-100/20 w-full h-[1.875rem] text-sm12 px-2.5 py-2"
            onChange={handleKeyboardItemSearch}
          />
          {columnRef?.current?.value && (
            <button
              id="keyboard_items_clear"
              type="button"
              tabIndex={-1}
              className={` w-5 h-5 absolute top-1/2 -translate-y-1/2 right-2.5 flex items-center justify-center fill-violet-500 group-hover:fill-white bg-white rounded-full   hover:fill-white cursor-pointer hover:bg-violet-500  transition-all`}
              onClick={() => {
                if (columnRef?.current) {
                  columnRef.current.value = "";
                  handleResetKeyboardItems();
                }
              }}
            >
              <Tooltip
                anchorId={`keyboard_items_clear`}
                content={`Clear`}
                positionStrategy="fixed"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
              />
              <SVG src={CrossSVG} className={"w-2 h-2"} />
            </button>
          )}
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any, snapshot: any) => {
            return (
              <div
                className="cb-block border-b px-2.5 py-2"
                {...provided.droppableProps}
                ref={provided.innerRef}
                key={snapshot}
              >
                {!allSearchedTrue ? (
                  <ul className={`min-h-[${keyboardItemsLength}px] pb-3`}>
                    {Array.isArray(layout.keyboardItems) &&
                      layout.keyboardItems?.map((item: any, index: any) => {
                        let ticketType =
                          keyboardField?.eventRecords?.[0]?.ticketType;

                        // SKIP RENDER CONDITIONALLY
                        if (
                          ticketType?.id !== "eticket" &&
                          item.name === "Sub Ticket type"
                        ) {
                          return null; // Skip this item
                        }
                        if (
                          ticketType?.id !== "paper" &&
                          item.name === "Delivery type"
                        ) {
                          return null; // Skip this item
                        }
                        return (
                          <li
                            className={`font-medium empty:min-h-0 min-h-[1.125rem]`}
                            key={index}
                          >
                            {item.searched && (
                              <Draggable
                                draggableId={item.name}
                                key={item.id}
                                index={index}
                                isDragDisabled={!item.isDraggable}
                              >
                                {(provided: any, snapshot: any) => (
                                  <div
                                    className="singe_cb flex justify-between items-center pb-3"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                  >
                                    <div className="inline-flex items-center text-violet-800transition font-medium rounded">
                                      <div
                                        className="drag_icon"
                                        {...provided.dragHandleProps}
                                      >
                                        <IKTSVG
                                          className="min-w-[0.8125rem] max-w-[0.8125rem] flex items-center justify-center fill-gray-400  transition"
                                          path={DragSVG}
                                        />
                                      </div>

                                      <label
                                        htmlFor={`report-filter-${item.id}`}
                                        className="ml-2 text-sm12 font-medium dark:text-gray-300"
                                      >
                                        {" "}
                                        {item.name}
                                      </label>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            )}
                          </li>
                        );
                      })}
                  </ul>
                ) : (
                  <div className="singe_cb flex justify-center items-center anim-fade">
                    <label className="ml-2 text-sm12 font-medium dark:text-gray-300">
                      {" "}
                      No data found
                    </label>
                  </div>
                )}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <div className="restore-btn px-2.5 py-2">
        <button
          type="button"
          className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
          onClick={() => handleResetKeyboardItems()}
        >
          <IKTSVG
            className="min-w-4 max-w-4 mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
            path={RefreshSVG}
          />
          Restore defaults
        </button>
      </div>
    </>
  );
};

export default KeyboardFilterDrag;
