import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { size } from "lodash";
import moment from "moment";
import { TableVirtuoso } from "react-virtuoso";
import { ConnectorContext } from "../core/ConnectorProvider";
import DataCell from "./component/DataCell";
import EventDataCell from "./component/EventDataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left   "
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 border_th"
    style={{
      ...style,
    }}
  />
);

function getNewEvents(data: any) {
  return data
    .map((item: any) => {
      if (size(item?.hits) > 0) {
        return {
          tixstockEventId: item?.hits[0]?.id,
          externalEventId: item.id,
          currency: item?.currency,
        };
      }
      return null;
    })
    .filter(Boolean);
}

function updateMappedEvents(newEvents: any) {
  return (current: any) => {
    // Create a map for easy lookup of existing events
    const currentEventsMap = new Map(
      current.map((event: any) => [event.externalEventId, event])
    );

    // Merge new events with existing ones, prioritizing new events
    const updatedEvents = [
      ...newEvents.map(
        (item: any) => currentEventsMap.get(item.externalEventId) || item
      ),
      ...current.filter(
        (item: any) =>
          !newEvents.some(
            (event: any) => event.externalEventId === item.externalEventId
          )
      ),
    ];

    return updatedEvents;
  };
}

const EventsMappingTable = ({ data }: any) => {
  const layout = useContext(ConnectorContext);

  const tableRef: any = useRef();

  const accordionRef: any = useRef<any>();

  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  const selectEventsFromData = useMemo(() => getNewEvents(data), [data]);

  useEffect(() => {
    const element = document.getElementById("tableId2");
    if (element) {
      const elem1 = document.querySelectorAll("#left-shad-list");

      element.addEventListener("scroll", () => {
        const elementScrollLeft = element.scrollLeft;
        const elementScrollRight =
          element.scrollWidth - element.clientWidth - elementScrollLeft;

        elem1.forEach((element: any, index: any) => {
          elementScrollRight === 0
            ? element.classList.remove("left-shad")
            : element.classList.add("left-shad");
        });
      });
    }
  });

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion2 = document.getElementById(
        "EventAccordion2"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive

      if (topfilterTop?.style) {
        topfilterTop.style.top = `${newValue}px`; // Apply the new positive value back as a string
      }
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      const totleScroll = headingOne + inventoryaccordion + 16;

      const stickyHead: any = document.getElementById("sticky-head");
      const filterChipArea = document.querySelector(
        ".filterChipArea"
      ) as HTMLElement;
      let filterChipAreaWidth = 0;
      let width = 0;

      function getBodyScrollbarWidth() {
        const filterChipArea = document.querySelector(
          ".filterChipArea"
        ) as HTMLElement;
        // Creating a div element
        const scrollDiv = document.createElement("div");
        scrollDiv.style.width = "100px";
        scrollDiv.style.height = "100px";
        scrollDiv.style.overflow = "scroll";
        scrollDiv.style.position = "absolute";
        scrollDiv.style.top = "-9999px"; // Hide the div off-screen

        // Append the div to the document body
        filterChipArea?.appendChild(scrollDiv);
        // Calculate the body scrollbar width
        const scrollbarWidth =
          filterChipArea?.offsetHeight - filterChipArea.clientHeight;
        // Remove the div from the document
        filterChipArea.removeChild(scrollDiv);
        return scrollbarWidth;
      }
      const bodyScrollbarWidth = getBodyScrollbarWidth();

      if (
        filterChipArea &&
        filterChipArea.children.length > 0 &&
        bodyScrollbarWidth !== 0
      ) {
        width = filterChipArea.scrollWidth;
        filterChipAreaWidth = filterChipArea.offsetWidth;
      }
      if (width > filterChipAreaWidth && width != 0) {
        stickyHead?.classList.add("sticky-header-scroll");
      }

      const filters_block =
        document.getElementById("filters_block")?.offsetHeight || 0;
      const salesFilter =
        document.getElementById("salesFilter")?.offsetHeight || 0;
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const stickyShadow: any = document.getElementById("sticky-shadow");
      if (windScroll > totleScroll) {
        EventAccordion2?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion2?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };
  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  // CALCULATE THE HEIGHT OF TABLE VIRTOUSO
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();

  let timeout: any;

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#eventsmap-lazyload");
      const thead = document.querySelector("#tableId2 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  // const eventData = layout?.clientsEvents?.filter((eventObj: any) => {
  //   return data?.length > 0
  //     ? data?.find((innerObj: any) => innerObj?.id === eventObj?.id)
  //     : eventObj;
  // });
  return (
    <div className="accordion-body relative h-full bg-white">
      <div
        className="order-replacement-table  overflow-auto scrollbar-thin scrollbar-thumb-violet-800
                  scrollbar-thumb-rounded-full hover-style2 md:!h-full tx-trade-table"
        id={`EventAccordion2`}
        ref={accordionRef}
        style={{
          height: layout?.clientsEvents?.length * 40 + 40,
        }}
      >
        <TableVirtuoso
          // style={{
          //   height: "25rem",
          // }}
          // useWindowScroll
          data={data}
          overscan={200}
          totalCount={data?.length}
          id={"eventsmap-lazyload"}
          components={{
            Table: Table,
            TableHead: TableHead,
            TableRow: (props) => {
              return <TableRow {...props} />;
            },
          }}
          fixedHeaderContent={() => (
            <tr className="border-b bg-white">
              <HeaderCell
                title="Events name"
                width="14rem"
                arrow={false}
                postKey="id"
                padding="pl-5"
                TableType="events"
              />

              <HeaderCell
                title="Tixstock events"
                width="18rem"
                arrow={false}
                postKey="id"
                padding="pl-3"
                TableType="events"
                isInput={true}
              >
                <input
                  type="checkbox"
                  className={`mr-2 ${
                    selectEventsFromData && size(selectEventsFromData) === 0
                      ? "pointer-events-none"
                      : "cursor-pointer"
                  } `}
                  name="select-all"
                  disabled={
                    selectEventsFromData && size(selectEventsFromData) === 0
                  }
                  onChange={(e) => {
                    if (!e.target.checked) {
                      layout.setMappedEvents([]);
                      return;
                    }

                    if (selectEventsFromData && size(selectEventsFromData) > 0)
                      layout.setMappedEvents(
                        updateMappedEvents(selectEventsFromData)
                      );
                  }}
                />
              </HeaderCell>
            </tr>
          )}
          itemContent={(sIndex: any, list: any) => {
            return (
              <React.Fragment key={sIndex}>
                <DataCell classname="block" padding="pl-5" width="24rem">
                  <p className="text-sm">{list?.name}</p>
                  <span className="text-xxs">
                    (
                    {list?.datetime
                      ? moment(list?.datetime).format(
                          "ddd, DD MMM YYYY HH:mm:ss"
                        )
                      : "-"}
                    )
                  </span>
                </DataCell>

                <EventDataCell
                  hits={list?.hits}
                  eventId={list?.id}
                  currency={list?.currency}
                  // width="18rem"
                />
              </React.Fragment>
            );
          }}
          ref={virtuosoRef}
        />
      </div>
    </div>
  );
};

export default EventsMappingTable;
