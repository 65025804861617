import EURFlag from "../../../assets/media/icons/other_icons/eur-flag.svg";
import GBPFlag from "../../../assets/media/icons/other_icons/gbp-flag.svg";
import USDFlag from "../../../assets/media/icons/other_icons/usd-flag.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { decimalNumber } from "../../../helpers/Functions";

const AccountBalanceWithFlag = ({ value, loading }: any) => {
  let GBP: any;
  let EUR: any;
  let USD: any;

  for (const { currency, balance } of value || []) {
    switch (currency) {
      case "EUR":
        EUR = balance;
        break;
      case "GBP":
        GBP = balance;
        break;
      case "USD":
        USD = balance;
        break;
      default:
        break;
    }
  }

  return (
    <div
      className={`master_account_block p-5 pb-0 anim-fade ${
        GBP || USD || EUR || loading ? "py-5" : "py-0"
      }`}
    >
      {(GBP || USD || EUR || loading) && (
        <div className="inner_master_account px-[5px] py-[.5625rem] rounded-xl border border-green-600/30 bg-green-600 bg-opacity-[7%] flex md:flex-wrap gap-y-2.5 max-md:overflow-auto max-md:whitespace-nowrap">
          <div className="title_block lg2:mr-[4.375rem] lg2:w-auto w-full px-2.5 flex justify-center items-start flex-col">
            <h3 className="text-sm15 font-semibold leading-4 mb-1.5">
              Master account
            </h3>
            <span className="text-sm12 text-gray-500 block leading-3">
              Total balance
            </span>
          </div>
          {(GBP || loading) && (
            <div className="flag-block flex items-center min-w-[11.4375rem] px-2.5 md:w-auto sm:w-1/2 w-full">
              <div className="flagImg w-10 h-10 rounded-full overflow-hidden mr-2.5">
                <IKTSVG path={GBPFlag} svgClassName="w-10 h-10" />
              </div>
              <div className="flag-info">
                <h3
                  className={`text-sm15 leading-4 mb-1 ${
                    loading && "shimmer-effect w-[6.25rem]"
                  }  `}
                >
                  <span className={`${loading && "opacity-0 "}`}>
                    £{decimalNumber(GBP)}
                  </span>
                </h3>
                <span
                  className={`text-sm12 text-gray-500 block leading-3 ${
                    loading && "shimmer-effect"
                  } `}
                >
                  <span className={`${loading && "opacity-0"}`}>GBP</span>
                </span>
              </div>
            </div>
          )}
          {(EUR || loading) && (
            <div className="flag-block flex items-center min-w-[11.4375rem] px-2.5 md:w-auto sm:w-1/2 w-full">
              <div className="flagImg w-10 h-10 rounded-full overflow-hidden mr-2.5">
                <IKTSVG path={EURFlag} svgClassName="w-10 h-10" />
              </div>
              <div className="flag-info">
                <h3
                  className={`text-sm15 leading-4 mb-1 ${
                    loading && "shimmer-effect w-[6.25rem]"
                  }  `}
                >
                  <span className={`${loading && "opacity-0"}`}>
                    {" "}
                    €{decimalNumber(EUR)}
                  </span>
                </h3>
                <span
                  className={`text-sm12 text-gray-500 block leading-3 ${
                    loading && "shimmer-effect"
                  } `}
                >
                  <span className={`${loading && "opacity-0"}`}>EUR</span>
                </span>
              </div>
            </div>
          )}
          {(USD || loading) && (
            <div className="flag-block flex items-center min-w-[11.4375rem] px-2.5 md:w-auto sm:w-1/2 w-full">
              <div className="flagImg w-10 h-10 rounded-full overflow-hidden mr-2.5">
                <IKTSVG path={USDFlag} svgClassName="w-10 h-10" />
              </div>
              <div className="flag-info">
                <h3
                  className={`text-sm15 leading-4 mb-1 ${
                    loading && "shimmer-effect w-[6.25rem]"
                  }  `}
                >
                  <span className={`${loading && "opacity-0"}`}>
                    ${decimalNumber(USD)}
                  </span>
                </h3>
                <span
                  className={`text-sm12 text-gray-500 block leading-3 ${
                    loading && "shimmer-effect"
                  } `}
                >
                  <span className={`${loading && "opacity-0"}`}>USD</span>
                </span>
              </div>
            </div>
          )}
          {/* <div className="flag-block flex items-center min-w-[11.4375rem] px-2.5 md:w-auto sm:w-1/2 w-full">
          <div className="flagImg w-10 h-10 rounded-full overflow-hidden mr-2.5">
            <IKTSVG path={CHFFlag} svgClassName="w-10 h-10" />
          </div>
          <div className="flag-info">
            <h3 className="text-sm15 leading-4 mb-1">₣165,807.00</h3>
            <span className="text-sm12 text-gray-500 block leading-3">CHF</span>
          </div>
        </div> */}
        </div>
      )}
    </div>
  );
};

export default AccountBalanceWithFlag;
