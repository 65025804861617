import React from "react";

interface SubmitButtonInterface {
  disabled?: boolean;
  handleSubmit?: any;
  margin?: string;
}

const SubmitButton: React.FC<SubmitButtonInterface> = ({
  disabled,
  handleSubmit,
  margin = "",
}: any) => {
  return (
    <button
      type="button"
      className={`${
        disabled
          ? "text-gray-400 bg-gray-100"
          : "bg-green-600 hover:bg-indigo-500 !text-white"
      } flex text-sm13 font-medium py-1 px-2.5  rounded border-hidden hover:border-hidden ${margin}`}
      onClick={handleSubmit}
      disabled={disabled ? disabled : false}
    >
      Submit
    </button>
  );
};

export default SubmitButton;
