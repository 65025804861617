/* eslint-disable array-callback-return */

import { size } from "lodash";
import moment from "moment";
import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../../assets/media/icons/other_icons/Eye.svg";
import downloadIcon from "../../../assets/media/icons/other_icons/download-icon.svg";
import angleLeftSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../assets/media/icons/standard_icons/angle_right.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { handleShift } from "../../../helpers/Functions";
import { SYMBOLS } from "../../../helpers/const";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../core/TxAccountProvider";
import { downloadPDF, invoiceViewAPI } from "../core/_request";
import DataCell from "./component/DataCell";
import HeaderCell from "./component/HeaderCell";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  md:min-w-[87.5rem]"
    style={{
      ...style,
      //width: 700
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTableInvoices = ({ paginateData, loading, setIsLoading }: any) => {
  const layout = useContext(TxAccountContext);
  const globalLayout = useContext(LayoutContext);
  const [height, setHeight] = useState<any | undefined>(undefined);

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");
  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#Report-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      // setTimeout(() => {
      //   nomoreListingHeight = nomoreListing?.clientHeight;
      // }, 2000);

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 1000);
  };
  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });

  const tableRef: any = useRef<any>();
  const stickyTableRef: any = useRef<any>();

  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      if (topfilterTop) {
        const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
        // if (topValue.includes("-")) {
        // Check if the value contains a negative sign
        const currentValue = parseInt(topValue); // Extract the numeric value
        const newValue = Math.abs(currentValue); // Make it positive
        topfilterTop && (topfilterTop.style.top = `${newValue}px`); // Apply the new positive value back as a string
        // }
        const windScroll: number = window.scrollY;

        const headingOne: any =
          document.getElementById("headingOne")?.clientHeight;
        const inventoryaccordion: any =
          document.getElementById("inventoryaccordion")?.clientHeight;
        // const totleScroll = headingOne + inventoryaccordion + 16;
        // const currentTop = parseInt(topfilterTop.style.top || "0");
        const topFiltersHeight = topfilterTop.clientHeight;
        let totleScroll = 0;
        if (window.matchMedia("(max-width: 767px)").matches) {
          totleScroll = topFiltersHeight;
        } else {
          totleScroll = headingOne + inventoryaccordion + 16;
        }

        const stickyHead: any = document.getElementById("sticky-head");
        const stickyShadow: any = document.getElementById("sticky-shadow");
        if (windScroll > totleScroll) {
          EventAccordion?.classList?.add("-mt-10");
          stickyHead?.classList.remove("opacity-0");
          stickyHead?.classList.remove("h-0");
          stickyHead?.classList.add("h-[2.5625rem]");
          stickyShadow.classList.add("!block");
        } else if (windScroll < totleScroll) {
          EventAccordion?.classList?.remove("-mt-10");
          stickyHead?.classList.add("opacity-0");
          stickyHead?.classList.add("h-0");
          stickyHead?.classList.remove("h-[2.5625rem]");
          stickyShadow.classList.remove("!block");
        }
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  // HANDLE CHECK
  const handleCheck = (e: any, id: string) => {
    layout.setInvoiceList((prev: any) => {
      return prev.map((current: any) => {
        if (current.xero_invoice_id === id) {
          return {
            ...current,
            selected: e === true ? true : e?.target?.checked,
          };
        } else {
          return current;
        }
      });
    });
  };

  // HANDLE - SELECT ALL
  const handleSelectAll = (data: any) => {
    layout.setInvoiceList((prev: any) => {
      return prev.map((current: any) => {
        return {
          ...current,
          selected: data?.target?.checked,
        };
      });
    });
  };

  // VIEW INVOICE
  const fetchInvoiceList = async (id: any) => {
    layout.setCustomLoader(true);
    const data = { page: 1, per_page: 20 };
    let response = await invoiceViewAPI({ ...data, id });
    layout.setInvoicesViewData({ ...response, status: true, id: id });
    layout.setCustomLoader(false);
  };

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  const getColumnItems = () => {
    return layout.columnItems.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  // HANDLE - PDF DOWNLOAD
  const handleDownloadPDF = (data: any) => {
    layout.setCustomLoader(true);
    downloadPDF(data?.xero_invoice_id).then((response: any) => {
      const blob = new Blob([response], { type: "application/pdf" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute(
        "download",
        "tixstock-invoice-" +
          data?.invoice_number +
          "-" +
          data?.invoice_status?.toLowerCase().replace(/ /g, "-") +
          ".pdf"
      );
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      layout.setCustomLoader(false);
    });
  };

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0 lg2:min-h-[8.75rem]"
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none th_last last-auto`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  md:min-w-[87.5rem] absolute left-0 top-0">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              <th
                className={`min-w-[2.5rem] w-[2.5rem] max-w-[2.5rem] h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                id="checkbox_shad"
              >
                <div
                  className={`h-full flex items-center justify-center border-r w-10`}
                >
                  <input
                    type="checkbox"
                    checked={
                      size(layout.invoiceList) > 0 &&
                      layout.invoiceList?.every((item: any) => item?.selected)
                    }
                    readOnly
                    className={` w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                    onChange={(data) => handleSelectAll(data)}
                  />
                </div>
              </th>
              {getColumnItems().map((columnItem: any, index: number) => {
                switch (columnItem.id) {
                  case 19:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Marketplace"
                          width="min-w-[11.25rem] w-[11.25rem]"
                          arrow={true}
                          postKey="marketplace_name"
                          padding="pl-4"
                        />
                      </React.Fragment>
                    );
                  case 20:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Invoice date"
                          width="min-w-[7.5rem] w-[7.5rem]"
                          arrow={true}
                          postKey="created_at"
                        />
                      </React.Fragment>
                    );
                  case 21:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Invoice number"
                          width="min-w-[7.5rem] w-[7.5rem]"
                          arrow={true}
                          postKey="invoice_number"
                        />
                      </React.Fragment>
                    );
                  case 22:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Invoice value"
                          width="min-w-[7.5rem] w-[7.5rem]"
                          arrow={true}
                          postKey="total_amount"
                        />
                      </React.Fragment>
                    );
                  case 23:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Invoice status"
                          width="min-w-[7.5rem] w-[7.5rem]"
                          arrow={true}
                          postKey="status"
                        />
                      </React.Fragment>
                    );
                  case 24:
                    return (
                      <React.Fragment key={index}>
                        <HeaderCell
                          title="Payment date"
                          width="min-w-[7.5rem] near_arrow"
                          arrow={true}
                          postKey="payment_date"
                        />
                      </React.Fragment>
                    );
                }
              })}

              <th className="sticky right-0 ml-auto  p-0 w-[5.0625rem] min-w-[5.0625rem]">
                <div
                  className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white hidden"
                  id="left-shad-list"
                >
                  <div className="arrow_buttons flex shadow-op1 rounded ">
                    <button
                      className="group rounded-l  transition px-[.5625rem] py-2"
                      type="button"
                      onClick={(e: any) => scroll(-120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleLeftSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                    <button
                      className="group rounded-r  transition px-[.5625rem] py-2"
                      type="button"
                      onClick={(e: any) => scroll(120)}
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={angleRightSVG}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        {/* <EventFields /> */}
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2 last-auto"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              // style={{
              //   height: `${
              //     virtuosoHeight() > height ? virtuosoHeight() : height
              //   }px`,
              // }}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={layout?.invoiceList}
                endReached={() => {
                  if (
                    paginateData?.current_page < paginateData?.last_page &&
                    !loading
                  ) {
                    setIsLoading(true);
                    layout.setInvoicesFilter((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };
                    });
                  }
                }}
                // ref={containerRef}
                useWindowScroll
                overscan={200}
                totalCount={layout?.invoiceList?.length}
                atBottomThreshold={100}
                id={"Report-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return (
                      <TableRow
                        {...props}
                        // hoveredId={hoveredId}
                        // setHoveredId={setHoveredId}
                        // showOrder={showOrder}
                      />
                    );
                  },
                  TableFoot: (props) => {
                    if (
                      layout?.invoiceList?.length === paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return (
                        <tfoot
                          className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                          id={`NoMoreListing`}
                        >
                          <tr className="border-b bg-white">
                            <td
                              colSpan={11}
                              align="center"
                              className="h-[2.8125rem]"
                            >
                              No more items to show
                            </td>
                          </tr>
                        </tfoot>
                      );
                    } else {
                      return null;
                    }
                  },
                }}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                fixedHeaderContent={() => (
                  <tr className="border-b bg-white">
                    <th
                      className={`min-w-[2.5rem] max-w-[2.5rem] w-[2.5rem] h-[2.5rem] items-center justify-center text-center sticky left-0 p-0 z-10 no-scroll checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                      id="checkbox_shad"
                    >
                      <div
                        className={`h-full flex items-center justify-center border-r w-10`}
                      >
                        <label
                          className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] flex items-center absolute top-0  justify-center select-none p-1 border-r`}
                          htmlFor={`checkbox`}
                        ></label>
                        <input
                          id="checkbox"
                          type="checkbox"
                          checked={
                            size(layout.invoiceList) > 0 &&
                            layout.invoiceList?.every(
                              (item: any) => item?.selected
                            )
                          }
                          readOnly
                          className={` w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 `}
                          onChange={(data) => handleSelectAll(data)}
                        />
                      </div>
                    </th>
                    {getColumnItems().map((columnItem: any, index: number) => {
                      switch (columnItem.id) {
                        case 19:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Marketplace"
                                width="min-w-[11.25rem] w-[11.25rem]"
                                arrow={true}
                                postKey="marketplace_name"
                                padding="pl-4"
                              />
                            </React.Fragment>
                          );
                        case 20:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Invoice date"
                                width="min-w-[7.5rem] w-[7.5rem]"
                                arrow={true}
                                postKey="created_at"
                              />
                            </React.Fragment>
                          );
                        case 21:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Invoice number"
                                width="min-w-[7.5rem] w-[7.5rem]"
                                arrow={true}
                                postKey="invoice_number"
                              />
                            </React.Fragment>
                          );
                        case 22:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Invoice value"
                                width="min-w-[7.5rem] w-[7.5rem]"
                                arrow={true}
                                postKey="total_amount"
                              />
                            </React.Fragment>
                          );
                        case 23:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Invoice status"
                                width="min-w-[7.5rem] w-[7.5rem]"
                                arrow={true}
                                postKey="status"
                              />
                            </React.Fragment>
                          );
                        case 24:
                          return (
                            <React.Fragment key={index}>
                              <HeaderCell
                                title="Payment date	"
                                width="min-w-[7.5rem] w-[7.5rem] near_arrow"
                                arrow={true}
                                postKey="payment_date"
                              />
                            </React.Fragment>
                          );
                      }
                    })}

                    <th className="relative right-0 ml-auto  p-0 min-w-[2.5rem] md:min-w-[5rem] w-[5rem]">
                      {/* <div
                        className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
                        id="left-shad-list"
                      >
                        <button
                          className="group rounded-l bg-gray-100 hover:bg-indigo-500 transition px-[.5625rem] py-2"
                          type="button"
                          onClick={(e: any) => scroll(-120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-white"
                            path={angleLeftSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                        <button
                          className="group rounded-r bg-gray-100 hover:bg-indigo-500 transition px-[.5625rem] py-2"
                          type="button"
                          onClick={(e: any) => scroll(120)}
                        >
                          <IKTSVG
                            className="flex items-center justify-center fill-violet-500 transition group-hover:fill-white"
                            path={angleRightSVG}
                            svgClassName="w-1.5 h-[.5625rem]"
                          />
                        </button>
                      </div> */}
                    </th>
                    {globalLayout?.isMobile && (
                      <th className="sticky right-0 ml-auto p-0 icon_td min-w-[2.5rem] border-b md:hidden"></th>
                    )}
                  </tr>
                )}
                itemContent={(sIndex: any, list: any) => {
                  const matchedView = list?.selected;
                  return (
                    <React.Fragment key={sIndex}>
                      {/* CHECKBOX */}
                      <td
                        className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.8125rem] text-center sticky left-0 no-scroll p-0 checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
                        id="checkbox_shad"
                      >
                        <div
                          className={`h-full flex items-center justify-center bg-inherit border-r w-10 `}
                        >
                          <label
                            className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] flex items-center absolute top-0  justify-center select-none p-1 border-r`}
                            htmlFor={`checkbox-all-${sIndex}`}
                          ></label>
                          <input
                            id={`checkbox-all-${sIndex}`}
                            type="checkbox"
                            className={`cursor-pointer w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 relative`}
                            checked={list?.selected}
                            onChange={(e: any) =>
                              handleCheck(e, list?.xero_invoice_id)
                            }
                            onClick={(e) =>
                              handleShift(e, sIndex, layout?.invoiceList)
                            }
                          />
                        </div>
                      </td>
                      {getColumnItems().map(
                        (columnItem: any, index: number) => {
                          switch (columnItem.id) {
                            case 19:
                              return (
                                <React.Fragment key={index}>
                                  {/* MARKETPLACE */}
                                  <DataCell
                                    value={
                                      list?.marketplace_name
                                        ? list?.marketplace_name
                                        : "-"
                                    }
                                    classname="block"
                                    padding="pl-4"
                                    width="w-[9.875rem]"
                                  />
                                </React.Fragment>
                              );
                            case 20:
                              return (
                                <React.Fragment key={index}>
                                  {/* INVOICE DATE */}
                                  <DataCell
                                    value={
                                      list?.invoice_date
                                        ? moment(list?.invoice_date).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"
                                    }
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 21:
                              return (
                                <React.Fragment key={index}>
                                  {/* INVOICE NUMBER */}
                                  <DataCell
                                    value={
                                      list?.invoice_number
                                        ? list?.invoice_number
                                        : "-"
                                    }
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 22:
                              return (
                                <React.Fragment key={index}>
                                  {/* INVOICE VALUE */}
                                  <DataCell
                                    value={
                                      list?.invoice_value
                                        ? `${SYMBOLS[list?.currency]}${
                                            list?.invoice_value
                                          }`
                                        : "-"
                                    }
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 23:
                              return (
                                <React.Fragment key={index}>
                                  {/* INVOICE STATUS */}
                                  <DataCell
                                    value={
                                      list?.invoice_status
                                        ? list?.invoice_status
                                        : "-"
                                    }
                                    classname="block"
                                    width="w-[6.75rem]"
                                  />
                                </React.Fragment>
                              );
                            case 24:
                              return (
                                <React.Fragment key={index}>
                                  {/* PAYMENT */}
                                  <DataCell
                                    value={
                                      list?.payment_date
                                        ? moment(list?.payment_date).format(
                                            "DD/MM/YYYY"
                                          )
                                        : "-"
                                    }
                                    classname="block"
                                    width="w-full"
                                  />
                                </React.Fragment>
                              );
                          }
                        }
                      )}

                      <td
                        className={`relative md:sticky right-0 ml-auto p-0 icon_td  border-b min-w-[2.5rem] md:min-w-[5rem] w-[5rem]`}
                      >
                        <React.Fragment key={sIndex}>
                          <div
                            className={`${
                              matchedView ? "bg-indigo-500 bg-opacity-[7%]" : ""
                            } `}
                          >
                            <div
                              className="flex relative w-full h-full border-l"
                              id="left-shad-list"
                            >
                              {/* DOWNLOAD BUTTON */}
                              <button
                                className={`group flex items-center justify-center p-1 md:p-1 w-full
                               `}
                                type="button"
                                id={`record-view-tooltip${sIndex}`}
                                data-tooltip-id={`download-invoice-${sIndex}`}
                                onClick={() => handleDownloadPDF(list)}
                              >
                                <TooltipPortal
                                  id={`download-invoice-${sIndex}`}
                                  content={"Download PDF"}
                                  className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default mt-2"
                                />
                                <IKTSVG
                                  className={`flex items-center justify-center transition fill-violet-500 group-hover:fill-indigo-500 `}
                                  path={downloadIcon}
                                  svgClassName="w-[.9375rem] h-3.5"
                                />
                              </button>

                              {/* VIEW BUTTON */}
                              <button
                                className={`max-md:hidden group flex items-center justify-center p-1 md:p-1 w-full border-l
                               `}
                                type="button"
                                id={`record-view-tooltip${sIndex}`}
                                onClick={() => {
                                  fetchInvoiceList(list?.id);
                                }}
                                data-tooltip-id={`eye-invoice-${sIndex}`}
                              >
                                <TooltipPortal
                                  id={`eye-invoice-${sIndex}`}
                                  content={"View"}
                                  className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default mt-2"
                                />
                                <IKTSVG
                                  className={`flex items-center justify-center transition group-hover:fill-indigo-500 fill-violet-500`}
                                  path={eyeSVG}
                                  svgClassName="w-4 h-[.6875rem]"
                                />
                              </button>
                            </div>
                          </div>
                        </React.Fragment>
                      </td>
                      <td className="sticky right-0 ml-auto p-0 icon_td  border-b md:hidden">
                        <div className="arrow_buttons flex h-full shadow-op1 rounded ">
                          {/* VIEW BUTTON */}
                          <button
                            className={`group flex items-center justify-center p-1 md:p-1 w-full h-full border-l
                          `}
                            type="button"
                            id={`record-view-tooltip${sIndex}`}
                            onClick={() => {
                              fetchInvoiceList(list?.id);
                            }}
                            data-tooltip-id={`eye-invoice-${sIndex}`}
                          >
                            <TooltipPortal
                              id={`eye-invoice-${sIndex}`}
                              content={"View"}
                              className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default mt-2"
                            />
                            <IKTSVG
                              className={`flex items-center justify-center transition group-hover:fill-indigo-500 fill-violet-500`}
                              path={eyeSVG}
                              svgClassName="w-4 h-[.6875rem]"
                            />
                          </button>
                        </div>
                      </td>
                      {/* </tr> */}
                    </React.Fragment>
                  );
                }}
              />
            </div>
          </div>
          <div
            id="custom-loader"
            className={`accordion bg-white font-medium pt-2.5 border-t `}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            {/* <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "30px" }}
              ></div> */}
          </div>
        </div>
      </div>

      {/* LOADER */}
      {layout?.customLoader && <ProgressBardLoader secondCounter={2.5} />}
    </div>
  );
};

export default DataTableInvoices;
