import { useContext } from "react";
import {
  PAYOUT_TAB,
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_INVOICE_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_INITIAL_FILTER,
  TX_ACCOUNT_ORDER_PAYOUT_SELLER,
  TX_ACCOUNT_PAYOUT_INTIAL_FILTER,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
} from "../../../../helpers/const";
import Tab from "../../component/Tab";
import { TxAccountContext } from "../../core/TxAccountProvider";

const Tabs = () => {
  const layout = useContext(TxAccountContext);
  return (
    <div className="tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
      <Tab
        title="Orders"
        handleTab={() => {
          layout.setTabActive("Orders");
          layout.setPayOutFilter(TX_ACCOUNT_PAYOUT_INTIAL_FILTER);
          layout.setInvoicesFilter(TX_ACCOUNT_INVOICE_INITIAL_FILTER);
          layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
          layout.setWithdrawRequestFilter(
            TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
          );
        }}
      />
      <Tab
        title="Invoices"
        handleTab={() => {
          layout.setTabActive("Invoices");
          layout.setPayOutFilter(TX_ACCOUNT_PAYOUT_INTIAL_FILTER);
          layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
          layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
          layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
          layout.setWithdrawRequestFilter(
            TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
          );
        }}
      />
      <Tab
        title={PAYOUT_TAB}
        handleTab={() => {
          layout.setTabActive(PAYOUT_TAB);
          layout.setInvoicesFilter(TX_ACCOUNT_INVOICE_INITIAL_FILTER);
          layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
          layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
          layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
          layout.setWithdrawRequestFilter(
            TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
          );
        }}
      />
      {/* <Tab
        title="Seller wallets"
        handleTab={() => {
          layout.setTabActive("Seller wallets");
          layout.setPayOutFilter(TX_ACCOUNT_PAYOUT_INTIAL_FILTER);
          layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
          layout.setInvoicesFilter(TX_ACCOUNT_INVOICE_INITIAL_FILTER);
          layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
          layout.setWithdrawRequestFilter(
            TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
          );
        }}
      />
      <Tab
        title="Bank accounts"
        handleTab={() => {
          layout.setTabActive("Bank accounts");
          layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
          layout.setPayOutFilter(TX_ACCOUNT_PAYOUT_INTIAL_FILTER);
          layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
          layout.setInvoicesFilter(TX_ACCOUNT_INVOICE_INITIAL_FILTER);
          layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
          layout.setWithdrawRequestFilter(
            TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
          );
        }}
      />
      <Tab
        title="Withdraw requests"
        handleTab={() => {
          layout.setTabActive("Withdraw requests");
          layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
          layout.setPayOutFilter(TX_ACCOUNT_PAYOUT_INTIAL_FILTER);
          layout.setOrderFilters(TX_ACCOUNT_ORDER_INITIAL_FILTER);
          layout.setInvoicesFilter(TX_ACCOUNT_INVOICE_INITIAL_FILTER);
          layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
        }}
      /> */}
    </div>
  );
};

export default Tabs;
