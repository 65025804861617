import clsx from "clsx";
import _ from "lodash";
import { useContext } from "react";
import NoJSXComponent from "../../../../../components/noJSX/NoJSXComponent";
import { InventoryContext } from "../../../core/InventoryProvider";
import Image from "../mobileViewComponent/Image";

type TLockComponent = {
  event: Record<string, any>;
  eventRecord: Record<string, any>;
  rIndex: string | number;
  setList: (...args: any[]) => void;
};

const LockComponent = ({
  event,
  eventRecord,
  rIndex,
  setList,
}: TLockComponent) => {
  const layout = useContext(InventoryContext);
  const { isGodAdmin } = NoJSXComponent();
  let pngImagePath = "/media/png/";

  //   HANDLE LOCK / UNLOCK
  const handleOnSingleUnLock = (e: any, eId: any, sIndex: any) => {
    setList((prev: any) => {
      return prev.map((event: any, eInnerIndex: any) => {
        if (event?.id === eId) {
          return {
            ...event,
            eventRecords: Array.isArray(event["eventRecords"])
              ? event["eventRecords"].map((eventRecord: any, index: any) => {
                  if (rIndex === index) {
                    return {
                      ...eventRecord,
                      isLock: !eventRecord?.isLock,
                      selected: false,
                    };
                  } else {
                    return { ...eventRecord };
                  }
                })
              : [],
          };
        } else {
          return event;
        }
      });
    });
  };

  return isGodAdmin ? (
    <div
      style={{ width: "40px" }}
      className={clsx(
        "flex td_block items-center checkbox_td justify-center h-[2.5rem] pointer-events-auto td_block min-w-[2.5rem] max-w-[2.5rem] w-[2.5rem] sticky left-0 no-scroll p-0 z-[3] lock-img-view group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]",
        _.size(layout?.isTableEditable) > 0 ||
          layout?.selectAllConfirmation ||
          layout?.selectAllLoader
          ? "!pointer-events-none"
          : "pointer-events-auto"
      )}
      id="checkbox_shad_lock"
    >
      <div
        className={clsx(
          "lock-icon relative w-full h-full border-r",
          isGodAdmin && eventRecord?.isLock === true && "bg-[#f0f0f0]"
        )}
      >
        <label
          className={`cursor-pointer w-full h-full flex items-center justify-center ${
            eventRecord?.isLock === true ? " lock" : "unlock"
          }`}
        >
          <input
            type="checkbox"
            className={`invisible absolute ${
              _.size(layout?.isTableEditable) > 0
                ? "!pointer-events-none"
                : "pointer-events-auto"
            }`}
            checked={eventRecord?.isLock ? true : false}
            onChange={(e: any) => {
              handleOnSingleUnLock(e, event?.id, rIndex);
            }}
          />

          <Image
            className="w-[.8125rem] h-[.9375rem]"
            path={
              eventRecord?.isLock === true
                ? `${pngImagePath}lock.png`
                : `${pngImagePath}unlock.png`
            }
            alt={eventRecord?.isLock === true ? "lock" : "unlock"}
          />
        </label>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default LockComponent;
