import { Tooltip } from "react-tooltip";
import DropDownField from "../../formComponent/DropDownField";
import PriceField from "../../formComponent/PriceField";
import { KTSVG } from "../KTSVG";
// import { Checkbox } from 'flowbite-react';
import Checkbox from "../../formComponent/Checkbox";
import DatePickerField from "../../formComponent/DatePickerField";
import MultiDropDownField from "../../formComponent/MultiDropDownField";
import Textfield from "../../formComponent/Textfield";
// import { LoadImages } from "../../helpers/AssetHelpers";
// import Header from "../../components/layout/Header";
import MultiSelect from "../../formComponent/MultiSelect";
import SearchableMultiDropdown from "../../formComponent/SearchableMultiDropdown";

let benefits = [
  {
    value: "Aisle",
    label: "Aisle",
  },
  {
    value: "Centre Aisle",
    label: "Centre Aisle",
  },
  {
    value: "VIP Parking Pass",
    label: "VIP Parking Pass",
  },
  {
    value: "Full Suite (Not Shared)",
    label: "Full Suite (Not Shared)",
  },
  {
    value: "12-person suite",
    label: "12-person suite",
  },
  {
    value: "14-person suite",
    label: "14-person suite",
  },
  {
    value: "16-person suite",
    label: "16-person suite",
  },
  {
    value: "18-person suite",
    label: "18-person suite",
  },
  {
    value: "20-person suite",
    label: "20-person suite",
  },
  {
    value: "24-person suite",
    label: "24-person suite",
  },
  {
    value: "Full Suite - Includes 8 fixed seats and 4 bar stools",
    label: "Full Suite - Includes 8 fixed seats and 4 bar stools",
  },
  {
    value: "Includes unlimited food and soft drinks",
    label: "Includes unlimited food and soft drinks",
  },
  {
    value: "Includes limited alcoholic beverages - beer, wine and liquor",
    label: "Includes limited alcoholic beverages - beer, wine and liquor",
  },
  {
    value: "Includes unlimited food and drinks (beer and soft drinks)",
    label: "Includes unlimited food and drinks (beer and soft drinks)",
  },
  {
    value: "Actual Third Row Over Visitor Dugout",
    label: "Actual Third Row Over Visitor Dugout",
  },
  {
    value: "Actual Third Row Over Visitor Dugout",
    label: "Actual Third Row Over Visitor Dugout",
  },
  {
    value: "Tunnel Club access - Includes pregame food and beverage",
    label: "Tunnel Club access - Includes pregame food and beverage",
  },
  {
    value: "Under Overhang",
    label: "Under Overhang",
  },
  {
    value: "Actual Second Row Over Home Bullpen",
    label: "Actual Second Row Over Home Bullpen",
  },
  {
    value: "In-Seat Wait Service",
    label: "In-Seat Wait Service",
  },
  {
    value: "Includes unlimited food and drinks (beer, wine and liquor)",
    label: "Includes unlimited food and drinks (beer, wine and liquor)",
  },
  {
    value: "Actual Third Row Over Home Dugout",
    label: "Actual Third Row Over Home Dugout",
  },
  {
    value: "Food and drink voucher included",
    label: "Food and drink voucher included",
  },
  {
    value: "Actual 4th row of section",
    label: "Actual 4th row of section",
  },
];

const Typography = () => {
  let options = [
    {
      value: "asd",
      label: "Paper",
      date: "Mon, 30 2023",
      time: "15:00",
      place: "Ibrox Statium, Glasgow",
    },
    {
      value: "asd",
      label: "E-ticket",
      date: "Sat, 15 2020",
      time: "17:00",
      place: "Wembley Statium, London",
    },
    {
      value: "asd",
      label: "Mobile transfer",
      date: "Sat, 15 2020",
      time: "17:00",
      place: "Wembley Statium, London",
    },
  ];

  return (
    <>
      {/* Colours */}
      <div className="p-16 pb-4 border-b">
        <h2 className="font-semibold text-lg mb-5">Colour</h2>
        <div className="flex flex-wrap">
          <div className="text-center mr-12 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-white border border-gray-100"></div>
            <div className="uppercase">#FFFFFF</div>
            <div className="text-sm text-gray-400 mt-1">white</div>
          </div>
          <div className="text-center mr-12 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-gray-100"></div>
            <div className="uppercase">#F2F2F2</div>
            <div className="text-sm text-gray-400 mt-1">gray-100</div>
          </div>
          <div className="text-center mr-12 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-gray-200"></div>
            <div className="uppercase">#E6E6E6</div>
            <div className="text-sm text-gray-400 mt-1">gray-200</div>
          </div>
          <div className="text-center mr-12 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-gray-300"></div>
            <div className="uppercase">#D9D9D9</div>
            <div className="text-sm text-gray-400 mt-1">gray-300</div>
          </div>
          <div className="text-center mr-12 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-gray-400"></div>
            <div className="uppercase">#BFBFBF</div>
            <div className="text-sm text-gray-400 mt-1">gray-400</div>
          </div>
          <div className="text-center mr-12 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-gray-500"></div>
            <div className="uppercase">#808080</div>
            <div className="text-sm text-gray-400 mt-1">gray-500</div>
          </div>
          <div className="text-center mr-12 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-gray-600"></div>
            <div className="uppercase">#404040</div>
            <div className="text-sm text-gray-400 mt-1">gray-600</div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="text-center mr-10 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-violet-800"></div>
            <div className="uppercase">#0D0019</div>
            <div className="text-sm text-gray-400 mt-1">violet-800</div>
          </div>
          <div className="text-center mr-10 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-violet-700"></div>
            <div className="uppercase">#210040</div>
            <div className="text-sm text-gray-400 mt-1">violet-700</div>
          </div>
          <div className="text-center mr-10 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-violet-600"></div>
            <div className="uppercase">#350066</div>
            <div className="text-sm text-gray-400 mt-1">violet-600</div>
          </div>
          <div className="text-center mr-10 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-violet-500"></div>
            <div className="uppercase">#41007F</div>
            <div className="text-sm text-gray-400 mt-1">violet-500</div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="text-center mr-8 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-green-500"></div>
            <div className="uppercase">#00E500</div>
            <div className="text-sm text-gray-400 mt-1">green-500</div>
          </div>
          <div className="text-center mr-8 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-indigo-500"></div>
            <div className="uppercase">#6F6CFF</div>
            <div className="text-sm text-gray-400 mt-1">indigo-500</div>
          </div>
          <div className="text-center mr-8 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-green-600"></div>
            <div className="uppercase">#00D900</div>
            <div className="text-sm text-gray-400 mt-1">green-600</div>
          </div>
          <div className="text-center mr-8 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-sky-blue-500"></div>
            <div className="uppercase">#0dcaff</div>
            <div className="text-sm text-gray-400 mt-1">sky-blue-500</div>
          </div>
          <div className="text-center mr-8 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-orange-500"></div>
            <div className="uppercase">#ff6c00</div>
            <div className="text-sm text-gray-400 mt-1">orange-500</div>
          </div>
          <div className="text-center mr-8 mb-10">
            <div className="h-16 w-16 mx-auto mb-4 bg-rose-500"></div>
            <div className="uppercase">#f9004b</div>
            <div className="text-sm text-gray-400 mt-1">rose-500</div>
          </div>
        </div>
      </div>

      {/* Menu List with Icons */}
      <div className="p-16 pb-10 border-b">
        <h2 className="font-semibold text-lg mb-5">Menu</h2>
        <div className="flex flex-wrap">
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Dashboard.svg"
              />{" "}
              {/* for active menu replace class "bg-indigo-500" */}
            </div>
            <div className="">Dashboard</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Add-List.svg"
              />
            </div>
            <div className="">Add listings</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Inventory.svg"
              />
            </div>
            <div className="">Inventory</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Sales.svg"
              />
            </div>
            <div className="">Sales</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Reports.svg"
              />
            </div>
            <div className="">Reports</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Invoices.svg"
              />
            </div>
            <div className="">Invoices</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Settings.svg"
              />
            </div>
            <div className="">Settings</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Alerts.svg"
              />
            </div>
            <div className="">Alerts</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-16 w-16 mx-auto mb-3 bg-violet-600">
              <KTSVG
                className="flex items-center justify-center h-10 w-10 p-2 rounded bg-violet-600 hover:bg-indigo-500/50 transition"
                path="menu_icons/Alerts-Indicator.svg"
              />
            </div>
            <div className="">Alerts: indicator</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="h-16 w-16 flex align-center justify-center mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center p-2 fill-purple-900 hover:fill-indigo-500 transition"
                path="menu_icons/Logout.svg"
              />
            </div>
            <div className="">Logout</div>
          </div>
        </div>
      </div>

      {/* Other icons and its Tooltip */}
      <div className="p-16 pb-10 border-b">
        <h2 className="font-semibold text-lg mb-5">Icons</h2>
        <div className="flex flex-wrap mb-8">
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <Tooltip
                anchorId="market-tooltip"
                content="Market Insights"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10"
              />
              <div id="market-tooltip">
                <KTSVG
                  className="flex items-center justify-center fill-green-600 hover:fill-indigo-500 transition"
                  path="other_icons/Market-Insights-Green.svg"
                />
              </div>
            </div>
            <div className="">Market Insights</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Clone.svg"
              />
            </div>
            <div className="">Clone</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Delete.svg"
              />
            </div>
            <div className="">Delete</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Upload.svg"
              />
            </div>
            <div className="">Upload</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/PPE.svg"
              />
            </div>
            <div className="">PPE</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Tags.svg"
              />
            </div>
            <div className="">Tags & notes</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Eye.svg"
              />
            </div>
            <div className="">View</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Download.svg"
              />
            </div>
            <div className="">Download PDF</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Collapse.svg"
              />
            </div>
            <div className="">Collapse</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition"
                path="other_icons/Expand.svg"
              />
            </div>
            <div className="">Expand</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-gray-400 hover:fill-indigo-500 transition"
                path="other_icons/Publish-Gray.svg"
              />
            </div>
            <div className="">Publish</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-violet-800 hover:fill-indigo-500 transition"
                path="other_icons/Clear.svg"
              />
            </div>
            <div className="">Clear</div>
          </div>
        </div>
        <div className="flex flex-wrap">
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <Tooltip
                anchorId="uavailable-tooltip"
                content="Market Insights Unavailable"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10"
                clickable
              />
              <div id="uavailable-tooltip">
                <KTSVG
                  className="flex items-center justify-center fill-violet-200"
                  path="other_icons/Market-Insights-Green.svg"
                />
              </div>
            </div>
            <div className="text-gray-400">Unavailable</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-sky-blue-500 transition"
                path="other_icons/Upload.svg"
              />
            </div>
            <div className="text-gray-400">Ticket uploaded</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-sky-blue-500 transition"
                path="other_icons/PPE.svg"
              />
            </div>
            <div className="text-gray-400">PPE set</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="relative flex items-center justify-center h-6 w-6 mx-auto mb-3">
              <span className="absolute right-0.5 top-0 flex items-center justify-center leading-3 h-3 w-3 border border-white rounded-full bg-violet-500 text-white text-[0.44rem] font-semibold pr-[1px]">
                1
              </span>
              <KTSVG
                className="flex items-center justify-center fill-sky-blue-500 transition"
                path="other_icons/Tags.svg"
              />
            </div>
            <div className="text-gray-400">Tag/note set</div>
          </div>
          <div className="max-w-[8.75rem] mx-4 mb-6 text-center">
            <div className="relative flex items-center justify-center h-6 w-6 mx-auto mb-3">
              <span className="absolute right-0.5 top-0 flex items-center justify-center leading-3 h-3 w-3 border border-white rounded-full bg-violet-500 text-white text-[0.44rem] font-semibold pr-[1px]">
                4
              </span>
              <KTSVG
                className="flex items-center justify-center fill-orange-500 transition"
                path="other_icons/Tags.svg"
              />
            </div>
            <div className="text-gray-400">
              Top-priority tag colour displays
            </div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-green-600"
                path="other_icons/Publish-Gray.svg"
              />
            </div>
            <div className="text-gray-400">Published</div>
          </div>
          <div className="mx-4 mb-6 text-center">
            <div className="flex items-center justify-center h-7 w-7 mx-auto mb-3">
              <KTSVG
                className="flex items-center justify-center fill-indigo-500"
                path="other_icons/Inventory-Light-Purple.svg"
              />
            </div>
            <div className="text-gray-400">Inventory only</div>
          </div>
        </div>
      </div>

      {/* Form Fiels & Dropdowns */}
      <div className="p-16 border-b">
        <h2 className="font-semibold text-lg mb-5">Keyboard</h2>
        <div className="w-full md:w-3/4 lg:w-1/2 xl:w-1/3 py-[.0625rem] mb-8">
          <MultiSelect
            classNamePrefix="inventory_select"
            className="searchbar-wrap"
            options={[
              {
                id: "1",
                value: "harry",
              },
              {
                id: "2",
                value: "Manchester",
              },
            ]}
          />
        </div>

        <div className="flex flex-wrap gap-8">
          <div className="max-w-[11.44rem] w-full">
            <MultiDropDownField
              name="marketplaces"
              options={benefits}
              classNamePrefix={"form_multiDropDown"}
              placeholder="Marketplaces"
              handleOnChange={(data: any) => data}
            />
          </div>
          <div className="max-w-[11.44rem] w-full">
            <DropDownField
              options={options}
              placeholder="Ticket type"
              name="marketplace"
              handleOnChange={(data: any) => { }}
            />
          </div>
          <div className="max-w-[11.44rem] w-full">
            <Textfield
              name="quantity"
              type="text"
              label="Quantity"
              id="quantity"
              isFocus={(data: any) => { }}
              handleOnChange={(data: any) => data}
            />
          </div>
          <div className="max-w-[11.44rem] w-full">
            <PriceField />
          </div>
          <div className="max-w-[11.44rem] w-full">
            <Checkbox
              label="PPE"
              tooltip="true"
              handleOnchange={(data: any) => { }}
            />
          </div>
          <div className="max-w-[11.44rem] w-full">
            <DatePickerField
              name="date1"
              placeholder="00/00/00"
              value={new Date()}
              handleOnChange={() => { }}
            />
          </div>
          <div className="max-w-[11.44rem] w-full">
            <DatePickerField
              name="performerDateRange"
              placeholder="Date range"
              isDateRange={true}
              handleOnChange={() => { }}
            />
          </div>
          <div className="max-w-[11.44rem] w-full">
            <SearchableMultiDropdown
              name="marketplaces"
              options={benefits}
              classNamePrefix={"searchable_form_multiDropDown"}
              placeholder="Marketplaces"
              handleOnChange={(data: any) => data}
            />
          </div>
        </div>
      </div>

      {/* Buttons */}
      <div className="p-16 border-b">
        <h2 className="font-semibold text-lg mb-5">Buttons</h2>
        <div className="flex flex-wrap">
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="relative inline-flex items-center z-10 text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase after:content-[''] after:h-2/4 after:w-full after:bg-violet-400 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 after:-z-10"
            >
              <KTSVG
                className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-white"
                path="other_icons/plus.svg"
              />
              Add Listing
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="relative z-10 text-white bg-violet-500 hover:bg-white active:bg-indigo-500 border border-violet-500 hover:border-indigo-500 hover:text-indigo-500 active:text-white transition font-semibold rounded text-sm13 px-2.5 py-0.5 after:content-[''] after:h-2/4 after:w-full after:bg-violet-400 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 active:after:opacity-0 after:-z-10"
            >
              Add to Inventory
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="relative z-10 border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase after:content-[''] after:h-2/4 after:w-full after:bg-green-500 after:absolute after:top-0 after:left-0 after:rounded after:transition hover:after:opacity-0 active:after:opacity-0 after:-z-10"
            >
              PUBLISH LIVE
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="inline-flex items-center group text-violet-800 hover:text-white bg-gray-100 
             hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
            >
              <KTSVG
                className="min-w-[0.625rem] max-w-[0.63rem] mr-1 flex items-center justify-center fill-violet-500 group-hover:fill-white transition"
                path="other_icons/Clone.svg"
              />
              Clone
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
            bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
            >
              Cancel
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="inline-flex items-center text-white bg-green-500 hover:bg-indigo-500 transition
              font-medium rounded text-sm13 px-2.5 py-0.5"
            >
              <KTSVG
                className="min-w-[0.75rem] max-w-[0.75rem] mr-1 flex items-center justify-center fill-white"
                path="other_icons/edit-square.svg"
              />
              Confirm
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="border hover:border-indigo-500 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
            >
              Hide errors
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="text-rose-500 hover:text-white bg-white hover:bg-rose-500 border hover:border-rose-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
            >
              Remove affected marketplace(s)
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="border border-green-600 text-white hover:text-green-600 bg-green-600 hover:bg-white transition font-medium rounded text-sm13 px-2.5 py-0.5"
            >
              Review & fix errors
            </button>
          </div>
          <div className="mr-20 mb-5">
            <button
              type="button"
              className="text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
            >
              Go to Inventory
            </button>
          </div>
          <div className="mr-10 mb-5 mt-1">
            <button
              type="button"
              className="flex items-center justify-center h-6 w-6 text-white bg-violet-500 hover:bg-indigo-500 transition rounded"
            >
              <KTSVG className="fill-white" path="other_icons/check.svg" />
            </button>
            <div className="text-gray-400 mt-1">confirm</div>
          </div>
          <div className="mr-10 mb-5 mt-1">
            <button
              type="button"
              className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
            >
              <KTSVG
                className="fill-violet-500 group-hover:fill-white transition"
                path="standard_icons/cross.svg"
              />
            </button>
            <div className="text-gray-400 mt-1">cancel</div>
          </div>
          <div className="mb-5 mt-1">
            <button
              type="button"
              className="group flex items-center justify-center h-6 w-6 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
            >
              <KTSVG
                className="fill-violet-500 group-hover:fill-white transition"
                path="other_icons/refresh.svg"
              />
            </button>
            <div className="text-gray-400 mt-1">reset</div>
          </div>
        </div>

        {/* Toggle buttons */}
        <div className="flex flex-wrap items-center pt-10">
          <div className="w-1/2">
            <div className="inline-flex flex-row-reverse p-0.5 mr-0.5 border border-indigo-500/20 rounded bg-white mb-3">
              <div className="relative flex items-center">
                <input
                  id="search-radio-2"
                  type="radio"
                  name="search-radio"
                  className="absolute hidden peer"
                  defaultChecked
                />
                <label
                  htmlFor="search-radio-2"
                  className="py-0.5 px-1 rounded text-indigo-500 peer-checked:text-white peer-checked:bg-indigo-500 peer-checked:shadow transition text-xxs cursor-pointer"
                >
                  {" "}
                  Performer/venue
                </label>
              </div>
              <div className="relative flex items-center">
                <input
                  id="search-radio-1"
                  type="radio"
                  name="search-radio"
                  className="absolute hidden peer"
                  defaultChecked
                />
                <label
                  htmlFor="search-radio-1"
                  className="py-0.5 px-1 rounded text-indigo-500 peer-checked:text-white peer-checked:bg-indigo-500 peer-checked:shadow transition text-xxs cursor-pointer"
                >
                  {" "}
                  Event
                </label>
              </div>
            </div>
            <div className="text-gray-400">
              Search toggle: Event & Performer/venue
            </div>
          </div>
          <div className="w-1/2">
            <label className="relative inline-flex items-center mb-5 cursor-pointer">
              <input type="checkbox" value="" className="sr-only peer" />
              <div className="w-7 h-3 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:bg-indigo-500 peer-checked:bg-indigo-500 peer-checked:bg-opacity-50 after:content-[''] after:absolute after:-top-0.5 after:-left-0.5 after:bg-violet-500 after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 peer-checked:after:bg-tick after:bg-center after:bg-no-repeat after:bg-8"></div>
            </label>
            <div className="text-gray-400">Normal toggle</div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Typography;
