import { useContext } from "react";
import { default as leffArrow } from "../../../../../assets/media/icons/other_icons/leftborderArrow.svg";
import { default as CrossSVG } from "../../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import {
  NEW_ORDER_INITIAL_FILTER_STATE,
  ORDER_ASSIGN_TO_NEW_LISTING,
  ORDER_ASSIGN_TO_NEW_TEAM,
} from "../../../../../helpers/const";
import { SalesContext } from "../../../core/SalesProvider";

const BackToOrder = ({
  backToOrderListingStatus,
  assign = "listing",
  confirmed,
}: any) => {
  const layout = useContext(SalesContext);

  let BackStatus: string = "order";

  if (assign === "team" && confirmed) {
    BackStatus = "edit";
  } else if (
    assign === "listing" &&
    layout?.orderAssign?.backToOrderListingStatus
  ) {
    BackStatus = "listings";
    if (confirmed) {
      BackStatus = "edit";
    }
  }

  // CANCEL/BACK TO LISTING
  const handleOrderAssign = () => {
    backToOrderListingStatus === undefined &&
      layout.setCustomNewOrderLoader(true);

    if (assign === "listing") {
      layout?.setOrderAssign((current: any) => {
        if (BackStatus === "edit") {
          return {
            ...current,
            reviewReplacement: true,
            confirmedReplacement: false,
          };
        } else {
          backToOrderListingStatus === undefined &&
            layout.setOrderNewFilterHandle(NEW_ORDER_INITIAL_FILTER_STATE);
          return {
            ...current,
            backToOrderListingStatus: backToOrderListingStatus,
            ...(backToOrderListingStatus === undefined && {
              status: false,
              backToOrderListingStatus: false,
              data: undefined,
            }),
          };
        }
      });
    } else if (assign === "team") {
      layout?.setOrderAssignNewTeam((current: any) => {
        if (BackStatus === "edit") {
          return {
            ...current,
            confirmed: false,
          };
        } else {
          return {
            ...current,
            status: false,
          };
        }
      });
    }
  };

  return (
    <div className="border-t flex justify-between items-center border-b sticky top-0 bg-white z-[60]">
      <div className="top-row flex justify-between w-full">
        <div className="left_block flex items-center flex-1 flex-wrap">
          <button
            type="button"
            className="back_to text-sm13 flex items-center md:pl-5 md:pr-4 py-3 px-2.5 border-r cursor-pointer group hover:text-indigo-500"
            onClick={handleOrderAssign}
          >
            <IKTSVG path={leffArrow} className=" mr-2.5" />
            Back to {BackStatus}
          </button>
          <h4 className="md:text-sm15 text-sm13 font-semibold md:pl-[.9375rem] pl-2.5 flex-1 ellips-text">
            Assign order to new {assign === "team" ? "team" : "listing"}:{" "}
            {assign === "team"
              ? layout?.orderAssignNewTeam?.data?.order_id
              : layout?.orderAssign?.data?.order_id}
          </h4>
        </div>
        <button
          className="group crossIcon w-10 border-l flex items-center justify-center"
          type="button"
          onClick={() => {
            layout.setOrderAssignNewTeam(ORDER_ASSIGN_TO_NEW_TEAM);
            layout.setOrderAssign(ORDER_ASSIGN_TO_NEW_LISTING);
            layout.setOrderNewFilterHandle(NEW_ORDER_INITIAL_FILTER_STATE);
          }}
        >
          <IKTSVG
            className={`fill-violet-500 group-hover:fill-indigo-500 transition`}
            svgClassName="w-[.6563rem] h-[.6563rem]"
            path={CrossSVG}
          />
        </button>
      </div>
    </div>
  );
};

export default BackToOrder;
