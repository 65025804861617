import React from "react";

const DataCell = ({ title, capitalize = false }: any) => {
  return (
    <td className={`md:px-4 px-3 py-4 font-medium  `}>
      <p className={`${capitalize && "capitalize"}`}>{title}</p>
    </td>
  );
};
export default DataCell;
