const PayoutAccordionHeader = () => {
  return (
    <table className="w-full">
      <tr className="border-b bg-white text-sm12 text-gray-400">
        <td className="py-2 pr-1.5 pl-4 whitespace-nowrap font-medium min-w-[8.125rem] w-[8.125rem] ">
          Date
        </td>
        <td className="py-2 px-1.5 font-medium min-w-[7.5rem] w-[7.5rem]">
          Order ID
        </td>
        <td className="py-2 px-1.5 whitespace-nowrap font-medium min-w-[7.5rem] w-[7.5rem]">
          Price
        </td>
        <td className="py-2 px-1.5 font-medium">Event name</td>
        <td className="ml-auto font-medium group px-4 py-2.5 min-w-[2.5rem] w-[2.5rem]"></td>
      </tr>
    </table>
  );
};

export default PayoutAccordionHeader;
