import React from "react";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import crossSVG from "../../../assets/media/icons/standard_icons/cross.svg";

interface FilterChipInterface {
  title: string;
  indexNumber: number;
  handleOnClick: any;
}

const FilterChipBox: React.FC<FilterChipInterface> = ({
  title,
  indexNumber,
  handleOnClick,
}: any) => {
  return (
    <div className="flex py-1 px-2.5 text-xs border rounded-full my-0.5">
      {title}
      <button
        type="button"
        className="ml-2 hover:fill-indigo-500"
        onClick={(e: any) => handleOnClick(indexNumber)}
      >
        <IKTSVG path={crossSVG} svgClassName="h-2 w-2" />
      </button>
    </div>
  );
};

export default FilterChipBox;
