import Amex from "../../../assets/media/icons/other_icons/amex.svg";
import dinerClub from "../../../assets/media/icons/other_icons/diners.svg";
import Discover from "../../../assets/media/icons/other_icons/discover.svg";
import JCB from "../../../assets/media/icons/other_icons/jcb.svg";
import MastercardSVG from "../../../assets/media/icons/other_icons/mastercard.svg";
import random from "../../../assets/media/icons/other_icons/random.svg";
import UnionPay from "../../../assets/media/icons/other_icons/unionpay.svg";
import Visa from "../../../assets/media/icons/other_icons/visa.svg";

const display = (showOrder: any, ref: any) => {
  var pop_width =
    document?.getElementsByClassName("buy_canvas")?.[0]?.clientWidth;
  var body = document?.querySelector("body");
  const EventAccordion: any = document?.getElementById("EventAccordion");
  if (showOrder) {
    if (EventAccordion) {
      EventAccordion.transition = "all .3s";
      EventAccordion.style.marginRight = `${pop_width}px`;
    }
    // document.body.style.overflow = "hidden";
    body?.classList?.add("scrollbar-none");
  } else {
    if (EventAccordion) {
      EventAccordion.style.marginRight = `0px`;
      EventAccordion.transition = "none";
    }
    // document.body.style.overflow = "unset";
    body?.classList?.remove("scrollbar-none");
  }
};

const displayFAQ = (showOrder: any, ref: any) => {
  var pop_width =
    document?.getElementsByClassName("buy_canvas")?.[0]?.clientWidth;
  var body = document?.querySelector("body");
  const EventAccordion: any = document?.getElementById("EventAccordion");
  if (showOrder) {
    if (EventAccordion) {
      EventAccordion.transition = "all .3s";
      EventAccordion.style.marginRight = `${pop_width}px`;
    }
    // document.body.style.overflow = "hidden";
    body?.classList?.add("scrollbar-none");
  } else {
    if (EventAccordion) {
      EventAccordion.style.marginRight = `0px`;
      EventAccordion.transition = "none";
    }
    // document.body.style.overflow = "unset";
    body?.classList?.remove("scrollbar-none");
  }
};

const calculateTicketTypeOptions = (
  split_type: any,
  maxDisplay: any,
  quantity: any,
  split_quantity: any
) => {
  let priorityQuantity: any = quantity;
  if (maxDisplay && maxDisplay <= quantity) {
    priorityQuantity = maxDisplay;
  }
  if (split_type === "No Preferences" || split_type === "Single Tickets") {
    const options = Array.from({ length: priorityQuantity }, (_, index) => ({
      id: index + 1,
      name: index + 1,
    }));
    return options;
  } else if (split_type === "All Together") {
    return [
      {
        id: priorityQuantity,
        name: priorityQuantity,
      },
    ];
  } else if (split_type === "Avoid Leaving One Ticket") {
    const options = Array.from({ length: priorityQuantity }, (_, index) => ({
      id: index + 1,
      name: index + 1,
    }));
    if (options.length >= 2) {
      options.splice(-2, 1);
    }
    return options;
  } else if (split_type === "Sell In Multiples") {
    let tempQuantity = priorityQuantity;
    if (split_quantity && tempQuantity && split_quantity <= tempQuantity) {
      const options = Array.from(
        { length: Math.floor(tempQuantity / split_quantity) },
        (_, index) => ({
          id: (index + 1) * split_quantity,
          name: (index + 1) * split_quantity,
        })
      );
      return options;
    }
  }
};

function addBenefitsRestrictions(
  obj: any,
  require_attendee_details: number,
  require_attendee_id: number
) {
  !Array.isArray(obj) && (obj = []);
  require_attendee_details === 1 &&
    (obj = [...obj, "Attendee delivery details required"]);
  require_attendee_id === 1 &&
    (obj = [...obj, "Attendee identification details required"]);
  return obj;
}

function benefitsRestrictions(
  obj: any,
  require_attendee_details: number,
  require_attendee_id: number
) {
  obj = addBenefitsRestrictions(
    obj,
    require_attendee_details,
    require_attendee_id
  );
  if (obj.length > 0) {
    return `<div>
        <p class='text-gray-500 text-left'>Benefits/Restrictions</p>
        <ul class='py-0.5 grid grid-cols-2 gap-x-4'>
          ${obj
            ?.map(
              (innerObj: any, index: number) =>
                `<li key=${index} class='text-left col-span-1'>- ${innerObj}</li>`
            )
            .join("")}
        </ul>
      </div>`;
  } else {
    return "";
  }
}

function toolTipForCommissionFee(data: string) {
  return `<div class="text-center">
  Card payments incur a ${data}% <br/> service fee. TX Pay purchases <br/>  do not incur a fee.
  <div/>`;
}

function findCreditCardImg(data: any) {
  return data === "Mastercard"
    ? MastercardSVG
    : data === "Visa"
    ? Visa
    : data === "Amex"
    ? Amex
    : data === "Diners"
    ? dinerClub
    : data === "Discover"
    ? Discover
    : data === "Unionpay"
    ? UnionPay
    : data === "Jcb"
    ? JCB
    : random;
}

const getBodyScroll = () => {
  const bodyTag: HTMLBodyElement | null = document.querySelector("body");
  if (bodyTag) {
    bodyTag.style.overflowY = "visible";
  }
};

const stringSpaceToHypen = (originalString: any) => {
  let words = originalString?.split(" ");
  let convertedWords = words?.map((word: any) => word?.toLowerCase());
  let convertedString = convertedWords?.join("-");
  return convertedString;
};

const hypenToSpaceConversion = (data: any) => {
  return data?.replace(/[_-]/g, " ");
};
const spaceToHypenConversion = (data: any) => {
  return data.replace(/-(?=[^-]*$)/, "_");
};

const toggleSectionColor = (
  matchedSection: any = "",
  toRemove: boolean = false
) => {
  const sectionsBlocks = document.querySelectorAll("[data-section]");
  sectionsBlocks?.forEach((element: any) => {
    let sec = element?.getAttribute("data-section");
    if (sec === matchedSection && !toRemove) {
      element?.classList?.add("colored");
    } else {
      element?.classList?.remove("colored");
    }
  });
};

const removeCategoryColor = () => {
  const categorysBlock = document.querySelectorAll("[data-category]");
  categorysBlock?.forEach((element: any) => {
    element?.classList?.remove("colored");
  });
};

export {
  addBenefitsRestrictions,
  benefitsRestrictions,
  calculateTicketTypeOptions,
  display,
  displayFAQ,
  findCreditCardImg,
  getBodyScroll,
  hypenToSpaceConversion,
  removeCategoryColor,
  spaceToHypenConversion,
  stringSpaceToHypen,
  toggleSectionColor,
  toolTipForCommissionFee,
};
