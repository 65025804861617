export const CUSTOMIZE_TAB_KEYS = {
  DESIGN_MENU: "design_menu",
  DESIGN_HOME_PAGE: "design_home_page",
  CONTENT_PAGE: "content_page",
};

export const CUSTOMIZE_TABS = [
  { title: "Design menu", key: CUSTOMIZE_TAB_KEYS.DESIGN_MENU },
  { title: "Design home page", key: CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE },
  { title: "Content", key: CUSTOMIZE_TAB_KEYS.CONTENT_PAGE },
];

export const TYPES: any = {
  Event: "event",
  Performer: "performer",
  Venue: "venue",
  Category: "category",
};

export const INITIAL_TYPES = Object.fromEntries(
  Object.entries(TYPES).map(([key, value]) => [value, key])
);
