import { decimalNumber } from "helpers/Functions";
import { InventoryContext } from "pages/inventory/core/InventoryProvider";
import { useContext } from "react";
import { SYMBOLS } from "../../../../../helpers/const";
import Image from "../mobileViewComponent/Image";

type TPPPWrapper = {
  eventRecord: Record<string, any>;
  eIndex: any;
  firstSelectedIndex?: any;
};

const PPPWrapper = ({
  eventRecord,
  eIndex,
  firstSelectedIndex,
}: TPPPWrapper) => {
  let pngImagePath = "/media/png/";

  const layout = useContext(InventoryContext);

  return eventRecord?.ppeIsOpen ||
    (layout.isTableEditable?.length > 0 &&
      (firstSelectedIndex?.price_per_exchange === 1 ||
        firstSelectedIndex?.price_per_exchange === true) &&
      firstSelectedIndex?.ppeIsOpen) ? (
    <div className="PPEViewFields">
      <div className="flex border-r w-[2.5rem] min-h-[1.9375rem] sticky left-0 z-50 checkbox_td bg-inherit">
        <label className="relative w-full h-full z-100 bg-inherit"></label>
      </div>
      <div className="PPEFieldView">
        <div className="PPEToggle">
          <span>PPP</span>
          <div className="flex group pl-[.3125rem] pr-2 ">
            <Image
              path={`${pngImagePath}info-gray.png`}
              className="w-[.875rem] h-[.875rem] group-hover:hidden"
              alt=""
            />
            <Image
              path={`${pngImagePath}info-purple.png`}
              className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
              alt=""
            />
          </div>
          <span className="PPEToggleResponde">
            {eventRecord?.price_per_exchange ? "Yes" : "No"}
          </span>
        </div>
        <div
          className={`singleMKTPrice ${
            layout.tableDataEditChanging?.[eIndex]?.includes("sell_price") &&
            "edited"
          } `}
        >
          <div className="label">
            TX Network
            <div className="flex group pl-1">
              <Image
                path={`${pngImagePath}info-gray.png`}
                className="w-[.875rem] h-[.875rem] group-hover:hidden"
                alt=""
              />
              <Image
                path={`${pngImagePath}info-purple.png`}
                className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
                alt=""
              />
            </div>
          </div>
          <div
            className="seventeen-col"
            data-sybmol-attribute={SYMBOLS[eventRecord?.sell_price_currency]}
          >
            <span>
              {typeof eventRecord?.sell_price === "object"
                ? eventRecord?.sell_price?.value
                : decimalNumber(eventRecord?.sell_price)}
            </span>
          </div>
        </div>
        {eventRecord?.ppePartners?.map((partner: any) => {
          if (partner?.name && !partner?.is_hidden) {
            return (
              <div
                className={`singleMKTPrice ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    partner?.name
                  ) && "edited"
                }`}
              >
                <div className="label">
                  {partner?.name}
                  {partner?.name === "TxTrade" && (
                    <div className="flex group pl-1">
                      <Image
                        path={`${pngImagePath}info-gray.png`}
                        className="w-[.875rem] h-[.875rem] group-hover:hidden"
                      />
                      <Image
                        path={`${pngImagePath}info-purple.png`}
                        className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
                      />
                    </div>
                  )}
                </div>
                <div
                  className={`seventeen-col   `}
                  data-sybmol-attribute={SYMBOLS[partner?.value?.symbol]}
                >
                  <span>{decimalNumber(partner?.value?.value)}</span>
                </div>
              </div>
            );
          } else return null;
        })}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default PPPWrapper;
