/* eslint-disable array-callback-return */
import React, { memo, useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import AngleLeft from "../../../../assets/media/icons/standard_icons/angle_left.svg";
import AngleRight from "../../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";

const InventoryPagination = memo(
  ({
    paginateData,
    handleOnChange,
    isBulkActive,
    shimmerLoader,
    isGodAdmin,
    uniqueKey,
  }: any) => {
    const pageSizeList = [
      10,
      15,
      20,
      25,
      ...(isGodAdmin ? [30, 35, 40] : [35]),
      50,
    ];

    const [currentPage, setCurrentPage] = useState<any>(0);
    const [paginatationData, setPaginateData] = useState<any>(1);

    useEffect(() => {
      if (paginateData?.current_page) {
        let paginate =
          paginateData?.total &&
          Number.isInteger(paginateData?.total) &&
          Math.ceil(
            Number(paginateData?.total) / Number(paginateData?.per_page)
          );

        setCurrentPage(paginateData?.current_page);
        setPaginateData(paginate);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [paginateData]);

    return (
      <React.Fragment key={uniqueKey}>
        <div
          className={`flex items-center px-5 py-3 border-l md:gap-x-5 gap-x-2 w-full md:w-auto max-md:order-4 max-md:justify-end max-md:border-t ${
            isBulkActive ? "pointer-events-none" : ""
          }`}
        >
          {shimmerLoader ? (
            <div className="flex gap-5">
              {Array.from({ length: 1 }, (v, i) => (
                <div className="flex gap-5" key={i}>
                  <div
                    className={`flex !rounded overflow-hidden ${
                      shimmerLoader && "shimmer-effect"
                    }`}
                    style={{ height: "26px", width: "250px" }}
                  >
                    &nbsp;
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <>
              <div className="flex items-center text-xs gap-x-2">
                <span>View</span>
                <select
                  name="per_page"
                  id="per_page"
                  className="border-gray-300 focus:border-indigo-500 hover:border-indigo-500 rounded py-1 pl-2 text-xs"
                  onChange={handleOnChange}
                  value={paginateData?.per_page}
                >
                  {pageSizeList?.length > 0 &&
                    pageSizeList.map((page, pIndex: number) => {
                      return (
                        <option value={page} key={`project-${pIndex}`}>
                          {page}
                        </option>
                      );
                    })}
                </select>
              </div>

              <div className="flex items-center text-xs gap-x-2 navigation_pg">
                <span>Page</span>
                <input
                  type="number"
                  name="current-page-number"
                  value={currentPage}
                  max={10}
                  readOnly
                  className="w-6 h-6 text-center border border-gray-300 rounded p-1 text-xs focus:border-indigo-500 "
                  onChange={(e: any) => {
                    if (e.target.value <= paginateData.last_page) {
                      handleOnChange(e);
                      setCurrentPage(e.target.value);
                    }
                  }}
                />
                <span>of {paginateData?.last_page}</span>
                <ReactPaginate
                  // initialPage={0}
                  forcePage={currentPage === 0 ? currentPage : currentPage - 1}
                  previousLabel={
                    <button
                      type="button"
                      className=" group rounded-l transition py-2 px-[.5625rem]"
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={AngleLeft}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                  }
                  nextLabel={
                    <button
                      className="group rounded-r transition py-2 px-[.5625rem]"
                      type="button"
                    >
                      <IKTSVG
                        className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
                        path={AngleRight}
                        svgClassName="w-1.5 h-[.5625rem]"
                      />
                    </button>
                  }
                  pageCount={paginatationData}
                  onPageChange={handleOnChange}
                  pageClassName="pg_num hidden flex cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg"
                  activeClassName="active:bg-red-500"
                />
              </div>
            </>
          )}
        </div>
      </React.Fragment>
    );
  }
);

export default InventoryPagination;
