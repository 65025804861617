import { useContext, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { storeFields } from "../../../components/core/requests";
import {
  columnsOutSideClick,
  outSideClick,
} from "../../../helpers/AssetHelpers";
import { existInLocalstorage } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import { SalesContext } from "../core/SalesProvider";
import DraggableColumns from "./DraggableColumns";
import DraggableFilters from "./DraggableFilters";

const HeaderfilterContainer = () => {
  const bulkActionRef: any = useRef(null);
  const bulkAction1Ref: any = useRef(null);
  const layout = useContext(SalesContext);
  const globalLayout = useContext(LayoutContext);

  // console.log(globalLayout?.headerDraggableFields);

  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveSalesFilter;

  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.salesTableColumn;

  let payload: any = [
    {
      key: `saveSalesFilter`,
      value: layout.filterItems,
    },
    {
      key: `salesTableColumn`,
      value: layout.columnItems,
    },
  ];
  // console.log(payload, "payload");

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, "saveSalesFilter")
    );
    document.addEventListener("mousedown", (e: any) =>
      columnsOutSideClick(e, "salesTableColumn")
    );
    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, "saveSalesFilter")
      );
      document.removeEventListener("mousedown", (e: any) =>
        columnsOutSideClick(e, "salesTableColumn")
      );
    };
  }, []);

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );
  useEffect(() => {
    if (layout.filterItems && existInLocalstorage("saveSalesFilter")) {
      layout.setFilterItems(existInLocalstorage("saveSalesFilter"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("saveSalesFilter");
      }, 3000);
    }

    if (layout.columnItems && existInLocalstorage("salesTableColumn")) {
      layout.setColumnItems(existInLocalstorage("salesTableColumn"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("salesTableColumn");
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      setTimeout(() => {
        layout.setFilterItems(isFilterExistInDB);
        layout.setDraggedItems(isFilterExistInDB);
      }, 1000);
    }
    if (isColumnFilterExistInDB) {
      setTimeout(() => {
        layout.setColumnItems(isColumnFilterExistInDB);
        layout.setDraggedColumns(isColumnFilterExistInDB);
      }, 1000);
    }
  }, [isFilterExistInDB, isColumnFilterExistInDB]);

  return (
    <div className="flex flex-wrap items-center" id="filters">
      <div className="ba_item relative max-md:hidden" ref={bulkActionRef}>
        <span
          className="sel_item block py-0.5 md:pr-6 pr-5 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#saveSalesFilter"
          aria-expanded="false"
          aria-controls="saveSalesFilter"
        >
          Filters
        </span>
        <div
          id="saveSalesFilter"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden"
          data-te-collapse-item
          aria-labelledby="saveSalesFilter"
          data-te-parent="#filters"
        >
          <DraggableFilters refetch={refetch} />
        </div>
      </div>

      <div className="ba_item relative" ref={bulkAction1Ref}>
        <span
          className="sel_item block py-0.5 md:pr-6 pr-5 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#salesTableColumn"
          aria-expanded="false"
          aria-controls="salesTableColumn"
        >
          Columns
        </span>
        <div
          id="salesTableColumn"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden cursor-pointer"
          data-te-collapse-item
          aria-labelledby="salesTableColumn"
          data-te-parent="#filters"
        >
          <DraggableColumns refetch={refetch} />
        </div>
      </div>
    </div>
  );
};

export default HeaderfilterContainer;
