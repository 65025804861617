import { useContext, useEffect, useState } from "react";
import backToTOp from "../../../assets/media/icons/other_icons/backtotop.svg";
import CollapseAllSVG from "../../../assets/media/icons/standard_icons/Collapse.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import CSVExport from "../component/CSVExport";
import { TXContext } from "../core/TXProvider";

const Footer = () => {
  const [collapse, setCollapse] = useState(false);
  const [isCollapse, setIsCollapse] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const layout = useContext(TXContext);

  // COLLAPSE ALL FUNCTION
  const handleCollapseAll = () => {
    // setCollapse(!collapse);
    const updatedInput = layout?.items?.map((item: any) => ({
      ...item,
      isOpen: false,
    }));
    layout.setItems(updatedInput);
    setIsCollapse(false);
  };

  // Function to scroll back to the top
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // Function to show/hide the button based on scroll position
  const handleScroll = () => {
    const scrollPosition = window.pageYOffset;

    // Show the button when the user scrolls down 400px
    if (scrollPosition > 250) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  // Add event listener when the component mounts
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    let isButtonExpanded = false;
    const accordions: any = document.querySelectorAll(".title-accordion");
    accordions.forEach((accordion: any) => {
      if (accordion.getAttribute("aria-expanded") === "true") {
        isButtonExpanded = true;
      }
    });
    setCollapse(isButtonExpanded);
  }, [collapse, layout.items]);

  return (
    <>
      <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-3 bg-white shadow-2xl shadow-black z-20 min-h-[3.125rem]">
        <button
          type="button"
          className={`bg-violet-550 hover:bg-indigo-500 rounded-l w-[1.875rem] h-[1.875rem] items-center justify-center flex fixed bottom-[4.0625rem] right-0 transition duration-300 ${
            isVisible ? "opacity-100 visible" : "opacity-0 invisible"
          }`}
          id="backtotop"
          onClick={scrollToTop}
        >
          <IKTSVG path={backToTOp} svgClassName="w-[.9375rem] h-[.9375rem]" />
        </button>

        <div className="flex flex-wrap justify-between w-full">
          {layout?.items?.length > 0 && (
            <div className="flex flex-wrap items-center gap-2 my-0.5">
              <CSVExport />
            </div>
          )}

          <div className="flex justify-center items-center ml-auto">
            {layout?.items?.length > 0 && (
              <button
                className={`
                ${isCollapse ? "pointer-events-none" : ""}
                 inline-flex group items-center text-violet-800 hover:text-white bg-gray-100
              hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                type="button"
                id={"collapse-tx-pay"}
                data-te-target={`.accordion-cont${
                  collapse ? `[data-te-collapse-show]` : ""
                }`}
                data-te-collapse-init
                onClick={() => {
                  setIsCollapse(true);
                  handleCollapseAll();
                  // setTimeout(() => {
                  //   handleCollapseAll();
                  // }, 700);
                }}
              >
                <IKTSVG
                  className={`min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white 
                ${!collapse && `rotate-180`} `}
                  path={CollapseAllSVG}
                />
                {collapse ? "Collapse all" : "Expand all"}
              </button>
            )}
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
