const FormRow = ({
  headerTitle,
  handleSeller,
  children,
  className = "seller_info pb-2.5",
  error,
  headerClassName = "text-sm15 font-basier_med leading-5 flex-1",
  isAsterickRequired = true,
  mobileHalfWidth = false,
  questionmarkNedeed = "?",
  titleNedeed = true,
}: any) => {
  return (
    <>
      <div className={className}>
        <div
          className={`title flex flex-wrap justify-between items-center md:mb-5 mb-[.9375rem] ${
            !titleNedeed && "hidden"
          }`}
        >
          <h4 className={headerClassName}>
            {headerTitle}
            {questionmarkNedeed}
            {isAsterickRequired && (
              <span
                className={`ml-1 ${error ? `text-red-500` : `text-gray-400`} `}
              >
                *
              </span>
            )}
          </h4>
        </div>
        <div
          className={`seller_type flex flex-wrap   ${
            mobileHalfWidth ? "-mx-[.4688rem] md:-mx-2.5" : "-mx-2.5"
          }`}
        >
          {children}
        </div>
      </div>
    </>
  );
};

export default FormRow;
