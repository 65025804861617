import { useContext } from "react";
import TxAccount from "./TxAccount";
import DraftInvoice from "./component/DraftInvoice";
import OrderPayoutSeller from "./component/OrderPayoutSeller";
import PayoutsView from "./component/PayoutsView";
import DraftViewInvoice from "./component/invoiceTabComponent/DraftViewInvoice";
import { TxAccountContext } from "./core/TxAccountProvider";
import { PAYOUT_TAB } from "helpers/const";

const ContentWrapper = () => {
  const layout = useContext(TxAccountContext); 
  const commonConfirmation = layout?.commonConfirmation;
  const orderPayoutSeller = layout?.orderPayoutSeller;
  const tab = layout?.isTabActive;

  if (tab === "Orders") {
    if (commonConfirmation?.draft_invoice_next_step) {
      return <DraftInvoice />;
    } else if (orderPayoutSeller?.next_step) {
      return <OrderPayoutSeller />;
    } else {
      return <TxAccount />;
    }
  } else if (tab === "Invoices" && layout.invoicesViewData?.status) {
    return <DraftViewInvoice />;
  } else if (tab === PAYOUT_TAB && layout.payoutViewData?.view_status) {
    return <PayoutsView />;
  } else {
    return <TxAccount />;
  }
};

export default ContentWrapper;
