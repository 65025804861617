import { useFormik } from "formik";
import _, { size } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { useQuery } from "react-query";
import { Tooltip } from "react-tooltip";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import * as Yup from "yup";
import DeleteSVG from "../../../assets/media/icons/other_icons/Delete.svg";
import UploadSVG from "../../../assets/media/icons/other_icons/Upload.svg";
import twoArrows from "../../../assets/media/icons/other_icons/two-arrows.svg";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import DropDownField from "../../../components/formComponent/DropDownField";
import PriceField from "../../../components/formComponent/PriceField";
import Textfield from "../../../components/formComponent/Textfield";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { decimalNumber } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { SYMBOLS } from "../../../helpers/const";
import { getTrasnferFundsData, postTrasnferFundsData } from "../core/requests";

// INITITAL VALUES
const initialValues = {
  from_wallet: "",
  to_wallet: "",
  amount: "",
  from_desc: "",
  to_desc: "",
  from_files: [],
  to_files: [],
  date: moment().toDate(),
};

const TransferFundPopup = ({ popupOpen, refetch }: any) => {
  const intialState = {
    currentValue: "",
    fromWalletBalance: [],
    toWalletBalance: [],
    currentId: "",
  };

  const [submitLoader, setSubmitLoader] = useState<any>(false);

  const [dropDownChangeAndValues, setDropDownChangeAndValues] =
    useState<any>(intialState);

  const fundingSchema = Yup.object().shape({
    from_wallet: Yup.object().nullable().required("From wallet is required"),
    to_wallet: Yup.object().nullable().required("To wallet is required"),
    amount: Yup.object()
      .nullable()
      .required("Amount is required.")
      .test(
        "is-not-greater-than-from-wallet",
        "Amount must not be greater than from wallet balance",
        function (value: any) {
          const valueData: any =
            dropDownChangeAndValues?.fromWalletBalance?.find(
              (obj: any) => obj?.currency === value?.symbol
            );
          if (valueData?.balance === undefined) {
            return true;
          }
          return valueData?.balance && value?.value
            ? Number(value?.value) <= Number(valueData?.balance)
            : false;
        }
      ),
    from_desc: Yup.string().nullable().required("From description is required"),
    to_desc: Yup.string().nullable().required("To description is required"),
    date: Yup.date().required("Date is required"),
  });

  const formik: any = useFormik({
    initialValues: initialValues,
    validationSchema: fundingSchema,
    enableReinitialize: true,
    onSubmit: async (values: any, { resetForm }) => {
      const fromFiles: any = [];
      const toFiles: any = [];
      setSubmitLoader(true);

      for (let i = 0; i < values?.from_files.length; i++) {
        const file = values?.from_files[i];
        const object = {
          name: file.name,
          lastModified: file.lastModified,
          size: file.size,
          type: file.type,
          base64: file?.base64,
        };
        fromFiles.push(object);
      }

      for (let i = 0; i < values?.to_files.length; i++) {
        const file = values?.to_files[i];
        const object = {
          name: file.name,
          lastModified: file.lastModified,
          size: file.size,
          type: file.type,
          base64: file?.base64,
        };
        toFiles.push(object);
      }

      const payload = {
        ...(values?.from_wallet?.mc_account_id && {
          from_wallet_id: values?.from_wallet?.mc_account_id,
        }),
        ...(values?.to_wallet?.mc_account_id && {
          to_wallet_id: values?.to_wallet?.mc_account_id,
        }),
        ...(values?.amount?.value && { amount: values?.amount?.value }),
        ...(values?.amount?.symbol && { currency: values?.amount?.symbol }),
        ...(values?.to_desc && { to_description: values?.to_desc }),
        ...(values?.from_desc && { from_description: values?.from_desc }),
        ...(fromFiles?.length > 0 && {
          from_transfer_file: fromFiles,
        }),
        ...(toFiles?.length > 0 && {
          to_transfer_file: toFiles,
        }),
        date: moment(values?.date ? values?.date : new Date()).format(
          "YYYY-MM-DD"
        ),
      };
      await postTrasnferFundsData(payload)
        .then((res) => {
          if (res?.data?.status) {
            showAlert(res?.message, false, 1000);
            resetForm();
            const btn: any = document.getElementById("close-popup");
            if (btn) {
              btn.click();
              refetch();
            }
          } else if (!res?.data?.status) {
            showAlert(
              Array.isArray(res?.errors) && res?.errors?.title
                ? res?.errors?.title
                : size(res?.errors) > 0
                ? res?.errors?.join(", <br/>")
                : "Something went wrong!",
              true,
              1000
            );
          }
        })
        .catch((error) => {
          showAlert(error, true);
        })
        .finally(() => {
          setSubmitLoader(false);
        });
      setDropDownChangeAndValues(intialState);
    },
  });

  const { getInputProps: getFromFileInputProps, open: fromFileOpen } =
    useDropzone({
      // Disable click and keydown behavior
      maxSize: 5244899, // 5MB in bytes
      onDrop: (acceptedFiles, rejectedFiles) => {
        const uniqueCodes = Array.from(
          new Set(
            rejectedFiles.flatMap((item: any) =>
              item.errors.map((error: any) =>
                error.code === "file-too-large"
                  ? "Please upload less then 5MB"
                  : "Invalid File Type"
              )
            )
          )
        );
        if (uniqueCodes && uniqueCodes.length > 0) {
          showAlert(uniqueCodes?.join(", <br/>"), true);
        }
        if (
          acceptedFiles &&
          _.size([...acceptedFiles, ...formik?.values?.from_files]) > 5
        ) {
          showAlert("You can only select a maximum of five files", true);
        } else {
          // const uniqueAcceptedFiles = _.uniqBy(acceptedFiles, "name");
          // HANDLE FILE UPLOAD IN STATE
          formik.setFieldValue("from_files", [
            ...formik?.values?.from_files,
            ...acceptedFiles?.map((file) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => {
                // @ts-ignore
                file.base64 = reader.result.substr(
                  // @ts-ignore
                  reader.result.indexOf(",") + 1
                );
              };
              return file;
            }),
          ]);
        }
      },
      noClick: true,
      noKeyboard: true,
      accept: {
        "application/pdf": [],
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // .xlsx
        "application/vnd.ms-excel": [], // .xls
        "text/csv": [], // .csv
      },
    });

  const { getInputProps: getToFileInputProps, open: toFileOpen } = useDropzone({
    maxSize: 5244899,
    onDrop: (acceptedFiles, rejectedFiles) => {
      const uniqueCodes = Array.from(
        new Set(
          rejectedFiles.flatMap((item: any) =>
            item.errors.map((error: any) =>
              error.code === "file-too-large"
                ? "Please upload less then 5MB"
                : "Invalid File Type"
            )
          )
        )
      );
      if (uniqueCodes && uniqueCodes.length > 0) {
        showAlert(uniqueCodes?.join(", <br/>"), true);
      }
      if (
        acceptedFiles &&
        _.size([...acceptedFiles, ...formik.values.to_files]) > 5
      ) {
        showAlert("You can only select a maximum of five files", true);
      } else {
        // const uniqueAcceptedFiles = _.uniqBy(acceptedFiles, "name");
        // HANDLE FILE UPLOAD IN STATE
        formik.setFieldValue("to_files", [
          ...formik.values.to_files,
          ...acceptedFiles?.map((file) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              // @ts-ignore
              file.base64 = reader.result.substr(
                // @ts-ignore
                reader.result.indexOf(",") + 1
              );
            };
            return file;
          }),
        ]);
      }
    },
    noClick: true,
    noKeyboard: true,
    accept: {
      "application/pdf": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [], // .xlsx
      "application/vnd.ms-excel": [], // .xls
      "text/csv": [], // .csv
    },
  });
  const deleteFiles = (index: any, name: any) => {
    if (name === "to_files") {
      const newValue = formik.values.to_files?.filter(
        (obj: any, objIndex: any) => objIndex !== index
      );
      formik.setFieldValue("to_files", newValue);
    }
    if (name === "from_files") {
      const newValue = formik.values.from_files?.filter(
        (obj: any, objIndex: any) => objIndex !== index
      );
      formik.setFieldValue("from_files", newValue);
    }
  };

  const amountValueObj: any = formik.values.amount;
  const amountValue: any = amountValueObj?.value;

  const { isLoading: loading, data: transferData } = useQuery(
    [`transfer-funds`],
    () => getTrasnferFundsData(""),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: popupOpen ? true : false,
      cacheTime: 0,
    }
  );

  const { isLoading, data } = useQuery(
    [`transfer-funds`, dropDownChangeAndValues?.currentId],
    () => getTrasnferFundsData(dropDownChangeAndValues?.currentId),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: dropDownChangeAndValues?.currentId ? true : false,
      cacheTime: 0,
      onSettled: (data: any) => {
        setDropDownChangeAndValues((pre: any) => {
          if (dropDownChangeAndValues?.currentValue === "from_wallet") {
            return {
              ...pre,
              fromWalletBalance: data?.data?.account_balances,
              currentValue: "",
            };
          } else if (dropDownChangeAndValues?.currentValue === "to_wallet") {
            return {
              ...pre,
              toWalletBalance: data?.data?.account_balances,
              currentValue: "",
            };
          }
        });
      },
    }
  );

  const optionsData = getOptionsForFunds(transferData?.data?.txpay_wallets);
  function getOptionsForFunds(data: any) {
    if (data) {
      return data?.map((obj: any) => {
        return {
          ...obj,
          name: obj?.display_name,
          label: obj?.display_name,
          value: obj?.mc_account_id,
        };
      });
    }
    return [];
  }

  const fromOptions = optionsData?.filter(
    (obj: any) => obj?.value !== formik.values.to_wallet?.value
  );
  const toOpions = optionsData?.filter(
    (obj: any) => obj?.value !== formik.values.from_wallet?.value
  );

  const currrency: any = dropDownChangeAndValues?.fromWalletBalance
    ?.map((obj: any) =>
      SYMBOLS[obj?.currency] ? SYMBOLS[obj?.currency] : obj?.currency
    )
    ?.reduce((acc: any, item: any) => {
      const symbolKey = Object.keys(SYMBOLS).find(
        (key) => SYMBOLS[key] === item
      );
      if (symbolKey) {
        acc[symbolKey] = item;
      } else {
        acc[item] = item;
      }
      return acc;
    }, {});

  return (
    <div
      data-te-modal-init
      className={`offcanvas currency offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium md:max-w-[43.125rem] max-w-full w-full upload-ticket-popup z-40`}
      tabIndex={-1}
      id="withdrowFunds1"
      aria-labelledby="withdrowFundsLabel1"
    >
      <div className="bg-white shadow-3xl w-full h-full ml-auto">
        <div className=" w-full h-full   ml-auto relative flex flex-col">
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              Transfer funds between accounts
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                id="close-popup"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
                onClick={() => {
                  formik.resetForm();
                  setDropDownChangeAndValues(intialState);
                }}
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path="standard_icons/cross.svg"
                  svgClassName="w-2.5 h-2.5"
                />
              </button>
            </div>
          </div>
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-5 mb-1">
            <form onSubmit={formik.handleSubmit}>
              <div className="">
                <div className="flex sm:gap-5 max-sm:gap-y-2.5 items-center max-sm:flex-wrap">
                  <div className="w-full    ">
                    <div className="flex justify-between leading-4 mb-2.5">
                      <label className="text-sm13 font-semibold ">From</label>
                    </div>
                    <div className="inner-inp relative">
                      <DropDownField
                        options={fromOptions}
                        className={` ${
                          formik.touched?.from_wallet &&
                          formik.errors?.from_wallet &&
                          "all-dropdown-errorField"
                        }`}
                        placeholder=""
                        name="from_wallet"
                        getOp
                        value={formik.values.from_wallet}
                        handleOnChange={(data: any) => {
                          setDropDownChangeAndValues((pre: any) => {
                            return {
                              ...pre,
                              currentValue: "from_wallet",
                              currentId: data?.target?.value?.mc_account_id,
                              fromWalletBalance: [],
                            };
                          });
                          formik.handleChange(data);
                        }}
                      />
                      {formik.touched.from_wallet &&
                        formik.errors.from_wallet && (
                          <span className="text-xxs text-rose-500 italic error-msg left-3">
                            {formik.errors.from_wallet as string}
                          </span>
                        )}
                    </div>
                    <div>
                      <span
                        className={`text-xs text-gray-500 font-medium mt-2.5 flex gap-1  `}
                      >
                        Available:{"  "}
                        <span
                          className={`${
                            isLoading &&
                            dropDownChangeAndValues?.currentValue ===
                              "from_wallet" &&
                            "shimmer-effect w-[12.5rem] block"
                          }`}
                        >
                          <span
                            className={`${
                              isLoading &&
                              dropDownChangeAndValues?.currentValue ===
                                "from_wallet" &&
                              "opacity-0"
                            }`}
                          >
                            {dropDownChangeAndValues?.fromWalletBalance
                              ?.length > 0
                              ? dropDownChangeAndValues?.fromWalletBalance
                                  ?.map(
                                    (obj: any) =>
                                      `${
                                        SYMBOLS[obj?.currency]
                                          ? SYMBOLS[obj?.currency]
                                          : obj?.currency
                                      }${decimalNumber(obj?.balance)}`
                                  )
                                  .join(", ")
                              : "0"}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                  <span className="flex justify-center items-center w-[1.25rem] max-sm:my-1.5">
                    <IKTSVG
                      className="  "
                      path={twoArrows}
                      svgClassName={"w-[.9375rem] h-[.9375rem]"}
                    />
                  </span>

                  <div className="w-full ">
                    <div className="flex justify-between leading-4 mb-2.5">
                      <label className="text-sm13 font-semibold ">To</label>
                    </div>
                    <div className="relative inner-inp ">
                      <DropDownField
                        options={toOpions}
                        divClassName={"h-[2.5rem]"}
                        className={` ${
                          formik.touched?.to_wallet &&
                          formik.errors?.to_wallet &&
                          "all-dropdown-errorField"
                        }`}
                        placeholder=""
                        name="to_wallet"
                        value={formik.values.to_wallet}
                        handleOnChange={(data: any) => {
                          setDropDownChangeAndValues((pre: any) => {
                            return {
                              ...pre,
                              currentValue: "to_wallet",
                              currentId: data?.target?.value?.mc_account_id,
                              toWalletBalance: [],
                            };
                          });
                          formik.handleChange(data);
                        }}
                      />
                      {formik.touched.to_wallet && formik.errors.to_wallet && (
                        <div className="text-xxs text-rose-500 italic error-msg left-3">
                          {formik.errors.to_wallet as string}
                        </div>
                      )}
                    </div>
                    <div>
                      <span className="text-xs text-gray-500 font-medium mt-2.5 flex gap-1">
                        Available:
                        <span
                          className={`${
                            isLoading &&
                            dropDownChangeAndValues?.currentValue ===
                              "to_wallet" &&
                            "shimmer-effect w-[12.5rem] block"
                          }`}
                        >
                          <span
                            className={`${
                              isLoading &&
                              dropDownChangeAndValues?.currentValue ===
                                "to_wallet" &&
                              "opacity-0"
                            }`}
                          >
                            {dropDownChangeAndValues?.toWalletBalance?.length >
                            0
                              ? dropDownChangeAndValues?.toWalletBalance
                                  ?.map((obj: any) => {
                                    return `${
                                      SYMBOLS[obj?.currency]
                                        ? SYMBOLS[obj?.currency]
                                        : obj?.currency
                                    }${decimalNumber(obj?.balance)}`;
                                  })
                                  .join(", ")
                              : "0"}
                          </span>
                        </span>
                      </span>
                    </div>
                  </div>
                </div>

                {/* DATEPICKER */}
                <div className="relative formField whiteFormFields pt-5 flex flex-wrap flex-col [&_input]:h-10 focus:[&_input]:text-indigo-500 [&_a]:min-w-[2.5rem]">
                  <div className="flex justify-between leading-4 mb-2.5">
                    <label className="text-sm13 font-semibold">
                      Select date
                    </label>
                  </div>
                  <div className="flex -mx-2.5">
                    <div className={`sm:w-1/2 w-full text-xs px-2.5`}>
                      <DatePickerField
                        name="date"
                        placeholder=""
                        width="w-[2.58rem]"
                        height="!h-10"
                        disabledKeyboardNavigation={false}
                        value={formik?.values?.date}
                        padding="!pl-[3rem]"
                        handleOnChange={(data: any) => {
                          formik.handleChange(data);
                        }}
                        error={formik.touched.date && formik.errors.date}
                        format="dd/MM/yy"
                        popperClassName={
                          formik.touched.date && formik.errors.date
                            ? "mt-2"
                            : ""
                        }
                      />
                      {formik.touched.date && formik.errors.date && (
                        <div className="text-xxs text-rose-500 italic error-msg left-11">
                          {formik.errors.date as string}
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* AMOUNT */}
                <div className="relative formField whiteFormFields py-5 flex flex-wrap flex-col [&_input]:h-10 focus:[&_input]:text-indigo-500 [&_a]:min-w-[2.5rem]">
                  <div className="flex justify-between leading-4 mb-2.5">
                    <label className="text-sm13 font-semibold">Amount</label>
                  </div>
                  <div className="relative">
                    <PriceField
                      id="amount"
                      name="amount"
                      value={amountValue ? amountValue : ""}
                      handleOnChange={(data: any) => {
                        if (data?.target?.value?.value) {
                          formik.handleChange(data);
                        } else {
                          formik.setFieldValue("amount", null);
                        }
                      }}
                      currrency={currrency}
                      errorClass={formik.touched.amount && formik.errors.amount}
                      tooltipDisplay={false}
                    />
                    {formik.touched.amount && formik.errors.amount && (
                      <div className="text-xxs text-rose-500 italic error-msg left-11">
                        {formik.errors.amount as string}
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex -mx-2.5 flex-wrap max-sm:gap-y-5">
                  <div className="sm:w-1/2 w-full px-2.5">
                    <div className="flex justify-between leading-4 mb-2.5">
                      <label className="text-sm13 font-semibold">
                        From description
                      </label>
                    </div>
                    <div className="relative">
                      <Textfield
                        name={"from_desc"}
                        width={"h-[2.5rem]"}
                        value={formik.values.from_desc}
                        handleOnChange={(data: any) =>
                          formik.handleChange(data)
                        }
                        errorClass={`${
                          formik.touched.from_desc &&
                          formik.errors.from_desc &&
                          "border-rose-500 focus:border-rose-500 #{!important} "
                        }`}
                      />
                      {formik.touched.from_desc && formik.errors.from_desc && (
                        <div className="text-xxs text-rose-500 italic error-msg left-3">
                          {formik.errors.from_desc as string}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <div className="flex justify-between leading-4 mb-2.5">
                      <label className="text-sm13 font-semibold">
                        To description
                      </label>
                    </div>
                    <div className="relative">
                      <Textfield
                        name={"to_desc"}
                        width={"h-[2.5rem]"}
                        value={formik.values.to_desc}
                        handleOnChange={formik.handleChange}
                        errorClass={`${
                          formik.touched.to_desc &&
                          formik.errors.to_desc &&
                          "border-rose-500 focus:border-rose-500 #{!important} "
                        }`}
                      />
                      {formik.touched.to_desc && formik.errors.to_desc && (
                        <div className="text-xxs text-rose-500 italic error-msg left-3">
                          {formik.errors.to_desc as string}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="flex -mx-2.5 py-5 flex-wrap max-sm:gap-y-5">
                  <div className="sm:w-1/2 w-full px-2.5">
                    <div className="flex justify-between leading-4 mb-2.5">
                      <label className="text-sm13 font-semibold">
                        From files
                      </label>
                    </div>
                    <div className="upload">
                      {formik?.values?.from_files?.length !== 5 && (
                        <>
                          <div
                            className={`fieldset w-full flex flex-wrap anim-fade`}
                          >
                            <div
                              className={`flex flex-col items-center justify-center text-center ${
                                formik?.values?.from_files &&
                                formik?.values?.from_files?.length > 0
                                  ? "h-20"
                                  : "h-full"
                              } w-full min-h-[5rem] border border-dashed  rounded bg-violet-300/50 text-xs`}
                            >
                              <input {...getFromFileInputProps()} />
                              <div className="mb-2.5">Click here to upload</div>
                              <button
                                type="button"
                                onClick={fromFileOpen}
                                className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                              >
                                <IKTSVG
                                  className="fill-white mr-2"
                                  path={UploadSVG}
                                  svgClassName="fill-white w-[.75rem] h-[.75rem]"
                                />
                                Upload
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {/* UPLOADED FILEs LISTING */}
                      <TransitionGroup component="div" className={""}>
                        {formik?.values?.from_files &&
                          _.size(formik?.values?.from_files) > 0 &&
                          formik?.values?.from_files?.map(
                            (file: any, index: number) => {
                              return (
                                <CSSTransition
                                  key={index}
                                  classNames="row"
                                  timeout={500}
                                >
                                  <div className="text-sm12 rounded border px-2.5 py-1.5 mb-2 hover:bg-indigo-500 hover:bg-opacity-[7%] first:mt-5">
                                    <div className="file_name flex items-center justify-between">
                                      <button
                                        type="button"
                                        className="w-full text-left overflow-hidden whitespace-nowrap text-ellipsis transition-all duration-150"
                                        // onClick={() => {
                                        //   window.open(
                                        //     base64TOURL(file?.base64, file?.type),
                                        //     "_blank"
                                        //   );
                                        // }}
                                        // title="Open file in new tab"
                                      >
                                        {file?.name}
                                      </button>
                                      <button
                                        type="button"
                                        id={`delete-funding-${index}`}
                                        onClick={() =>
                                          deleteFiles(index, "from_files")
                                        }
                                      >
                                        <Tooltip
                                          anchorId={`delete-funding-${index}`}
                                          content={"Delete"}
                                          place="top"
                                          variant="light"
                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10"
                                        />
                                        <IKTSVG
                                          className="w-3 h-[.9375rem] flex items-center justify-center fill-violet-500 hover:fill-indigo-500 rounded-full cursor-pointer"
                                          path={DeleteSVG}
                                          svgClassName="w-3 h-[.9375rem]"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </CSSTransition>
                              );
                            }
                          )}
                      </TransitionGroup>
                    </div>
                  </div>
                  <div className="sm:w-1/2 w-full px-2.5">
                    <div className="flex justify-between leading-4 mb-2.5">
                      <label className="text-sm13 font-semibold">
                        To files
                      </label>
                    </div>
                    <div className="upload">
                      {formik?.values?.to_files?.length !== 5 && (
                        <>
                          <div
                            className={`fieldset w-full flex flex-wrap anim-fade`}
                          >
                            <div
                              className={`flex flex-col items-center justify-center text-center ${
                                formik?.values?.to_files &&
                                formik?.values?.to_files?.length > 0
                                  ? "h-20"
                                  : "h-full"
                              } w-full min-h-[5rem] border border-dashed rounded bg-violet-300/50 text-xs`}
                            >
                              <input {...getToFileInputProps()} />
                              <div className="mb-2.5">Click here to upload</div>
                              <button
                                type="button"
                                onClick={toFileOpen}
                                className="flex items-center text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 px-2.5 py-1"
                              >
                                <IKTSVG
                                  className="fill-white mr-2"
                                  path={UploadSVG}
                                  svgClassName="fill-white w-[.75rem] h-[.75rem]"
                                />
                                Upload
                              </button>
                            </div>
                          </div>
                        </>
                      )}
                      {/* UPLOADED FILEs LISTING */}
                      <TransitionGroup component="div" className={""}>
                        {formik.values?.to_files &&
                          _.size(formik.values?.to_files) > 0 &&
                          formik.values?.to_files?.map(
                            (file: any, index: number) => {
                              return (
                                <CSSTransition
                                  key={index}
                                  classNames="row"
                                  timeout={500}
                                >
                                  <div className="text-sm12 rounded border px-2.5 py-1.5 mb-2 hover:bg-indigo-500 hover:bg-opacity-[7%] first:mt-5">
                                    <div className="file_name flex items-center justify-between">
                                      <button
                                        type="button"
                                        className="w-full text-left overflow-hidden whitespace-nowrap text-ellipsis transition-all duration-150"
                                        // title="Open file in new tab"
                                      >
                                        {file?.name}
                                      </button>
                                      <button
                                        type="button"
                                        id={`delete-funding-${index}`}
                                        onClick={() =>
                                          deleteFiles(index, "to_files")
                                        }
                                      >
                                        <Tooltip
                                          anchorId={`delete-funding-${index}`}
                                          content={"Delete"}
                                          place="top"
                                          variant="light"
                                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium !text-xxs z-10"
                                        />
                                        <IKTSVG
                                          className="w-3 h-[.9375rem] flex items-center justify-center fill-violet-500 hover:fill-indigo-500 rounded-full cursor-pointer"
                                          path={DeleteSVG}
                                          svgClassName="w-3 h-[.9375rem]"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </CSSTransition>
                              );
                            }
                          )}
                      </TransitionGroup>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className=" offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2 flex flex-wrap items-center">
              <button
                className="btn border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 "
                type="button"
                data-bs-dismiss="offcanvas"
                onClick={() => {
                  formik.resetForm();
                  setDropDownChangeAndValues(intialState);
                }}
              >
                Cancel
              </button>
              <button
                className={`btn text-white bg-green-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5`}
                type="button"
                onClick={(e: any) => formik.handleSubmit()}
              >
                Transfer
              </button>
            </div>
          </div>
        </div>
      </div>
      {submitLoader && <ProgressBardLoader secondCounter={2} />}
    </div>
  );
};

export default TransferFundPopup;
