import downAngleSVG from "assets/media/icons/other_icons/down-angle.svg";
import { IKTSVG } from "components/ui/IKTSVG";
import { useMemo } from "react";
import { Tooltip } from "react-tooltip";

const handleSort = (sortedKey: any, setOrderState: any) => () => {
  if (!sortedKey || !setOrderState) return;
  setOrderState((preValue: any) => {
    return {
      order_by: sortedKey,
      sort_order:
        preValue &&
        Object.keys(preValue).length > 0 &&
        preValue.order_by !== sortedKey
          ? "desc"
          : preValue.sort_order === "desc"
          ? "asc"
          : preValue.sort_order === "asc"
          ? undefined
          : "desc",
    };
  });
};

export const HeaderCell = ({
  title,
  width,
  arrow,
  postKey,
  padding,
  uniqueKey,
  orderState,
  setOrderState,
}: any) => {
  const getIconColor = useMemo(
    () =>
      orderState?.order_by === postKey && orderState?.sort_order === "desc"
        ? "rotate-180 !fill-violet-500"
        : orderState?.order_by === postKey && orderState?.sort_order === "asc"
        ? " !fill-violet-500"
        : "fill-gray-400",
    [orderState?.order_by, orderState?.sort_order, postKey]
  );

  return (
    <div
      className={`px-1.5 py-3 font-medium whitespace-nowrap flex-none ${padding} max-w-[${width}] w-[${width}] min-w-[${width}] `}
      key={uniqueKey}
    >
      {arrow ? (
        <div className="w-full relative">
          {title}
          <button type="button" onClick={handleSort(postKey, setOrderState)}>
            <IKTSVG
              path={downAngleSVG}
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${getIconColor}`}
            />
          </button>
        </div>
      ) : (
        <div className="w-full relative">{title}</div>
      )}
      <Tooltip
        anchorId={`pricePerExchange-tooltip${title}`}
        content="Price per exchange"
        place="top"
        variant="light"
        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
      />
    </div>
  );
};
