const Messages = {
  someWrong: "Something went wrong.",
  inValidOrderNote: "Please enter valid order note.",
  orderNotePlaceholder: "Please enter order note",
  penaltyNotePlaceholder: "Please enter penalty note",
  ledgerNotePlaceholder: "Please enter ledger note",
  bulkEditRestrictPPE:
    "Update price is not possible when selected listing(s) use Price Per Partner",
  bulkEditRestrictDiffTeam:
    "Update price is not possible when selected multiple team listing(s) use Price Per Partner",
  ppeSelectExchange:
    "Please select the exchanges you wish to list your tickets on to be able to set your prices for each exchange.",
  solveErrorTry: "Please solve the errors and try again",
  txNetworkTooltipMessage:
    "Your default proceed price across 20+ partners in the TX Network",
  pppToggleTooltipMessage:
    "Set proceed prices for individual partners - face value and proceed price must be set to enable",
};

export default Messages;
