const Upload = (props: any) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 11.001" {...props}>
      <path
        id="Subtraction_23"
        data-name="Subtraction 23"
        d="M5.75,11H1a1,1,0,0,1-1-1V1A1,1,0,0,1,1,0H12a1,1,0,0,1,1,1v9a1,1,0,0,1-1,1H7.25V6.5H9.875L6.5,2,3.125,6.5H5.75V11Z"
      />
    </svg>
  );
};

export default Upload;
