import { showAlert } from "helpers/ShowAlert";
import SubmitButton from "pages/API_connector/component/SubmitButton";
import { postHomeDesign } from "pages/customize/core/_.request";
import { CUSTOMIZE_TAB_KEYS } from "pages/customize/core/constants/constants";
import { useCustomize } from "pages/customize/hooks/useCustomize";

const Footer = () => {
  const { isTabActive, state, setIsLoading, isDelete, setIsDelete } =
    useCustomize();

  const filterArray = state?.filter((obj: any) => !obj?.is_deleted) || [];

  const allHaveTypesId = filterArray.every((item: any) => {
    const hasTypesId = item.types?.id;
    const hasTypeComponents = item.type_components?.length > 0;
    const allComponentsHaveName = item.type_components?.every(
      (component: any) => component?.compoName
    );

    return hasTypesId && hasTypeComponents && allComponentsHaveName;
  });

  // HANDLE SUBMIT
  const handleSubmit = async (e: React.FormEvent) => {
    setIsLoading(true);

    try {
      const response = await postHomeDesign(state);

      if (response?.data?.status) {
        showAlert(response.data.message, false);
      }
    } catch (error) {
      console.error("Error submitting home design:", error);
      showAlert("An error occurred. Please try again.", true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white shadow-3xl z-20 min-h-[3.125rem]">
      <div className="flex w-full overflow-auto">
        {isTabActive === CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE ? (
          <div className="flex whitespace-nowrap items-center justify-between gap-2.5 my-2 w-full">
            <SubmitButton
              handleSubmit={handleSubmit}
              disabled={!allHaveTypesId}
            />
          </div>
        ) : isTabActive === CUSTOMIZE_TAB_KEYS.CONTENT_PAGE &&
          isDelete?.status ? (
          <div className="flex whitespace-nowrap items-center justify-between gap-2.5 my-2 w-full">
            <div className="flex items-center ">
              <button
                type="button"
                className="inline-flex group items-center text-violet-800 border transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 hover:border-indigo-500 hover:text-white"
                onClick={() => {
                  setIsDelete({
                    status: false,
                    data: undefined,
                  });
                }}
              >
                Cancel
              </button>
              <button
                type="button"
                className="ml-2 bg-rose-500  inline-flex group items-center text-white   
 transition font-medium rounded text-sm13 px-2.5 py-0.5 hover:bg-indigo-500 "
                onClick={() => {
                  setIsDelete((current: any) => {
                    return {
                      ...current,
                      confirm: true,
                    };
                  });
                }}
              >
                <i className="fas fa-solid fa-trash mr-1.5"></i>
                Delete
              </button>
              <span className="text-sm13 font-medium pl-3 leading-4">
                Confirm to delete?
              </span>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Footer;
