/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "lodash";
import { useState } from "react";
import Select, { NoticeProps } from "react-select";
// import "./styles.css";
import { components } from "react-select";

const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // styles
  let bg = "transparent";
  // if (isFocused) bg = "#eee";
  // if (isActive) bg = "#B2D4FF";

  const style = {
    alignItems: "center",
    backgroundColor: bg,
    color: "inherit",
    display: "flex ",
    justifyContent: "space-between",
  };

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
    style,
  };
  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      {children}
      {/* @ts-ignore */}
      <input type="checkbox" checked={isSelected} readOnly />
    </components.Option>
  );
};

const allOptions = [
  { value: "option 1", label: "Preferred Partners" },
  { value: "option 2", label: "Stubhub" },
];

const SearchableMultiDropdown = ({
  name,
  handleOnChange,
  classNamePrefix,
  options,
  value,
  placeholder,
  isSearchable=true,
}: any) => {
  const customStyle = {
    menuPortal: (provided: any) => ({
      ...provided,
      zIndex: 9999,
    }),
  };

  const MultiValue = (props: any) => {
    // Only interested in rendering one/first multiValue
    if (props.index > 0) return null;

    const { length } = props.getValue();
    return length > 0 ? (
      <div className="option-label font-medium">{`${length} selected`}</div>
    ) : (
      <components.MultiValue {...props} />
    );
  };

  const MenuList = (props: any, notice: NoticeProps) => {
    return (
      <components.MenuList
        {...props}
        className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
        {props.children}
      </components.MenuList>
    );
  };
  return (
    <div className="App relative searhable-multi-select-dropdown min-w-[6.875rem]">
      <label className="absolute -top-2 left-2 block text-xxs text-gray-400 bg-white px-1 rounded-full font-medium z-10">Restrictions</label>
      <Select
        // value={value}
        isMulti
        classNamePrefix={classNamePrefix}
        closeMenuOnSelect={false}
        hideSelectedOptions={false}
        isClearable={true}
        onChange={(selectedOption: any) => {
          let event = { target: { name: name, value: selectedOption } };
          handleOnChange(event);
        }}
        name={name}
        isSearchable={isSearchable}
        components={{
          Option: InputOption,
          MenuList,
          MultiValue: MultiValue,
        }}
        menuPortalTarget={document.body}
        styles={customStyle}
        // className={`${isSearchable === true && 'NoSearchable'}`}
        // menuIsOpen={true}
        //@ts-ignore
        options={options}
        placeholder={placeholder}
      />
    </div>
  );
};

export default SearchableMultiDropdown;
