import { size } from "lodash";
import React, { useContext, useEffect, useRef } from "react";
import SVG from "react-inlinesvg";
import { Tooltip } from "react-tooltip";
import { LayoutContext } from "../../core/LayoutProvider";

const AsideItemList = ({ props, handleOnClick }: any) => {
  const layout = useContext(LayoutContext);
  const {
    state,
    title,
    svgName,
    path,
    width,
    height,
    pathname,
    showSubmenu,
    subMenuActive,
    setsubMenuActive,
  } = props;

  const isSamePath = (path: any, pathname: any) => {
    if (typeof path === "string") {
      return path === pathname;
    } else if (Array.isArray(path)) {
      return path.includes(pathname);
    } else {
      return false;
    }
  };

  const ref = useRef<any>(null);

  const handleClickOutside = (event: any) => {
    if (ref?.current && !ref.current.contains(event.target)) {
      setsubMenuActive && setsubMenuActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment key={title}>
      <li
        className={`flex group parent rounded p-2 mb-2 min-h-[2.5rem] ${
          showSubmenu ? "cursor-default" : "cursor-pointer"
        }  hover:bg-light-white text-gray-300 transition text-sm13 items-center gap-x-3 md:hover:bg-indigo-500/50  ${
          (isSamePath(path, pathname) ||
            showSubmenu?.some((item: any) =>
              isSamePath(item?.path, pathname)
            )) &&
          "bg-indigo-500 active hover:!bg-indigo-500"
        } positon-relative`}
        title={""}
        id={`${path}-${title}`}
        onClick={() => {
          handleOnClick();
        }}
        onBlur={() => {
          setsubMenuActive(false);
        }}
        ref={ref}
      >
        <div className="flex items-center gap-x-3">
          <div className="flex justify-center min-w-[1.5rem]">
            <SVG
              src={svgName}
              onClick={() => layout.setNotificationId(undefined)}
              className={`${width} ${height}`}
            />
          </div>
          <span
            className={`${
              !state && "hidden"
            } origin-left duration-400 text-white font-medium leading-4 text-truncate`}
          >
            {title}
          </span>
          {!state && size(showSubmenu) === 0 && (
            <Tooltip
              anchorId={title}
              content={title}
              place="right"
              variant="light"
              className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
              positionStrategy="fixed"
            />
          )}
        </div>
        {!state && size(showSubmenu) === 0 && (
          <Tooltip
            anchorId={title}
            content={title}
            place="right"
            variant="light"
            className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
            positionStrategy="fixed"
          />
        )}
        {showSubmenu && size(showSubmenu) > 0 && (
          <div
            className={`wrapper fixed max-lg:!hidden hidden lg:group-hover:block ${
              subMenuActive ? "max-lg:block" : ""
            } lg:hover:block pl-2.5 z-[99999] pointer-events-auto text-sm12`}
          >
            <ul
              id={title}
              className="submenu text-violet-800 bg-white  shadow-op3"
            >
              {showSubmenu?.map((item: any, index: number) => {
                return (
                  <li
                    className="cursor-pointer px-2.5 py-1 border-b leading-[1.3125rem] hover:text-indigo-500 hover:bg-indigo-500 hover:bg-opacity-[7%]"
                    key={`${index}-${title}-${item?.name}`}
                  >
                    <span>
                      <a
                        href={item?.path}
                        target={item?.externalLink ? "_blank" : "_self"}
                        rel="noreferrer"
                      >
                        {item?.name}
                      </a>
                    </span>
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </li>
    </React.Fragment>
  );
};

export default AsideItemList;
