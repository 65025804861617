interface ShimemrInterface {
  isLoading?: boolean;
  loopCount: any;
  headerClassName: any;
  titleClassName: any;
  shimmerHeight: any;
}

const Shimmer: React.FC<ShimemrInterface> = ({
  isLoading,
  loopCount = 3,
  headerClassName,
  titleClassName,
  shimmerHeight,
}: any) => {
  return (
    <>
      {Array.from({ length: loopCount }, (v, i) => (
        <div className={`${headerClassName} ${i === 0 && "mt-2.5"} `} key={i}>
          <div
            className={`${titleClassName} ${isLoading && "shimmer-effect"} `}
            style={{ height: shimmerHeight }}
          ></div>
        </div>
      ))}
    </>
  );
};

export default Shimmer;
