const Label = ({ data, required = false }: any) => {
  return (
    <label className="text-gray-400 leading-4 mb-1 block w-full text-sm12">
      {data}
      {required && <span className="text-red-500 ml-1">*</span>}
    </label>
  );
};

export default Label;
