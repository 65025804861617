import { useContext } from "react";
import ArrowSVG from "../../../assets/media/icons/standard_icons/angle_left.svg";
import { KTSVG } from "../../../components/ui/KTSVG";
import NavigationButtons from "../../Sales/component/NavigationButtons";
import { DashboardContext } from "../core/DashboardProvider";

const OffCanvas = (props: any) => {
  const layout = useContext(DashboardContext);
  const newsData = layout?.industryNewsData;
  const showData = layout.newsOffCanvas;

  // HANDLE NAVIGATION OF ORDER VIEW PAGE
  const handleNavigation = (nav: string) => {
    let preRcord = newsData.find((order: any, index: number) =>
      nav === "back"
        ? index === showData?.index - 1
        : index === showData?.index + 1
    );
    let indexId = newsData.findIndex((order: any, index: number) =>
      nav === "back"
        ? index === showData?.index - 1
        : index === showData?.index + 1
    );

    layout.setNewsOffCanvas({
      data: preRcord,
      index: indexId,
    });
  };

  return (
    <>
      <div
        className="offcanvas top-[3.75rem] offcanvas-end shadow-3xl fixed bottom-0 flex flex-col bg-white invisible bg-clip-padding outline-none transition duration-300 ease-in-out text-gray-700 right-0  md:max-w-2xl md:ml-0 z-40 w-full"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-bs-backdrop="false"
      >
        <div className="offcanvas-header flex items-center justify-between border-t border-b">
          <h5
            className="offcanvas-title mb-0 leading-normal font-semibold py-2 px-5 text-sm15"
            id="offcanvasRightLabel"
          >
            Industry News
          </h5>
          <button
            type="button"
            className="flex items-center justify-center h-10 w-10 border-l box-content group"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            onClick={() => {
              layout.setNewsOffCanvas("");
            }}
          >
            {/* <span className="btn-close w-3 h-3 text-black hover:text-indigo-500"></span> */}
            <KTSVG
              className="fill-violet-800 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
              path="standard_icons/cross.svg"
              svgClassName="w-2.5 h-2.5"
            />
          </button>
        </div>
        <div className="offcanvas-body md:px-7 md:pt-7 px-5 pt-5 flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
          {/* <div className="rounded">
            <img src="https://mdbootstrap.com/img/new/slides/041.jpg" className="rounded" alt="..." />
          </div> */}
          <div className="">
            <h2
              className="text-sm font-semibold text-violet-800 text-sm15"
              dangerouslySetInnerHTML={{
                __html: showData?.data?.title,
              }}
            ></h2>
            <div className="mt-4 text-sm15">
              <div
                className="text-xs font-medium mb-2 text-gray-600 [&>p]:pb-5 [&>p]:text-sm13"
                dangerouslySetInnerHTML={{
                  __html: showData?.data?.description,
                }}
              ></div>
            </div>
          </div>
        </div>
        <div className="border-t text-right mt-auto py-2 px-5 flex">
          <div className="flex justify-end shadow-op1 rounded ml-auto">
            {/* <button
            type="button"
            id="back"
            className={`rounded-l bg-gray-100 hover:bg-gray-200 transition p-2 ${
              showData?.index === 0 && "pointer-events-none opacity-40"
            }`}
            onClick={() => handleNavigation("back")}
          >
            <KTSVG
              className="flex items-center justify-center fill-violet-500 transition"
              path="standard_icons/angle_left.svg"
            />
          </button> */}
            <NavigationButtons
              id="back"
              imgPath={ArrowSVG}
              className={`${showData?.index === 0 && "pointer-events-none"}`}
              handleNavigation={(data: any) => handleNavigation(data)}
              svgClassName={`${
                showData?.index === 0 ? "fill-gray-400" : "fill-violet-500"
              }`}
            />
            <NavigationButtons
              id="next"
              imgPath={ArrowSVG}
              className={`${
                newsData?.length - 1 === showData?.index &&
                "pointer-events-none"
              }`}
              svgClassName={`${
                newsData?.length - 1 === showData?.index
                  ? "fill-gray-400"
                  : "fill-violet-500"
              }`}
              handleNavigation={(data: any) => handleNavigation(data)}
            />
            {/* <button
            type="button"
            id="next"
            className={`rounded-r bg-gray-100 hover:bg-gray-200 transition p-2 `}
            onClick={() => handleNavigation("next")}
          >
            <KTSVG
              className="flex items-center justify-center fill-violet-500 transition"
              path="standard_icons/angle_right.svg"
            />
          </button> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default OffCanvas;
