import { useContext, useEffect, useRef } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { storeFields } from "../../../components/core/requests";
import {
  columnsOutSideClick,
  outSideClick,
} from "../../../helpers/AssetHelpers";
import { existInLocalstorage } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import { InstructionLetterContext } from "../core/InstructionLetterProvider";
import DraggableColumns from "./DraggableColumns";
import DraggableFilters from "./DraggableFilters";

const HeaderfilterContainer = () => {
  const bulkActionRef: any = useRef(null);
  const bulkAction1Ref: any = useRef(null);
  const layout = useContext(InstructionLetterContext);
  const globalLayout = useContext(LayoutContext);

  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveInstructionLetterFilter;

  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings
      ?.InstructionLetterTableColumn;

  let payload: any = [
    {
      key: `saveInstructionLetterFilter`,
      value: layout.filterItems,
    },
    {
      key: `InstructionLetterTableColumn`,
      value: layout.columnItems,
    },
  ];

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, "saveInstructionLetterFilter")
    );
    document.addEventListener("mousedown", (e: any) =>
      columnsOutSideClick(e, "InstructionLetterTableColumn")
    );
    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, "saveInstructionLetterFilter")
      );
      document.removeEventListener("mousedown", (e: any) =>
        columnsOutSideClick(e, "InstructionLetterTableColumn")
      );
    };
  }, []);

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );
  useEffect(() => {
    if (
      layout.filterItems &&
      existInLocalstorage("saveInstructionLetterFilter")
    ) {
      layout.setFilterItems(existInLocalstorage("saveInstructionLetterFilter"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("saveInstructionLetterFilter");
      }, 3000);
    }

    if (
      layout.columnItems &&
      existInLocalstorage("InstructionLetterTableColumn")
    ) {
      layout.setColumnItems(
        existInLocalstorage("InstructionLetterTableColumn")
      );
      setTimeout(() => {
        refetch();
        localStorage.removeItem("InstructionLetterTableColumn");
      }, 3000);
    }
  }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      setTimeout(() => {
        layout.setFilterItems(isFilterExistInDB);
        layout.setDraggedItems(isFilterExistInDB);
      }, 1000);
    }
    if (isColumnFilterExistInDB) {
      setTimeout(() => {
        layout.setColumnItems(isColumnFilterExistInDB);
        layout.setDraggedColumns(isColumnFilterExistInDB);
      }, 1000);
    }
  }, [isFilterExistInDB, isColumnFilterExistInDB]);

  return (
    <div className="flex flex-wrap" id="filters">
      <div className="ba_item relative" ref={bulkActionRef}>
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#saveInstructionLetterFilter"
          aria-expanded="false"
          aria-controls="saveInstructionLetterFilter"
        >
          Filters
        </span>
        <div
          id="saveInstructionLetterFilter"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden"
          data-te-collapse-item
          aria-labelledby="saveInstructionLetterFilter"
          data-te-parent="#filters"
        >
          <DraggableFilters refetch={refetch} />
        </div>
      </div>

      <div className="ba_item relative" ref={bulkAction1Ref}>
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#InstructionLetterTableColumn"
          aria-expanded="false"
          aria-controls="InstructionLetterTableColumn"
        >
          Columns
        </span>
        <div
          id="InstructionLetterTableColumn"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden cursor-pointer"
          data-te-collapse-item
          aria-labelledby="InstructionLetterTableColumn"
          data-te-parent="#filters"
        >
          <DraggableColumns refetch={refetch} />
        </div>
      </div>
    </div>
  );
};

export default HeaderfilterContainer;
