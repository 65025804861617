import { size } from "lodash";
import moment from "moment";
import { useContext } from "react";
import { default as refreshSVG } from "../../../assets/media/icons/other_icons/refresh.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { addCommaInNumber } from "../../../helpers/Functions";
import { INITIAL_INSTRUCTION_DETAILS } from "../../../helpers/const";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { InstructionLetterContext } from "../core/InstructionLetterProvider";
import FilterChipBox from "./FilterChipBox";

const FilterChips = ({ loading }: any) => {
  const layout = useContext(InstructionLetterContext);
  const paginateData = layout?.meta;
  const filterData = layout?.filterData;

  // RESET ALL
  const handleRefresh = () => {
    layout.setFilterData(INITIAL_INSTRUCTION_DETAILS);
    let searchField: any = document.getElementById("simple-search-instruction");
    if (searchField) {
      searchField.value = "";
    }
  };

  // HANDLE RESET
  const handleOnReset = (index: number, name: string, isArr: any) => {
    layout.setFilterData((current: any) => {
      if (name === "query") {
        let searchField: any = document.getElementById(
          "simple-search-instruction"
        );
        if (searchField) {
          searchField.value = "";
        }
      }
      return {
        ...current,
        [name]: Array.isArray(isArr)
          ? current?.[name]?.filter(
              (user: any, indexNumber: number) => index !== indexNumber
            )
          : isArr,
        page: 1,
        per_page: 20,
      };
    });
  };

  return (
    <div
      className="flex flex-wrap customStyle relative bg-white "
      id={`salesFilter`}
    >
      <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center">
        {/* TOTAL RESULT */}
        <div
          className={`${loading ? "shimmer-effect" : ""} inline-block rounded`}
        >
          <span className="">
            {paginateData?.total > 0
              ? `${addCommaInNumber(paginateData?.total)} Templates`
              : "0 Templates"}
          </span>
        </div>
      </div>
      {/* CHIPS */}
      <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
        {loading ? (
          <div className="flex gap-5">
            <div
              className={`flex !rounded overflow-hidden ${
                loading && "shimmer-effect"
              }`}
              style={{ height: "1.625rem", width: "9.375rem" }}
            >
              &nbsp;
            </div>
          </div>
        ) : (
          (size(filterData?.teams) > 0 ||
            size(filterData?.status) > 0 ||
            size(filterData?.risk_levels) > 0 ||
            size(filterData?.register_date) > 0 ||
            filterData?.query) && (
            <button
              type="button"
              className={`group border text-center bg-white rounded w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition ${
                loading && "shimmer-effect"
              }`}
              onClick={handleRefresh}
              title="Reset"
              data-tooltip-id="reset-all"
            >
              <TooltipPortal
                id={`reset-all`}
                content={`Reset`}
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
              />
              <IKTSVG
                path={refreshSVG}
                className="fill-violet-500 group-hover:fill-white transition"
                svgClassName="mx-[.313rem] w-3 h-3"
              />
            </button>
          )
        )}
        {!loading && (
          <>
            {/* SEARCH FIELD */}
            {filterData?.query && (
              <FilterChipBox
                title={filterData?.query}
                indexNumber={0}
                handleOnClick={(data: any) => {
                  handleOnReset(data, "query", "");
                }}
              />
            )}

            {/* TEAMS */}
            {size(filterData?.teams) > 0 &&
              filterData?.teams?.map((item: any, index: number) => {
                return (
                  <FilterChipBox
                    title={item?.name}
                    indexNumber={index}
                    handleOnClick={(data: any) => {
                      handleOnReset(data, "teams", []);
                    }}
                  />
                );
              })}

            {/* STATUS */}
            {filterData?.status && (
              <FilterChipBox
                title={filterData?.status?.name}
                indexNumber={0}
                handleOnClick={(data: any) => {
                  handleOnReset(data, "status", null);
                }}
              />
            )}

            {/* RISK LEVEL */}
            {size(filterData?.risk_levels) > 0 &&
              filterData?.risk_levels?.map((item: any, index: number) => {
                return (
                  <FilterChipBox
                    title={item?.name}
                    indexNumber={index}
                    handleOnClick={(data: any) => {
                      handleOnReset(data, "risk_levels", []);
                    }}
                  />
                );
              })}

            {/* REGISTER_DATE */}
            {size(filterData?.register_date) > 0 && (
              <FilterChipBox
                title={`${moment(filterData?.register_date?.startDate).format(
                  "DD/MM/YYYY"
                )}${" - "}
            ${moment(filterData?.register_date?.endDate).format("DD/MM/YYYY")}`}
                indexNumber={0}
                handleOnClick={(data: any) => {
                  handleOnReset(data, "register_date", null);
                }}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default FilterChips;
