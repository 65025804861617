/* eslint-disable react-hooks/exhaustive-deps */
import _, { isObject } from "lodash";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import Collapse from "../../assets/media/icons/other_icons/Collapse.svg";
import OutlineCheckSVG from "../../assets/media/icons/other_icons/outline_check.svg";
import Topup from "../../assets/media/icons/other_icons/upload-outline.svg";
import ClockOutlinkSVG from "../../assets/media/icons/standard_icons/clock-outline.svg";
import { LayoutContext } from "../../components/core/LayoutProvider";
import NoDataComponent from "../../components/tableComponent/NoDataComponent";
import { IKTSVG } from "../../components/ui/IKTSVG";
import { KTSVG } from "../../components/ui/KTSVG";
import ProgressBardLoader from "../../components/ui/ProgressBardLoader";
import {
  addCommaInNumber,
  checkCompleteKYC,
  getCurrencySymbol,
  isMenuAllowed,
  priceFormat,
} from "../../helpers/Functions";
import { showAlert } from "../../helpers/ShowAlert";
import { QUERIES } from "../../helpers/const";
import useDebounce from "../add_inventory/core/useDebounce";
import BankDetailPopup from "../tx_trade/widget/Sidebar/BankDetailPopup";
import Header from "./component/Header";
import KycOverlay from "./component/KycOverlay";
import TXFilters from "./component/TXFilters";
import TXHeader from "./component/TXHeader";
import TransferFundPopup from "./component/TransferFundPopup";
import WithdrawFundsOffcanvas from "./component/WithdrawFundsOffcanvas";
import { TXContext } from "./core/TXProvider";
import {
  cancelWithdrawlRequest,
  getTxPayListing,
  getTxPayOverviewData,
} from "./core/requests";
import DataTable from "./table/DataTable";

const TXPay = () => {
  const [loader, setLoader] = useState(false);
  const globalLayout: any = useContext(LayoutContext);
  const layout = useContext(TXContext);
  const [query, setQuery] = useState<any>("");
  const [teamsOptions, setTeamsOptions] = useState<any>();
  const [headerOptions, setHeaderOptions] = useState<any>();
  const debouncedSearch = useDebounce(query, 1200);
  const [transferPopUpOpen, setTransferPopUpOpen] = useState<any>(false);

  let filters = layout.TXPayFilterHandle;
  let coutFilters = {
    ...layout.TXPayFilterHandle,
    page: undefined,
    per_page: undefined,
  };

  const access = globalLayout.allowedAccess?.allowed_access;
  const teamOptionsData = globalLayout?.allowedAccess?.teams;
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;

  const isSettinsAvailable = access?.some(
    (item: any) => item.id === "settings" && item.status
  );

  const [customReportLoading, setReportLoading] = useState(true);
  const [secondCounter, setSecondCounter] = useState(3);
  const heightRef1: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);
  const [overViewHeight, setOverViewHeight] = useState<any>();

  // PAGINATION SET TO FIRST PAGE ON FILTER CHANGE
  useEffect(() => {
    layout.setTXPayFilterHandle((prev: any) => {
      return {
        ...prev,
        page: 1,
        per_page: 50,
      };
    });
    setReportLoading(true);
  }, [
    layout.TXPayFilterHandle?.transaction_date,
    layout.TXPayFilterHandle?.transaction_type,
    layout.TXPayFilterHandle?.team_id,
    debouncedSearch,
  ]);

  // GET TX PAY DATA API
  const {
    isLoading: txPayLoading,
    data: txPayData,
    refetch: listingRefetch,
  } = useQuery(
    [`${QUERIES.TX_PAY_LISTING}`, debouncedSearch, filters],
    () =>
      getTxPayListing({
        debouncedSearch,
        filters,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  // GET TX PAY OVERVIEW DATA API
  const {
    isLoading: txPayOverViewLoading,
    data: txPayOverviewData,
    refetch,
  } = useQuery(
    [`tx-pay-overview`, debouncedSearch, coutFilters],
    () =>
      getTxPayOverviewData({
        debouncedSearch,
        filters: coutFilters,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      onError: (err) => {},
    }
  );

  // ON ENTER - SEARCH QUERY (ALGOLIA)

  function isGodadminBasedCurrency(Array: any) {
    const teamArr = Array;
    if (teamArr === undefined || teamArr?.length === 0) {
      return globalLayout.currencySymbol;
    } else {
      return getCurrencySymbol(teamArr?.base_currency);
    }
  }

  const handleKeyDown = (event: any) => {
    if (event?.target?.value && event.key === "Enter") {
      event.preventDefault();
      setQuery(event?.target?.value);
      layout?.setTXPayFilterHandle((currentValue: any) => {
        return {
          ...currentValue,
          query: event?.target?.value,
        };
      });
    }
  };

  useEffect(() => {
    if (txPayData && txPayData?.data?.ledger) {
      // layout.setItems(txPayData?.data?.ledger);
      const existingMonths = layout?.items?.map(
        (outerObj: any) => outerObj?.month
      );
      const mergedItems = layout?.items?.map((outerObj: any) => {
        const foundIndex = txPayData?.data?.ledger?.findIndex(
          (txObj: any) => txObj?.month === outerObj?.month
        );

        if (foundIndex !== -1) {
          const updatedTransactions = [
            ...outerObj?.transactions,
            ...(txPayData?.data?.ledger[foundIndex]?.transactions || []),
          ];

          return {
            ...outerObj,
            transactions: _.uniqBy(updatedTransactions, "id"),
          };
        }

        return outerObj;
      });

      const missingMonths = txPayData?.data?.ledger?.filter(
        (txObj: any) => !existingMonths?.includes(txObj.month)
      );

      const updatedLayoutItems =
        layout?.TXPayFilterHandle?.page > 1
          ? [...mergedItems, ...missingMonths]
          : txPayData?.data?.ledger;

      const newUpdateData = updatedLayoutItems?.map((obj: any) => {
        return {
          ...obj,
          isOpen: true,
        };
      });
      layout.setItems(newUpdateData);
      if (txPayOverviewData?.data) {
        setHeaderOptions(txPayOverviewData?.data);
      }
    }
  }, [txPayData, txPayOverviewData, filters]);

  // get team otpions
  useEffect(() => {
    if (teamOptionsData?.length > 0) {
      setTeamsOptions(teamOptionsData);
    }
  }, [teamOptionsData?.length > 0]);

  useEffect(() => {
    if (txPayLoading === false && filters?.page === 1) {
      // setTimeout(() => {
      setReportLoading(false);
      // }, 500);
    }
  }, [txPayLoading, filters]);
  useEffect(() => {
    if (customReportLoading) {
      setSecondCounter((pre: any) => pre + 1);
    }
  }, [customReportLoading]);

  useEffect(() => {
    let height3: any;
    const element: any = document.querySelector("#inventoryaccordion");
    setTimeout(() => {
      height3 = IsToggle === true ? element?.offsetHeight : 0;
      setOverViewHeight(height3);
    }, 400);
  }, [IsToggle]);

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;
    if (stickyElement) {
      const heightheightRef1 = heightRef1?.current?.offsetHeight;

      const MarginTopTotle =
        IsToggle === true
          ? heightheightRef1 + overViewHeight - 60
          : heightheightRef1 - 60;

      const initialPosition = stickyElement.getBoundingClientRect().top;

      const makeSticky = function () {
        if (window.scrollY + 60 >= initialPosition) {
          stickyElement.classList.add("sticky");
          stickyElement.style.top = `-${MarginTopTotle}px`;
        } else {
          stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0px`;
        }
      };

      if (window.scrollY > 200) {
        makeSticky();
      }

      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);
      return () => {
        window.removeEventListener("scroll", makeSticky);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);
  /* end sticky event */

  const handleCancelWithdrawRequest = () => {
    if (!isGodAdmin) {
      setLoader(true);
      cancelWithdrawlRequest([])
        .then((response: any) => {
          if (response?.data?.status) {
            refetch &&
              refetch().then(() => {
                setLoader(false);
                showAlert(response?.message, false);
              });
          } else {
            setLoader(false);
            showAlert(response?.message, true);
          }
        })
        .catch((error: any) => {
          setLoader(false);
        });
    }
  };

  const pendingOrders =
    typeof headerOptions?.pending_order_count === "number"
      ? addCommaInNumber(headerOptions?.pending_order_count)
      : headerOptions?.pending_order_count;
  const orderText = pendingOrders
    ? `${pendingOrders} order${pendingOrders > 1 ? "s" : ""} pending`
    : "";

  return (
    <>
      <div className="main-content-wrap tx-pay-module" id="tx-pay-module">
        <div
          className={`topFilters sticky-element top-[60px] z-[11] mb-4 max-md:!static`}
          id="topFilters"
        >
          <div className="top-accor shadow-op2">
            <div className="accordion font-medium" ref={heightRef1}>
              <div className="accordion-item !rounded-b-none !rounded-t bg-white border-gray-200 shadow-op2">
                <div
                  className="flex  justify-between accordion-header mb-0"
                  id="headingOne"
                >
                  {/* HEADER FILTES & COLUMN FILTER */}
                  <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2 bg-white">
                    <div className="flex flex-wrap" id="filters">
                      <Header title="Filters" parent="filters" />
                    </div>
                  </div>

                  {/* HEADER COLLAPSE BUTTON */}
                  <div>
                    {/* <HeaderCollapseButton /> */}
                    <button
                      className="group relative flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
                      type="button"
                      data-te-collapse-init
                      data-te-toggle="collapse"
                      data-te-target="#inventoryaccordion"
                      aria-expanded="true"
                      aria-controls="inventoryaccordion"
                      onClick={() =>
                        setIsToggle(IsToggle === false ? true : false)
                      }
                    >
                      <IKTSVG
                        className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                        path={Collapse}
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="bg-white border-t accordion-collapse font-medium collapse show"
              id="inventoryaccordion"
              data-te-collapse-item
              data-te-collapse-show
              aria-labelledby="headingOne"
            >
              {/*FIRST LAYER*/}
              <div className="px-5 pt-3">
                <div className="divide-gray-200 divide-y-2">
                  <div className="flex flex-nowrap pt-3 -mx-2.5 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                    {/* AVAILABLE FUNDS */}
                    <TXHeader
                      title="Available funds"
                      amount={`${
                        headerOptions?.available_amount
                          ? priceFormat(headerOptions?.available_amount)
                          : "0"
                      }`}
                      svgPath={OutlineCheckSVG}
                      svgFillColor="fill-green-600"
                      // bggradient={true}
                      txPayLoading={txPayOverViewLoading}
                    >
                      {isObject(headerOptions?.open_withdrawal_request) &&
                        _.size(headerOptions?.open_withdrawal_request) > 0 && (
                          <>
                            <p className="text-xs text-gray-500 mt-3">
                              Pending withdraw request for{" "}
                              {getCurrencySymbol(
                                headerOptions?.open_withdrawal_request?.currency
                              )}
                              {addCommaInNumber(
                                headerOptions?.open_withdrawal_request?.amount
                              )}{" "}
                              created on{" "}
                              {moment(
                                headerOptions?.open_withdrawal_request
                                  ?.process_date
                              ).format("DD MMM YYYY")}
                            </p>
                          </>
                        )}
                      <div className={`flex flex-nowrap mt-3 w-full`}>
                        {isObject(headerOptions?.open_withdrawal_request) &&
                        _.size(headerOptions?.open_withdrawal_request) > 0 ? (
                          <>
                            <button
                              type="button"
                              className={`${
                                (isGodAdmin || loader) &&
                                "pointer-events-none !bg-gray-400"
                              } group min-w-[33.33%] bg-gray-100 inline-flex group items-center text-violet-800 hover:text-indigo-500 transition font-medium first:rounded-l last:rounded-r text-sm13 leading-[1.0625rem] px-2.5`}
                              onClick={() => handleCancelWithdrawRequest()}
                            >
                              Cancel withdraw request
                            </button>
                          </>
                        ) : (
                          <button
                            type="button"
                            className={`${
                              (isGodAdmin ||
                                headerOptions?.available_amount <= 0) &&
                              "pointer-events-none !text-gray-400"
                            } group flex min-w-[33.33%] bg-gray-100 py-[.8125rem] flex-wrap flex-col group items-center text-violet-800 hover:text-indigo-500 outline-none first:rounded-l last:rounded-r text-sm13  leading-[1.0625rem]  px-2.5`}
                            data-bs-toggle="offcanvas"
                            data-bs-target={
                              !globalLayout?.userDetailShow?.txPayStatus
                                ? ""
                                : "#withdrowFunds"
                            }
                            aria-controls="withdrowFundsLabel"
                          >
                            <KTSVG
                              className="mr-1.5 flex items-center justify-center fill-violet-500 .9375rem"
                              path="other_icons/withdrow.svg"
                              svgClassName={`w-[.9375rem] h-[.9375rem] ${
                                isGodAdmin ||
                                headerOptions?.available_amount <= 0
                                  ? "!fill-gray-400"
                                  : "group-hover:fill-indigo-500"
                              } `}
                            />
                            Withdraw
                          </button>
                        )}
                        <button
                          type="button"
                          data-te-toggle="modal"
                          data-te-target="#topup"
                          className={`${
                            isGodAdmin && "pointer-events-none !text-gray-400"
                          } group min-w-[33.33%] bg-gray-100 py-[.8125rem] group-hover:fill-indigo-500  flex flex-wrap flex-col group items-center text-violet-800 hover:text-indigo-500 outline-none first:rounded-l last:rounded-r text-sm13  leading-[1.0625rem]  px-2.5`}
                        >
                          <IKTSVG
                            path={Topup}
                            className="mr-1.5 flex items-center justify-center fill-violet-500"
                            svgClassName={`w-[.9375rem] h-[.9375rem] ${
                              isGodAdmin && "!fill-gray-400"
                            } group-hover:fill-indigo-500 `}
                          />
                          Deposit
                        </button>
                        {isGodAdmin &&
                          isMenuAllowed(
                            access,
                            "wallet_to_wallet_transfer"
                          ) && (
                            <button
                              type="button"
                              className={` group bg-gray-100 py-[.8125rem] flex flex-wrap flex-col group items-center text-violet-800 hover:text-indigo-500 outline-none first:rounded-l last:rounded-r text-sm13  leading-[1.0625rem] min-w-[33.33%] px-2.5`}
                              data-bs-toggle="offcanvas"
                              data-bs-target="#withdrowFunds1"
                              aria-controls="withdrowFundsLabel1"
                              onClick={() => setTransferPopUpOpen(true)}
                            >
                              <IKTSVG
                                path={Topup}
                                className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 mb-1 rotate-90 `}
                                svgClassName="w-[.9375rem] h-[.9375rem]"
                              />
                              Transfer
                            </button>
                          )}
                      </div>
                    </TXHeader>

                    {/* PENDING FUNDS */}
                    <TXHeader
                      title="Pending funds"
                      amount={`${
                        headerOptions?.pending_amount
                          ? headerOptions?.pending_amount
                          : "0"
                      }`}
                      svgPath={ClockOutlinkSVG}
                      svgFillColor="fill-violet-800"
                      txPayLoading={txPayOverViewLoading}
                    >
                      <div className="text-xs text-gray-500 mt-auto flex items-center flex-wrap">
                        {orderText}
                      </div>
                    </TXHeader>

                    {/* TOTAL REVENUE */}
                    {globalLayout.isTxTradeUser === 0 && (
                      <TXHeader
                        title="Total revenue"
                        amount={`${
                          headerOptions?.total_revenue
                            ? headerOptions?.total_revenue
                            : "0"
                        }`}
                        txPayLoading={txPayOverViewLoading}
                      >
                        <div className="text-xs mt-auto flex items-center flex-wrap">
                          <p className="w-full mb-1">
                            YTD:&nbsp;
                            {isGodAdmin
                              ? isGodadminBasedCurrency(
                                  layout?.TXPayFilterHandle?.team_id
                                )
                              : globalLayout.currencySymbol}
                            {headerOptions?.ytd ? headerOptions?.ytd : "0"}
                          </p>
                          <p className="text-gray-500 w-full">
                            Last year:{" "}
                            {isGodAdmin
                              ? isGodadminBasedCurrency(
                                  layout?.TXPayFilterHandle?.team_id
                                )
                              : globalLayout.currencySymbol}
                            {headerOptions?.last_year
                              ? headerOptions?.last_year
                              : "0"}
                          </p>
                        </div>
                      </TXHeader>
                    )}

                    {/* Account details */}
                    <div
                      className={`w-full md:w-1/2 lg2:w-1/4 mb-5 px-2.5 min-h-[11.25rem] min-w-max`}
                    >
                      <div
                        className={`border rounded-lg h-full ${
                          txPayOverViewLoading && "shimmer-effect"
                        } `}
                      >
                        <div className="top-row flex flex-wrap items-center justify-between w-full px-4 py-3 border-b">
                          <h3 className="text-sm15 leading-5 font-semibold ">
                            Account details
                          </h3>

                          {isSettinsAvailable && !isGodAdmin && (
                            <div className="det_row text-sm12 text-right ml-4">
                              <Link
                                to={"/settings"}
                                state={{ data: "TX Pay" }}
                                className={`
                               group inline-flex group items-center text-white bg-violet-500 hover:text-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2 py-1 leading-[1.0625rem]`}
                              >
                                Go to settings
                              </Link>
                            </div>
                          )}
                        </div>
                        <div className="account_det_list px-4 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-y-auto max-h-32 ">
                          <div className="det_row border-b text-sm12 py-2.5 last:border-b-0">
                            <span className="block leading-4 mb-1 text-gray-500">
                              Account holder
                            </span>
                            <span className="block leading-4">
                              {headerOptions?.wallet_details?.name
                                ? headerOptions?.wallet_details?.name
                                : ""}
                            </span>
                          </div>

                          <div className="det_row border-b text-sm12 py-2.5 flex items-center justify-between last:border-b-0">
                            <div>
                              <span className="block leading-4 mb-1 text-gray-500">
                                Base currency
                              </span>
                              <span
                                className="block leading-4"
                                dangerouslySetInnerHTML={{
                                  __html: headerOptions?.wallet_details
                                    ?.base_currency
                                    ? headerOptions?.wallet_details
                                        ?.base_currency
                                    : "",
                                }}
                              ></span>
                            </div>
                          </div>
                          {headerOptions?.withdrawal_account?.iban && (
                            <div className="det_row border-b text-sm12 py-2.5 flex items-center justify-between last:border-b-0">
                              <div>
                                <span className="block leading-4 mb-1 text-gray-500">
                                  IBAN
                                </span>
                                <span className="block leading-4">
                                  {headerOptions?.withdrawal_account?.iban}
                                </span>
                              </div>
                            </div>
                          )}

                          {headerOptions?.deposit_account?.bank_name &&
                            isGodAdmin && (
                              <div className="det_row border-b text-sm12 py-2.5 flex items-center justify-between last:border-b-0">
                                <div>
                                  <span className="block leading-4 mb-1 text-gray-500">
                                    Deposit account
                                  </span>
                                  {headerOptions?.deposit_account
                                    ?.bank_name && (
                                    <span className="block leading-4">
                                      Bank name :{" "}
                                      {
                                        headerOptions?.deposit_account
                                          ?.bank_name
                                      }{" "}
                                    </span>
                                  )}
                                  {headerOptions?.deposit_account
                                    ?.account_holder_name && (
                                    <span className="block leading-4">
                                      Account holder name :{" "}
                                      {
                                        headerOptions?.deposit_account
                                          ?.account_holder_name
                                      }{" "}
                                    </span>
                                  )}
                                  {headerOptions?.deposit_account
                                    ?.ac_number && (
                                    <span className="block leading-4">
                                      Account number :{" "}
                                      {
                                        headerOptions?.deposit_account
                                          ?.ac_number
                                      }{" "}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}

                          {headerOptions?.withdrawal_account?.bank_name &&
                            isGodAdmin && (
                              <div className="det_row border-b text-sm12 py-2.5 flex items-center justify-between last:border-b-0">
                                <div>
                                  <span className="block leading-4 mb-1 text-gray-500">
                                    Withdraw account
                                  </span>
                                  {headerOptions?.withdrawal_account
                                    ?.bank_name && (
                                    <span className="block leading-4">
                                      Bank name :{" "}
                                      {
                                        headerOptions?.withdrawal_account
                                          ?.bank_name
                                      }{" "}
                                    </span>
                                  )}
                                  {headerOptions?.withdrawal_account
                                    ?.account_holder_name && (
                                    <span className="block leading-4">
                                      Account holder name :{" "}
                                      {
                                        headerOptions?.withdrawal_account
                                          ?.account_holder_name
                                      }{" "}
                                    </span>
                                  )}
                                  {headerOptions?.withdrawal_account
                                    ?.ac_number && (
                                    <span className="block leading-4">
                                      Account number :{" "}
                                      {
                                        headerOptions?.withdrawal_account
                                          ?.ac_number
                                      }{" "}
                                    </span>
                                  )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* FILTERS */}
            <TXFilters
              options={txPayData?.data?.transaction_types}
              query={query}
              debouncedSearch={debouncedSearch}
              handleQuery={(data: any) => setQuery(data)}
              paginateData={txPayData?.meta}
              handleKeyDown={(event: any) => handleKeyDown(event)}
              teamsOptions={teamsOptions}
              isGodAdmin={isGodAdmin}
              txPayLoading={txPayLoading}
            />
          </div>
        </div>
        {customReportLoading
          ? Array.from({ length: 10 }, (v, i) => (
              <div
                className={`accordion font-medium  mb-2.5 mx-5 ${
                  i === 0 && "mt-2.5"
                } `}
              >
                <div
                  key={i}
                  className={`accordion-item  !rounded overflow-hidden ${
                    txPayLoading && "shimmer-effect"
                  } `}
                  style={{ height: "40px" }}
                ></div>
              </div>
            ))
          : layout.items.length > 0 && (
              <DataTable
                ledgerData={layout.items}
                refetch={refetch}
                paginateData={txPayData?.meta}
                txPayLoading={txPayLoading}
              />
            )}
        {layout?.items?.length === 0 && !customReportLoading && (
          <>
            <NoDataComponent />
          </>
        )}
        {customReportLoading && (
          <ProgressBardLoader
            LoadingText="Loading your TX Pay data"
            secondCounter={secondCounter}
          />
        )}
        <WithdrawFundsOffcanvas txPayData={headerOptions} refetch={refetch} />

        {/* KYC */}
        {checkCompleteKYC(globalLayout?.userDetailShow)?.status && (
          <KycOverlay
            isFixed={true}
            kycStatus={
              checkCompleteKYC(globalLayout?.userDetailShow)?.proofSize > 0
                ? true
                : false
            }
          />
        )}
      </div>
      <BankDetailPopup />
      <TransferFundPopup
        popupOpen={transferPopUpOpen}
        refetch={listingRefetch}
      />
    </>
  );
};

export default TXPay;
