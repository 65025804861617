export const Messages = {
  ticketHoldLine1: "This listing is currently on hold.",
  ticketHoldLine1_new: "On hold: ",
  ticketHoldLine2:
    "You will be limited to only updating the price and quantity. If required you may also unpublished the listing.",
  ticketHoldLine2_new:
    "Editing is restricted to Price and Publish status whilst on hold. Edits will apply to live listings immediately and applied to listings on hold upon release by the marketplace.",
  ticketHoldLine3_new:
    "Editing is restricted to Publish status whilst on hold. Edits will apply to live listings immediately and applied to listings on hold upon release by the marketplace.",
  heldTickets: "Held Tickets",
  ticketProcessing:
    "This listing cannot be edited, until the pending request has finished processing.",

  ticketRestricted:
    "These tickets are secured for delivery and will sell faster on the Tixstock Network. Editing restricted to Proceed price, Clone, Delete and Publish status.",
};
