import { useContext } from "react";
import DropDownField from "../../../../components/formComponent/DropDownField";
import { HTTP_METHODS } from "../../../../helpers/const";
import { ConnectorContext } from "../../core/ConnectorProvider";

const APISearchField = ({ handleSend }: any) => {
  const layout = useContext(ConnectorContext);
  const state: any = layout?.state;

  return (
    <div className={`country-code flex-1 flex justify-center`}>
      <div className="dropdown w-full relative flex phone_field  border rounded  items-center">
        <DropDownField
          options={HTTP_METHODS}
          placeholder=""
          isCapital={true}
          isValueCapital={true}
          isSearchable={true}
          isClearable={false}
          name={"httpMethod"}
          value={state?.httpMethod}
          handleOnChange={(data: any) =>
            layout.setState((current: any) => {
              return {
                ...current,
                httpMethod: data?.target?.value,
              };
            })
          }
          optionClassEnable={true}
          menuPlacement="bottom"
        />

        <input
          type="text"
          className={`placeholder:truncate block px-2.5 font-medium w-full h-10 text-xs transition bg-white border border-none appearance-none  focus:outline-none focus:ring-0 focus:border-none focus:bg-violet-300/50 peer rounded-r form`}
          name={"url"}
          id={"url"}
          placeholder="Enter URL or paste text"
          value={state?.url}
          onChange={(e: any) => {
            layout.setState((current: any) => {
              return {
                ...current,
                url: e.target.value,
              };
            });
          }}
        />
      </div>

      {/* SEND BUTTON */}
      <button
        type="button"
        className={`${
          state?.url ? "" : "pointer-events-none"
        } ml-2 group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5`}
        onClick={handleSend}
        disabled={state?.url ? false : true}
      >
        Send
      </button>
    </div>
  );
};

export default APISearchField;
