import { useRef, useState } from "react";
import SVG from "react-inlinesvg";
import PurpleCheckSVG from "../../assets/media/icons/other_icons/purple-check.svg";
import CrossSVG from "../../assets/media/icons/standard_icons/cross.svg";
import { fnBrowserDetect } from "../../helpers/AssetHelpers";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";

const PasswordInput = ({
  label,
  value,
  type = "text",
  id,
  name,
  handleOnChange,
  handleClearValue,
  disabled = false,
  errorClass,
  required = true,
  labelErrorClass,
  apiError,
  isIcon = false,
  width = "",
  autoComplete,
}: any) => {
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const ref = useRef<any>();

  // FIX BROWSER AUTOFILL VALUES
  // useEffect(() => {
  //   if (name === "username") {
  //     ref?.current?.focus();
  //     setTimeout(() => {
  //       setReRender(1);
  //     }, 500);
  //   }
  //   setTimeout(() => {
  //     if (name === "password") {
  //       ref?.current?.focus();
  //       setReRender(2);
  //       ref?.current?.blur();

  //     }
  //   }, 500);
  // }, [ref, reRender, name]);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     if (name === "password") {
  //       ref.current.focus();
  //     }
  //   }, 100);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <div
        className={`date_h relative w-full  ${isFocused && "dp-focused "} ${
          isIcon && "group"
        }`}
      >
        {isIcon && (
          <span
            className={`table-date-icon h-[2.5rem] ${width} cursor-pointer absolute left-0 top-0 flex items-center justify-center fill-violet-500 rounded-l border-r border-gray-300 w-8  pointer-events-none group-[.dp-focused]:bg-indigo-500 group-[.dp-focused]:z-10 group-[.dp-focused]:!fill-white group-[.dp-focused]:border-indigo-500`}
          >
            <KTSVG svgClassName="h-3 w-3" path="standard_icons/geo_icon.svg" />
          </span>
        )}
        <input
          type={type}
          id={id}
          name={name}
          autoComplete={autoComplete}
          ref={ref}
          className={`${
            value && !isFocused && "!bg-white border-indigo-500"
          } ${errorClass} ${
            isIcon && "pl-[3.125rem]"
          } placeholder:truncate block px-2.5 font-medium w-full h-10 text-xs transition bg-gray-100 border border-gray-300 appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer undefined peer-focus:bg-white autofill:bg-indigo-500`}
          placeholder=" "
          value={value}
          disabled={disabled}
          onFocus={(data: any) => setIsFocused(true)}
          onBlur={(data: any) => {
            if (fnBrowserDetect() !== "safari") {
              setIsFocused(false);
            }
          }}
          onChange={(data) => {
            let event = {
              target: { name: data.target.name, value: data.target.value },
            };
            handleOnChange(event);
          }}
        />

        {/* CLEAR & CHECK ICON */}
        {value && isFocused ? (
          <button
            tabIndex={-1}
            type="button"
            className={` w-5 h-5 absolute top-1/2 -translate-y-1/2 right-2.5 flex items-center justify-center fill-violet-500 group-hover:fill-white bg-white rounded-full   hover:fill-white cursor-pointer hover:bg-violet-500  transition-all`}
            onMouseDown={(e: any) => {
              e.preventDefault();
              handleClearValue();
            }}
          >
            <SVG src={CrossSVG} className={"w-2 h-2"} />
          </button>
        ) : (
          value &&
          !errorClass && (
            <IKTSVG
              className="flex items-center justify-center transition absolute  w-5 h-5  top-1/2 -translate-y-1/2 right-2.5"
              path={PurpleCheckSVG}
              svgClassName="w-[1.125rem]"
            />
          )
        )}

        {/* LABEL */}
        <label
          htmlFor={id}
          className={`${
            value && "!bg-white text-indigo-500"
          } ${labelErrorClass} ${apiError} ${
            isIcon && "left-11"
          }  absolute text-xxs rounded-full text-gray-400 duration-300 transform -translate-y-4 font-medium top-2 left-3 z-10 origin-[0] bg-gray-100 peer-focus:bg-white px-2 peer-focus:px-2 peer-focus:text-indigo-500 peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-placeholder-shown:text-xs peer-focus:text-xxs peer-placeholder-shown:text-gray-500 peer-focus:-translate-y-4`}
        >
          {label}
          {required && <span className="text-red-500 ml-1">*</span>}
        </label>
      </div>
    </>
  );
};

export default PasswordInput;
