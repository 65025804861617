import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styled from "styled-components";
import * as Yup from "yup";
import DropDownField from "../../../../components/formComponent/DropDownField";
import TextInput from "../../../../components/formComponent/TextInput";
import { KTSVG } from "../../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import Messages from "../../../../helpers/Messages";
import { showAlert } from "../../../../helpers/ShowAlert";
import { INITIAL_INSTRUCTION_DETAILS } from "../../../../helpers/const";
import { InstructionLetterContext } from "../../core/InstructionLetterProvider";
import { fetchInstructioData, updateInstruction } from "../../core/requests";

const StyledEditor = styled(ReactQuill)`
  .ql-editor {
    min-height: 18em;
  }
`;
const Quill = StyledEditor.Quill;
var Font = Quill.import("formats/font");
Font.whitelist = [
  "Roboto",
  "sans-serif",
  "Raleway",
  "Montserrat",
  "Lato",
  "Rubik",
  "PT-Sans",
];
Quill.register(Font, true);

const editorToolbarOptions = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: Font.whitelist }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ color: [] }, { background: [] }],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link"],
    ["clean"],
    ["code-block"],
  ],
};

const dropdownOptions = [
  { id: 1, value: 1, name: "Active" },
  { id: 0, value: 0, name: "Inactive" },
];

const instructionSchema = Yup.object().shape({
  title: Yup.string().required("Title is required"),
  html_content: Yup.string().required("Content is required"),
});

const InstructionPopup = ({ data, handleCloseForm, listRefetch }: any) => {
  const layout = useContext(InstructionLetterContext);
  const [loader, setLoader] = useState<boolean>(false);
  const [status, setStatus] = useState<any>({
    id: 1,
    value: 1,
    name: "Active",
  });
  const [initialState, setInitialState] = useState({
    title: "",
    html_content: "",
    status: 1,
  });

  useEffect(() => {
    if (data?.isEditable) {
      const filterOption: any = dropdownOptions.filter(
        (option: any) => option.value === data?.data?.status
      )[0];
      setStatus(filterOption);
    }
  }, [data]);

  useEffect(() => {
    if (data?.isEditable) {
      // setInitialState(data.data);
      setLoader(true);
      fetchInstructioData(data?.data?.id)
        .then((res: any) => {
          setInitialState(res?.data[0]);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [data]);

  // FORMIK CALL
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: instructionSchema,
    enableReinitialize: true,
    validateOnMount: true,
    onSubmit: (values: any) => {
      setLoader(true);

      let payload = {
        ...values,
      };

      updateInstruction(payload).then((response: any) => {
        if (response?.data?.status === true) {
          setLoader(false);
          handleCloseForm();
          showAlert(response?.message, false, 1500);
          // listRefetch();
          layout.setListings((pre: any) => {
            return {
              ...pre,
              instruction_letter_templetes:
                pre.instruction_letter_templetes.map((letter: any) => {
                  if (letter?.id === data?.data?.id) {
                    return payload;
                  } else {
                    return letter;
                  }
                }),
            };
            // console.log(pre, "pre");
            // const list = pre.find()
          });

          if (!data?.isEditable) {
            layout.setFilterData(INITIAL_INSTRUCTION_DETAILS);
            listRefetch();
          }
        } else {
          showAlert(
            Array.isArray(response?.data?.errors)
              ? response?.data?.errors.join("<br/>")
              : Messages?.someWrong,
            true
          );
          setLoader(false);
        }
      });
    },
  });

  useEffect(() => {
    const quillLink: any = document.querySelector(".ql-tooltip");

    const adjustQuillLinkPosition = () => {
      if (quillLink && parseFloat(quillLink?.style.left) < 0) {
        quillLink.style.left = "0px";
      }
    };

    // Create a MutationObserver to watch for changes in the .ql-tooltip element
    const observer = new MutationObserver(() => {
      adjustQuillLinkPosition();
    });

    // Observe the .ql-tooltip element for attribute changes
    if (quillLink) {
      observer.observe(quillLink, {
        attributes: true,
        attributeFilter: ["style"],
      });
    }

    // Initial adjustment
    adjustQuillLinkPosition();

    // Cleanup the observer on component unmount
    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);

  return (
    <div
      data-te-modal-init
      className={`${
        data?.isOpen ? "show" : ""
      } s_ticket offcanvas fill_canvas offcanvas-end md:max-w-[43.125rem] fixed bottom-0 top-[3.75rem] right-0 shadow-lg bg-clip-padding outline-none transition duration-300 ease-in-out font-medium  w-full upload-ticket-popup`}
      tabIndex={-1}
      aria-labelledby="fillCanvasLabel"
    >
      <form className="w-full h-full ml-auto">
        <div
          className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col"
          id={`instructionPopup`}
        >
          <div className="offcanvas-header flex justify-between border-t border-b">
            <h5
              className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 px-5"
              id="offcanvasRightLabel"
            >
              {data?.isEditable ? "Edit" : "Add"} Instruction Letter
            </h5>
            <div className="buttons flex flex-wrap">
              <button
                type="button"
                className="flex items-center justify-center w-9 border-l box-content group"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                onClick={() => handleCloseForm()}
              >
                <KTSVG
                  className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
                  path="standard_icons/cross.svg"
                />
              </button>
            </div>
          </div>
          <div className="offcanvas-body h-full w-full flex-row overflow-y-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full px-5 py-8 mb-1">
            <div className="address_fields flex flex-wrap -mx-2.5 inner-inp">
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <TextInput
                    type="text"
                    label="Title"
                    name="title"
                    id="title"
                    required={false}
                    value={formik.values?.title}
                    handleOnChange={(data: any) =>
                      formik.setFieldValue("title", data?.target?.value)
                    }
                    errorClass={
                      formik.touched.title &&
                      formik.errors.title &&
                      "border-rose-500"
                    }
                    handleClearValue={() => formik.setFieldValue("title", "")}
                  />
                  {formik.touched.title && formik.errors.title && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.title as string}
                    </div>
                  )}
                </div>
              </div>
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full px-2.5 relative">
                  <StyledEditor
                    theme="snow"
                    value={formik.values?.html_content}
                    onChange={(value: any) =>
                      formik.setFieldValue(
                        "html_content",
                        value.trim() === "<p><br></p>" ? "" : value
                      )
                    }
                    modules={editorToolbarOptions}
                    placeholder="Write content...."
                  />
                  {formik.touched.html_content &&
                    formik.errors.html_content && (
                      <div className="text-xs text-rose-500 error-msg">
                        {formik.errors.html_content as string}
                      </div>
                    )}
                </div>
                <div className="fieldset w-full flex flex-wrap">
                  <div className="w-full mb-5 px-2.5 relative">
                    <span className="text-sm13">
                      <b>Instruction:</b> You can use this short code
                      [DYNAMIC_CONTENT] or [Key1:DYNAMIC_CONTENT] in your above
                      content which will be replaced by dynamic content during
                      sales fulfillment.
                    </span>
                  </div>
                </div>
              </div>
              <div className="fieldset w-full flex flex-wrap">
                <div className="w-full sm:w-1/2 md:w-1/2 mb-5 px-2.5 relative">
                  <DropDownField
                    options={dropdownOptions}
                    placeholder="Status"
                    name="status"
                    isClearable={false}
                    classname="block"
                    width="w-[6.125rem]"
                    handleOnChange={(data: any) => {
                      setStatus(data.target.value);
                      formik.setFieldValue("status", data?.target?.value.value);
                    }}
                    value={status}
                  />
                  {formik.touched.status && formik.errors.status && (
                    <div className="text-xs text-rose-500 error-msg">
                      {formik.errors.status as string}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className=" offcanvas-footer bottom-0 right-0 w-full flex justify-start px-5 py-3 bg-white z-10 border-t">
            <div className="l_btn gap-2.5 flex flex-wrap items-center">
              <button
                type="button"
                className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
                onClick={() => handleCloseForm()}
              >
                Cancel
              </button>

              <button
                type="button"
                className={`${
                  loader && "pointer-events-none opacity-50"
                } relative z-10 border border-green-600 hover:text-green-600 active:text-white text-white hover:bg-white bg-green-600 active:bg-green-600 transition font-semibold rounded text-sm13 px-2.5 py-0.5 `}
                onClick={() => {
                  formik.handleSubmit();
                }}
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </form>
      {loader && <ProgressBardLoader LoadingText="" secondCounter={0.5} />}
    </div>
  );
};

export default InstructionPopup;
