import { useContext } from "react";
import DataCell from "../../../Reports/table/component/DataCell";
import { InstructionLetterContext } from "../../core/InstructionLetterProvider";

const ItemContent = ({ list }: any) => {
  const layout = useContext(InstructionLetterContext);

  //  HEADER GET COLUMNS
  const getColumnItems = () => {
    return layout.columnItems?.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.columnItems.findIndex((item: any) => item.id === id);
    return index;
  };

  return (
    <>
      {getColumnItems().map((columnItem: any) => {
        switch (columnItem.id) {
          case 0:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(0)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.title}
                    classname="block"
                    width="w-[7.5rem]"
                  />
                )}
              </>
            );
          case 1:
            return (
              <>
                {layout.columnItems[`${getFilterIndexForCheckbox(1)}`]
                  ?.checked && (
                  <DataCell
                    value={list?.status === 1 ? "Active" : "Inactive"}
                    classname="block"
                    width="w-[6.125rem]"
                  />
                )}
              </>
            );
        }
      })}
    </>
  );
};

export default ItemContent;
