import { createContext, PropsWithChildren, useContext, useState } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { getHeaderFilter } from "../../../helpers/AssetHelpers";
import {
  API_CONNECTOR_CLIENT_FILTERS,
  INITIAL_CONNECTOR_VIEW,
  INITIAL_HEADERS,
} from "../../../helpers/const";
import { QueryState } from "../../inventory/core/_models";

export interface LayoutContextModel {
  connectorFilter: any;
  setConnectorFilter: (data: any) => void;

  isTabActive: any;
  setIsTabActive: (data: any) => void;

  canvasToggle: any;
  setCanvasToggle: (data: any) => void;

  sideOption: any;
  setSideOption: (data: any) => void;

  filterItems: Array<any>;
  setFilterItems: (data: any) => void;

  draggedItems: Array<any>;
  setDraggedItems: (data: any) => void;

  state: Array<any>;
  setState: (data: any) => void;

  orderState: any;
  setOrderState: (data: any) => void;

  eventsOrderState: any;
  setEventsOrderState: (data: any) => void;

  columnItems: Array<any>;
  setColumnItems: (data: any) => void;

  clients: Array<any>;
  setClients: (data: any) => void;

  loader: boolean;
  setLoader: (data: any) => void;

  headers: Array<any>;
  setHeaders: (data: any) => void;

  mapping: any;
  setMapping: (data: any) => void;

  clientsOptionsData: any;
  setClientsOptionsData: (data: any) => void;

  variableData: any;
  setVariableData: (data: any) => void;

  eventsFilters: any;
  setEventsFilters: (data: any) => void;

  clientsEvents: Array<any>;
  setClientsEvents: (data: any) => void;

  connectorViewState: any;
  setConnectorViewState: (data: any) => void;

  selectedEvents: Array<any>;
  setSelectedEvents: (data: any) => void;

  eventsMappingState: boolean;
  setEventsMappingState: (data: any) => void;

  mappedEvents: Array<any>;
  setMappedEvents: (data: any) => void;

  listingFilters: any;
  setListingFilters: (data: any) => void;

  listingData: Array<any>;
  setListingData: (data: any) => void;

  orderData: Array<any>;
  setOrderData: (data: any) => void;

  orderFilters: any;
  setOrderFilters: (data: any) => void;
}

const ConnectorContext = createContext<LayoutContextModel>({
  connectorFilter: [],
  setConnectorFilter: (data: any) => {},

  isTabActive: "",
  setIsTabActive: (data: any) => {},

  canvasToggle: false,
  setCanvasToggle: (data: any) => {},

  sideOption: "",
  setSideOption: (data: any) => {},

  filterItems: [],
  setFilterItems: (data: any) => {},

  draggedItems: [],
  setDraggedItems: (data: any) => {},

  state: [],
  setState: (data: any) => {},

  orderState: [],
  setOrderState: (data: any) => {},

  columnItems: [],
  setColumnItems: (data: any) => {},

  clients: [],
  setClients: (data: any) => {},

  loader: false,
  setLoader: (data: any) => {},

  headers: [],
  setHeaders: (data: any) => {},

  mapping: [],
  setMapping: (data: any) => {},

  clientsOptionsData: [],
  setClientsOptionsData: (data: any) => {},

  variableData: [],
  setVariableData: (data: any) => {},

  eventsFilters: {},
  setEventsFilters: (data: any) => {},

  clientsEvents: [],
  setClientsEvents: (data: any) => {},

  eventsOrderState: {},
  setEventsOrderState: (data: any) => {},

  connectorViewState: {},
  setConnectorViewState: (data: any) => {},

  selectedEvents: [],
  setSelectedEvents: (data: any) => {},

  eventsMappingState: false,
  setEventsMappingState: (data: any) => {},

  mappedEvents: [],
  setMappedEvents: (data: any) => {},

  listingFilters: {},
  setListingFilters: () => {},

  listingData: [],
  setListingData: (data: any) => {},

  orderData: [],
  setOrderData: (data: any) => {},

  orderFilters: {},
  setOrderFilters: () => {},
});

const ConnectorProvider = ({ children }: PropsWithChildren) => {
  const [connectorFilter, setConnectorFilter] = useState<any>({
    statuses: null,
    per_page: 20,
  });
  const [eventsFilters, setEventsFilters] = useState<any>({
    page: 1,
    per_page: 20,
  });
  const [listingFilters, setListingFilters] = useState<any>({
    page: 1,
    per_page: 30,
    listingStatuses: [],
  });
  const [orderFilters, setOrderFilters] = useState<any>({
    page: 1,
    per_page: 30,
  });

  const [isTabActive, setIsTabActive] = useState<string>("Configuration");
  const [canvasToggle, setCanvasToggle] = useState<boolean>(true);
  const [sideOption, setSideOption] = useState<string>("Variables");

  const [listingData, setListingData] = useState([]);

  const [orderData, setOrderData] = useState([]);

  const globalLayout = useContext(LayoutContext);
  // FILTER - CONDITION
  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveAPIConnectorFilters;

  let apiConnectorItems = getHeaderFilter(
    "saveAPIConnectorFilters",
    isFilterExistInDB,
    API_CONNECTOR_CLIENT_FILTERS
  );

  // COLUMN FILTER - CONDITION
  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveAPIConnectorFilters;

  let columnsItem = getHeaderFilter(
    "saveAPIConnectorFilters",
    isColumnFilterExistInDB,
    API_CONNECTOR_CLIENT_FILTERS
  );

  const [filterItems, setFilterItems] = useState(apiConnectorItems);

  const [draggedItems, setDraggedItems] = useState(filterItems);

  const [state, setState] = useState<any>(INITIAL_CONNECTOR_VIEW);

  const [orderState, setOrderState] = useState<QueryState>({});
  const [eventsOrderState, setEventsOrderState] = useState<QueryState>({});

  const [columnItems, setColumnItems] = useState(() => {
    return columnsItem;
  });

  const [clients, setClients] = useState([]);
  const [clientsEvents, setClientsEvents] = useState([]);
  const [loader, setLoader] = useState<boolean>(false);
  const [headers, setHeaders] = useState([INITIAL_HEADERS]);
  const [mapping, setMapping] = useState({});

  const [clientsOptionsData, setClientsOptionsData] = useState([]);
  const [variableData, setVariableData] = useState([]);

  const initialConnectorViewState: { status: boolean; data: any } = {
    status: false,
    data: undefined,
  };
  const [connectorViewState, setConnectorViewState] = useState(
    initialConnectorViewState
  );

  const [selectedEvents, setSelectedEvents] = useState([]);
  const [eventsMappingState, setEventsMappingState] = useState(false);
  const [mappedEvents, setMappedEvents] = useState([]);

  // ALL STATES
  const value: LayoutContextModel = {
    connectorFilter,
    setConnectorFilter,

    isTabActive,
    setIsTabActive,

    canvasToggle,
    setCanvasToggle,

    sideOption,
    setSideOption,

    filterItems,
    setFilterItems,

    draggedItems,
    setDraggedItems,

    state,
    setState,

    orderState,
    setOrderState,

    columnItems,
    setColumnItems,

    clients,
    setClients,

    loader,
    setLoader,

    headers,
    setHeaders,

    mapping,
    setMapping,

    clientsOptionsData,
    setClientsOptionsData,

    variableData,
    setVariableData,

    eventsFilters,
    setEventsFilters,

    clientsEvents,
    setClientsEvents,

    eventsOrderState,
    setEventsOrderState,

    connectorViewState,
    setConnectorViewState,

    selectedEvents,
    setSelectedEvents,

    eventsMappingState,
    setEventsMappingState,

    mappedEvents,
    setMappedEvents,

    listingFilters,
    setListingFilters,

    listingData,
    setListingData,

    orderData,
    setOrderData,

    orderFilters,
    setOrderFilters,
  };

  return (
    <ConnectorContext.Provider value={value}>
      {children}
    </ConnectorContext.Provider>
  );
};

export { ConnectorContext, ConnectorProvider };
