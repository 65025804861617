import { LayoutContext } from "components/core/LayoutProvider";
import { useContext, useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import CrossSVG from "../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../../components/ui/ProgressBardLoader";
import { showAlert } from "../../../../helpers/ShowAlert";
import { ConnectorContext } from "../../core/ConnectorProvider";
import { mapEvents, searchEventInAlgolia } from "../../core/requests";
import EventsMappingTable from "../../table/EventsMappingTable";

const EventsMapping = ({ refetch, setCustomEventsLoading }: any) => {
  const { allowedAccess } = useContext(LayoutContext);
  const layout = useContext(ConnectorContext);
  const [loader, setLoader] = useState(true);
  const [algoliaData, setAlgoliaData] = useState<any>({});
  // const [mappedEvents, setMappedEvents] = useState<any>([]);

  // async function fetchEvents() {
  //   const selectedEvents = layout.selectedEvents;
  //   const eventPromises = selectedEvents.map(async (event) => {
  //     return await searchEventInAlgolia({ query: event?.name }).then(
  //       (result) => {
  //         return {
  //           id: event.id,
  //           result: result?.[0]?.hits,
  //         };
  //       }
  //     );
  //   });
  //   // Wait for all promises to resolve
  //   const eventsWithResults = await Promise.all(eventPromises);
  //   console.log(eventsWithResults, "eventsWithResults");
  //   // Map results to IDs
  //   const mappedResults = eventsWithResults.reduce((acc: any, current: any) => {
  //     acc[current.id] = current.result;
  //     return acc;
  //   }, {});
  //   return mappedResults;
  // }

  async function fetchEvents() {
    const selectedEvents = layout?.selectedEvents.filter(
      (item) => !item?.is_mapped
    );

    const algoliaConfiguration = {
      minWordSizefor2Typos: 8,
    };

    const eventPromises = selectedEvents.map(async (event) => {
      const result = await searchEventInAlgolia({
        query: event?.name,
        datetime: event?.datetime,
        allowedAccess,
        algoliaConfiguration,
      });
      return {
        id: event.id,
        hits: result?.hits,
        name: event?.name,
        datetime: event?.datetime,
        currency: event?.currency,
      };
    });

    // Wait for all promises to resolve
    const eventsWithResults = await Promise.allSettled(eventPromises);

    // Return the array of results
    return eventsWithResults;
  }

  useEffect(() => {
    // Call the function

    fetchEvents()
      .then((results) => {
        setLoader(false);

        let finalResults = results.reduce((acc: any, result: any) => {
          if (result?.status === "fulfilled") acc.push(result.value);

          return acc;
        }, []);

        setAlgoliaData(finalResults);
      })
      .catch((err) => {
        setLoader(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEventsMap = async () => {
    setLoader(true);
    mapEvents({
      id: layout?.connectorViewState?.data?.id,
      events: layout?.mappedEvents,
    })
      ?.then((res) => {
        layout?.setEventsMappingState(false);
        if (res?.data?.status) {
          refetch();
          showAlert("Events mapped successfully", false);
        }
        layout.setEventsFilters((curr: any) => {
          return {
            ...curr,
            page: 1,
          };
        });
        setCustomEventsLoading(true);
        layout?.setSelectedEvents([]);
        layout?.setMappedEvents([]);
        setAlgoliaData([]);
      })
      .catch((err) => {
        showAlert("Something went wrong!", false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  return (
    <div
      className="inventory-list w-full px-5 pt-5 h-full flex flex-col absolute top-0 right-0 bg-white main-content-wrap z-[11]"
      id="eventsmapping"
    >
      <div
        className={
          "border-b mb-4 flex justify-between  items-center sticky top-0 bg-white"
        }
      >
        <div className={`flex-1 px-4 font-bold`}>Events mapping</div>
        <div className="flex flex-wrap">
          <button
            className={`group relative sadsadsad mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l`}
            type="button"
            id={`Close-header-Events-Mapping`}
            onClick={() => {
              layout?.setEventsMappingState(false);
              layout.setMappedEvents([]);
            }}
          >
            <IKTSVG
              className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
              path={CrossSVG}
              svgClassName="w-[.5966rem] h-[.5966rem]"
            />

            <Tooltip
              anchorId={`Close-header-Events-Mapping`}
              content={`Close`}
              place={"left"}
              variant="light"
              className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 `}
            />
          </button>
        </div>
      </div>

      {loader ? (
        <>
          {Array.from({ length: 5 }, (v, i) => (
            <div
              className={`accordion font-medium  mb-2.5 mx-5 ${
                i === 0 && "mt-5"
              } `}
              key={i}
            >
              <div
                key={i}
                className={`accordion-item bg-white !rounded overflow-hidden ${
                  loader && "shimmer-effect"
                } `}
                style={{ height: "40px" }}
              ></div>
            </div>
          ))}
        </>
      ) : (
        <>
          <EventsMappingTable data={algoliaData} />{" "}
        </>
      )}

      {/* <div className="flex flex-1 flex-col p-4 ">
        {Object?.keys(algoliaData)?.map((key: any) => {
          const event = layout?.selectedEvents?.find((e) => e.id == key);
          const hits = algoliaData?.[key];
          return (
            <div className="flex border-b mb-5">
              <div className="flex-1 w-full">
                <p className="text-sm">{event?.name}</p>
              </div>
              <div className="flex-1 w-full">
                {hits?.length > 0 ? (
                  hits?.map((hit: any) => {
                    console.log(isChecked(event?.id, hit?.id));
                    return (
                      <div className="flex gap-3 items-center">
                        <input
                          type="radio"
                          name={event?.id}
                          id={hit?.id}
                          onChange={() => handleHitSelect(hit?.id, event?.id)}
                          value={hit?.id}
                          checked={isChecked(hit?.id, event?.id)}
                        />
                        <div>
                          <p className="text-sm">{hit?.name} </p>
                          <span className="text-xxs">
                            (
                            {moment(new Date(hit?.event_date_local)).format(
                              "ll"
                            )}
                            ,{hit?.venue_name})
                          </span>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <p className="text-sm text-red-400">No event Available</p>
                )}
              </div>
            </div>
          );
        })}
      </div> */}

      <div className="px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem] w-full bottom-0">
        <button
          type="button"
          className={`${
            layout?.mappedEvents?.length <= 0
              ? "text-gray-400 bg-gray-100 pointer-events-none"
              : "bg-green-600 hover:bg-indigo-500 !text-white"
          } flex text-sm13 font-medium py-1 px-2.5  rounded border-hidden hover:border-hidden`}
          onClick={handleEventsMap}
        >
          Update
        </button>
      </div>
      {loader && <ProgressBardLoader secondCounter={2} />}
    </div>
  );
};

export default EventsMapping;
