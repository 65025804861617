import { size } from "lodash";
import { useContext } from "react";
import { useQuery } from "react-query";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { QUERIES } from "../../../helpers/const";
import { errorReflect } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import SubmitButton from "../component/SubmitButton";
import { ConnectorContext } from "../core/ConnectorProvider";
import { getVariable, postVariable } from "../core/requests";
import Headers from "./component/Headers";

const Variables = ({ data }: any) => {
  const layout = useContext(ConnectorContext);
  const headers = layout?.headers;
  const sideTabOption = layout.sideOption;
  const { id } = data;

  // GET VARIABLES IF EXISTS
  const { isFetching, refetch } = useQuery(
    QUERIES.GET_VARIABLES,
    () => getVariable(id),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: sideTabOption === "Variables" ? true : false,
      onError: (err) => {},
      onSettled(data, error) {
        if (data?.data && size(data?.data) > 0) {
          layout.setHeaders(data?.data);
        }
      },
    }
  );

  // HANDLE SUBMIT
  const handleSubmit = () => {
    layout.setLoader(true);
    postVariable({ headers, id }).then((response: any) => {
      layout.setLoader(false);
      if (response?.data?.status) {
        showAlert(response?.message, false, 1500);
        setTimeout(() => {
          refetch();
        }, 1500);
      } else {
        errorReflect(response);
      }
    });
  };

  return (
    <div
      className={`inventory-list w-full  pt-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full ${
        layout.canvasToggle ? "px-5" : "pl-10 px-5"
      }`}
    >
      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
        role="tabpanel"
        data-te-tab-active
      >
        <Headers
          id={id}
          handleSubmit={handleSubmit}
          refetch={refetch}
          isSubmitBtnShow={sideTabOption === "Variables"}
        />
      </div>

      {/* SUBMIT BUTTON */}
      <SubmitButton margin={"mt-4"} handleSubmit={handleSubmit} />

      {/* LOADER */}
      {isFetching && <ProgressBardLoader secondCounter={1.5} />}
    </div>
  );
};

export default Variables;
