import React from "react";

const TableRow = React.memo(
  ({ showOrder, hoveredId, setHoveredId, reportsLoading, ...props }: any) => {
    const list = props?.item;
    const matchedView =
      showOrder?.show &&
      showOrder?.data &&
      showOrder?.data?.order_id === list.order_id;

    return (
      <>
        {false ? (
          <tr {...props} className="shimmer-effect highlight" />
        ) : (
          <tr
            {...props}
            className={`border-b bg-white ${matchedView ? "matchView" : ""}
          ${list?.is_legacy_order == "true" && "legacy_block"}

          `}
          />
        )}
      </>
    );
  }
);

export default TableRow;
