import { size } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { TableVirtuoso } from "react-virtuoso";
import fileSVG from "../../../assets/media/icons/other_icons/file.svg";
import processSVG from "../../../assets/media/icons/other_icons/pure-process.svg";
import cross from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { addCommaInNumber, decimalNumber } from "../../../helpers/Functions";
import { showAlert } from "../../../helpers/ShowAlert";
import { SYMBOLS } from "../../../helpers/const";
import { getTxPayListing } from "../../tx_pay/core/requests";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../core/TxAccountProvider";
import {
  downloadPDF,
  getTXAccountPayoutListings,
  savePayoutsCommission,
} from "../core/_request";
import { downloadFile } from "../core/functions";
import CommissionPDFView from "./CommissionPDFView";
import PayoutAccordionHeader from "./PayoutAccordionHeader";
import PayoutsAccordian from "./PayoutsAccordian";
import PopupDetailBox from "./PopupDetailBox";

const PayoutsView = () => {
  const layout = useContext(TxAccountContext);
  const globalLayout = useContext(LayoutContext);
  const { id } = layout.payoutViewData;
  const [viewData, setViewData] = useState<any>();
  const [exportLoader, setExportLoader] = useState<boolean>(false);

  const [payOutFilters, setPayoutFiltersData] = useState<any>({
    page: 1,
    per_page: 20,
  });

  const [isCommissionLoading, setIsCommissionLoading] =
    useState<boolean>(false);

  const { isLoading, refetch } = useQuery(
    [`payout-view-page`],
    () =>
      getTXAccountPayoutListings({
        id,
        payOutFilters,
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: id ? true : false,
      cacheTime: 0,
      onSettled(data, err) {
        setViewData({
          data: data?.data?.[0],
          meta: data?.meta,
        });
      },
    }
  );

  // HANDLE EXPORT CSV
  const handleExportCSV = (id: any) => {
    setExportLoader(true);
    getTxPayListing({
      filters: payOutFilters,
      export_to_csv: 1,
      id: id,
    }).then((response: any) => {
      setExportLoader(false);
      if (response?.data && size(response?.data) > 0) {
        downloadFile({
          data: response?.data.replace(/\\n/g, "\n"),
          fileName: `payouts.csv`,
          fileType: "text/csv",
        });
      } else if (response?.errors && size(response?.errors) > 0) {
        showAlert(response?.errors?.title, true);
      } else {
        showAlert("Something went wrong!", true);
      }
    });
  };

  const [height, setHeight] = useState<any | undefined>(undefined);
  // let timeout: any;
  // const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  useEffect(() => {
    const thead = document.querySelector("#tableId11 table thead");
    const theadHeight: any = thead?.scrollHeight;

    const dataHeight = theadHeight + viewData?.data?.items?.length * 45;
    setHeight(dataHeight);
  });

  const handlePDFDownload = async (data: any, type: string, name: string) => {
    layout.setCustomLoader(true);
    const response = await downloadPDF(data).then((response: any) => {
      return new Blob([response], { type: "application/pdf" });
    });

    const url = window.URL.createObjectURL(response);

    if (type === "view") {
      window.open(url, "_blank");
      layout.setCustomLoader(false);
    } else {
      const anchor = document.createElement("a");
      anchor.href = url;
      anchor.setAttribute("download", name);
      anchor.click();
      window.URL.revokeObjectURL(url);
      layout.setCustomLoader(false);
    }
  };

  const createCommissionPayouts = (e: any) => {
    e.preventDefault();
    setIsCommissionLoading(true);
    savePayoutsCommission(viewData?.data?.id).then(async (response: any) => {
      if (response?.data?.status) {
        await refetch();
        setIsCommissionLoading(false);
      } else if (size(response?.errors) > 0) {
        showAlert(response?.errors?.[0], true);
        setIsCommissionLoading(false);
      } else {
        showAlert("Something went wrong", true);
        setIsCommissionLoading(false);
      }
    });
  };

  return (
    <div className="main-content-wrap bg-white">
      <div className="inner_wrap flex flex-col lg2:h-[calc(100vh-3.8125rem)] 1111">
        <div className="offcanvas-header flex justify-between border-b">
          <h5
            className="offcanvas-title mb-0 leading-normal text-sm15 font-semibold py-2 pr-5 pl-[1.875rem]"
            id="offcanvasRightLabel"
          >
            Payout: #{viewData?.data?.payout_reference || ""}
          </h5>

          {/* CLOSE DRAFT */}
          <button
            type="button"
            className="flex items-center justify-center w-9 border-l box-content group"
            data-tooltip-id={`close-draft`}
            onClick={() => {
              layout.setPayoutViewData((pre: any) => {
                return {
                  ...pre,
                  view_status: false,
                };
              });
            }}
          >
            <TooltipPortal
              id={`close-draft`}
              content={"Close"}
              place="left"
              className="text-center  !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-100 max-w-[9rem] cursor-default "
            />
            <IKTSVG
              path={cross}
              className="fill-violet-500 group-hover:fill-indigo-500 h-2.5 w-2.5 transition"
              svgClassName="w-2.5 h-2.5"
            />
          </button>
        </div>
        <div className="offcanvas-body max-h-full w-full flex flex-col overflow-hidden h-full">
          <div className="inner_body flex flex-wrap h-full">
            {/* LEFT SIDE - TABLE */}
            <div className="left_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5 flex flex-col">
              <div className="totle_order mb-5">
                <h5 className="font-semibold text-sm14 leading-4">
                  {viewData?.data?.items?.length
                    ? addCommaInNumber(viewData?.data?.items?.length)
                    : "0"}{" "}
                  orders
                </h5>
              </div>
              <div className="left_table border h-full rounded overflow-hidden">
                <div className="accordion-body relative h-full">
                  {isLoading && payOutFilters?.page === 1 ? (
                    Array.from({ length: 10 }, (v, i) => (
                      <div
                        className={`accordion font-medium  mb-2.5 py-2${
                          i === 0 && "mt-5"
                        } `}
                      >
                        <div
                          key={i}
                          className={`accordion-item bg-white !rounded overflow-hidden ${
                            isLoading && "shimmer-effect"
                          } `}
                          style={{ height: "40px" }}
                        ></div>
                      </div>
                    ))
                  ) : (
                    <div
                      className={`order-replacement-table  overflow-auto scrollbar-thin scrollbar-thumb-violet-800
                    scrollbar-thumb-rounded-full hover-style2 md:!h-full invoice-table
               
                
                   `}
                      // ref={tableRef}
                      id={`tableId11`}
                      // style={{ height: height > 41 ? `${height}px` : "auto" }}
                      style={{
                        height: globalLayout?.isMobile ? `${height}px` : "",
                      }}
                    >
                      <div
                        className="payout_pop h-full acc_title inventory-table sales-table overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full "
                        id={`payout_pop`}
                      >
                        <TableVirtuoso
                          data={viewData?.data?.items}
                          endReached={() => {
                            if (
                              viewData?.meta?.current_page <
                              viewData?.meta?.last_page
                            ) {
                              setPayoutFiltersData((current: any) => {
                                let nextPage: number =
                                  viewData?.meta?.current_page + 1;
                                return {
                                  ...current,
                                  page: nextPage,
                                };
                              });
                            }
                          }}
                          overscan={200}
                          totalCount={viewData?.data?.items?.length}
                          id={"payout-inner-lazyload"}
                          itemContent={(index, data) => {
                            return (
                              <React.Fragment key={index}>
                                <PayoutsAccordian item={data} index={index} />
                              </React.Fragment>
                            );
                          }}
                          // useWindowScroll
                          fixedHeaderContent={() => <PayoutAccordionHeader />}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* RIGHT SIDE -  DETAILS */}
            <div className="right_body border-r lg2:w-1/2 w-full h-full scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full overflow-auto p-5">
              <div className="totle_order mb-5">
                <h5 className="font-semibold text-sm14 leading-4">
                  Payment details
                </h5>
              </div>
              <div className="right_block ">
                <div className=" after_border flex flex-wrap pb-5 -mx-2.5">
                  <PopupDetailBox
                    label="Payout status"
                    headerValue={"Paid"}
                    // isDisabled={data?.invoice_status === "Draft" ? true : false}
                    // disabledColor={
                    //   data?.invoice_status === "Awaiting Payment"
                    //     ? "danger"
                    //     : data?.invoice_status === "Invoice Paid"
                    //     ? "success"
                    //     : ""
                    // }
                    disabledColor="success"
                    loading={isLoading}
                  />
                </div>
                <div className={`flex flex-wrap gap-y-5 pt-5 -mx-2.5`}>
                  {/* TEAM DROPDOWN */}
                  <PopupDetailBox
                    label="Teams"
                    headerValue={viewData?.data?.team_name}
                    // isDisabled={isDisabled}
                    loading={isLoading}
                  />

                  {/* EVENT DATE */}
                  <PopupDetailBox
                    label="Event date"
                    headerValue={`${moment(
                      viewData?.data?.min_event_date
                    ).format("DD/MM/YYYY")} - ${moment(
                      viewData?.data?.max_event_date
                    ).format("DD/MM/YYYY")}`}
                    // isDisabled={isDisabled}
                    loading={isLoading}
                  />

                  {/* ORDERS */}
                  <PopupDetailBox
                    label="Orders"
                    headerValue={viewData?.data?.orders}
                    // countValue={""}
                    // isDisabled={isDisabled}
                    loading={isLoading}
                  />

                  {/* INVOICE VALUE */}
                  <PopupDetailBox
                    label="Total seller payout"
                    headerValue={`${
                      SYMBOLS[viewData?.data?.currency]
                    }${decimalNumber(viewData?.data?.total_seller_payout)}`}
                    // countValue=""
                    // isDisabled={isDisabled}
                    loading={isLoading}
                  />
                </div>
              </div>

              {/* Commission PDF COMPONENT */}
              <CommissionPDFView
                fileName={
                  viewData?.data?.is_payout_invoice
                    ? `tixstock-payout-invoice-${String (viewData?.data?.payout_reference)
                        ?.toLowerCase()
                        .replace(/ /g, "-")}.pdf`
                    : "Save payout invoice"
                }
                isLoading={isCommissionLoading}
                isCustomLoading={isLoading}
                draftKey={viewData?.data?.xero_invoice_id}
                isDownloadBtn={viewData?.data?.is_payout_invoice}
                downloadPDF={(data: any, type: string, name: string) => {
                  handlePDFDownload(data, type, name);
                }}
                saveCommissionInvoice={createCommissionPayouts}
                title={
                  viewData?.data?.is_payout_invoice
                    ? "View payout invoice"
                    : "Payout invoice"
                }
                loaderMessage="Saving payout invoice"
                isGenerate={!viewData?.data?.is_payout_invoice}
              />
            </div>
          </div>
        </div>

        {/* FOOTER */}
        {/* <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white border border-t z-20 min-h-[3.125rem]">
      <div className={`flex flex-wrap w-full justify-end `}>
        <div className="right-btns flex items-center gap-2.5 anim-fade">
          <button
            type="button"
            className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5"
          >
            <IKTSVG
              className="mr-2 flex items-center justify-center fill-white"
              svgClassName="w-3 h-3"
              path={payoutIcon}
            />
            Payout to sellers
          </button>
        </div>
      </div>
    </footer> */}

        <footer className="sticky bottom-0 border-b flex md:flex-nowrap flex-wrap items-center justify-between px-5 py-1 bg-white border border-t z-20 min-h-[3.125rem]">
          <div className="flex flex-wrap items-center justify-between gap-2.5 my-2 w-full">
            <button
              type="button"
              className={`
              ${
                exportLoader || viewData?.data?.items?.length === 0
                  ? "pointer-events-none bg-gray-100 text-gray-400"
                  : "text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500"
              }
              group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5
              `}
              onClick={(event) => {
                !exportLoader && handleExportCSV(id);
              }}
            >
              {exportLoader ? (
                <IKTSVG
                  className={`flex items-center justify-center fill-violet-500 transition animate-spin group-hover:fill-white mr-1`}
                  path={processSVG}
                />
              ) : (
                <IKTSVG
                  className={`min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center ${
                    viewData?.data?.items?.length === 0
                      ? "fill-gray-300"
                      : "fill-violet-500 group-hover:fill-white"
                  } `}
                  path={fileSVG}
                  svgClassName="w-[.6875rem] h-[.8438rem]"
                />
              )}
              Export to CSV
            </button>
          </div>
        </footer>
      </div>

      {/* LOADER */}
      {layout?.customLoader && <ProgressBardLoader secondCounter={2.5} />}
    </div>
  );
};

export default PayoutsView;
