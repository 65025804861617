import { Info } from "assets/media/icons/other_icons/Info";
import { Stubhub } from "assets/media/icons/other_icons/Stubhub";
import { Ticketpad } from "assets/media/icons/other_icons/Ticketpad";
import Ticombo from "assets/media/icons/other_icons/Ticombo";
import { TXNetwork } from "assets/media/icons/other_icons/TXNetwork";
import clsx from "clsx";
import NoJSXComponent from "components/noJSX/NoJSXComponent";
import ComponentSVG from "components/ui/typography/ComponentSVG";
import { colorForMeter, priceCalculation } from "helpers/Functions";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { memo, useContext, useMemo } from "react";
import {
  keyBoardVisiblityDynamicMessage,
  keyBoardVisiblityDynamicMessage1,
  visiblityMeter,
} from "../core/_functions";
import { AddInventoryContext } from "../core/AddInventoryProvider";

const InfoTooltip = ({
  onClick,
  tooltipComponent,
}: {
  onClick: () => void;
  tooltipComponent: React.ReactNode;
}) => {
  const addRemoveAddOnBlock = (isAddOrRemove: string) => {
    let visibilityBox: HTMLElement | null = document.getElementById(
      "add-inventory-header-visibility-block"
    );

    if (visibilityBox) {
      if (isAddOrRemove === "add") {
        visibilityBox.classList.add("border-indigo-500");
      } else if (isAddOrRemove === "remove") {
        visibilityBox.classList.remove("border-indigo-500");
      }
    }
  };
  return (
    <span
      data-tooltip-id="visibility_info"
      id="add-inventory-header-info-icon"
      onClick={onClick}
      onMouseEnter={() => addRemoveAddOnBlock("add")}
      onMouseLeave={() => addRemoveAddOnBlock("remove")}
    >
      <ComponentSVG>
        <Info className="w-3.5 h-3.5 ml-[.3125rem] fill-gray-400 hover:fill-indigo-500 cursor-pointer" />
      </ComponentSVG>
      {tooltipComponent}
    </span>
  );
};

const LogosDisplay = memo(() => {
  const svgImageUrls = useMemo(
    () => [
      <Stubhub key="Stubhub" />,
      <Ticombo key="Ticombo" />,
      <Ticketpad key="Ticketpad" />,
      <TXNetwork key="TXNetwork" />,
    ],
    []
  );
  return (
    <div className="logos flex items-center gap-2.5 transition-all anim-fade max-md:whitespace-nowrap">
      {svgImageUrls.map((img, index) => (
        <div className="single-logo" key={index}>
          <ComponentSVG>{img}</ComponentSVG>
        </div>
      ))}
      <div className="more text-sm12 font-medium text-violet-500">+20 more</div>
    </div>
  );
});

type TListingQualityHeader = {
  categorySectionRequired: any;
  priceFiledFocused: boolean;
  proceedPriceLoader: any;
  setIsOpenListingQualityPopup: (open: boolean) => void;
};

const ListingQualityHeader = ({
  categorySectionRequired,
  priceFiledFocused,
  proceedPriceLoader,
  setIsOpenListingQualityPopup,
}: TListingQualityHeader) => {
  const layout = useContext(AddInventoryContext);
  const { priceFactor } = NoJSXComponent();
  const searchedValue = layout.searchFieldValue;
  const keyboardField = layout.keyboardField;

  const listingQualityMeter = useMemo(() => {
    if (categorySectionRequired?.withCategory && keyboardField?.avg_price) {
      return priceCalculation(
        keyboardField?.eventRecords?.[0]?.proceedValue?.value,
        Number(keyboardField?.avg_price),
        priceFactor
      );
    }
    return false;
  }, [
    categorySectionRequired?.withCategory,
    keyboardField?.avg_price,
    keyboardField?.eventRecords,
    priceFactor,
  ]);

  const tooltipComponent = (
    <TooltipPortal
      id={`visibility_info`}
      content="Click for more info"
      place="top"
      variant="light"
      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium "
    />
  );

  const renderMessage = () => {
    const visibility = keyboardField?.eventRecords?.[0]?.visibility;
    const isVisibilityZero = visibility === 0 && searchedValue === "";
    const isVisibilitySix = visibility === 6;

    if (isVisibilityZero) {
      return (
        <span className="flex items-center">
          Listing Quality
          <InfoTooltip
            onClick={() => setIsOpenListingQualityPopup(true)}
            tooltipComponent={tooltipComponent}
          />
        </span>
      );
    }

    if (isVisibilitySix) {
      return (
        <>
          <LogosDisplay />
          <InfoTooltip
            onClick={() => setIsOpenListingQualityPopup(true)}
            tooltipComponent={tooltipComponent}
          />
        </>
      );
    }

    return (
      <>
        <span
          className={keyBoardVisiblityDynamicMessage1(
            visibility,
            keyboardField?.eventRecords?.[0],
            searchedValue,
            listingQualityMeter,
            priceFiledFocused,
            keyboardField,
            proceedPriceLoader?.status
          )}
        >
          {keyBoardVisiblityDynamicMessage(
            visibility,
            keyboardField?.eventRecords?.[0],
            searchedValue,
            listingQualityMeter,
            priceFiledFocused,
            keyboardField,
            proceedPriceLoader?.status
          )}
        </span>
        <InfoTooltip
          onClick={() => setIsOpenListingQualityPopup(true)}
          tooltipComponent={tooltipComponent}
        />
      </>
    );
  };

  return (
    <div className="visible-cir ml-auto flex items-center md:px-5 md:pl-2.5 px-[.9375rem] py-[.875rem] md:w-auto w-full justify-end md:border-b-0 border-b md:order-2">
      <div className="inner-wrap-cir w-full flex max-md:gap-4 max-md:flex-wrap max-md:justify-end items-center px-2.5 pr-0 rounded">
        <div className="text-sm12 scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:mr-2.5 leading-4 anim-fade text-violet-800 flex g-2 items-center">
          {renderMessage()}
        </div>
        <span id="visibility_addInventory" className="cursor-pointer">
          <div
            className={clsx(
              "svg-wrap border rounded p-[.1875rem] flex items-center justify-center gap-0.5",
              searchedValue !== "" &&
                visiblityMeter(
                  keyboardField?.eventRecords?.[0]?.oldVisibility,
                  keyboardField?.eventRecords?.[0]?.visibility,
                  searchedValue,
                  keyboardField?.eventRecords?.[0]
                ),
              colorForMeter(
                listingQualityMeter,
                keyboardField?.eventRecords?.[0]
              ),
              "hover:border-indigo-500"
            )}
            id="add-inventory-header-visibility-block"
            data-tooltip-id="visibility_info"
            onClick={() => setIsOpenListingQualityPopup(true)}
          >
            <span className="s_block w-[4.125rem] h-1.5 bg-gray-300 rounded-l-[.125rem]" />
            <span className="s_block w-[.9375rem] h-1.5 bg-gray-300" />
            <span className="s_block w-[.9375rem] h-1.5 bg-gray-300" />
            <span className="s_block w-[.9375rem] h-1.5 bg-gray-300" />
            <span className="s_block w-[.9375rem] h-1.5 bg-gray-300 rounded-r-[.125rem]" />
          </div>
        </span>
      </div>
    </div>
  );
};

export { ListingQualityHeader };
