import { useState } from "react";
import FilterChips from "./FilterChips";
import Filters from "./Filters";

const FilterWrapper = ({ loading }: any) => {
  const [searchValue, setSearchValue] = useState("");
  
  return (
    <>
      {/* ALL FILTERS */}
      <Filters
        loading={loading}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
      />

      {/* FILTER CHIPS */}
      <FilterChips loading={loading} setSearchValue={setSearchValue} />
    </>
  );
};

export default FilterWrapper;
