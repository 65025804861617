import { LayoutContext } from "components/core/LayoutProvider";
import { QUERIES } from "helpers/const";
import _ from "lodash";
import useDebounce from "pages/add_inventory/core/useDebounce";
import { algoliaSearch } from "pages/Reports/core/requests";
import { useContext, useState } from "react";
import { useQuery } from "react-query";

export const useAlgoliaSearch = ({ inputChange }: any) => {
  const coreLayout = useContext(LayoutContext);
  const [algoliaOptions, setAlgoliaOptions] = useState<any>([]);

  const algoliaDebounce = useDebounce(inputChange, 1200);

  const algoliaConfiguration = {
    minWordSizefor2Typos: 8,
  };

  // MULTI SELECT ALGOLIA
  const { isLoading, data: optionsData } = useQuery(
    [`${QUERIES.ALGOLIA_INVENTORY}`, algoliaDebounce],
    () => {
      if (inputChange && inputChange.trim() !== "") {
        return algoliaSearch({ inputChange, coreLayout, algoliaConfiguration });
      }
      return Promise.resolve(); // Return an empty resolved promise when algoliaDebounce is falsy or an empty string
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: Boolean(algoliaDebounce),
      onError: (err) => {},
      onSuccess: (result: any) => {
        if (result?.hits) {
          setAlgoliaOptions((current: any) => {
            return current?.length > 0
              ? _.uniqBy([...current, ...result?.hits], "id")
              : result?.hits;
          });
        }
      },
    }
  );

  return {
    isLoading,
    algoliaDebounce,
    optionsData,
    algoliaOptions,
    setAlgoliaOptions,
  };
};
