import { IKTSVG } from "components/ui/IKTSVG";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import DataCell from "pages/Reports/table/component/DataCell";
import React from "react";
import { Tooltip } from "react-tooltip";
import DeleteSvg from "../../../../../assets/media/icons/other_icons/Delete.svg";
import EDITSVG from "../../../../../assets/media/icons/other_icons/edit-square.svg";
import { removeHtmlTags } from "helpers/Functions";

const ItemContent = ({
  list,
  sIndex,
  handleCheck,
  handleDelete,
  handleView,
}: {
  list: any;
  sIndex: any;
  handleCheck: any;
  handleDelete: any;
  handleView: any;
}) => {
  const { contentList, isTabActive, setIsPopupActive } = useCustomize();
  const matchedView = list?.selected;

  return (
    <React.Fragment>
      {/* <td
        className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.8125rem] text-center sticky left-0 no-scroll p-0 checkbox_td group-hover:bg-indigo-500 group-hover:bg-opacity-[7%]`}
        id="checkbox_shad"
      >
        <div
          className={`h-full flex items-center justify-center bg-inherit border-r w-10 `}
        >
          <label
            className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] flex items-center absolute top-0  justify-center select-none p-1 border-r`}
            htmlFor={`checkbox-all-${sIndex}`}
          ></label>
          <input
            id={`checkbox-all-${sIndex}`}
            type="checkbox"
            className={`cursor-pointer w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 relative`}
            checked={list?.selected}
            onChange={(e: any) => handleCheck(e, list?.id)}
            onClick={(e) => handleShift(e, sIndex, contentList)}
          />
        </div>
      </td> */}
      <DataCell
        value={list?.title}
        className="block"
        padding="pl-5"
        width="w-[15.25rem]"
      />
      <DataCell value={list?.type} className="block" width="w-[4.25rem]" />
      <DataCell
        value={removeHtmlTags(list?.description)}
        className="block"
        width="w-[10.5rem]"
        noCapitalize="yes"
      />

      <td
        className={`relative md:sticky right-0 ml-auto p-0 icon_td  border-b min-w-[2.5rem] md:min-w-[5rem] w-[5rem]`}
      >
        <React.Fragment key={sIndex}>
          <div
            className={`${matchedView ? "bg-indigo-500 bg-opacity-[7%]" : ""} `}
          >
            <div
              className="flex relative w-full h-full border-l"
              id="left-shad-list"
            >
              {/* EDIT BUTTON */}
              <div className="flex left-shad relative w-full h-full ">
                <button
                  className={`group my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 md:border-r md:flex-auto flex-1 `}
                  type="button"
                  id={`record-delete--tooltip${sIndex}`}
                  onClick={() => {
                    handleDelete(list, sIndex);
                  }}
                >
                  <Tooltip
                    anchorId={`record-delete--tooltip${sIndex}`}
                    content={`Delete`}
                    place={`${sIndex === 0 ? "left" : "top"}`}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                      sIndex === 0 ? "mt-0" : "mt-1.5"
                    }`}
                  />
                  <IKTSVG
                    className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
                    svgClassName="w-3.5 h-3.5"
                    path={DeleteSvg}
                  />
                </button>
                <button
                  className={` group my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 border-r md:flex-auto flex-1 max-md:hidden`}
                  type="button"
                  id={`record-view-tooltip${sIndex}`}
                  // data-bs-toggle="offcanvas"
                  // data-bs-target={`#add_new_content`}
                  // aria-controls={`AddContentLabel`}
                  onClick={() => handleView(list)}
                >
                  <Tooltip
                    anchorId={`record-view-tooltip${sIndex}`}
                    content={`${"Edit"}`}
                    place={`${sIndex === 0 ? "left" : "top"}`}
                    variant="light"
                    className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                      sIndex === 0 ? "mt-0" : "mt-1.5"
                    }`}
                  />
                  <IKTSVG
                    className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
                    svgClassName="w-3.5 h-3.5"
                    path={EDITSVG}
                  />
                </button>
              </div>
            </div>
          </div>
        </React.Fragment>
      </td>
      <td className="sticky right-0 ml-auto p-0 icon_td  border-b md:hidden">
        <div className="flex h-full border-l">
          {/* Edit BUTTON */}
          <button
            className={` group my-button flex items-center justify-center p-1 md:p-1 w-8 md:w-10 border-r md:flex-auto flex-1 `}
            type="button"
            id={`record-view-tooltip${sIndex}`}
            data-bs-toggle="offcanvas"
            data-bs-target={`#add_new_content`}
            aria-controls={`AddContentLabel`}
            onClick={() => {
              setIsPopupActive({
                tabActive: isTabActive,
                status: true,
                data: list,
              });
            }}
          >
            <Tooltip
              anchorId={`record-view-tooltip${sIndex}`}
              content={`${"Edit"}`}
              place={`${sIndex === 0 ? "left" : "top"}`}
              variant="light"
              className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                sIndex === 0 ? "mt-0" : "mt-1.5"
              }`}
            />
            <IKTSVG
              className={`flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition`}
              svgClassName="w-3.5 h-3.5"
              path={EDITSVG}
            />
          </button>
        </div>
      </td>
    </React.Fragment>
  );
};

export { ItemContent };
