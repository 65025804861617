import React, { useContext, useEffect, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { KTSVG } from "../../../components/ui/KTSVG";
import { NotificationsContext } from "../core/NotificationsProvider";
import ActivityDetail from "../widgets/ActivityDetail";

const ActivityLogDataTable = ({
  data,
  tabValue,
  show,
  handleShow,
  paginateData,
  notificationsLoading,
}: any) => {
  const layout = useContext(NotificationsContext);
  const [showActivity, setShowActivity] = useState<any>({
    show: false,
    data: "",
  });

  // HANDLE NOTIFICATION VIEW PAGE
  const handleActivityView = (activity: any, index: number) => {
    setShowActivity({
      show: true,
      data: activity,
      index: index,
    });
  };
  const ScrollSeekPlaceholder = ({
    height,
    index,
  }: // context: { randomHeights },
  any) => (
    <div
      key={index}
      style={{
        height,
        // width: "710px",
        padding: "8px",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
      className=" abc"
    >
      <div
        className=" shimmer-effect "
        style={{
          // width: "700px!important",
          // height: randomHeights[index % 10],
          height: "50px",
        }}
      ></div>
    </div>
  );

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  return (
    <>
      <div
        className="notification_list my-5 font-medium transition-all md:px-5 max-md:!mr-0"
        id="notification_list"
      >
        <div className="inner_notification bg-white  !rounded">
          <div className="  bg-white w-full h-full ml-auto relative flex flex-col">
            <Virtuoso
              data={layout.activities}
              endReached={() => {
                if (paginateData?.current_page < paginateData?.last_page) {
                  layout?.setActivitiesFilterHandle((current: any) => {
                    let nextPage: number = paginateData?.current_page + 1;
                    return {
                      ...current,
                      page: nextPage,
                      per_page: 20,
                    };
                  });
                }
              }}
              useWindowScroll
              overscan={200}
              totalCount={layout.activities?.length}
              id={"activities-lazyload"}
              components={{ ScrollSeekPlaceholder }}
              scrollSeekConfiguration={{
                enter: (velocity) => Math.abs(velocity) > 300,
                exit: (velocity) => {
                  const shouldExit = Math.abs(velocity) < 10;
                  if (shouldExit) {
                    // setVisibleRange(["-", "-"]);
                  }
                  return shouldExit;
                },
                // change: (_velocity, { startIndex, endIndex }) => setVisibleRange([startIndex, endIndex])
              }}
              itemContent={(index, activity) => {
                let matchedView =
                  showActivity?.show &&
                  showActivity?.data &&
                  showActivity?.data?.id === activity.id;
                return (
                  <React.Fragment>
                    <div
                      className={`single_notification flex flex-wrap border-b hover:bg-indigo-500 hover:bg-opacity-[7%] ${
                        matchedView && "bg-indigo-500 bg-opacity-[7%]"
                      }`}
                    >
                      {activity.is_new ? (
                        <div className="notification_details flex-1 px-5 py-3">
                          <div className="details flex flex-wrap items-center text-xxs text-green-600 font-medium mb-1">
                            <span className="flex items-center mr-1">
                              <span className="w-2 h-2 rounded-full bg-green-600 mr-1.5"></span>
                              New
                            </span>{" "}
                            <span>{activity.created_at}</span>
                          </div>
                          <div className="not_content font-medium text-sm12">
                            <h5
                              dangerouslySetInnerHTML={{
                                __html: activity.text,
                              }}
                            ></h5>
                          </div>
                        </div>
                      ) : (
                        <div className="notification_details flex-1 px-5 py-3">
                          <div className="details flex flex-wrap items-center text-xxs text-indigo-600 font-medium mb-1">
                            <span className="flex items-center mr-1">
                              <span className="w-2 h-2 rounded-full bg-indigo-600 mr-1.5"></span>
                            </span>{" "}
                            <span>{activity.created_at}</span>
                          </div>
                          <div className="not_content font-medium text-sm12">
                            <h5
                              dangerouslySetInnerHTML={{
                                __html: activity.text,
                              }}
                            ></h5>
                          </div>
                        </div>
                      )}

                      <div
                        className={`not_eye flex items-center justify-center border-l ${
                          matchedView && ""
                        }`}
                      >
                        <button
                          className={`p-1 md:p-1 w-10 flex items-center justify-center`}
                          type="button"
                          onClick={() => {
                            handleActivityView(activity, index);
                          }}
                        >
                          <KTSVG
                            className={`flex items-center justify-center ${
                              matchedView
                                ? "fill-indigo-500"
                                : "fill-violet-500"
                            } hover:fill-indigo-500 transition h-4 w-4`}
                            path="other_icons/Eye.svg"
                            svgClassName="w-4 h-[.6875rem]"
                          />
                        </button>
                      </div>
                    </div>
                  </React.Fragment>
                );
              }}
            />

            <div
              id="custom-loader"
              className={`accordion bg-white font-medium pt-2.5  border `}
            >
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "40px" }}
              ></div>
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "40px" }}
              ></div>
              <div
                className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
                style={{ height: "40px" }}
              ></div>
            </div>
            {/* {notificationsLoading && (
              <div
                className={"w-full bg-white flex justify-center items-center"}
                style={{ height: "40px" }}
              >
                <IKTSVG
                  className="flex items-center justify-center fill-violet-500 transition animate-spin"
                  path={ProcessSVG}
                />
              </div>
            )} */}
          </div>
        </div>
      </div>

      {showActivity?.show && (
        <ActivityDetail
          show={showActivity}
          handleShow={(data: any) => setShowActivity(data)}
        />
      )}
    </>
  );
};
export default ActivityLogDataTable;
