import algoliasearch from "algoliasearch";
import axios from "axios";
import { FileToObjectConversion } from "helpers/AssetHelpers";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "helpers/const";
import _ from "lodash";
import { mapEditRecordDataToFormData } from "./functions";

const algoliaSearch = async (data: any): Promise<any> => {
  let query = data?.inputChange;
  let filtersArray: string[] = [];
  let coreLayout = data?.coreLayout?.allowedAccess;
  let checkExchange =
    coreLayout?.is_connected_to_preferred_partners === 0
      ? coreLayout?.connected_exchanges &&
        Array.isArray(coreLayout?.connected_exchanges) &&
        _.size(coreLayout?.connected_exchanges) > 0
        ? coreLayout?.connected_exchanges
        : ""
      : "";

  // IF LOGGEDIN USER IS NOT CONNECTED WITH TIXSTOCK THEN PASS CONNECTED MARKETPLACE IDS
  if (checkExchange) {
    let exchangeCondition = checkExchange
      .map((ex: number) => `exchanges=${ex}`)
      .join(" OR ");
    filtersArray.push(`(${exchangeCondition})`);
  } else {
    // IF LOGGED USER IS CONNECTED WITH TIXSTOCK THEN PASS BELOW FILTER
    filtersArray.push(`is_source_tixstock=1`);
  }

  // Get today's date and format it as a timestamp (in seconds)
  const today = Math.floor(new Date().getTime() / 1000);
  filtersArray.push(`event_date_local >= ${today}`);

  // Build the filters string from the filtersArray
  const filtersString = filtersArray.join(" AND ");

  const payload = [
    {
      indexName: "events",
      query: query,
      params: {
        hitsPerPage: 1000,
        filters: filtersString, // Use the final filters string here
      },
    },
    {
      indexName: "performers",
      query: query,
      params: {
        filters: `events_exists=1`,
      },
    },
    {
      indexName: "venues",
      query: query,
      params: {
        filters: `events_exists=1`,
      },
    },
    {
      indexName: "categories",
      query: query,
      params: {
        hitsPerPage: 1000,
      },
    },
    {
      indexName: "venues",
      query: query,
      params: {
        hitsPerPage: 100000,
        filters: `events_exists=1`,
        facets: ["city"],
      },
    },
  ];

  // Connect and authenticate with your Algolia app
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );

  return client.multipleQueries(payload).then(({ results }: any) => {
    return results;
  });
};

export const getMenuRecords = (filters: any) => () => {
  return axios
    .get(`tx-trade/txtrade-menu`, { params: filters })
    .then((response) => response.data);
};

export const addMenuRecords = (body: any) => {
  let formdata = { ...body, is_main_item: true };
  return axios
    .post(`tx-trade/txtrade-menu`, formdata)
    .then((response) => response.data);
};

export const editMenuRecords = (body: any) => {
  let formData = mapEditRecordDataToFormData(body);

  return axios
    .post(`tx-trade/txtrade-menu`, formData)
    .then((response) => response.data);
};

// HOME PAGE
const getHomeDesign = async () => {
  const response = await axios.get(`tx-trade/txtrade-home`);
  return response.data;
};

const getPerHomeDesign = async (id: any) => {
  const response = await axios.get(`tx-trade/txtrade-get-content`, {
    params: { id: id },
  });
  return response.data;
};

function generateSlug(input: any) {
  return input
    .toLowerCase() // Convert to lowercase
    .trim() // Trim leading/trailing spaces
    .replace(/[^\w\s-]/g, "") // Remove special characters
    .replace(/\s+/g, "-") // Replace spaces with hyphens
    .replace(/-+/g, "-"); // Remove duplicate hyphens
}

// POST
const postHomeDesign = async (data: any) => {
  let payload = {
    items: data?.map((item: any) => {
      return {
        ...(item?.id && { id: item?.id }),
        is_deleted: item?.is_deleted ? true : false,
        // is_deleted: true,
        type: item?.types?.id,
        type_components: null,
        status: true,
        type_comp_ids: item?.type_components?.map((component: any) => {
          return {
            title: component?.compoName,
            type: (component?.compoType as string).toLowerCase(),
            type_id: component?.compoId,
            slug: generateSlug(component?.compoName),
          };
        }),
      };
    }),
  };
  const response = await axios.post(`tx-trade/txtrade-home`, payload);
  return response.data;
};

// CONTENT PAGE API'S
const getContentListings = async (data: any) => {
  const keyword =
    data?.eventName && data?.eventName.length > 0
      ? _.map(data.eventName, "id")
      : null;

  const per_page = data?.per_page;
  const page = data?.page;

  const params = {
    per_page,
    page,
    ...(keyword && { type_id: keyword }),
    ...(data?.title && data?.eventName?.length <= 0 && { title: data?.title }),
  };

  const response = await axios.get(`tx-trade/txtrade-get-content`, {
    params,
  });

  return response.data;
};

const ContentAddRecord = async (data: any) => {
  const isFile = (file: any) => file instanceof File;

  let payload: any = {
    ...(data?.id && { id: data?.id }),
    type: data?.type?.type,
    type_id: data?.type?.id,
    title: data?.type?.name,
    description: data?.description,
    ...(!isFile(data?.logo_image[0]) &&
      data?.logo_is_deleted && { logo_is_deleted: data?.logo_is_deleted }),
    ...(!isFile(data?.banner_image[0]) &&
      data?.banner_is_deleted && {
        banner_is_deleted: data?.banner_is_deleted,
      }),
  };

  if (isFile(data?.logo_image[0])) {
    payload.logo_image = FileToObjectConversion(data?.logo_image)?.[0];
  }
  if (isFile(data?.banner_image[0])) {
    payload.banner_image = FileToObjectConversion(data?.banner_image)?.[0];
  }

  const response = await axios.post(`tx-trade/txtrade-content`, payload);
  return response.data;
};

// DELETE
const deleteContentListings = async (id: any) => {
  const response = await axios.delete(`tx-trade/txtrade-content`, {
    params: {
      id,
    },
  });
  return response.data;
};

export {
  algoliaSearch,
  ContentAddRecord,
  deleteContentListings,
  getContentListings,
  getHomeDesign,
  getPerHomeDesign,
  postHomeDesign,
};
