import InfoList from "../layout/auth/sign_up/component/InfoList";

const KYCInstructions = ({
  error,
  userType,
  errorHighLight,
  useSmall = false,
}: any) => {
  // IDENTITY PROOF ARRAY
  const identityProof = [
    {
      key: "ProofBankAccount",
      title:
        userType === "Business"
          ? "Proof of business bank account to be used for Tixstock wallet withdrawals"
          : "Proof of bank account to be used for Tixstock wallet withdrawals",
      subTitle:
        "First page of bank statement from the last 3 months (blank out balance and transactions)",
      content:
        "Must match the Tixstock account name. Please ensure all important account identifiers are visible - e.g. Name of bank, Account name, IBAN/SWIFT/BIC, Account number, Sort code/Routing number, Account holder address",
    },
    ...(userType === "Business"
      ? [
          {
            key: "CertificateOfIncorporation",
            title: "Certificate of incorporation",
            subTitle: "",
            content: "",
          },
          {
            key: "CompanyShareRegister",
            title: "Company shareholder register",
            subTitle: "",
            content: "",
          },
          {
            key: "RegulatoryFillings",
            title: "Regulatory filings",
            subTitle: "",
            content: "",
          },
          {
            key: "UltimateBeneficial",
            title: "Ultimate beneficial owner(s) verification",
            subTitle: "",
            content: "",
          },
        ]
      : [
          {
            key: "PhotoID",
            title: "Photo ID",
            subTitle: "Copy of passport, identity card or driver’s license",
            content: "",
          },
        ]),
  ];
  return (
    <div
      title={error && "Upload required documents"}
      className={`${useSmall ? 'bottom w-full border-t p-5 pb-0' : 'form need_info bg-gray-100 p-[.9375rem] md:p-6 pb-[.3125rem] md:pb-3.5 rounded-lg mb-5 md:mb-9'} ${
        errorHighLight && "bg-rose-500 bg-opacity-[15%]"
      }`}
      data-name={"identity_proof"}
    >
      <h4
        className={
          useSmall
            ? "text-sm13 font-basier_med leading-4 mb-4"
            : "text-sm14 md:text-sm15 font-basier_med leading-5 mb-5"
        }
      >
        We require the following documents to process your application:
      </h4>
      <div className="inner_need flex flex-wrap -mx-2.5">
        {userType === "Business"
          ? [identityProof?.[0]].map((item: any, index: number) => (
              <InfoList
                key={item.key}
                item={item}
                index={index % 2 !== 0}
                isPopup={useSmall}
              />
            ))
          : identityProof?.map((item: any, index: number) => (
              <InfoList
                key={item.key}
                item={item}
                index={index % 2 !== 0}
                isPopup={useSmall}
              />
            ))}
      </div>
      {userType === "Business" && (
        <>
          <h4
            className={
              useSmall
                ? "text-sm13 font-basier_med leading-4 mb-4"
                : "text-sm15 font-basier_med leading-5 mb-5"
            }
          >
            Plus any 2 of the following business documents:
          </h4>
          <div
            className={
              useSmall
                ? "inner_need flex flex-wrap -mx-2.5 mb-2.5"
                : "inner_need flex flex-wrap -mx-2.5"
            }
          >
            {identityProof?.map((item: any, index: number) => {
              if (index !== 0) {
                return (
                  <InfoList
                    key={item.key}
                    className="px-2.5 flex flex-wrap md:w-1/2 mb-2.5"
                    item={item}
                    index={index % 2 !== 0}
                    isPopup={useSmall}
                  />
                );
              }
              return null;
            })}
          </div>
        </>
      )}
    </div>
  );
};

export default KYCInstructions;
