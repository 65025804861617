import { useContext, useEffect } from "react";
import CacheBuster from "react-cache-buster";
// import { ErrorBoundary } from "react-error-boundary";
import { ErrorBoundary } from "react-error-boundary";
import WebFont from "webfontloader";
import version from "../package.json";
import ErrorBoundaryContent from "./components/core/ErrorBoundary";
import {
  LayoutContext,
  LayoutProvider,
} from "./components/core/LayoutProvider";
import Loader from "./helpers/Loader";
import AppRoutes from "./routing/AppRoutes";

const App = () => {
  const layout = useContext(LayoutContext);

  const Caching = (version: any) => {
    let local_version: any = localStorage.getItem("version");
    if (local_version !== version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach((name) => {
            caches.delete(name);
          });
        });
      }

      //localStorage.clear();
      localStorage.setItem("version", version);

      // let target_version = "0.2.0";
      // if (compareVersions(local_version, target_version) < 0) {
      // removed saved filter of report module
      //localStorage.removeItem("saveDraggableFilterData");
      // }
    }
  };

  useEffect(() => {
    Caching(version?.version);
  }, []);
  useEffect(() => {
    WebFont.load({
      google: {
        families: [
          "Roboto",
          "Raleway",
          "Montserrat",
          "Lato",
          "Rubik",
          "PT Sans",
          "Sans Serif",
        ],
      },
    });
  }, []);

  return (
    // <ErrorBoundaryContent FallbackComponent={ErrorBoundaryContent} />
    <ErrorBoundary FallbackComponent={ErrorBoundaryContent}>
      <CacheBuster
        currentVersion={version?.version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={false} //If true, the library writes verbose logs to console.
        loadingComponent={<Loader />} //If not pass, nothing appears at the time of new version check.
        metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
      >
        <div style={{ fontSize: layout.htmlFontSize + "%" }}>
          <LayoutProvider>
            <AppRoutes />
          </LayoutProvider>
        </div>
      </CacheBuster>
    </ErrorBoundary>
  );
};

export default App;
