import { useState } from "react";
import CloneSVG from "../../assets/media/icons/other_icons/Clone.svg";
import { copy } from "../../pages/add_inventory/core/_functions";
import { IKTSVG } from "../ui/IKTSVG";

const CopyToClipboard = ({
  header,
  value,
  className = "md:w-1/2 md:p-2 py-2 w-full",
  id,
}: any) => {
  const [isCopied, setIsCopied] = useState<any>();

  return (
    <div className={className}>
      <div className="text-gray-400 text-sm12 mb-1 line-clamp-1" title={header}>
        {header}
      </div>
      <div className="relative">
        <input
          type="text"
          value={value}
          className="text-sm12 rounded bg-gray-100 w-full border-none px-2 py-0 block text-violet-800 text-ellipsis whitespace-nowrap pr-8"
          disabled
        />

        {value !== "" && (
          <button
            className="flex items-center justify-center p-1 absolute top-1/2 right-1 -translate-y-1/2"
            type="button"
            onClick={(e: any) => {
              copy(value);
              setIsCopied(id);
            }}
          >
            <IKTSVG
              className={`flex items-center justify-center   transition duration-1000${
                isCopied !== "" && isCopied === id
                  ? "fill-green-600  hover:fill-green-600"
                  : "fill-violet-500 hover:fill-indigo-500"
              }`}
              path={CloneSVG}
              svgClassName="w-[.7813rem] h-[.6563rem]"
            />
            {/* {isCopied !== "" && isCopied === id ? (
                <span className="text-xs">copied</span>
              ) : (
                <IKTSVG
                  className={`flex items-center justify-center  hover:fill-indigo-500 transition ${
                    isCopied !== "" && isCopied === id
                      ? "fill-green-600"
                      : "fill-violet-500"
                  }`}
                  path={CloneSVG}
                  svgClassName="w-[.7813rem] h-[.6563rem]"
                />
              )} */}
          </button>
        )}
      </div>
    </div>
  );
};

export default CopyToClipboard;
