const Info = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={14}
      height={14}
      viewBox="0 0 14 14"
      {...props}
    >
      <g
        id="Group_3155"
        data-name="Group 3155"
        transform="translate(-1155.5 -302)"
      >
        <g id="Group_3154" data-name="Group 3154">
          <rect
            id="Rectangle_42"
            data-name="Rectangle 42"
            width={14}
            height={14}
            rx={7}
            transform="translate(1155.5 302)"
          />
        </g>
        <text
          id="i"
          transform="translate(1162.5 312.5)"
          fill="#fff"
          fontSize={10}
          fontFamily="Inter-SemiBold, Inter"
          fontWeight={600}
        >
          <tspan x="-1.303" y={0}>
            i
          </tspan>
        </text>
      </g>
    </svg>
  );
};

export { Info };
