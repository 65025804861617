import clearSVG from "../../../../../assets/media/icons/other_icons/Clear.svg";
import LockIconOpen from "../../../../../assets/media/icons/other_icons/lock-open-solid.svg";
import LockIcon from "../../../../../assets/media/icons/other_icons/lock-solid.svg";

import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";

const HeaderView = ({
  title,
  id,
  isLock,
  handleLock,
  isLockIconRequired = true,
  isCloseBtnRequired = false,
  handleClose,
  children,
}: any) => {
  return (
    <div className="title_block w-full flex  justify-between min-h-[2.5rem]">
      <div
        className={`title_block w-full border-b  flex  justify-between min-h-[2.5rem]`}
      >
        <h5 className="text-sm13 px-5 py-[.4688rem] flex items-center justify-between flex-1 font-semibold">
          <span>{title}</span>
          {id && <span>Recipient ID - {id}</span>}
        </h5>

        {children}

        {isLockIconRequired && (
          <button
            type="button"
            className="w-10 flex items-center justify-center group border-l"
            onClick={() => handleLock()}
          >
            <IKTSVG
              path={isLock ? LockIcon : LockIconOpen}
              svgClassName={`group-hover:fill-indigo-500 h-[.9375rem] ${
                isLock
                  ? "w-3 fill-violet-500"
                  : "w-[.8438rem] ml-[.1875rem] fill-indigo-500"
              }`}
            />
          </button>
        )}
        {isCloseBtnRequired && (
          <button
            className="flex items-center justify-center border-l w-11 group"
            type="button"
            data-tooltip-id="change-Request-close-account"
            onClick={(data: any) => handleClose()}
          >
            <TooltipPortal
              id={`change-Request-close-account`}
              content={"Close"}
              className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
            />
            <IKTSVG
              svgClassName="h-4 w-4"
              className="flex items-center justify-center fill-violet-800 group-hover:fill-indigo-500 transition"
              path={clearSVG}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default HeaderView;
