import _ from "lodash";
import moment from "moment";
import axios from "../../../axois/SetupAxios";
import {
  getIDFromArray,
  priceCalculationWithNumber,
} from "../../../helpers/Functions";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "../../../helpers/const";
import { visiblityCountForPayload } from "../../add_inventory/core/_functions";
import { setEventRecordPayload } from "./_functions";
const algoliasearch = require("algoliasearch");

// EVENT LISTING
const getInventoryEventList = (data: any): Promise<any> => {
  let start_date;
  let end_date;
  if (
    data?.dateRange &&
    data?.dateRange?.target &&
    data?.dateRange?.target?.value
  ) {
    start_date = moment(data?.dateRange?.target?.value?.startDate).format(
      "YYYY-MM-DD"
    );
    end_date = moment(data?.dateRange?.target?.value?.endDate).format(
      "YYYY-MM-DD"
    );
  }
  const validQualityNames = [
    "Price low",
    "Price good",
    "Price high",
    "Price okay",
  ];

  let publishStatusIds =
    Array.isArray(data.publishStatus) && data.publishStatus.length > 0
      ? Array.isArray(data.publishStatus) &&
        data.publishStatus.length > 0 &&
        _.map(data.publishStatus, "id")
      : undefined;
  let event_id = data?.inventoryFilterCopy?.event_id?.id
    ? [data?.inventoryFilterCopy?.event_id?.id]
    : _.map(data?.inventoryFilterCopy?.event_id, "id");
  let users: any = Array.isArray(data.users) && _.map(data.users, "id");
  let team_id: any =
    _.size(data?.teamsSelected) > 0 && _.map(data?.teamsSelected, "id");

  let in_hand = getIDFromArray(
    data?.newInventoryFilter?.ticketstatus,
    "Tickets in hand"
  );

  // let is_ticket_uploaded = getIDFromArray(
  //   data?.newInventoryFilter?.ticketstatus,
  //   "Tickets uploaded"
  // );

  let ppe_enabled = getIDFromArray(
    data?.newInventoryFilter?.ticketstatus,
    "Price per partner"
  );

  let is_ticket_uploaded =
    data?.newInventoryFilter?.ticketstatus &&
    data?.newInventoryFilter?.ticketstatus?.find(
      (obj: any) => obj?.name === "Tickets uploaded"
    )?.id;
  let category_ids: any =
    _.size(data?.newInventoryFilter?.category_ids) > 0 &&
    _.map(data?.newInventoryFilter?.category_ids, "id");

  let listing_ids: any = data?.inventoryFilterCopy?.listingIds;
  let hospitality = data?.newInventoryFilter?.hospitality;

  const { newInventoryFilter } = data;
  let params = {
    ...data.pagination,
    status: publishStatusIds,
    ...(_.size(users) > 0 ? { user_id: users } : { user_id: "all" }),
    start_date,
    end_date,
    ...((data.debouncedSearch ||
      data?.inventoryFilterCopy?.debouncedSearch) && {
      query: data.debouncedSearch || data?.inventoryFilterCopy?.debouncedSearch,
    }),
    ...(event_id && event_id.length > 0 && { event_id }),
    ...(data?.team_member && { team_member: data?.team_member }),
    ...(_.size(team_id) > 0 && { team_id }),
    ...(_.size(category_ids) > 0 && { category_ids }),
    // ...(data?.newInventoryFilter?.listing_quality && {
    //   listing_quality: [data?.newInventoryFilter?.listing_quality?.id],
    // }),
    ...(newInventoryFilter &&
      newInventoryFilter.listing_quality && {
        price_quality: newInventoryFilter.listing_quality
          .filter((obj: any) => validQualityNames.includes(obj.name))
          .map((obj: any) => obj?.id),
      }),
    ...(newInventoryFilter &&
      newInventoryFilter.listing_quality && {
        listing_quality: newInventoryFilter.listing_quality
          .filter((obj: any) => !validQualityNames.includes(obj?.name))
          .map((obj: any) => obj.id),
      }),

    ...(_.size(listing_ids) > 0 && { listing_ids: listing_ids }),
    ...(in_hand && {
      in_hand: "yes",
    }),
    ...(ppe_enabled && {
      ppe_enabled: 1,
    }),
    ...(is_ticket_uploaded && {
      is_ticket_uploaded: is_ticket_uploaded,
    }),

    ...(data?.newInventoryFilter?.ticket_type?.length > 0 && {
      ticket_type: _.map(data?.newInventoryFilter?.ticket_type, "id"),
    }),

    ...(hospitality && { hospitality }),
  };
  return axios.post(`inventory/get`, params).then((response) => response.data);
  // return axios
  //   .get(`inventory/get`, {
  //     params: params,
  //   })
  //   .then((response) => response.data);
};

// GET SINGLE EVENT RECORD STATUS
const getSingleEventStatus = (data: any): Promise<any> => {
  let params = {
    event_id: [data],
  };
  return axios
    .get(`inventory/get`, {
      params: params,
    })
    .then((response) => response.data);
};

// COUNT LISTING
const getCountListing = (data: any): Promise<any> => {
  let start_date;
  let end_date;
  if (
    data?.dateRange &&
    data?.dateRange?.target &&
    data?.dateRange?.target?.value
  ) {
    start_date = moment(data?.dateRange?.target?.value?.startDate).format(
      "YYYY-MM-DD"
    );
    end_date = moment(data?.dateRange?.target?.value?.endDate).format(
      "YYYY-MM-DD"
    );
  }
  let publishStatusIds =
    Array.isArray(data.publishStatus) && data.publishStatus.length > 0
      ? Array.isArray(data.publishStatus) &&
        data.publishStatus.length > 0 &&
        _.map(data.publishStatus, "id")
      : undefined;
  let event_id = data?.inventoryFilterCopy?.event_id?.id
    ? [data?.inventoryFilterCopy?.event_id?.id]
    : _.map(data?.inventoryFilterCopy?.event_id, "id");
  let users: any = Array.isArray(data.users) && _.map(data.users, "id");
  let team_id: any =
    _.size(data?.teamsSelected) > 0 && _.map(data?.teamsSelected, "id");

  const validQualityNames = [
    "Price low",
    "Price good",
    "Price high",
    "Price okay",
  ];

  let in_hand =
    data?.newInventoryFilter?.ticketstatus &&
    data?.newInventoryFilter?.ticketstatus?.find(
      (obj: any) => obj?.name === "Tickets in hand"
    )?.id;

  let is_ticket_uploaded =
    data?.newInventoryFilter?.ticketstatus &&
    data?.newInventoryFilter?.ticketstatus?.find(
      (obj: any) => obj?.name === "Tickets uploaded"
    )?.id;

  let listing_ids: any = data?.inventoryFilterCopy?.listingIds;
  let ppe_enabled = getIDFromArray(
    data?.newInventoryFilter?.ticketstatus,
    "Price per partner"
  );

  const { newInventoryFilter } = data;

  let params = {
    ...data.pagination,
    // user_id: _.size(users) > 0 ? users : 'all',
    ...(!data?.team_member && _.size(users) > 0
      ? { user_id: users }
      : !data?.team_member && { user_id: "all" }),
    start_date,
    end_date,
    query: data.debouncedSearch || data?.inventoryFilterCopy?.debouncedSearch,
    status: publishStatusIds,
    ...(event_id && event_id.length > 0 && { event_id }),
    ...(data?.team_member && { team_member: data?.team_member }),
    ...(_.size(team_id) > 0 && { team_id }),
    ...(newInventoryFilter &&
      newInventoryFilter.listing_quality && {
        price_quality: newInventoryFilter.listing_quality
          .filter((obj: any) => validQualityNames.includes(obj.name))
          .map((obj: any) => obj?.id),
      }),
    ...(newInventoryFilter &&
      newInventoryFilter.listing_quality && {
        listing_quality: newInventoryFilter.listing_quality
          .filter((obj: any) => !validQualityNames.includes(obj?.name))
          .map((obj: any) => obj.id),
      }),
    ...(in_hand && {
      in_hand: "yes",
    }),
    ...(is_ticket_uploaded && {
      is_ticket_uploaded: is_ticket_uploaded,
    }),
    ...(data?.newInventoryFilter?.ticket_type?.length > 0 && {
      ticket_type: _.map(data?.newInventoryFilter?.ticket_type, "id"),
    }),
    ...(data?.newInventoryFilter?.category_ids?.length > 0 && {
      category_ids: _.map(data?.newInventoryFilter?.category_ids, "id"),
    }),
    ...(_.size(listing_ids) > 0 && { listing_ids: listing_ids }),
    ...(ppe_enabled && {
      ppe_enabled: 1,
    }),
    ...(newInventoryFilter?.hospitality && {
      hospitality: newInventoryFilter?.hospitality,
    }),
  };
  // return axios
  //   .get(`inventory/counts`, {
  //     params: params,
  //   })
  //   .then((response) => response.data);

  return axios
    .post(`inventory/counts`, params)
    .then((response) => response.data);
};

// EVENT RECORD LISTING
const eventReocordPerPage = 20;
const getInventoryRecordListing = async (data: any): Promise<any> => {
  const { userId, publishStatusId, id, page, teamsSelected, per_page } = data;
  const pagination = {
    per_page: per_page ? per_page : eventReocordPerPage,
    // per_page: 3,
    page: page ? page : 1,
  };
  const validQualityNames = [
    "Price low",
    "Price good",
    "Price high",
    "Price okay",
  ];
  let in_hand =
    data?.newInventoryFilter?.ticketstatus &&
    data?.newInventoryFilter?.ticketstatus?.find(
      (obj: any) => obj?.name === "Tickets in hand"
    )?.id;

  let is_ticket_uploaded =
    data?.newInventoryFilter?.ticketstatus &&
    data?.newInventoryFilter?.ticketstatus?.find(
      (obj: any) => obj?.name === "Tickets uploaded"
    )?.id;

  let listing_ids: any = data?.listingIds;

  let ppe_enabled = getIDFromArray(
    data?.newInventoryFilter?.ticketstatus,
    "Price per partner"
  );

  const { newInventoryFilter } = data;

  const params = {
    ...pagination,
    ...(userId && { user_id: userId }),
    ...(publishStatusId && { status: publishStatusId }),
    order_by: data?.orderState?.sort,
    sort_order: data?.orderState?.order,
    listing_id: data?.listing_id,
    ...(Number(data?.query) && { query: data?.query }),
    ...(_.size(teamsSelected) > 0 && { team_id: _.map(teamsSelected, "id") }),
    // ...(data?.newInventoryFilter?.listing_quality && {
    //   listing_quality: [data?.newInventoryFilter?.listing_quality?.id],
    // }),
    ...(in_hand && {
      in_hand: "yes",
    }),
    ...(is_ticket_uploaded && {
      is_ticket_uploaded: is_ticket_uploaded,
    }),
    ...(data?.newInventoryFilter?.ticket_type?.length > 0 && {
      ticket_type: _.map(data?.newInventoryFilter?.ticket_type, "id"),
    }),
    ...(newInventoryFilter &&
      newInventoryFilter.listing_quality && {
        price_quality: newInventoryFilter.listing_quality
          .filter((obj: any) => validQualityNames.includes(obj.name))
          .map((obj: any) => obj.id),
      }),
    ...(newInventoryFilter &&
      newInventoryFilter.listing_quality && {
        listing_quality: newInventoryFilter.listing_quality
          .filter((obj: any) => !validQualityNames.includes(obj.name))
          .map((obj: any) => obj.id),
      }),
    ...(_.size(listing_ids) > 0 && { listing_ids: listing_ids }),
    ...(ppe_enabled && {
      ppe_enabled: 1,
    }),
    ...(newInventoryFilter?.hospitality && {
      hospitality: newInventoryFilter?.hospitality,
    }),
  };
  // const response = await axios.get(`inventory/event/${id}/lists`, { params });
  const response = await axios.post(`inventory/event/${id}/lists`, params);
  return response.data;
};

// UPDATE INVENTORY
const UpdateInventory = (data: any) => {
  return axios.post(`inventory/update`, data).then((response) => response.data);
};

// ALGOLIA for INVENTORY
const algoliaInventory = () => {
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );
  const index = client.initIndex("events");
  return index.search().then(({ hits }: any) => {
    return hits;
  });
};

//BULK PUBLISH UNPUBLISH AND DELETE
const handleBulk = (data: any) => {
  return axios
    .post(`inventory/bulk-update`, data)
    .then((response) => response.data);
};

const handleListingTransfer = (data: any) => {
  return axios
    .post(`inventory/transfer`, data)
    .then((response) => response.data);
};

//MARKET PLACE INSIGHTS
const getMarketPlaceInsights = (data: any) => {
  const params = {
    event_name: encodeURIComponent(data?.name),
    event_date: moment(new Date(data?.date).toString()).format("DD-MM-YYYY"),
  };
  return axios
    .get("inventory/market-insights", { params })
    .then((response) => response.data);
};

// setEventRecordPayload
// BULK UPDATE ACTION
const bulkUpdateAction = (data: any) => {
  let payload = {
    listings: data?.map((item: any) => {
      let listing_quality = visiblityCountForPayload(item?.visibility);
      const price_quality = priceCalculationWithNumber(
        typeof item?.sell_price === "object"
          ? item?.sell_price?.value
          : item?.sell_price,
        item?.avg_price,
        item?.priceFactor
      );
      let data = setEventRecordPayload(
        { id: item?.event_id, name: item?.name },
        {
          ...item,
          id: item?.listing_id,
          listing_quality,
          price_quality,
        }
      );
      return data;
    }),
  };
  return axios
    .post(`inventory/bulk-update-action`, payload)
    .then((response) => response.data);
};

//MARKET PLACE INSIGHTS
const getInventoryOption = () => {
  return axios.get("inventory/options").then((response) => response.data);
};

const getInventoryLogListing = (params: any) => () => {
  const payload: any = { listing_id: params?.id };

  return axios
    .get("inventory/inventory-logs", { params: payload })
    .then((response) => response.data);
};

export {
  algoliaInventory,
  bulkUpdateAction,
  getCountListing,
  getInventoryEventList,
  getInventoryLogListing,
  getInventoryOption,
  getInventoryRecordListing,
  getMarketPlaceInsights,
  getSingleEventStatus,
  handleBulk,
  handleListingTransfer,
  UpdateInventory,
};
