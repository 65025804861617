import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import SVG from "react-inlinesvg";
import { Link, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { REACT_APP_URL } from "../../../helpers/const";
import { handleLogout } from "../../../helpers/Functions";
import { LayoutContext } from "../../core/LayoutProvider";
import AsideItemList from "./AsideItemList";

type Props = {
  state: any;
  title: string;
  svgName: string;
  path: any;
  width: string;
  height: string;
  showSubmenu?: any;
};

const AsideMenuItem: FC<Props> = ({
  state,
  title,
  svgName,
  path,
  width,
  height,
  showSubmenu,
}: any) => {
  const layout = useContext(LayoutContext);
  const { pathname } = useLocation();
  const FAQPaths = [
    "/faq",
    "/faq/",
    "/faq/account-settings",
    "/faq/listing",
    "/faq/sales",
    "/faq/payments",
    "/faq/technical-support",
    "/faq/tx-trade",
    "/faq/miscellaneous",
    "/faq/tx-trade-policy",
  ];

  const [subMenuActive, setsubMenuActive] = useState(false);
  const trimmedTitle = title.toLowerCase().replace(/\s+/g, "_");
  useEffect(() => {
    const Parents = document.querySelectorAll(".parent");

    const MenuHover = () => {
      Parents.forEach((Parent: any) => {
        const submenuWrapper = Parent.querySelector(".wrapper") as HTMLElement;
        const menu_wrap: any = document.querySelector(".menu-wrap ");
        const menu_wrapWidth = menu_wrap.offsetWidth;

        if (submenuWrapper) {
          const submenuWrapperStyles: any =
            window.getComputedStyle(submenuWrapper);

          const submenuWrapperPaddingLeft = parseFloat(
            submenuWrapperStyles.paddingLeft
          );

          const topPosition = Parent.getBoundingClientRect().top;
          const leftDiv = menu_wrapWidth - submenuWrapperPaddingLeft;
          submenuWrapper.style.top = `${topPosition}px`;
          submenuWrapper.style.left = `${leftDiv}px`;
        }
      });
    };

    Parents.forEach((Parent) => {
      Parent.addEventListener("mouseover", MenuHover);
      window.addEventListener("resize", MenuHover);
    });

    // Cleanup function to remove event listeners
    return () => {
      Parents.forEach((Parent) => {
        Parent.removeEventListener("mouseover", MenuHover);
        window.removeEventListener("resize", MenuHover);
      });
    };
  }, [showSubmenu]); // Empty dependency array ensures this runs only once

  if (title === "Logout") {
    return (
      <React.Fragment key={title}>
        <AsideItemList
          props={{
            state,
            title,
            svgName,
            path,
            width,
            height,
            pathname,
          }}
          handleOnClick={() => {
            handleLogout();
          }}
        />
      </React.Fragment>
    );
  } else {
    return (
      <Fragment key={title}>
        {title === "Notifications" ? (
          <Link key={title} to={path}>
            <li
              className={`${
                !state ? "flex " : "flex"
              }  123 rounded p-2 mb-2 min-h-[2.5rem] cursor-pointer hover:bg-light-white text-gray-300 transition text-sm13 items-center gap-x-3 hover:bg-indigo-500/50 ${
                pathname === "/notifications" &&
                "bg-indigo-500 hover:!bg-indigo-500 active"
              }`}
              id={"Notifications"}
              onClick={() => {
                if (layout.notificationId === 1) {
                  layout.setNotificationId(undefined);
                } else {
                  layout.setNotificationId(1);
                }
              }}
            >
              <div className="flex justify-center min-w-[1.5rem]">
                <SVG src={svgName} className={`${width} ${height}`} />
              </div>
              <span
                className={`${
                  !state && "hidden"
                } origin-left duration-400 text-white font-medium leading-4 text-truncate`}
              >
                {title}
              </span>
              {!state && (
                <Tooltip
                  anchorId={"Notifications"}
                  content={"Notifications"}
                  place="right"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
                  positionStrategy="fixed"
                />
              )}
            </li>
          </Link>
        ) : title === "FAQ Centre" ? (
          <a key={title} href="#" title={title} className="parent">
            <AsideItemList
              props={{
                state,
                title,
                svgName,
                path,
                width,
                height,
                pathname,
                showSubmenu,
                subMenuActive,
                setsubMenuActive,
              }}
              handleOnClick={() => {
                let logo: any = document.querySelector(".logo");
                logo && logo.click();
                setsubMenuActive((submenu) => !submenu);
                layout.setNotificationId(0);
              }}
            />
          </a>
        ) : // <a key={title} href={REACT_APP_URL + path}>
        //   <li
        //     className={`${
        //       !state ? "flex " : "flex"
        //     }  123 rounded p-2 mb-2 min-h-[2.5rem] cursor-pointer hover:bg-light-white text-gray-300 transition text-sm13 items-center gap-x-3 hover:bg-indigo-500/50 ${
        //       FAQPaths?.includes(pathname) &&
        //       "bg-indigo-500 hover:!bg-indigo-500 active"
        //     }`}
        //     onClick={() => {
        //       let logo: any = document.querySelector(".logo");
        //       logo && logo.click();
        //       layout.setNotificationId(0);
        //     }}
        //     id={"faq"}
        //   >
        //     <div className="flex justify-center min-w-[1.5rem]">
        //       <SVG src={svgName} className={`${width} ${height}`} />
        //     </div>
        //     <span
        //       className={`${
        //         !state && "hidden"
        //       } origin-left duration-400 text-white font-medium leading-4 text-truncate`}
        //     >
        //       {title}
        //     </span>
        //     {!state && (
        //       <Tooltip
        //         anchorId={"faq"}
        //         content={"FAQ Centre"}
        //         place="right"
        //         variant="light"
        //         className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-1000 -ml-1.5 no-angle z-[1] max-lg:hidden"
        //         positionStrategy="fixed"
        //       />
        //     )}
        //   </li>
        // </a>
        title === "Dashboard" ? (
          <Link key={title} to={path} title={title} reloadDocument>
            <AsideItemList
              props={{
                state,
                title,
                svgName,
                path,
                width,
                height,
                pathname,
              }}
              handleOnClick={(e: any) => {
                let logo: any = document.querySelector(".logo");
                logo && logo.click();
                layout.setNotificationId(0);
              }}
            />
          </Link>
        ) : (
          <a
            key={title}
            href={
              Array.isArray(path)
                ? REACT_APP_URL + path[0]
                : REACT_APP_URL + path || "#"
            }
            title={title}
            className="parent"
            data-menu-id={trimmedTitle}
          >
            <AsideItemList
              props={{
                state,
                title,
                svgName,
                path,
                width,
                height,
                pathname,
                showSubmenu,
                subMenuActive,
                setsubMenuActive,
              }}
              handleOnClick={() => {
                // let logo: any = document.querySelector(".logo");
                // logo && logo.click();
                setsubMenuActive((submenu) => !submenu);
                layout.setNotificationId(0);
              }}
            />
          </a>
        )}
      </Fragment>
    );
  }
};

export default AsideMenuItem;
