import Shimmer from "components/ui/Shimmer";
import { useScrollLeftEffect } from "hooks/useScrollLeftEffect";
import { handleScrollTable } from "pages/customize/core/functions";
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Tooltip } from "react-tooltip";
import { TableVirtuoso } from "react-virtuoso";
import eyeSVG from "../../assets/media/icons/other_icons/Eye.svg";
import NoDataComponent from "../tableComponent/NoDataComponent";
import { IKTSVG } from "../ui/IKTSVG";
import {
  DataCell,
  HeaderList,
  StyledTable,
  TableHead,
  TableRow,
} from "./DataGrid.styled";

export const DataGrid = (props: any) => {
  const {
    data = [],
    paginateData = {},
    handleView,
    onEndReach = () => {},
    shimmerLoading = false,
    columns = [],
    isViewAction = false,
    filters = { page: 0 },
    orderState = {},
    setOrderState = () => {},
    useWindowScroll = true,
  } = props;

  const tableRef: any = useRef();
  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();

  const { setIsSettled }: any = useScrollLeftEffect({
    elementId: "tableId1",
    headerId: "sticky-head",
  });

  const scroll = (scrollOffset: any) => {
    setIsSettled(true);
    tableRef.current.scrollLeft += scrollOffset;
    stickyTableRef.current.scrollLeft += scrollOffset;
  };

  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  // CALCULATE THE HEIGHT OF TABLE VIRTOUSO
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [hoveredId, setHoveredId] = useState(null);

  let timeout: any;

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#Report-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;
      if (divElement?.offsetHeight > 1) {
        let elemHeight = divElement?.offsetHeight + theadHeight;

        if (nomoreListingHeight) {
          elemHeight =
            divElement?.offsetHeight + theadHeight + nomoreListingHeight + 1;
        }
        setHeight(`${elemHeight}`);
      }
    }, 1000);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    // Clear the timeout in case the component unmounts or the effect re-runs
    return () => {
      clearTimeout(timeout);
    };
  });
  useEffect(() => {
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative md:px-5 max-lg:!mr-0 lg2:min-h-[8.75rem] last-auto"
      id={`EventAccordion`}
    >
      {/* --------------- sticky header start ---------------  */}
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  absolute left-0 top-0">
          <thead className="text-xs text-gray-400 ">
            <tr className="border-b bg-white">
              <HeaderList
                columns={columns}
                scroll={scroll}
                orderState={orderState}
                setOrderState={setOrderState}
              />
            </tr>
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden h-[0.5rem]"></span>
      {/* ---------------- sticky header ends -----------------  */}

      <div className="accordion-item bg-white  !rounded overflow-hidden">
        {/* <EventFields /> */}
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              {shimmerLoading ? (
                <Shimmer
                  loopCount={10}
                  headerClassName={
                    "accordion font-medium  px-2.5 mb-2.5 mt-2.5"
                  }
                  titleClassName={
                    "accordion-item border bg-white !rounded overflow-hidden shimmer-effect"
                  }
                  shimmerHeight={"60px"}
                />
              ) : (
                <Fragment>
                  {data?.length > 0 ? (
                    <TableVirtuoso
                      data={data}
                      endReached={onEndReach}
                      useWindowScroll={useWindowScroll}
                      overscan={200}
                      totalCount={data?.length}
                      atBottomThreshold={100}
                      id={"Report-lazyload"}
                      components={{
                        Table: StyledTable,
                        TableHead: TableHead,
                        TableRow: (props) => {
                          return (
                            <TableRow
                              {...props}
                              hoveredId={hoveredId}
                              setHoveredId={setHoveredId}
                            />
                          );
                        },
                        TableFoot: () => {
                          if (
                            data?.length === paginateData?.total &&
                            paginateData?.current_page > 1
                          ) {
                            return (
                              <tfoot
                                className={`text-center text-sm13 py-3 px-2.5 bg-white border-t`}
                                id={`NoMoreListing`}
                              >
                                <tr className="border-b bg-white">
                                  <td
                                    colSpan={11}
                                    align="center"
                                    className="h-[2.8125rem]"
                                  >
                                    No more items to show
                                  </td>
                                </tr>
                              </tfoot>
                            );
                          } else if (
                            filters?.page > 1 &&
                            paginateData === undefined
                          ) {
                            return (
                              <tfoot
                                id="custom-loader-replacement"
                                className={`accordion bg-white font-medium pt-2.5  border relative z-[1] w-full `}
                              >
                                <tr className="mb-2.5">
                                  <td colSpan={11}>
                                    <div
                                      id="custom-loader-replacement"
                                      className={`accordion bg-white font-medium pt-2.5  border relative z-[1] `}
                                    >
                                      <div
                                        className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
                                      ></div>
                                      <div
                                        className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect h-[1.875rem]`}
                                      ></div>
                                    </div>
                                  </td>
                                </tr>
                              </tfoot>
                            );
                          } else {
                            return null;
                          }
                        },
                      }}
                      fixedFooterContent={() => {
                        return (
                          <tr className="border-b bg-white">
                            <td colSpan={0}>Hi</td>
                          </tr>
                        );
                      }}
                      fixedHeaderContent={() => (
                        <tr className="border-b bg-white">
                          <HeaderList
                            columns={columns}
                            scroll={scroll}
                            orderState={orderState}
                            setOrderState={setOrderState}
                          />
                        </tr>
                      )}
                      itemContent={(sIndex: any, list: any) => {
                        return (
                          <Fragment key={sIndex}>
                            {columns.map((column: any, index: number) => (
                              <DataCell
                                key={index}
                                value={column?.getValue(list, sIndex)}
                                classname="block"
                                padding={column?.padding}
                                width={column?.width}
                                isTootTip={column?.isTootTip}
                                isSticky={column?.isSticky}
                              />
                            ))}

                            {isViewAction && (
                              <td className="group sticky right-0 ml-auto p-0 icon_td  border-b">
                                <div>
                                  <div
                                    className="flex left-shad relative w-full h-full border-l"
                                    id="left-shad-list"
                                  >
                                    <button
                                      className={`group flex items-center justify-center p-1 md:p-1 w-full`}
                                      type="button"
                                      id={`record-view-tooltip${sIndex}`}
                                      onClick={() => {
                                        handleView(list, sIndex);
                                      }}
                                    >
                                      <Tooltip
                                        anchorId={`record-view-tooltip${sIndex}`}
                                        content="View"
                                        place="top"
                                        variant="light"
                                        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                                      />
                                      <IKTSVG
                                        className="fill-violet-500 group-hover:fill-indigo-500 transition"
                                        path={eyeSVG}
                                        svgClassName="w-4 h-[.6875rem]"
                                      />
                                    </button>
                                  </div>
                                </div>
                              </td>
                            )}
                          </Fragment>
                        );
                      }}
                      ref={virtuosoRef}
                    />
                  ) : (
                    <NoDataComponent />
                  )}
                </Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
