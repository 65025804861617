import { createContext, PropsWithChildren } from "react";

export interface LayoutContextModel {

  

}

const FAQContext = createContext<LayoutContextModel>({

  

});

const FaqProvider = ({ children }: PropsWithChildren) => {



  const value: LayoutContextModel = {

   

  };

  return (
    <FAQContext.Provider value={value}>{children}</FAQContext.Provider>
  );
};

export { FAQContext, FaqProvider };
