import { useContext } from "react";
import CheckboxFilter from "../../tx_trade/component/filters/CheckboxFilter";
import { InstructionLetterContext } from "../core/InstructionLetterProvider";

const CountBoxWrapper = ({ loading }: any) => {
  const layout = useContext(InstructionLetterContext);
  const counts = layout?.meta?.total;

  return (
    <div
      className="bg-white border-t accordion-collapse font-medium collapse show"
      id="inventoryaccordion"
      data-te-collapse-item
      data-te-collapse-show
      aria-labelledby="headingOne"
    >
      <div
        className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block`}
        role="tabpanel"
        data-te-tab-active
      >
        <div className="px-5 pt-2">
          <div className="divide-gray-200 divide-y-2">
            <div className="flex flex-wrap lg2:flex-nowrap pt-3 -mx-2.5 overflow-x-auto whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
              <CheckboxFilter
                title="Total"
                trackedLoading={loading}
                count={counts}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountBoxWrapper;
