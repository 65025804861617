export const AddIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <g id="Group_3838" data-name="Group 3838" transform="translate(-358 -109)">
      <g
        id="Group_2110"
        data-name="Group 2110"
        transform="translate(361.333 111.334)"
      >
        <rect
          id="Rectangle_15"
          data-name="Rectangle 15"
          width="6"
          height="1.333"
          transform="translate(-0.333 3)"
          fill="#fff"
        />
        <rect
          id="Rectangle_16"
          data-name="Rectangle 16"
          width="6"
          height="1.333"
          transform="translate(3.333 0.667) rotate(90)"
          fill="#fff"
        />
      </g>
      <g
        id="Rectangle_28930"
        data-name="Rectangle 28930"
        transform="translate(358 109)"
        fill="none"
        stroke="#fff"
        strokeWidth="1.5"
      >
        <rect width="12" height="12" rx="2" stroke="none" />
        <rect
          x="0.75"
          y="0.75"
          width="10.5"
          height="10.5"
          rx="1.25"
          fill="none"
        />
      </g>
    </g>
  </svg>
);
