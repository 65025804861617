import ProgressBardLoader from "components/ui/ProgressBardLoader";
import { useEffect, useMemo, useRef, useState } from "react";
import CommonTableWrapper from "./component/CommonTableWrapper";
import { DesignHomePage } from "./component/DesignHomePage/DesignHomePage";
import { DesignMenu } from "./component/DesignMenu/DesignMenu";
import Footer from "./component/Footer";
import FilterPage from "./component/UI/FilterPage";
import HeaderComponent from "./component/UI/HeaderComponent";
import Tabs from "./component/UI/Tabs";
import { CUSTOMIZE_TAB_KEYS } from "./core/constants/constants";
import { useCustomize } from "./hooks/useCustomize";

const tabWiseComponent: any = {
  [CUSTOMIZE_TAB_KEYS.DESIGN_MENU]: DesignMenu,
  [CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE]: DesignHomePage,
  [CUSTOMIZE_TAB_KEYS.CONTENT_PAGE]: null,
};

export const Customize = () => {
  const { isTabActive, isLoading } = useCustomize();
  const heightRef1: any = useRef();
  const toggleRef: any = useRef();
  const [IsToggle, setIsToggle] = useState<any>(true);
  const [overViewHeight] = useState<any>();
  const ActiveTabComponent = useMemo(
    () => tabWiseComponent[isTabActive],
    [isTabActive]
  );

  useEffect(() => {
    const stickyElement = document.getElementById("topFilters") as HTMLElement;
    const stickyElementStyle = window.getComputedStyle(stickyElement);
    const stickyElementheight: any =
      stickyElementStyle.getPropertyValue("height");

    const filter = document.getElementById("filters_block") as HTMLElement;
    if (stickyElement) {
      // const heightheightRef2 = heightRef2?.current?.offsetHeight;

      const initialPosition = stickyElement.getBoundingClientRect().top;
      const accordion = document.getElementById("inventoryaccordion");

      const accorAttr = accordion?.getAttribute("data-te-collapse-show");

      let MarginTopTotle = 0;
      let topPosition = 0;

      // const MarginTopTotle =
      //     IsToggle === true
      //       ? heightheightRef1 + overViewHeight - HeaderHeight
      //       : heightheightRef1 - HeaderHeight;

      const recalculateMarginTopTotle = () => {
        const accordion = document.getElementById(
          "inventoryaccordion"
        ) as HTMLElement;
        const accordionStyle = window.getComputedStyle(accordion);
        const accordionheight: any = parseFloat(
          accordionStyle.getPropertyValue("height")
        );
        // Recalculate MarginTopTotle based on updated heights or values
        const heightheightRef1 = heightRef1?.current?.offsetHeight;
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const filter = document.getElementById("filters_block") as HTMLElement;
        const heightheightRef2 = filter?.offsetHeight;
        const salesFilter: any =
          document.getElementById("salesFilter")?.clientHeight;

        var computedStyle = window.getComputedStyle(stickyElement);
        var paddingBottom = computedStyle.paddingBottom;
        var paddingBottomValue = parseInt(paddingBottom, 10);

        if (window.matchMedia("(max-width: 767px)").matches) {
          topPosition = HeaderHeight;
          console.log(topPosition, "topPosition");
        } else {
          topPosition =
            heightheightRef2 +
            salesFilter +
            HeaderHeight +
            paddingBottomValue -
            1;
        }
        MarginTopTotle =
          IsToggle === true
            ? heightheightRef1 + accordionheight - HeaderHeight
            : HeaderHeight - heightheightRef1;
      };

      const makeSticky = function () {
        const HeaderHeight: any =
          document.querySelector("header")?.clientHeight;
        const sticky_head: any = document.getElementById("sticky-head");
        const stickyShadow: any = document.getElementById("sticky-shadow");
        const sticky_headHeight: any = sticky_head?.clientHeight;

        if (window.scrollY + HeaderHeight >= initialPosition) {
          stickyElement.classList.add("sticky");
          stickyElement.style.top =
            IsToggle === true ? `-${MarginTopTotle}px` : `${MarginTopTotle}px`;
          sticky_head.style.top = topPosition + "px";
          stickyShadow.style.top = topPosition + sticky_headHeight + "px";
        } else {
          stickyElement.classList.remove("sticky");
          stickyElement.style.top = `0px`;
          sticky_head.style.top = "0px";
          stickyShadow.style.top = "0px";
        }
        recalculateMarginTopTotle();
      };
      const handleWindowResize = () => {
        // Handle logic when the window is resized
        recalculateMarginTopTotle(); // Recalculate MarginTopTotle on window resize
        makeSticky(); // Update sticky behavior based on new values
      };

      // if (window.scrollY > 200) {
      //   makeSticky();
      // }

      window.addEventListener("scroll", makeSticky);
      window.addEventListener("change", makeSticky);
      window.addEventListener("blur", makeSticky);

      setTimeout(() => {
        document.addEventListener("resize", handleWindowResize);
      }, 200);
      return () => {
        window.removeEventListener("scroll", makeSticky);
        window.removeEventListener("change", makeSticky);
        window.removeEventListener("blur", makeSticky);
        document.removeEventListener("resize", handleWindowResize);
      };
    }
    // });
  }, [toggleRef, IsToggle, overViewHeight]);
  return (
    <>
      <div className="main-content-wrap">
        {/* TOP SCREEN */}
        <div
          id="topFilters"
          className={`topFilters sticky-element top-[60px] z-[11] ${
            isTabActive !== CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE
              ? "pb-5"
              : "pb-0"
          } bg-gray-200 max-md:!static `}
        >
          <div className="top-accor shadow-op2">
            <HeaderComponent
              heightRef1={heightRef1}
              setIsToggle={setIsToggle}
              IsToggle={IsToggle}
            />

            {/* HEADER */}
            <div className="bg-white border-t accordion-collapse font-medium collapse show">
              {/* TABS */}
              <Tabs />

              {isTabActive !== CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE ? (
                <FilterPage />
              ) : null}
            </div>
          </div>
        </div>
        {isTabActive === CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE ? (
          <div
            className={`tab_vontent opacity-100 transition-opacity duration-150 ease-linear block bg-white p-5 `}
            role="tabpanel"
            data-te-tab-active
          >
            <ActiveTabComponent />
          </div>
        ) : null}
        <CommonTableWrapper />

        {/* TABLES */}
      </div>
      {(isTabActive === CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE ||
        isTabActive === CUSTOMIZE_TAB_KEYS.CONTENT_PAGE) && <Footer />}

      {isLoading && <ProgressBardLoader secondCounter={1.5} />}
    </>
  );
};
