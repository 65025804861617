export const DataCell = (props: any) => {
  const {
    value,
    subValue,
    classname = "",
    width = "",
    padding,
    noCapitalize,
    children,
    key,
  } = props;

  return (
    <>
      <div
        key={key}
        className={`px-1.5 py-3 font-medium h-[2.8125rem] text-ellipsis overflow-hidden  ${padding} max-[${width}] w-[${width}] min-w-[${width}] `}
      >
        <span className="ellips-text ">
          <p
            className={`w-[${width}] ${classname} ${
              noCapitalize === "yes" ? "" : "capitalize"
            } ${value === "-" && "text-center"} `}
            title={value ? value : ""}
          >
            {children ? children : value}
          </p>
          {subValue && (
            <small className="text-xxs text-gray-500">{subValue}</small>
          )}
        </span>
      </div>
    </>
  );
};
