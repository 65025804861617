import { CrossIcon } from "assets/media/icons/other_icons/CrossIcon";
import MultiSelect from "components/formComponent/MultiSelect";
import { useAlgoliaSearch } from "hooks/useAlgoliaSearch";
import { Fragment, useContext, useMemo, useState } from "react";
import {
  convertDate,
  convertTime,
} from "../../../add_inventory/core/_functions";
import { ConnectorContext } from "../../core/ConnectorProvider";

// ON CLEAR BUTTON - ALGOLIA
const onSearch =
  (setInputChange: any, setAlgoliaOptions: any) => (data: any) => {
    if (data?.target?.id !== "search") {
      setInputChange("");
      setAlgoliaOptions([]);
    }
  };

const handleInputChange =
  (algoliaDebounce: any, setInputChange: any, setAlgoliaOptions: any) =>
  (data: any, e: any) => {
    if (["menu-close", "set-value", "input-blur"].includes(e.action)) {
      if (!data) {
        setInputChange((current: any) => {
          return current;
        });
      }
    } else {
      setInputChange(() => {
        if (algoliaDebounce !== data) {
          setAlgoliaOptions([]);
        }
        return data;
      });
    }
  };

const handleKeyDown = (setInputChange: any) => (e: any) => {
  if (e?.target?.value && e.key === "Enter") {
    e.preventDefault();
    setInputChange(e.target.value);
  }
};

const removeSelectedEvent = (layout: any, eventId: any) => () => {
  layout?.setMappedEvents((event: any) =>
    event.filter((evnt: any) => evnt?.externalEventId !== eventId)
  );
};

const EventDataCell = ({
  classname = "",
  width = "",
  title = "",
  padding,
  noCapitalize,
  hits,
  eventId,
  currency,
}: any) => {
  const layout = useContext(ConnectorContext);

  const [inputChange, setInputChange] = useState("");

  const { isLoading, algoliaDebounce, algoliaOptions, setAlgoliaOptions }: any =
    useAlgoliaSearch({ inputChange });

  const selectedMapEvent = useMemo(
    () =>
      layout?.mappedEvents.find((evnt) => evnt?.externalEventId === eventId),
    [eventId, layout?.mappedEvents]
  );

  const handleHitSelect = (
    tixstockEventId: any,
    externalEventId: any,
    addonData = {}
  ) => {
    layout?.setMappedEvents((prev: any) => {
      // Remove any existing event with the same tixstockEventId
      const filteredEvents = prev.filter(
        (event: any) => event.externalEventId !== externalEventId
      );
      // Add the new event selection
      return [
        ...filteredEvents,
        { tixstockEventId, externalEventId, currency: currency, ...addonData },
      ];
    });
  };

  const isChecked = (tixstockEventId: any, externalEventId: any) => {
    return layout?.mappedEvents.some(
      (event: any) =>
        event.tixstockEventId === tixstockEventId &&
        event.externalEventId === externalEventId
    );
  };

  return (
    <>
      <td className={`p-1.5 font-medium   ${padding}`}>
        {hits?.length > 0 ? (
          hits?.map((hit: any, index: any) => {
            return (
              <div key={index} className="flex gap-3 items-center">
                <input
                  type="radio"
                  name={eventId}
                  id={hit?.id}
                  onChange={() => handleHitSelect(hit?.id, eventId)}
                  value={hit?.id}
                  checked={isChecked(hit?.id, eventId)}
                />
                <div>
                  <p className="text-sm">{hit?.name} </p>
                  <span className="text-xxs">
                    ({convertDate(hit?.event_date_local)}{" "}
                    {convertTime(hit?.event_date_local)}
                    {", "}
                    {hit?.venue_name})
                  </span>
                </div>
              </div>
            );
          })
        ) : (
          <Fragment>
            {selectedMapEvent ? (
              <div className="w-full">
                <div className="inline-flex py-1 px-2.5 text-xs border rounded-full justify-between my-0.5">
                  <span className="w-[calc(100%_-_0.5rem)] block whitespace-nowrap text-ellipsis overflow-hidden">
                    {" "}
                    {selectedMapEvent?.name}
                  </span>
                  <button
                    type="button"
                    className="ml-2 hover:fill-indigo-500"
                    onClick={removeSelectedEvent(layout, eventId)}
                  >
                    <CrossIcon className="h-2 w-2" />
                  </button>
                </div>
              </div>
            ) : (
              <div className="w-full max-w-[18.75rem]">
                <MultiSelect
                  name="event_id"
                  options={algoliaOptions}
                  isLoading={isLoading}
                  handleOnChange={(e: any) => {
                    let value = e.target.value;
                    handleHitSelect(value?.id, eventId, { name: value?.name });
                  }}
                  value={selectedMapEvent}
                  inputValue={inputChange}
                  handleInputChange={handleInputChange(
                    algoliaDebounce,
                    setInputChange,
                    setAlgoliaOptions
                  )}
                  handleKeyDown={handleKeyDown(setInputChange)}
                  onSearch={onSearch(setInputChange, setAlgoliaOptions)}
                  isMulti={false}
                  classNamePrefix="inventory_select"
                  className="searchbar-wrap"
                  placeholder="Search event..."
                  menuPortalTarget={document.body}
                />
              </div>
            )}
          </Fragment>
        )}
      </td>
    </>
  );
};

export default EventDataCell;
