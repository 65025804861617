import PageTitle from "../../components/core/PageTitle";
import Connector from "./Connector";
import { ConnectorProvider } from "./core/ConnectorProvider";

const ConnectorWrapper = () => {
  return (
    <ConnectorProvider>
      <PageTitle title="API Connector" />
      <Connector />
    </ConnectorProvider>
  );
};

export default ConnectorWrapper;
