import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { storeFields } from "../../../components/core/requests";
import { outSideClick } from "../../../helpers/AssetHelpers";
import { existInLocalstorage } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import { AddInventoryContext } from "../core/AddInventoryProvider";
import KeyboardFilterDrag from "../widgets/KeyboardFilterDrag";
import DraggableColumns from "../widgets/DraggableColumns";

const HeaderfilterContainer = () => {
  const layout = useContext(AddInventoryContext);
  const globalLayout = useContext(LayoutContext);
  const [column, setColumn] = useState<any>();

  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveKeyboardItems;

    let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveAddInventoryTableColumn;

  let payload: any = [
    {
      key: `saveKeyboardItems`,
      value: layout.keyboardItems,
    },
    {
      key: `saveAddInventoryTableColumn`,
      value: column,
    },
  ];

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, "saveKeyboardItems")
    );
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, "saveAddInventoryTableColumn")
    );
    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, "saveKeyboardItems")
      );
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, "saveAddInventoryTableColumn")
      );
    };
  }, []);

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );
  useEffect(() => {
    if (layout.keyboardItems && existInLocalstorage("saveKeyboardItems")) {
      layout.setKeyboardItems(existInLocalstorage("saveKeyboardItems"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("saveKeyboardItems");
      }, 3000);
    }

    if (layout.columnItems && existInLocalstorage("saveAddInventoryTableColumn")) {
      layout.setColumnItems(existInLocalstorage("saveAddInventoryTableColumn"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("saveAddInventoryTableColumn");
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      layout.setKeyboardItems(isFilterExistInDB);
      layout.setDraggedItems(isFilterExistInDB);
    }
    if (isColumnFilterExistInDB) {
      setTimeout(() => {
        layout.setColumnItems(isColumnFilterExistInDB);
        layout.setDraggedColumns(isColumnFilterExistInDB);
        const value = isColumnFilterExistInDB?.find(
          (obj: any) => obj?.name === "Proceed price"
        )?.toggle;
        layout.setProceedPriceToggle(value);
      }, 1000);
    }
  }, [isColumnFilterExistInDB, isFilterExistInDB]);

  return (
    <>
      <div className="ba_item relative">
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer hover:text-indigo-500"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#saveKeyboardItems"
          aria-expanded="false"
          aria-controls="saveKeyboardItems"
        >
          Keyboard
        </span>
        <div
          id="saveKeyboardItems"
          className="!visible ba_dd absolute top-full bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden !z-60"
          data-te-collapse-item
          aria-labelledby="saveKeyboardItems"
          data-te-parent="#filters"
        >
          <KeyboardFilterDrag refetch={refetch} />
        </div>
      </div>
      <div className="ba_item relative">
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#saveAddInventoryTableColumn"
          aria-expanded="false"
          aria-controls="saveAddInventoryTableColumn"
        >
          Columns
        </span>
        <div
          id="saveAddInventoryTableColumn"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden cursor-pointer"
          data-te-collapse-item
          aria-labelledby="saveAddInventoryTableColumn"
          data-te-parent="#filters"
        >
          <DraggableColumns refetch={refetch} columnUpdate={setColumn} />
        </div>
      </div>
    </>
  );
};

export default HeaderfilterContainer;
