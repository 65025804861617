export const commonFaq = [
  {
    que: "I have forgot my password, what should I do?",
    ans: "Click 'forgot password' link on website you will be sent a link to the registered email to reset.",
    index: 1,
    slug: "account-settings",
  },
  {
    que: "Forgot your registered Username/email?",
    ans: 'Contact Wati customer support on Whatsapp on <a href="tel:07884577417">07884577417</a> who will be able to reset your login details.',
    index: 2,
    slug: "account-settings",
  },
  {
    que: "Can I use an alias when signing up?",
    ans: "No, you can't use an alias at sign up, this is due to us providing you with a virtual wallet that needs up to date KYC (know your customer) information.",
    index: 3,
    slug: "account-settings",
  },
  {
    que: "Will I get an email if my tickets sell?",
    ans: "Yes, you will recieve an email from Tixstock each time a sale is made with one of our partners.",
    index: 4,
    slug: "listing",
  },
  {
    que: "Why is my sale showing as sale on hold?",
    ans: "Some of our partners need to do some addiitonal payment checks before accepting an order. This will update to awaiting delivery once these checks are complete.",
    index: 5,
    slug: "listing",
  },
  {
    que: "What do I need to look out for when listing on high value events?",
    ans: "When listing on high value events, please be aware that netural venues are split into sections for each team, so make sure you're listing it in the right section. If unsure of what section your ticket will be in, make sure to update your listing once the information has been released. ",
    index: 6,
    slug: "listing",
  },
  {
    que: "Does my listing require proof of purchase?",
    ans: "You may require proof of purchase for certain events such as cup finals.",
    index: 7,
    slug: "listing",
  },
  {
    que: "Why is the in hand date so important?",
    ans: "The in hand date is important as this will be the date when the customers expects to recieve the tickets. If you don't think you're able to send the tickets before this deadline then you will need to speak to Seller Support on Whatsapp on 07884577417 to find a resolution.",
    slug: "listing",
    index: 8,
  },
  {
    que: "What is the fine for a broken order?",
    ans: "The fine for the broken order will vary from case to case. The fine will be determined on whether the customer eventually got replacement tickets and how much the replacement ticket cost. Please see our fines table (link below). ",
    slug: "listing",
    index: 9,
  },
  {
    que: "What happens if I provide different tickets to the one the customer ordered?",
    ans: "The tickets you list must be the tickets you provide to the customer. If this is not the case, you must speak with Tixstock seller support on Whatsapp on 07884577417 to make sure the customer is happy with the replacement tickets you are offering. If you provide tickets the customer has not ordered then the customer could potentially complain and get a full refund, plus you may recieve a fine for a broken order. ",
    slug: "listing",
    index: 10,
  },
  {
    que: "Why has my order been cancelled?",
    ans: "Once you have passed the delivery date (In hand date selected at the time of listing), the customer may cancel their order if they have not received the tickets. If there is going to be a delay on fulfilling your order, you must contact Seller Support on Whatsapp on 07884577417 immediately and let them know so they can inform the customer.",
    slug: "listing",
    index: 11,
  },
  {
    que: "How do I create a listing?",
    ans: "In order to create listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Inventory/How+to+add+a+listing.pdf",
    video: "",
    slug: "listing",
    index: 12,
  },
  {
    que: "How do I see that my listings are live?",
    ans: "In the inventory screen, published listings will show green with a tick, unpublished listings will show orange.",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Inventory/How+do+I+see+that+my+listings+are+live.pdf",
    slug: "listing",
    index: 13,
  },
  {
    que: "How do I edit my listing?",
    ans: "In order to edit your listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    slug: "listing",
    index: 14,
  },
  {
    que: "How do I bulk edit multiple listings?",
    ans: "In order to bulk edit your listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Inventory/How+to+bulk+edit+my+listing.pdf",
    slug: "listing",
    index: 15,
  },
  {
    que: "How do I change the price of my listing?",
    ans: "In order to change the price of your listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Inventory/How+to+change+the+price+of+my+listing.pdf",
    slug: "listing",
    index: 16,
  },
  {
    que: "How do I change the ticket type for an unsold listing?",
    ans: "In order to change the ticket type on one of your listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    slug: "listing",
    index: 17,
  },
  {
    que: "How do I change the ticket type for a listing that has sold?",
    ans: "You will need to contact Seller Support on Whatsapp on 07884577417 to reach out to the marketplace to ensure the customer is happy with the updated ticket type.",
    slug: "listing",
    index: 18,
  },
  {
    que: "The venue has changed my ticket information what should i do?",
    ans: "If the venue has changed your seating information and the ticket has sold, you must contact the Seller Support on Whatsapp on 07884577417 immediately to inform them so they can reach out to the martketplace to ensure the customer is happy with the new seat location. If the listing has not sold yet, then you can amend your listing in your 'Inventory' section by selecting the listing, changing the seat information and clicking apply afterwards.",
    slug: "listing",
    index: 19,
  },
  {
    que: "How to delete a listing.",
    ans: "In order to delete your listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Inventory/How+to+delete+listings.pdf",
    slug: "listing",
    index: 20,
  },
  {
    que: "How to delete multiple listings.",
    ans: "In order to delete multiple listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    slug: "listing",
    index: 21,
  },
  {
    que: "How to publish or unpublish a listing.",
    ans: "In order to publish or unpublish your listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    slug: "listing",
    index: 22,
  },
  {
    que: "Why can't I see all the marketplaces when I go to publish my listing ?",
    ans: "Tixstock will publish your listing to all our traditional marketplaces that we are in partnership with these include 1Box office, Ticketgum, 7 Tickets, Ticombo. Football Ticket Pad and Gigsberg. Tixstock is also working closely with the Hospitality and Reward sector to increase the number of sales you receive. These partnerships include Tevo in America who have a vast long standing distribution network and Hello Tickets who have a strong offering in hospitality. Both marketplaces you would not traditionally have access to. As we are always striving to improve and add to these channels we can not keep you up to date with who we have connected. If you have an issue with your inventory being pushed to a specific partner please contact customer support on Whatsapp 07884577417.",
    slug: "listing",
    index: 23,
  },
  {
    que: "How do I change which ticket exchanges my ticket is listed on once it's live?",
    ans: "By signing up to our preferred partners network you are accepting that Tixstock can push your inventory to our full host of partners. This can not be selected on a individual basis. We complete regular reviews with all our partners to ensure they are right for our sellers. Tixstock is your single point of contact for payment and customer support. if you have an issue with a particular partner please raise this with your Account Manager to discuss further steps. ",
    slug: "listing",
    index: 24,
  },
  {
    que: "How do I publish a listing in my inventory?",
    ans: "In order to publish your listings you can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    slug: "listing",
    index: 25,
  },
  {
    que: "How can I improve my listing ratings?",
    ans: "Your listing rating will improve by putting as much information as possible on the listing. The more information you have on the listing will increase the chances of selling it. Please see examples of a perfect listing, Or contact our Seller Support for any assistance on Whatsapp on 07884577417.",
    slug: "listing",
    index: 26,
  },
  {
    que: "Why do you ask for the date that the tickets will be 'In Hand'?",
    ans: "In order for you to list tickets through Tixstock we need to ensure you have the exact tickets in hand or have the date that you will receive them by. This will then be used to provide customers with information of when the ticket will be ready to be shipped. This is also used by the exchanges as a deadline to deliver by. This is defaulted to 48 hours on every event. ",
    slug: "listing",
    index: 27,
  },
  {
    que: "When do I need to deliver my tickets by?",
    ans: "Tickets must be delivered by the 'In Hand Date' set at the point of listing, this is normally defaulted to 48 hours before the event. If this is going to be an issue, then you need to speak to Seller Support on Whatsapp on 07884577417 to inform them of the delay in delivery. Please note unfulfilled orders may be subject to fines.",
    slug: "sales",
    index: 28,
  },
  {
    que: "Selling tickets and delivery expectations.",
    ans: "It is the sellers responsibility to adhere to all the sales that have come through their account. This means making sure orders are fulfilled on time and they are the correct seats that have been ordered. If there is an issue with fulfilling your delivery within the deadline (48 hours before the event), then you must contact Seller Support (on Whatsapp 07884577417) to find a resolution.",
    slug: "sales",
    index: 29,
  },
  {
    que: "How do I change the delivery method of my sale?",
    ans: "If the ticket type or delivery method has changed, you must contact Seller Support (on Whatsapp 07884577417) to find out if the marketplace and customer are happy with the changes.  ",
    slug: "sales",
    index: 30,
  },
  {
    que: "My tickets have sold but i can no longer fulfil the order, what can i do?",
    ans: "It is recommended that all tickets listed are in hand and ready to be shipped/delivered before any listings are completed. If tickets have been sold and you are unable to fullfill the order, you need to contact Seller Support (on Whatsapp on 07884577417) to find a resolution. This may be to offer replacement or upgraded tickets to the customer. If this cannot be done, then the sale may be cancelled and fine may be applied to your account. ",
    slug: "sales",
    index: 31,
  },
  {
    que: "I have sent the wrong tickets to the buyer.",
    ans: "Please contact Seller Support on Whatsapp on 07884577417 immediately.",
    slug: "sales",
    index: 32,
  },
  {
    que: "My order says the tickets are not delivered when I've already shipped.",
    ans: "Please make sure you have provided proof of transfer or tracking number to the order. If you have done this then contact Seller Support on Whatsapp on 07884577417.",
    slug: "sales",
    index: 33,
  },
  {
    que: "How do I see the customers details?",
    ans: "To see the customers details, you must find the order by going to the 'Sales' screen, if you have the order number then it will be easier to find by typing that in.",
    slug: "sales",
    index: 34,
  },
  {
    que: "How to upload PDF / E-tickets to a order or listing.",
    ans: "If you have the E-Ticket / PDF at the time of listing, it is best practice to upload this straight away to the listing. That way, when it is sold, it will get sent directly to the customer. If you are fulfilling orders once a sale has gone through then you will need to do this through the 'Sales' screen. You can follow our step by step guide below, or watch our video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    slug: "sales",
    index: 35,
  },
  {
    que: "How do I deliver paper ticket?",
    ans: "Paper tickets need to be sent directly to the buyer. Buyer details can be found in the sales section of Tixstock. Once the tickets have been sent out there is a field within the shipping section to input the tracking number. You must input the tracking number to be paid for the sale. You can view our guide or video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Sales/How+Do+I+deliver+a+paper+Ticket.pdf",
    slug: "sales",
    index: 36,
  },
  {
    que: "How do I deliver mobile tickets?",
    ans: "You must transfer your mobile tickets or pk passes externally to the customer. It is important to note that you must provide evidence of this transfer on Tixstock to be paid for the sale. You can view our guide or video tutorial. Alternatively, you can also reach out to our Seller Support for any assistance on Whatsapp on 07884577417.",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Sales/How+Do+I+deliver+a+mobile+ticket.pdf",
    slug: "sales",
    index: 37,
  },
  {
    que: "What do I do if the customers details or email are not showing on my listing?",
    ans: "The customers details should be displayed when you click into the order details from your sales screen. If this is not the case and you require them, contact Seller Support for assistance on Whatsapp on 07884577417.",
    slug: "sales",
    index: 38,
  },
  {
    que: "How do I find what exchange my listing has sold on?",
    ans: "Any sales that are sold through our Preferred Partners model you will recieve all the information you need fulfil the order direct to the customer. Please remember Tixstock is now your sole point of contact for all payment and customer support queries. ",
    slug: "sales",
    index: 39,
  },
  {
    que: "How do I find what exchange my listing has sold on?",
    ans: "Any sales that are sold through our Preferred Partners model you will recieve all the information you need fulfil the order direct to the customer. Please remember Tixstock is now your sole point of contact for all payment and customer support queries. ",
    slug: "sales",
    index: 40,
  },
  {
    que: "How do I find out the order ID?",
    ans: "The order ID should be displayed when you click into the order details through the 'Sales' screen.",
    pdf: "https://tixstock.s3.eu-west-2.amazonaws.com/FAQV02/Sales/How+do+I+find+out+the+Order+ID.pdf",
    slug: "sales",
    index: 41,
  },
  {
    que: "What happens if I cant upload tickets before in hand date?",
    ans: "Tickets must be delivered 48 hours before the event. If this is going to be an issue, then you need to speak to Seller Support (on Whatsapp on 07884577417) to inform them of the delay in delivery and to make sure the customer is happy with the updated delivery deadline. If it does go past the original delivery date and the customer isn't aware of a delay, then you may find yourself in a situation where the customer cancels the order or the marketplace moves to replace those tickets and you may face a fine.",
    slug: "sales",
    index: 42,
  },
  {
    que: "What happens if i'm unable to fulfil an order?",
    ans: "It is recommended that all tickets listed are in hand and ready to be shipped/delivered before any listings are completed. If tickets have been sold and you are unable to fulfil the ticket, contact Seller Support on Whatsapp on 07884577417 immediatly. This may lead to the sale being cancelled and fines applied to your account.",
    slug: "sales",
    index: 43,
  },
  {
    que: "If I know before the in hand date that I cannot fulfil an order what should i do?",
    ans: "Contact Seller Support immediately on Whatsapp on 07884577417 immediately.",
    slug: "sales",
    index: 44,
  },
  {
    que: "How do I find out a customers details if I need to send an instruction?",
    ans: "The customers details will be in the order details within the 'Sales' screen if you wish to send further instructions with the ticket.",
    slug: "sales",
    index: 45,
  },

  {
    que: "When will I receive my tickets?",
    ans: "We collaborate closely with our suppliers to ensure timely delivery of all tickets. Each listing includes an 'in-hand date,' indicating the earliest date you can expect to receive your tickets. Typically, tickets are delivered within 48 hours of this date. For tickets labeled as 'Tickets in hand,' you can expect delivery within 48 hours of your purchase. Please note that if you purchase tickets within 48 hours of the event start time, tickets may be delivered up to 2 hours before the event begins.",
    index: 46,
    slug: "tx-trade",
  },
  {
    que: "Will my tickets be sat together?",
    ans: "All tickets within a listing will be provided by the supplier as consecutive seat numbers, unless otherwise specified. ",
    index: 47,
    slug: "tx-trade",
  },
  {
    que: "Why do I have to provide 'Attendee details' for some events?",
    ans: "For some events, organisers or venues require attendee information to facilitate ticket transfers. We request this information only when necessary to ensure a seamless experience for the attendee. IMPORTANT: Events requiring this detail will be highlighted before purchase. Please note that failure to provide this information may result in your order being Cancelled without a refund.",
    index: 48,
    slug: "tx-trade",
  },
  {
    que: "How do I access tickets that have been purchased?",
    ans: "All of your purchases are visible in the 'Purchases' section of TX Trade. There you can download e-tickets, find mobile links and view proof of delivery.",
    index: 49,
    slug: "tx-trade",
  },
  {
    que: "Can I cancel my tickets?",
    ans: "Once a purchase is confirmed, it is considered final, and tickets cannot be cancelled or exchanged. If you no longer wish to use your tickets and there is sufficient time before the event, we recommend relisting them via Tixstock if you have an active seller account. If you do not have an active seller account, please contact our support team for assistance. ",
    index: 50,
    slug: "tx-trade",
  },
  {
    que: "What happens if the event is cancelled?",
    ans: "In the unlikely event that an event is cancelled your purchase will be refunded.",
    index: 51,
    slug: "tx-trade",
  },
  {
    que: "What happens if the event is rescheduled?",
    ans: "If an event is rescheduled, your tickets will remain valid. The original date may still be printed on the ticket, but it will be valid for entry on the new date. ",
    index: 52,
    slug: "tx-trade",
  },

  {
    que: "Events at neutral stadiums",
    ans: "In instances where events are held at neutral stadiums, tickets for these events may be available on the TX Trade platform before the participants are confirmed or announced, such as for the final of a major sporting event. Listings will indicate if the tickets are for 'Neutral,' meaning they will be in the official neutral area of the stadium, or 'Any side,' meaning the tickets could be located in any area of the stadium, provided they meet the listing criteria (e.g., Category, Block, and/or Row). Once the event participants are confirmed, tickets for specific 'fan sections' will become available and will be clearly stated on each listing. ",
    index: 53,
    slug: "tx-trade",
  },
  {
    que: "Benefits and Restrictions",
    ans: "Some tickets include additional benefits, such as 'Early access to the venue,' or restrictions, such as 'Under 18 only.' These details will be displayed on the listing, so please review them before confirming your purchase. ",
    index: 54,
    slug: "tx-trade",
  },
  {
    que: "Why is my name not on the ticket?",
    ans: "Personalization of tickets is not required for all events. If attendee details are necessary for entry, 'Attendee details required' will be indicated at the time of purchase. ",
    index: 55,
    slug: "tx-trade",
  },
  {
    que: "What should I do if I have problems with my ticket?",
    ans: "All issues must be reported immediately. If you have received an instruction letter with the seller's contact details, please attempt to reach them directly. If not, report any issues by calling +357 94 058833. Top Seller program members can also raise issues in their dedicated WhatsApp group. ",
    index: 56,
    slug: "tx-trade",
  },

  {
    que: "What are the requirements for getting paid? (are there any actions I must take to get paid for a fulfilled order)",
    ans: "In order to be paid for every sale Tixstock needs to ensure the customer recieved the tickets. You can provide this proof in the following ways  (Bulletpointed List) Paper:Tickets to be sent by mail with plenty of time leading up to the event, you must also post your tracking number / shipping label on your order on Tixstock to be paid for the sale.  Mobile/PK Pass: These tickets are transferred externally. You must have delivered the ticket within the delivery date and provide proof of transfer to be paid for the sale. Eticket/PDF: You must upload the E-Ticket / PDF to the order before the delivery date. If you have these tickets already, it is best practice to upload them to your Inventory before they sell so they can get sent straight to the buyer upon purchase. ",
    slug: "payments",
    index: 57,
  },
  {
    que: "How will I get paid?",
    ans: "Payments are released when we receive the funds from the marketplaces. The majority of these are around a week after the event there are a few that pay us every two weeks or monthly. <br/><br/> We do a payment run weekly to cover sales we have been paid for. If you have examples of missing sales please contact your account manager to investigate. <br/><br/> Please be aware, to be paid out for your sale you need to have provided proof of transfer if a mobile ticket or a tracking number if a paper ticket.",
    slug: "payments",
    index: 58,
  },
  {
    que: "When will I get paid?",
    ans: "You will be paid 5 days after the event.",
    slug: "payments",
    index: 59,
  },
  {
    que: "Who will I be getting paid by??",
    ans: "Payments will be made by Tixstock. Please remember Tixstock is now your sole point of contact for all payment and customer support queries.  ",
    slug: "payments",
    index: 60,
  },
  {
    que: "What currencies can I be paid in?",
    ans: "You can choose a base currency to be paid out from Tixstock these include GBP, EURO, USD, and CHF",
    slug: "payments",
    index: 61,
  },
  {
    que: "What is a TX account?",
    ans: "Your TX pay account is your virtual wallet which allows you recieve and manage all payments you recieve from Tixstock.",
    slug: "payments",
    index: 62,
  },
  {
    que: "How do i add funds to my TX pay wallet?",
    ans: "You can credit your TX pay wallet through sending a payment to the IBAN and swift provided with your wallet ID as reference. ",
    slug: "payments",
    index: 63,
  },
  {
    que: "How do I withdraw from my TX wallet?",
    ans: "You withdraw your money from the TX pay screen. Please see our guide.",
    slug: "payments",
    index: 64,
  },
  {
    que: "I want to change my account i get paid in.",
    ans: "Contact Seller Support on Whatsapp on 07884577417 to have your payment information updated.",
    slug: "payments",
    index: 65,
  },
  {
    que: "My account is locked, what do I do?",
    ans: "Contact Seller Support on Whatsapp on 07884577417. ",
    slug: "technical-support",
    index: 66,
  },
  {
    que: "I'm not getting the reset password email",
    ans: "Contact Seller Support on Whatsapp on 07884577417.",
    slug: "technical-support",
    index: 67,
  },
  {
    que: "My listing page is freezing / Tixstock is running slowly?",
    ans: "We recommend clearing your cache and cookies. If issues persist then contact Seller Support on Whatsapp on 07884577417.",
    slug: "technical-support",
    index: 68,
  },
  {
    que: "Who do I contact if I get an error messages using Tixstock?",
    ans: "Contact Seller Support on Whatsapp on 07884577417.",
    slug: "technical-support",
    index: 69,
  },
  {
    que: "Who do I contact if I haven't been paid for a fulfilled order?",
    ans: "You will be paid for for fulfilled orders 5 days after the event has taken place. If it goes beyond this, then reach out to Seller Support.",
    slug: "technical-support",
    index: 70,
  },
  {
    que: "If i'm having issues fulfilling an order due to system issues what do I do?",
    ans: "Contact Seller Support on Whatsapp on 07884577417.",
    slug: "technical-support",
    index: 71,
  },
  {
    que: "How do I request a new feature be added to Tixstock?",
    ans: "We welcome any feedback or any new ideas which can be added to Tixstock, mention it to Seller Support next time you speak to them. Alternatively, you can do this in Tixstock by going to the Settings page and clicking on 'Request a feature'.",
    slug: "technical-support",
    index: 72,
  },
  {
    que: "What happens if i'm fined and no money is in my TX wallet?",
    ans: "Your account will go into a negative standing and be recovered upon the next time you recieve a payment into your TX pay wallet. ",
    slug: "technical-support",
    index: 73,
  },
  {
    que: "How do you work out the fines?",
    ans: "Fines will vary depending on the issue that has arisen. A full breakdown of our fines table can be found below.",
    slug: "miscellaneous",
    index: 74,
  },
  {
    que: "How do i dispute a fine?",
    ans: "Reach out to Seller Support, providing as much evidence and detail as possible.",
    slug: "miscellaneous",
    index: 75,
  },
  {
    que: "How do I see my sales history?",
    ans: "You can see your full sales history from your reports screen on Tixstock.",
    slug: "miscellaneous",
    index: 76,
  },
  {
    que: "How can I see how much money I have taken out of Tixstock?",
    ans: "This will be displayed on your TX Pay tab on Tixstock.",
    slug: "miscellaneous",
    index: 77,
  },
  {
    que: "What information can I see on my dashboard?",
    ans: "On your dashboard it will give you various information. Firstly, you'll be able to see upcoming events and any sales activity. There will be a notifications section, a brief overview of your your Tixstock reports and latest industry information. ",
    slug: "miscellaneous",
    index: 78,
  },
  {
    que: "What is the latest I can list a ticket for an event?",
    ans: "You can create and publish listings up until the date of your event. If you are offering last minute delivery please indicate this on your listing. Please note if listing late for an event it is best practice to upload your ticket to avoid any delays in delivery.  ",
    slug: "miscellaneous",
    index: 79,
  },
  {
    que: "Will I ever have to contact an exchange regarding an issue with an order?",
    ans: "Tixsotck will become your sole contact for customer support and payment.",
    slug: "miscellaneous",
    index: 80,
  },
  {
    que: "What if an event i want to list on is not currently showing on Tixstock?",
    ans: "Reach out to Seller Support on Whatsapp on 07884577417 and they will make sure the event is added.",
    slug: "miscellaneous",
    index: 81,
  },
  {
    que: "How do I know which marketplace I am selling to?",
    ans: "Tixstock will publish your listing to all our traditional marketplaces that we are in partnership with these include 1Box office, Ticketgum, 7 Tickets, Ticombo. Football Ticket Pad and Gigsberg. Tixstock is also working closely with the Hospitality and Reward sector to increase the number of sales you receive. These partnerships include Tevo in America who have a vast long standing distribution network and Hello Tickets who have a strong offering in hopitality. Both marketplaces you would not traditionally have access to. As we are always striving to improve and add to these channels we can not keep you up to date with who we have connected. If you have an issue with your inventory being pushed to a specific partner please contact customer support on Whatsapp 07884577417.",
    slug: "miscellaneous",
    index: 82,
  },
];
