import React from "react";

interface APITabInterface {
  title: string;
  status: string;
  handleActive: any;
}

const APITab: React.FC<APITabInterface> = ({
  title,
  status,
  handleActive,
}: any) => {
  return (
    <div
      className={` ${
        title === status ? "bg-opacity-100 !z-[11]" : "bg-opacity-60"
      } single-tab lg:px-4 px-2.5 py-[.5625rem] lg2:max-w-[T20.938rem] min-w-[10.625rem lg:whitespace-normal whitespace-nowrap flex justify-between items-center bg-white rounded-t cursor-pointer mr-[1px] w-full gap-2`}
      onClick={() => handleActive(title)}
    >
      <h4 className={`lg:text-sm15 text-sm14 font-semibold `}>{title}</h4>
    </div>
  );
};

export default APITab;
