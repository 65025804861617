import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { TableVirtuoso } from "react-virtuoso";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import NoMoreItemInTable from "../../../components/tableComponent/NoMoreItemInTable";
import TablePopup from "../../../components/tableComponent/TablePopup";
import Messages from "../../../helpers/Messages";
import { showAlert } from "../../../helpers/ShowAlert";
import { InstructionLetterContext } from "../core/InstructionLetterProvider";
import { deleteTemplate } from "../core/requests";
import ItemContent from "./component/ItemContent";
import TableActionButtons from "./component/TableActionButtons";
import TableHeaderRow from "./component/TableHeaderRow";
import TableRow from "./component/TableRow";

const Table = ({ style, ...props }: any) => (
  <table
    {...props}
    className="w-full text-xs text-left  lg:min-w-[87.5rem] last-auto"
    style={{
      ...style,
    }}
  />
);
const TableHead = ({ style, ...props }: any) => (
  <thead
    {...props}
    className="text-xs text-gray-400 "
    style={{
      ...style,
      position: "static",
    }}
  />
);

const DataTable = ({
  loading,
  setLazyLoadLoading,
  lazyLoadLoading,
  listRefetch,
  handleOpenForm,
}: any) => {
  const layout = useContext(InstructionLetterContext);
  const globalLayout = useContext(LayoutContext);
  const stickyTableRef: any = useRef();
  const virtuosoRef: any = useRef();
  const tableRef: any = useRef();
  const [height, setHeight] = useState<any | undefined>(undefined);
  const [virtHeight, setVirtHeight] = useState<any>();
  const [deleteLoader, setDeleteLoader] = useState<any>(false);

  const [tableAlert, setTableAlert] = useState<any>({ show: false });

  const paginateData = layout.meta;
  const data = layout.listings?.instruction_letter_templetes;

  // CONFIRM DELETE
  const confirmEventFieldsDelete = () => {
    setDeleteLoader(true);
    deleteTemplate(tableAlert?.id).then((response: any) => {
      if (response) {
        setTimeout(() => {
          setDeleteLoader(false);
        }, 1500);
      }
      if (response?.data?.status) {
        showAlert(response.message, false, 1500);
        listRefetch();
      } else {
        showAlert(
          Array.isArray(response?.errors)
            ? response?.errors.join("<br/>")
            : Messages?.someWrong,
          true
        );
      }
    });
  };

  /*On scroll sticky head list scroll virtuoso*/
  const handleScrollHead = () => {
    const scrollLeft = stickyTableRef.current.scrollLeft;
    tableRef.current.scrollLeft = scrollLeft;
  };

  /*On scroll virtuoso list scroll stickyhead*/
  const handleScrollVirtuoso = () => {
    const scrollLeft = tableRef.current.scrollLeft;
    stickyTableRef.current.scrollLeft = scrollLeft;
  };

  let timeout: any;
  const nomoreListing = document.getElementById("NoMoreListing");

  const renderCall = () => {
    timeout = setTimeout(() => {
      const divElement: any = document.querySelector("#instruction-lazyload");
      const thead = document.querySelector("#tableId1 table thead");
      const theadHeight = thead?.scrollHeight;
      const nomoreListing = document.getElementById("NoMoreListing");
      let nomoreListingHeight = nomoreListing?.clientHeight;

      if (divElement) {
        if (divElement.offsetHeight) {
          let elemHeight = divElement.offsetHeight + theadHeight + 1;
          if (nomoreListingHeight) {
            elemHeight =
              divElement.offsetHeight + theadHeight + nomoreListingHeight;
          }

          setHeight(elemHeight);
        } else {
          setTimeout(renderCall, 100);
        }
      }
    }, 500);
  };

  useLayoutEffect(() => {
    renderCall();
    window.addEventListener("scroll", renderCall);
    return () => {
      clearTimeout(timeout);
    };
  });

  useEffect(() => {
    setTimeout(() => {
      const divElement: any = document.querySelector("#instruction-lazyload");
      const elemHeight = divElement?.offsetHeight;
      setVirtHeight(elemHeight);
    }, 300);
  }, [data, loading, layout?.filterData]);

  // RE-RENDER HEIGHT ON RESIZING RESOLUTION
  useEffect(() => {
    renderCall();
  }, [
    globalLayout?.htmlFontSize,
    data?.length === paginateData?.total && paginateData?.current_page > 1,
    nomoreListing,
  ]);

  useEffect(() => {
    setTimeout(() => {
      if (globalLayout?.htmlFontSize) {
        renderCall();
      }
    }, 1000);
  }, [globalLayout?.htmlFontSize]);

  useEffect(() => {
    const handleScrollTable = () => {
      const EventAccordion = document.getElementById(
        "EventAccordion"
      ) as HTMLElement;

      const topfilterTop: any = document.getElementById("topFilters");

      const topValue: any = topfilterTop?.style?.top; // Retrieve the current 'top' value
      // if (topValue.includes("-")) {
      // Check if the value contains a negative sign
      const currentValue = parseInt(topValue); // Extract the numeric value
      const newValue = Math.abs(currentValue); // Make it positive
      topfilterTop.style.top = `${newValue}px`; // Apply the new positive value back as a string
      // }
      const windScroll: number = window.scrollY;

      const headingOne: any =
        document.getElementById("headingOne")?.clientHeight;
      const inventoryaccordion: any =
        document.getElementById("inventoryaccordion")?.clientHeight;
      // const totleScroll = headingOne + inventoryaccordion + 16;
      const topFiltersHeight = topfilterTop.clientHeight;

      let totleScroll = 0;
      if (window.matchMedia("(max-width: 767px)").matches) {
        totleScroll = topFiltersHeight;
      } else {
        totleScroll = headingOne + inventoryaccordion + 16;
      }
      // const currentTop = parseInt(topfilterTop.style.top || "0");

      const stickyHead: any = document.getElementById("sticky-head");
      //code to adjust sticky header based on filter chip Area scrollbar
      const filterChipArea = document.querySelector(
        ".filterChipArea"
      ) as HTMLElement;
      let filterChipAreaWidth = 0;
      let width = 0;

      if (width > filterChipAreaWidth && width != 0) {
        // stickyHead?.classList.remove("top-[14.625rem]");
        stickyHead?.classList.add("sticky-header-scroll");
        // stickyHead?.classList.remove("xl:top-[12.375rem]");
        // stickyHead?.classList.remove("xl:top-[12.625rem]");
      }

      const filters_block =
        document.getElementById("filters_block")?.offsetHeight || 0;
      const salesFilter =
        document.getElementById("salesFilter")?.offsetHeight || 0;
      const HeaderHeight: any = document.querySelector("header")?.clientHeight;
      const topPosition = filters_block + salesFilter + HeaderHeight;
      const stickyShadow: any = document.getElementById("sticky-shadow");
      if (windScroll > totleScroll) {
        EventAccordion?.classList?.add("-mt-10");
        stickyHead?.classList.remove("opacity-0");
        stickyHead?.classList.remove("h-0");
        stickyHead?.classList.add("h-[2.5625rem]");
        stickyShadow.classList.add("!block");
      } else if (windScroll < totleScroll) {
        EventAccordion?.classList?.remove("-mt-10");
        stickyHead?.classList.add("opacity-0");
        stickyHead?.classList.add("h-0");
        stickyHead?.classList.remove("h-[2.5625rem]");
        stickyShadow.classList.remove("!block");
      }
    };
    document.addEventListener("scroll", handleScrollTable);
    document.addEventListener("resize", handleScrollTable);
  }, []);

  useEffect(() => {
    const parentDiv: any = document.getElementById("custom-loader");
    if (
      paginateData?.current_page === paginateData?.last_page &&
      paginateData?.current_page !== undefined &&
      paginateData?.last_page !== undefined
    ) {
      if (parentDiv) {
        parentDiv.classList.add("hidden");
      }
    }
    if (
      paginateData?.current_page !== paginateData?.last_page &&
      paginateData?.current_page < paginateData?.last_page
    ) {
      if (parentDiv) {
        parentDiv.classList.add("visible");
        parentDiv.classList.remove("hidden");
      }
    }
  }, [paginateData]);

  return (
    <div
      className="accordion mb-4 font-medium transition-all relative px-5 "
      id={`EventAccordion`}
    >
      <div
        id="sticky-head"
        className={`sticky sales-report-sticky-head z-10 opacity-0 h-0  overflow-auto scrollbar-none top-[14.625rem]`}
        ref={stickyTableRef}
        onScroll={handleScrollHead}
      >
        <table className="w-full text-xs text-left  min-w-[87.5rem] absolute left-0 top-0 last-auto">
          <thead className="text-xs text-gray-400 ">
            <TableHeaderRow
              stickyTableRef={stickyTableRef}
              tableRef={tableRef}
            />
          </thead>
        </table>
      </div>
      <span id="sticky-shadow" className="z-10 hidden"></span>
      <div className="accordion-item bg-white  !rounded overflow-hidden">
        <div
          id="EventCollapseBody"
          className="accordion-collapse collapse show"
          aria-labelledby="EventCollapseBody"
          data-te-collapse-show
        >
          <div className="accordion-body relative">
            <div
              className="inventory-table sales-table overflow-x-auto scrollbar-none scrollbar-thumb-transparent scrollbar-thumb-rounded-full hover-style2"
              ref={tableRef}
              id={`tableId1`}
              onScroll={handleScrollVirtuoso}
              style={{ height: height > 41 ? `${height}px` : "auto" }}
            >
              <TableVirtuoso
                data={data}
                endReached={() => {
                  if (
                    paginateData?.current_page < paginateData?.last_page &&
                    !lazyLoadLoading
                  ) {
                    setLazyLoadLoading(true);
                    layout.setFilterData((current: any) => {
                      let nextPage: number = paginateData?.current_page + 1;
                      return {
                        ...current,
                        page: nextPage,
                        per_page: 20,
                      };
                    });
                  }
                }}
                useWindowScroll
                overscan={200}
                totalCount={data?.length}
                atBottomThreshold={100}
                id={"instruction-lazyload"}
                components={{
                  Table: Table,
                  TableHead: TableHead,
                  TableRow: (props) => {
                    return <TableRow {...props} />;
                  },
                  TableFoot: (props) => {
                    if (
                      data?.length === paginateData?.total &&
                      paginateData?.current_page > 1
                    ) {
                      return <NoMoreItemInTable />;
                    } else {
                      return null;
                    }
                  },
                }}
                fixedFooterContent={() => {
                  return (
                    <tr className="border-b bg-white">
                      <td colSpan={0}>Hi</td>
                    </tr>
                  );
                }}
                fixedHeaderContent={() => (
                  <TableHeaderRow
                    stickyTableRef={stickyTableRef}
                    tableRef={tableRef}
                  />
                )}
                itemContent={(sIndex: any, list: any) => {
                  const matchedView = false;
                  return (
                    <React.Fragment key={sIndex}>
                      <ItemContent list={list} />
                      <TableActionButtons
                        list={list}
                        sIndex={sIndex}
                        matchedView={matchedView}
                        handleOpenForm={handleOpenForm}
                        listRefetch={listRefetch}
                        tableAlert={tableAlert}
                        setTableAlert={setTableAlert}
                        deleteLoader={deleteLoader}
                      />
                    </React.Fragment>
                  );
                }}
                ref={virtuosoRef}
              />
            </div>
          </div>

          <div
            id="custom-loader"
            className={`accordion bg-white font-medium pt-2.5 border-t `}
          >
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
            <div
              className={`accordion-item bg-white mb-2.5 !rounded overflow-hidden shimmer-effect`}
              style={{ height: "30px" }}
            ></div>
          </div>
        </div>
      </div>
      {tableAlert?.show && (
        <TablePopup
          isConfirm={true}
          message={"Are you sure you want to delete?"}
          cancelBtn={() => setTableAlert({ show: false, id: null })}
          confirmBtn={() => confirmEventFieldsDelete()}
        />
      )}
    </div>
  );
};

export default DataTable;
