type TComponentSVG = {
  className?: string;
  spanId?: string;
  dataTooltipID?: string;
  children?: React.ReactNode;
};

const ComponentSVG = ({
  className,
  spanId,
  dataTooltipID,
  children,
}: TComponentSVG) => {
  return (
    <span
      className={className}
      tabIndex={-1}
      id={spanId}
      data-tooltip-id={dataTooltipID}
    >
      {children}
    </span>
  );
};

export default ComponentSVG;
