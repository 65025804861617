import ComponentSVG from "../../../../components/ui/typography/ComponentSVG";

type TEventHeader = {
  className: string;
  title: string;
  Imgpath: React.ElementType;
  svgClassName: string;
};

const EventHeader = ({
  className,
  title,
  Imgpath,
  svgClassName,
}: TEventHeader) => {
  return (
    <>
      <div className={className}>
        <ComponentSVG className="flex items-center justify-center min-w-[0.75] fill-white mr-2">
          <Imgpath className={svgClassName} />
        </ComponentSVG>

        <p>{title}</p>
      </div>
    </>
  );
};

export default EventHeader;
