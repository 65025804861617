import { CUSTOMIZE_TAB_KEYS } from "../core/constants/constants";
import { useCustomize } from "../hooks/useCustomize";
import TableWrapper from "./ContentPage/table/TableWrapper";
import { DesignMenu } from "./DesignMenu/DesignMenu";

const CommonTableWrapper = () => {
  const { isTabActive } = useCustomize();

  switch (isTabActive) {
    case CUSTOMIZE_TAB_KEYS.CONTENT_PAGE:
      return <TableWrapper />;

    case CUSTOMIZE_TAB_KEYS.DESIGN_MENU:
      return <DesignMenu />;

    default:
      return null;
  }
};

export default CommonTableWrapper;
