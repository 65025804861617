import React, { forwardRef, useContext } from "react";
import { InstructionLetterContext } from "../../core/InstructionLetterProvider";
import HeaderCell from "./HeaderCell";

const TableHeaderRow = forwardRef((ref: any, addtionalRef: any) => {
  const layout = useContext(InstructionLetterContext);

  //  HEADER GET COLUMNS
  const getColumnItems = () => {
    return layout.columnItems?.filter(
      (columnItem) => columnItem.checked === true
    );
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.columnItems.findIndex((item: any) => item.id === id);
    return index;
  };

  // HANDLE SCROLL
  const scroll = (scrollOffset: any) => {
    if (addtionalRef?.tableRef && ref?.stickyTableRef) {
      addtionalRef.tableRef.current.scrollLeft += scrollOffset;
      ref.stickyTableRef.current.scrollLeft += scrollOffset;
    }
  };

  return (
    <tr className="border-b bg-white">
      {getColumnItems().map((columnItem: any, index: number) => {
        switch (columnItem.id) {
          case 0:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(0)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[9.375rem] min-w-[9.375rem]"
                    arrow={true}
                    postKey="title"
                  />
                </React.Fragment>
              )
            );
          case 1:
            return (
              layout.columnItems[`${getFilterIndexForCheckbox(1)}`]
                ?.checked && (
                <React.Fragment key={index}>
                  <HeaderCell
                    title={columnItem?.name}
                    width="w-[9.375rem] min-w-[9.375rem]"
                    arrow={true}
                    postKey="status"
                  />
                </React.Fragment>
              )
            );
        }
      })}
      <th className="md:sticky relative right-0 ml-auto  p-0 w-[5rem] max-w-[5rem] min-w-[5rem]">
        <div
          className="py-[.4375rem] px-4 flex justify-end h-[2.5rem] left-shad border-l bg-white"
          id="left-shad-list"
        >
          {/* <button
            className="group rounded-l  hover: transition px-[.5625rem] py-2"
            id="leftArrow"
            type="button"
            onClick={(e: any) => scroll(-120)}
          >
            <IKTSVG
              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
              path={angleLeftSVG}
              svgClassName="w-1.5 h-[.5625rem]"
            />
          </button>
          <button
            className="group rounded-r  transition px-[.5625rem] py-2"
            id="rightArrow"
            type="button"
            onClick={(e: any) => scroll(120)}
          >
            <IKTSVG
              className="flex items-center justify-center fill-violet-500 transition group-hover:fill-indigo-500"
              path={angleRightSVG}
              svgClassName="w-1.5 h-[.5625rem]"
            />
          </button> */}
        </div>
      </th>
    </tr>
  );
});

export default TableHeaderRow;
