import { PlusIcon } from "assets/media/icons/other_icons/PlusIcon";
import { IKTSVG } from "components/ui/IKTSVG";
import { CUSTOMIZE_TAB_KEYS } from "pages/customize/core/constants/constants";
import { useCustomize } from "pages/customize/hooks/useCustomize";
import { AddNewRecord } from "pages/customize/widget/AddEditRecord/AddNewRecord";
import TooltipPortal from "pages/tx_trade/table/component/TooltipPortal";
import { useMemo, useState } from "react";
import Collapse from "../../../../assets/media/icons/other_icons/Collapse.svg";

const HeaderComponent = ({ heightRef1, setIsToggle, IsToggle }: any) => {
  const { isTabActive, setIsPopupActive } = useCustomize();
  const [isCollapseAccordion, setIsCollapseAccordion] =
    useState<boolean>(false);

  const isContentPage = useMemo(
    () => Boolean(CUSTOMIZE_TAB_KEYS.CONTENT_PAGE === isTabActive),
    [isTabActive]
  );
  return (
    <>
      <div className="accordion font-medium">
        <div
          className=" bg-white accordion-item !rounded-none"
          ref={heightRef1}
        >
          <div
            className="flex  justify-between accordion-header mb-0"
            id="headingOne"
          >
            <div className="flex flex-wrap justify-end w-full pl-2 pr-5 py-2">
              <div className="btn text-end">
                {CUSTOMIZE_TAB_KEYS.DESIGN_HOME_PAGE !== isTabActive && (
                  <button
                    className="group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
                    type="button"
                    data-bs-toggle="offcanvas"
                    data-bs-target={`#${
                      isContentPage ? "" : "add_new_category"
                    }`}
                    aria-controls={`${isContentPage ? "" : "AddCategoryLabel"}`}
                    id="addEditRecord"
                    onClick={(e: any) => {
                      e?.preventDefault();
                      setIsPopupActive({
                        tabActive: isTabActive,
                        status: isContentPage ? true : false,
                        data: undefined,
                      });
                      // setIsToggle(!IsToggle);
                    }}
                  >
                    <div className=" mr-1.5 flex items-center justify-center fill-white">
                      <PlusIcon />
                    </div>
                    Add new
                  </button>
                )}
              </div>
            </div>

            <div>
              <button
                className={`${
                  false ? "pointer-events-none" : ""
                } group relative flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180`}
                type="button"
                data-te-collapse-init
                data-te-toggle="collapse"
                data-te-target="#inventoryaccordion"
                aria-expanded="true"
                aria-controls="inventoryaccordion"
                data-tooltip-id="tx-account-accordion"
                id="tx-account-accordion"
                onClick={() => {
                  setIsCollapseAccordion((current: boolean) => {
                    let isCollapse: any = document
                      .getElementById("tx-account-accordion")
                      ?.getAttribute("aria-expanded");
                    return isCollapse === "false" ? true : false;
                  });
                  setIsToggle(IsToggle === false ? true : false);
                }}
              >
                <TooltipPortal
                  id={`tx-account-accordion`}
                  content={`${isCollapseAccordion ? "Expand" : "Collapse"}`}
                  positionStrategy="fixed"
                  place="left"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                />
                <IKTSVG
                  className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                  path={Collapse}
                  svgClassName="w-[.5625rem] h-[.5625rem]"
                />
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* <AddNewRecordForContent /> */}
      <AddNewRecord />
    </>
  );
};

export default HeaderComponent;
