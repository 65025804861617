import axios from '../../axois/SetupAxios';

// NOTIFICATIONS LISTING
const getNotificationListing = (data: any): Promise<any> => {
    return axios.post(`notifications/listing`, data).then((response) => response.data);
}

// STORE FILTERS
const storeFields = async (data: any): Promise<any> => {
    let payload = data
    const response = await axios.post(`settings/store-field-settings`, payload);
    return response.data;
}

export {
    getNotificationListing,
    storeFields
};

