import React from "react";
import info from "../../../../../assets/media/icons/other_icons/info.svg";
import { IKTSVG } from "../../../../ui/IKTSVG";

interface SubFormHeaderInterface {
  title: string;
}

const SubFormHeader: React.FC<SubFormHeaderInterface> = ({ title }: any) => {
  return (
    <div className="flex flex-wrap md:items-center w-full mb-4">
      <IKTSVG
        className="w-4 h-4 mr-1.5 flex items-center justify-center fill-gray-200 bg-white rounded-full [&_text]:fill-violet-800"
        path={info}
        svgClassName="w-4 h-4"
      />
      <div className="flex-1">
        <p className="italic font-basier text-sm12 md:text-sm14">{title}</p>
      </div>
    </div>
  );
};

export default SubFormHeader;
