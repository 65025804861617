/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { debounce } from "lodash";
import React, { useMemo, useState } from "react";
import infoIcon from "../../assets/media/icons/other_icons/info.svg";
import TooltipPortal from "../../pages/tx_trade/table/component/TooltipPortal";
import { IKTSVG } from "../ui/IKTSVG";
import { KTSVG } from "../ui/KTSVG";

interface TextfieldInterface {
  id?: any;
  label?: any;
  name?: any;
  value?: any;
  type?: any;
  defaultValue?: any;
  placeholder?: any;
  width?: any;
  handleOnChange?: any;
  border_radius?: any;
  isPriceField?: any;
  reff?: any;
  errorClass?: any;
  textFielClassName?: any;
  isDisabled?: any;
  required?: any;
  isCheckIcon?: any;
  isError?: any;
  height?: any;
  passFocus?: any;
  handleOnBlur?: any;
  onKeyDown?: any;
  onClick?: any;
  isFocus?: any;
  className?: any;
  whiteLabel?: any;
  onChange?: any;
  disabled?: any;
  WithIcon?: Boolean;
  isPriceFieldFocused?: Boolean;
}

const Textfield: React.FC<TextfieldInterface> = ({
  id,
  label,
  name,
  value,
  type,
  defaultValue = undefined,
  placeholder,
  width,
  handleOnChange,
  border_radius,
  isPriceField = false,
  reff,
  errorClass,
  textFielClassName,
  isDisabled = false,
  required = false,
  isCheckIcon = false,
  isError = false,
  height = undefined,
  passFocus = () => {},
  handleOnBlur = () => {},
  onKeyDown = () => {},
  onClick,
  className,
  whiteLabel,
  onChange,
  disabled,
  WithIcon = false,
  isPriceFieldFocused = false,
}: any) => {
  const [isFocued, setIsFocused] = useState<boolean>(false);

  const handleChange = useMemo(() => {
    return debounce((data) => {
      let event = {
        target: { name: data.target.name, value: data.target.value },
      };
      handleOnChange(event);
    }, 300);
  }, []);
  return (
    <div className={`relative w-full ${isPriceFieldFocused && "z-[2]"}`}>
      <input
        type={type}
        id={id}
        ref={reff}
        name={name}
        defaultValue={defaultValue}
        className={`${width && width} ${
          border_radius === true ? "text_field" : ""
        } ${textFielClassName} ${
          isDisabled && ` !bg-gray-100`
        } ${errorClass} placeholder:truncate block  font-medium w-full h-[1.875rem] text-xs transition bg-white border border-gray-300 appearance-none ${
          isPriceField ? "rounded-r px-[.4375rem]" : "rounded px-[.5625rem]"
        } focus:outline-none ${
          !isError && isCheckIcon && !isFocued && value
            ? "border-indigo-500 bg-white"
            : value && isCheckIcon && "border-rose-500"
        } focus:ring-0 focus:border-indigo-500 focus:bg-indigo-500 focus:bg-opacity-[7%] peer ${height} placeholder:!text-gray-500 focus:!text-indigo-500`}
        placeholder={placeholder ? placeholder : " "}
        value={value}
        onFocus={(data: any) => {
          passFocus(true);
          setIsFocused(true);
        }}
        onBlur={(data: any) => {
          let event = {
            target: { name: data.target.name, value: data.target.value },
          };

          passFocus(false);
          setIsFocused(false);
          handleOnBlur(event);
        }}
        onChange={(data) => {
          let event = {
            target: { name: data.target.name, value: data.target.value },
          };
          handleOnChange(event);
        }}
        onClick={onClick}
        disabled={isDisabled}
        autoComplete={"off"}
        onKeyDown={onKeyDown}
      />

      {!isError && isCheckIcon && !isFocued && value ? (
        <KTSVG
          className="flex items-center justify-center transition absolute top-1/2 -translate-y-1/2 right-4"
          path="other_icons/purple-check.svg"
          svgClassName="w-[1.125rem]"
        />
      ) : (
        ""
      )}
      {/* ERRORS FORMIk */}
      {value && isCheckIcon ? (
        <div className="text-sm text-rose-500 italic">{isError as string}</div>
      ) : (
        ""
      )}
      <label
        htmlFor={id}
        className={`${
          !isError && isCheckIcon && !isFocued && value && "text-indigo-500"
        } flex items-center text-label !leading-[1.5] absolute text-xxs text-gray-400 duration-300 transform -translate-y-4 font-medium top-[.5625rem]  left-[.4375rem] z-10 origin-[0] !bg-transparent px-1 pl-[.1875rem] peer-placeholder-shown:pl-1 peer-focus:px-[.1875rem] peer-focus:text-indigo-500  peer-placeholder-shown:-translate-y-1/2  peer-placeholder-shown:top-1/2 peer-focus:top-[.5625rem] peer-placeholder-shown:text-xs peer-focus:text-xxs peer-placeholder-shown:text-gray-500 peer-focus:-translate-y-4 peer-hover:text-indigo-500 peer-focus:bg-transparent before:transition-all  peer-placeholder-shown:before:opacity-0 peer-focus:before:opacity-100 peer-placeholder-shown:bg-white ${
          isPriceField && "!left-[.3125rem]"
        }`}
      >
        {label}
        {/* {required ? <span className="text-red-500 ml-1">*</span> : ""} */}
        {WithIcon && (
          <>
            <div
              className={`${
                isFocued || defaultValue ? "hidden" : "block"
              } icon `}
              data-tooltip-id={`infoTooltipdisplay`}
            >
              <IKTSVG
                path={infoIcon}
                className="ml-1 fill-grey-400 block"
                svgClassName="fill-gray-400 hover:fill-indigo-500 w-3.5 h-3.5"
              />
            </div>
            <TooltipPortal
              id={`infoTooltipdisplay`}
              content={
                "Quantity to display on the front-end of each marketplace if sitting on a large number of tickets"
              }
              className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
            />
          </>
        )}
      </label>
    </div>
  );
};

export default Textfield;
