import PageTitle from "../../components/core/PageTitle";
import { InstructionLetterProvider } from "./core/InstructionLetterProvider";
import InstructionLetter from "./InstructionLetter";

const InstructionLetterWrapper = () => {
  return (
    <InstructionLetterProvider>
      <PageTitle title="Instruction Letters" />
      <InstructionLetter />
    </InstructionLetterProvider>
  );
};

export default InstructionLetterWrapper;
