import clsx from "clsx";
import { LayoutContext } from "components/core/LayoutProvider";
import Messages from "helpers/Messages";
import InventoryNoJSX from "pages/inventory/core/InventoryNoJSX";
import { memo, useContext } from "react";
import infoIcon from "../../../../../assets/media/icons/other_icons/info.svg";
import PPEComponent from "../../../../../components/formComponent/PPEComponent";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import {
  decimalNumber,
  removeCommaFromNumber,
} from "../../../../../helpers/Functions";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import { InventoryContext } from "../../../core/InventoryProvider";

type TPPPWrapper = {
  event: Record<string, any>;
  eventRecord: Record<string, any>;
  mainStateRecord: Record<string, any>;
  eIndex: string | number;
  rIndex: string | number;
  MemoizedHandleTableField: (data: any, eIndex: any, rIndex: any) => void;
  setList: any;
  isPriceField?: any;
  fieldMessage?: any;
  isValidationPopupVisible?: any;
  indexPlusLength?: any;
  avgPrice?: any;
  ppeAction: any;

  ppeErrosValidation: any;
  firstSelectedIndex: any;
};

const PPPWrapper = ({
  event,
  eventRecord,
  mainStateRecord,
  eIndex,
  rIndex,
  MemoizedHandleTableField,
  setList,
  isPriceField = true,
  fieldMessage = "",
  isValidationPopupVisible = false,
  indexPlusLength = "",
  avgPrice = "",
  ppeAction,
  ppeErrosValidation,
  firstSelectedIndex,
}: TPPPWrapper) => {
  const layout = useContext(InventoryContext);
  const coreLayout = useContext(LayoutContext);
  const target_currency = coreLayout.currencyLabel;

  const { isEventRecordError, isEventRecordHoldTickets, isEventRecordBEError } =
    InventoryNoJSX({
      event,
      eventRecord,
    });
  const allError =
    isEventRecordError ||
    eventRecord?.status === "failed" ||
    isEventRecordBEError;

  let priceFieldValue = removeCommaFromNumber(
    decimalNumber(
      typeof eventRecord?.sell_price === "object"
        ? eventRecord?.sell_price?.value
        : eventRecord?.sell_price
    )
  );

  const isPPEDisabled =
    isEventRecordHoldTickets &&
    eventRecord?.quantity_available < eventRecord?.listing_ticket_files?.length;
  return (
    <div
      id={`collapsePPE${event?.id}-${rIndex}`}
      className={clsx(
        "flex w-full collapse ppeBlock show border-b table_row",
        (mainStateRecord?.loader || layout?.isBulkActionLoader) &&
          "pointer-events-none row-disabled !bg-gray-300/40",
        eventRecord?.selected === true &&
          layout?.isTableEditable.length > 0 &&
          "bg-indigo-500 bg-opacity-[7%]",
        allError && "bg-rose-500 bg-opacity-[7%] border-b",

        // layout?.isTableEditable?.length > 0 &&
        //   !ppeAction?.allPPE &&
        //   ppeAction?.somePPE &&
        ppeErrosValidation && "row-disabled",
        layout.isTableEditable.length > 0 &&
          eventRecord?.id !== firstSelectedIndex?.id &&
          "row-disabled"
      )}
      aria-labelledby="bodyfour"
    >
      <div className="flex border-r w-[2.5rem] min-h-[1.9375rem] sticky left-0 z-50 checkbox_td bg-inherit">
        <label className="relative w-full h-full z-100 bg-inherit"></label>
      </div>
      <div className="p-0 equal-td-height flex items-start sticky left-[2.5rem] PPEList py-2 px-[.9375rem] gap-x-2.5 z-[1] focus-within:z-[3]">
        <div className="singleList toggle bg-white rounded flex items-center px-3 py-5">
          <div className="innerFieldPPE flex items-center">
            PPP
            <IKTSVG
              className="ml-[.3125rem] mr-2"
              svgClassName="w-3.5 h-3.5  fill-gray-400 hover:fill-indigo-500 cursor-pointer"
              path={infoIcon}
              dataTooltipID={`${eIndex}-${rIndex}-PPP`}
            />
            <TooltipPortal
              id={`${eIndex}-${rIndex}-PPP`}
              content={Messages.pppToggleTooltipMessage}
              place="top"
              variant="light"
              className={`text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-[999999999] max-w-[9rem] cursor-default`}
            />
            <div className="flex">
              <label
                className={`${
                  isPPEDisabled && "pointer-events-none"
                }  relative inline-flex items-center cursor-pointer `}
              >
                <input
                  type="checkbox"
                  name="price_per_exchange"
                  checked={
                    eventRecord?.price_per_exchange === 1 ||
                    eventRecord?.price_per_exchange === true
                      ? true
                      : false
                  }
                  className="sr-only peer"
                  onChange={(e) => {
                    const data = {
                      target: {
                        name: "price_per_exchange",
                        checked: e?.target?.checked,
                        type: "checkbox",
                      },
                    };
                    MemoizedHandleTableField(data, eIndex, rIndex);
                  }}
                />
                <div
                  className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 after:bg-center after:bg-no-repeat after:bg-8`}
                ></div>
              </label>
            </div>
          </div>
        </div>

        {/* TX NETWORK */}
        <PPEComponent
          id={`tx-network-${eIndex}-${rIndex}`}
          label="TX Network"
          name={`sell_price`}
          // value={eventRecord?.proceedValue?.value}
          value={
            typeof eventRecord?.sell_price === "object"
              ? eventRecord?.sell_price?.value
              : decimalNumber(eventRecord?.sell_price)
          }
          errors={
            eventRecord?.price_per_exchange
              ? eventRecord?.fieldErrors?.sell_price
              : ""
          }
          currencySymbol={
            eventRecord?.sell_price_currency
              ? eventRecord?.sell_price_currency
              : eventRecord?.sell_price?.symbol
          }
          isTooltip={true}
          tooltipContent={Messages.txNetworkTooltipMessage}
          ListPPE={true}
          className={
            layout.tableDataEditChanging?.[eIndex]?.includes("sell_price") &&
            "edited"
          }
          handleOnChange={(data: any) => {
            data?.target?.value?.event?.preventDefault();
            MemoizedHandleTableField(data, eIndex, rIndex);
          }}
          //  ={eventRecord?.ppe}
          // disabled={eventRecord?.ppe}
          txNetwork={true}
          // isPPEActive={true}
          isPPEActive={eventRecord?.price_per_exchange}
          errorClass={`${
            eventRecord?.price_per_exchange &&
            eventRecord?.fieldErrors?.sell_price
              ? eventRecord?.fieldErrors?.sell_price
              : ""
          }`}
          errorMessage={`${
            eventRecord?.price_per_exchange &&
            eventRecord?.fieldErrors?.sell_price
              ? eventRecord?.fieldErrors?.sell_price
              : ""
          }`}
          disabled={isPPEDisabled}
          isPriceField={isPriceField}
          fieldMessage={fieldMessage}
          isValidationPopupVisible={isValidationPopupVisible}
          indexPlusLength={indexPlusLength}
          avgPrice={avgPrice}
        />

        {eventRecord?.ppePartners?.map((partner: any) => {
          if (partner?.name && !partner?.is_hidden) {
            return (
              <PPEComponent
                key={`${event?.id}-${eventRecord?.id}-${partner?.id}-PPP-Partner`}
                id={partner?.id}
                label={partner?.name}
                name={partner?.name}
                value={
                  typeof partner?.value === "object"
                    ? partner?.value?.value
                    : partner?.value
                }
                errors={
                  eventRecord?.price_per_exchange
                    ? eventRecord?.fieldErrors?.[partner?.name]
                    : ""
                }
                currencySymbol={partner?.value?.symbol}
                className={`${
                  eventRecord?.loader || layout?.isBulkActionLoader
                    ? "bg-opacity-100 disable"
                    : ""
                }  ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    partner?.name
                  ) && "edited"
                }`}
                isPPEActive={eventRecord?.price_per_exchange}
                tooltipContent={`Your default proceed price across 10+ partners in the TX Network`}
                handleOnChange={(data: any) => {
                  MemoizedHandleTableField(data, eIndex, rIndex);
                }}
                isMatchedValue={
                  eventRecord?.price_per_exchange &&
                  priceFieldValue !==
                    removeCommaFromNumber(decimalNumber(partner?.value?.value))
                }
                ListPPE={true}
                errorClass={`${
                  eventRecord?.price_per_exchange &&
                  eventRecord?.fieldErrors?.[partner?.name]
                    ? eventRecord?.fieldErrors?.[partner?.name]
                    : ""
                }`}
                errorMessage={`${
                  eventRecord?.price_per_exchange &&
                  eventRecord?.fieldErrors?.[partner?.name]
                    ? eventRecord?.fieldErrors?.[partner?.name]
                    : ""
                }`}
                disabled={isPPEDisabled}
              />
            );
          } else return null;
        })}
      </div>
    </div>
  );
};

export default memo(PPPWrapper);
