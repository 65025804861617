import React from "react";

const TableRow = React.memo(
  ({
    showLedger,
    hoveredId,
    setHoveredId,
    txPayLoading,
    index,
    ...props
  }: any) => {
    const list = props?.item;

    const matchedView =
      // showLedger?.index === tIndex && showLedger?.ledgerIndex === index;
      showLedger?.show && showLedger?.id === list.id;
    // console.log(showLedger?.data?.order_id);

    return (
      <>
        {false ? (
          <tr {...props} className="shimmer-effect highlight" />
        ) : (
          <tr
            {...props}
            // id={`tx-pay-item-${index}-${list?.data-item-index}`}
            id="tx-pay-row"
            className={`border-b bg-white ${matchedView ? "matchView" : ""} 
            ${
              showLedger?.index === props?.[`data-item-index`] &&
              showLedger?.ledgerIndex === index
                ? "matchView"
                : ""
            } 
            `}
            //   className={`border-b ${
            //     showLedger?.index === tIndex && showLedger?.ledgerIndex === index
            //       ? `bg-violet-50`
            //       : ""
            //   } ${
            //     transaction?.id === hoveredId && !matchedView
            //       ? "bg-indigo-500 bg-opacity-[7%]"
            //       : ""
            //   }`}
            //   onMouseEnter={() => {
            //     setHoveredId(transaction.id);
            //   }}
            // onMouseLeave={() => setHoveredId(null)}
          />
        )}
      </>
    );
  }
);

export default TableRow;
