/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect, useContext } from "react";
import { KTSVG } from "../KTSVG";
import { AddInventoryContext } from "../../../pages/add_inventory/core/AddInventoryProvider";

const CollapseButton = ({ eventLength, list }: any) => {
  const layout = useContext(AddInventoryContext);
  const [collapse, setCollapse] = useState(true);
  // let eventLength = events &&  events.length > 0

  const handleCollapseAll = () => {
    setCollapse(!collapse);
  };
  useEffect(() => {
    let isButtonExpanded = false;
    const accordions: any = document.querySelectorAll(
      ".add-inventory-accordion .acc-click"
    );
    accordions.forEach((accordion: any) => {
      if (accordion.getAttribute("aria-expanded") === "true") {
        isButtonExpanded = true;
      }
    });
    setCollapse(isButtonExpanded ? true : false);
  }, [layout.isOpen, list]);

  return (
    <button
      type="button"
      className={`inline-flex group items-center hover:text-white bg-gray-100 
    hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
      eventLength ? "pointer-events-none text-gray-400" : "text-violet-800"
    }`}
      data-te-target={`.bottom_acc${collapse ? `[data-te-collapse-show]` : ""}`}
      data-te-collapse-init
      data-te-ripple-init
      data-te-ripple-color="light"
      onClick={() => handleCollapseAll()}
      id="collpaseBtn"
    >
      <KTSVG
        className={`${
          eventLength ? "fill-gray-400" : "fill-violet-500 "
        } min-w-[0.625rem] max-w-[0.63rem] mr-2 flex items-center justify-center  group-hover:fill-white 
      transition-all duration-300 ${!collapse && `rotate-180`}`}
        path="standard_icons/Collapse.svg"
        svgClassName="w-[.5625rem] h-[.5625rem]"
      />
      {collapse ? "Collapse all" : "Expand all"}
    </button>
  );
};

export default CollapseButton;
