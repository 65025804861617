import { size } from "lodash";
import { useContext, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { errorReflect } from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import {
  HTTP_METHODS,
  INITIAL_CONNECTOR_VIEW,
  INITIAL_HEADERS,
  QUERIES,
} from "../../../../helpers/const";
import LeftPanel from "../../component/LeftPanel";
import { ConnectorContext } from "../../core/ConnectorProvider";
import { getVariable, triggersSaveAPI } from "../../core/requests";
import TriggersDataTable from "../../table/TriggersDataTable";
import Authentication from "../Authentication";
import Triggers from "../Triggers";
import Variables from "../Variables";
import ResponseScreen from "./ResponseScreen";

let initialTriggredView = {
  status: false,
  data: null,
};

const ConfigurationTab = ({ data }: any) => {
  const layout = useContext(ConnectorContext);
  const [triggerState, setTriggerState] = useState<any>(initialTriggredView);
  const sideTabOption = layout.sideOption;
  const state: any = layout?.state;
  const headers: any = layout?.headers;
  const { id } = data?.data;

  // GET VARIABLES IF EXISTS
  useQuery([QUERIES.GET_VARIABLES_DATA, sideTabOption], () => getVariable(id), {
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
    retry: false,
    enabled: sideTabOption !== "Variables" ? true : false,
    onError: (err) => {},
    onSettled(data, error) {
      if (data?.data && size(data?.data) > 0) {
        layout.setVariableData(data?.data);
      }
    },
  });

  // UPDATE TRIGGER CONTENT
  const updateTriggerContent = () => {
    if (triggerState?.data && triggerState?.status) {
      const headers = triggerState?.data?.headers
        ? Object.keys(triggerState.data.headers).map((key) => ({
            name: key,
            value: triggerState.data.headers[key],
          }))
        : [];

      layout.setHeaders(headers);
      layout.setState((state: any) => {
        return {
          ...state,
          name: triggerState.data.name,
          url: triggerState.data.endpoint_url,
          body:
            Array.isArray(triggerState.data.body) &&
            size(triggerState.data.body) === 0
              ? ""
              : typeof (triggerState.data.body === "object")
              ? JSON.stringify(triggerState.data.body)
              : triggerState.data.body
              ? triggerState.data.body
              : "",
          headers: headers.length > 0 ? headers : state.headers,
          triggerId: triggerState.data.id,
          authRequired: !!triggerState.data.auth_required,
          httpMethod: HTTP_METHODS?.find(
            (method) => method.name === triggerState.data.request_type
          ),
        };
      });
    }
  };

  // EFFICIENT COMPONENT LOAD
  const renderContent = useMemo(() => {
    layout.setState(INITIAL_CONNECTOR_VIEW);
    layout.setHeaders([INITIAL_HEADERS]);

    switch (sideTabOption) {
      case "Variables":
        setTriggerState(initialTriggredView);
        return <Variables data={data?.data} />;

      case "Authentication":
        setTriggerState(initialTriggredView);
        return <Authentication data={data?.data} />;

      case "Triggers":
        updateTriggerContent();

        return triggerState.status ? (
          <Triggers
            data={triggerState.data}
            sellerData={data?.data}
            handleClose={() => {
              setTriggerState(initialTriggredView);
            }}
          />
        ) : (
          <TriggersDataTable
            triggerState={triggerState}
            setTriggerState={setTriggerState}
            data={data.data}
          />
        );

      default:
        return null;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sideTabOption, triggerState]);

  // SAVE DATA
  const handleSubmit = (e: any) => {
    layout.setLoader(true);
    e?.preventDefault();
    triggersSaveAPI({
      ...state,
      id,
      headers,
      mapping: layout.mapping,
      tab: sideTabOption,
    }).then((response: any) => {
      layout.setLoader(false);
      if (!response?.data?.status) {
        errorReflect(response);
      } else if (response?.data?.status) {
        showAlert(response?.message, false);
        layout.setState((current: any) => {
          return {
            ...current,
            triggerId: response?.data?.id,
          };
        });
      }
    });
  };
  return (
    <div className="bg-white min-h-[calc(100vh-10.25rem)] max-h-[calc(100vh-10.25rem)] content-block flex relative before:content before:absolute before:top-0 before:left-0 before:w-full before:h-16 before:-z-[1]  before:bg-gradient-to-b before:to-gray-650">
      <LeftPanel />

      <div className="tab-content relative flex-1 w-full flex-wrap flex-col lg2:w-8/12 block overflow-auto max-h-[calc(100vh-7.75rem)] scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
        {/* ALL TAB SCREENS */}
        {renderContent}

        {/* RESPONSE SCREEN */}
        {(sideTabOption === "Authentication" ||
          (sideTabOption === "Triggers" && triggerState.status)) && (
          <ResponseScreen handleSubmit={handleSubmit} />
        )}
      </div>
    </div>
  );
};

export default ConfigurationTab;
