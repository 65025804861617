import clearSVG from "../../../../../assets/media/icons/other_icons/Clear.svg";
import { default as Process } from "../../../../../assets/media/icons/other_icons/process.svg";
import angleLeftSVG from "../../../../../assets/media/icons/standard_icons/angle_left.svg";
import angleRightSVG from "../../../../../assets/media/icons/standard_icons/angle_right.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";

import { memo, useState } from "react";
import { Document, Page } from "react-pdf";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";

const PDFViewer = memo(
  ({ PDFView, setPDFView, setChangeRequestToggle, isRightSidePopup }: any) => {
    const [error, setError] = useState("null");
    const [renderedPageNumber, setRenderedPageNumber] = useState(null);

    function onDocumentLoadSuccess({ numPages }: any) {
      setPDFView((current: any) => {
        return {
          ...current,
          page: numPages,
          pageNumber: 1,
        };
      });
    }
    const onDocumentLoadError = (error: any) => {
      setError("There was an error loading the PDF document.");
    };

    const isLoading = renderedPageNumber !== PDFView?.pageNumber;

    return (
      <div
        className={`transition lg2:border-r w-full  lg2:h-full  ${
          isRightSidePopup
            ? ""
            : "lg2:w-1/2 max-lg2:absolute max-lg2:top-0 max-lg2:right-0 max-lg2:bg-white max-lg2:z-50 max-lg2:h-full"
        } p-4 md:p-5 flex items-center !justify-start flex-col scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full`}
      >
        <div className="border rounded w-full">
          <div className="flex w-full border-b min-h-[2.5rem]">
            <div className="flex items-center justify-between w-full px-4 py-1.5">
              <div className="text-xxs md:text-xs">{PDFView?.fileName}</div>
              {PDFView?.page > 1 && (
                <div className="flex">
                  <button
                    className={`group rounded-l  hover: transition px-[.5625rem] py-2 ${
                      PDFView?.pageNumber === 1 && "pointer-events-none"
                    }`}
                    type="button"
                    onClick={(data: any) =>
                      setPDFView((count: any) => {
                        return {
                          ...count,
                          pageNumber:
                            count.pageNumber !== 1 && count.pageNumber - 1,
                        };
                      })
                    }
                  >
                    <IKTSVG
                      className={`flex items-center justify-center  transition group-hover:fill-indigo-500 ${
                        PDFView?.pageNumber === 1
                          ? "fill-gray-400"
                          : "fill-violet-500"
                      }`}
                      path={angleLeftSVG}
                    />
                  </button>
                  <button
                    className={`group rounded-r  transition px-[.5625rem] py-2 ${
                      PDFView?.page === PDFView?.pageNumber &&
                      "pointer-events-none"
                    }`}
                    type="button"
                    onClick={(data: any) =>
                      setPDFView((count: any) => {
                        return {
                          ...count,
                          pageNumber:
                            count.pageNumber !== PDFView?.page &&
                            count.pageNumber + 1,
                        };
                      })
                    }
                  >
                    <IKTSVG
                      className={`flex items-center justify-center  transition group-hover:fill-indigo-500 ${
                        PDFView?.page === PDFView?.pageNumber
                          ? "fill-gray-400"
                          : "fill-violet-500"
                      }`}
                      path={angleRightSVG}
                    />
                  </button>
                </div>
              )}
            </div>
            <button
              className="flex items-center justify-center border-l w-11 group"
              type="button"
              data-tooltip-id="pdf-close-account"
              onClick={(data: any) => {
                setPDFView({
                  fileName: "",
                  file: "",
                  page: 1,
                  pageNumber: 1,
                });
                setChangeRequestToggle((current: any) => {
                  return {
                    ...current,
                    ...(current?.data ? { show: true } : { show: false }),
                  };
                });
              }}
            >
              <TooltipPortal
                id={`pdf-close-account`}
                content={"Close"}
                className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
              />
              <IKTSVG
                svgClassName="h-4 w-4"
                className="flex items-center justify-center fill-violet-800 group-hover:fill-indigo-500 transition"
                path={clearSVG}
              />
            </button>
          </div>
          <div className="uploaded-file-preview overflow-hidden">
            {/* PDF Document  */}
            <Document
              file={PDFView?.file}
              onLoadSuccess={onDocumentLoadSuccess}
              loading={
                <IKTSVG
                  className="flex items-center justify-center mr-2 mt-5 fill-violet-500 transition animate-spin"
                  path={Process}
                />
              }
              onLoadError={onDocumentLoadError}
              error={
                PDFView?.file?.path?.split(".").pop() === "pdf" ? (
                  error ? (
                    <div className="text-sm13 font-medium text-center p-2 bg-white rounded w-full">
                      {/* {PDFFailedLoad} */}
                      {error}
                    </div>
                  ) : (
                    ""
                  )
                ) : (
                  <img src={PDFView?.file} loading="eager" alt="img" />
                )
              }
            >
              {isLoading && renderedPageNumber ? (
                <Page
                  key={renderedPageNumber}
                  pageNumber={renderedPageNumber}
                />
              ) : null}
              <Page
                className={`${isLoading ? "loadingPage" : ""}`}
                key={PDFView?.pageNumber}
                pageNumber={PDFView?.pageNumber}
                onRenderSuccess={() =>
                  setRenderedPageNumber(PDFView?.pageNumber)
                }
              />

              {/* <Page
                pageNumber={PDFView?.pageNumber}
                pageIndex={PDFView?.pageNumber}
              /> */}
            </Document>
          </div>
          {PDFView?.file?.split(".").pop() === "pdf" && (
            <div className="ml-auto p-2 w-8 text-xs text-gray-400">
              {PDFView?.pageNumber}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default PDFViewer;
