import { useContext } from "react";
import {
  TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER,
  TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER,
  TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER,
} from "../../../../../helpers/const";
import Tab from "../../component/Tab";
import { WalletAndBankDetailsContext } from "../../core/WalletAndBankDetailsProvider";

const Tabs = () => {
  const layout = useContext(WalletAndBankDetailsContext);
  return (
    <div className="tab-panel bg-no-repeat bg-tradepattern bg-cover bg-indigo-500 bg-opacity-[15%] p-5 pb-0 flex after:content after:absolute after:bottom-0 after:left-0 after:w-full after:h-2 after:z-0 after:from-black/0 after:bg-gradient-to-b after:to-black/5 relative overflow-x-auto flex-nowrap overflow-hidden">
      <Tab
        title="Seller wallets"
        handleTab={() => {
          layout.setTabActive("Seller wallets");
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
          layout.setWithdrawRequestFilter(
            TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
          );
          layout.setIsSelectAll({
            status: "",
          });
        }}
      />
      <Tab
        title="Bank accounts"
        handleTab={() => {
          layout.setTabActive("Bank accounts");
          layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
          layout.setWithdrawRequestFilter(
            TX_ACCOUNT_WITHDRAW_REQUEST_INTIAL_FILTER
          );
          layout.setIsSelectAll({
            status: "",
          });
        }}
      />
      <Tab
        title="Withdraw requests"
        handleTab={() => {
          layout.setTabActive("Withdraw requests");
          layout.setSellerWalletsFilter(TX_ACCOUNT_SELLER_WALLET_INTIAL_FILTER);
          layout.setBankAccountFilter(TX_ACCOUNT_BANK_ACCOUNTS_INTIAL_FILTER);
          layout.setIsSelectAll({
            status: "",
          });
        }}
      />
    </div>
  );
};

export default Tabs;
