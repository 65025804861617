/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import close from "../../../assets/media/icons/other_icons/Clear.svg";
import filter from "../../../assets/media/icons/other_icons/filter.svg";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import DropDownField from "../../../components/formComponent/DropDownField";
import MultiSelect from "../../../components/formComponent/MultiSelect";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import { KTSVG } from "../../../components/ui/KTSVG";
import { addCommaInNumber } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import useDebounce from "../../add_inventory/core/useDebounce";
import { TXContext } from "../core/TXProvider";
import { algoliaSearch } from "../core/requests";

const TXFilters = ({
  options,
  query,
  handleQuery,
  debouncedSearch,
  paginateData,
  handleKeyDown,
  teamsOptions,
  isGodAdmin,
  txPayLoading,
  shimmerLoader,
}: any) => {
  const layout = useContext(TXContext);
  let inputRef = useRef<any>();
  const [algoliaOptions, setAlgoliaOptions] = useState<any>();
  const [inputChange, setInputChange] = useState<any>();
  const algoliaDebounce = useDebounce(inputChange, 1200);
  const txPayFiter = layout.TXPayFilterHandle;
  // // RESET ALGOLIA OPTIONS
  // useEffect(() => {
  //   if (_.size(layout.TXPayFilterHandle?.event_id) === 0) {
  //     // setAlgoliaOptions([]);
  //   }
  // }, [layout.TXPayFilterHandle?.event_id]);

  const { isLoading, data } = useQuery(
    [`${QUERIES.ALGOLIA_TxPAY}`, algoliaDebounce, txPayFiter],
    () => {
      if (inputChange && inputChange.trim() !== "") {
        return algoliaSearch({ inputChange, txPayFiter });
      }
      return Promise.resolve(); // Return an empty resolved promise when algoliaDebounce is falsy or an empty string
    },
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: true,
      enabled: algoliaDebounce ? true : false,
      onError: (err) => {},
    }
  );

  // LOAD ALGOLIA OPTIONS IN STATE
  useEffect(() => {
    if (data?.hits) {
      setAlgoliaOptions((current: any) => {
        return current?.length > 0
          ? _.uniqBy([...current, ...data?.hits], "id")
          : data?.hits;
      });
    }
  }, [data]);

  // ON CLEAR BUTTON - ALGOLIA
  const onSearch = (data: any) => {
    if (data?.target?.id !== "search") {
      setInputChange(undefined);
      setAlgoliaOptions([]);
    }
  };

  let algoliaData = data;

  // HANDLE INPUT CHANGE - ALGOLIA
  const handleInputChange = (data: any, event: any) => {
    if (
      event.action === "menu-close" ||
      event.action === "set-value" ||
      event.action === "input-blur"
    ) {
      if (!data) {
        setInputChange((current: any) => {
          return current;
        });
      }
    } else {
      setInputChange((current: any) => {
        if (algoliaDebounce !== data) {
          setAlgoliaOptions([]);
        }
        return data;
      });
    }
  };

  // HANDLE REFRESH
  const handleRefresh = () => {
    setAlgoliaOptions([]);
    handleQuery("");
    setInputChange(undefined);
    layout.setTXPayFilterHandle((currentVal: any) => {
      return {
        ...currentVal,
        transaction_date: "",
        transaction_type: "",
        team_id: "",
        event_id: null,
        query: "",
      };
    });
  };
  // Function to get index of dragged Filter item
  const getFilterIndex = (id: any) => {
    let index = layout.draggedItems.findIndex((item: any) => item.id === id);
    return index + 1;
  };

  const getFilterIndexForCheckbox = (id: any) => {
    let index = layout.filterItems.findIndex((item: any) => item.id === id);
    return index;
  };

  function ToggleElemClose() {
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.remove("activeFIlter");
    document.body.classList.remove("overflow-hidden");
  }
  function ToggleElemOpen() {
    console.log("object");
    const submenuWrapper: any = document.querySelector("#filtersMob");
    submenuWrapper.classList.add("activeFIlter");
    document.body.classList.add("overflow-hidden");
  }
  const clearDisable =
    (query !== undefined && debouncedSearch) ||
    layout.TXPayFilterHandle?.transaction_type ||
    layout.TXPayFilterHandle?.transaction_date ||
    layout.TXPayFilterHandle?.team_id;

  const handleEventSearch = (): void => {
    const isMobileView = window.matchMedia("(max-width: 768px)").matches;
    if (inputChange && isMobileView) {
      handleQuery(inputChange);
    }
  };
  return (
    <>
      <div className="filtersMob flex flex-col" id={"filtersMob"}>
        <div
          className="flex flex-wrap md:border-b border-t gap-y-2.5 py-4 px-[.9375rem] bg-white max-md:pt-14 max-md:flex-1 max-md:overflow-auto max-md:content-start"
          id={`filters_block`}
        >
          <div
            className="closePopup hidden max-md:flex"
            id="closePopup"
            onClick={ToggleElemClose}
          >
            <IKTSVG
              path={close}
              className="w-10 absolute top-[1px] right-0 h-10 flex items-center justify-center cursor-pointer z-[1] bg-white"
              svgClassName="w-4 h-4"
            />
          </div>
          <div className="w-full lg2:w-1/3 lg:w-1/2 xl:w-[25%] px-[.313rem]">
            <div
              className={`relative flex-1 max-w-full md:mb-0 ${
                txPayLoading && "shimmer-effect rounded"
              } `}
            >
              {/* <div className="absolute inset-y-0 left-0 flex items-center pl-3">
              <button type="button">
                <IKTSVG
                  className="fill-violet-500"
                  svgClassName="h-3.5 w-3.5"
                  path={SearchSVG}
                />
              </button>
            </div> */}

              <MultiSelect
                name="query"
                options={algoliaOptions}
                isLoading={isLoading}
                isMulti={false}
                handleOnChange={(data: any) => {
                  let eventName = data?.target?.value?.name;
                  handleQuery(eventName);
                  setInputChange(eventName);
                }}
                // onMenuScrollToBottom={(data: any) => {
                //   layout.setTXPayFilterHandle((currentValue: any) => {
                //     let currentPage = currentValue?.algoliaPage + 1;
                //     if (algoliaData?.nbPages - 1 >= currentPage) {
                //       return {
                //         ...currentValue,
                //         ...(currentValue?.algoliaPage
                //           ? { algoliaPage: currentValue?.algoliaPage + 1 }
                //           : { algoliaPage: 1 }),
                //       };
                //     } else {
                //       return { ...currentValue };
                //     }
                //   });
                // }}
                handleKeyDown={(event: any) => {
                  handleKeyDown(event);
                }}
                value={layout.TXPayFilterHandle?.event_id}
                inputValue={inputChange}
                handleInputChange={(data: any, event: any) => {
                  handleInputChange(data, event);
                }}
                onSearch={(data: any) => onSearch(data)}
                classNamePrefix="inventory_select"
                className="searchbar-wrap"
                placeholder="Search event or order ID or PR ID"
              />
            </div>
          </div>

          {/* TRANSACTION DATE */}
          {layout.filterItems[`${getFilterIndexForCheckbox(1)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] order-${getFilterIndex(
                1
              )}  `}
              id="txpayTransactionDate"
            >
              <div className={`${txPayLoading && "shimmer-effect rounded"} `}>
                <DatePickerField
                  name="transaction_date"
                  placeholder="Transaction date"
                  disabledKeyboardNavigation={false}
                  value={layout.TXPayFilterHandle.transaction_date}
                  handleOnChange={(data: any) =>
                    layout.setTXPayFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                  isDateRange={true}
                  format="dd/MM/yy"
                />
              </div>
            </div>
          )}

          {/* TRANSACTION TYPE */}
          {layout.filterItems[`${getFilterIndexForCheckbox(2)}`]?.checked && (
            <div
              className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] order-${getFilterIndex(
                2
              )} `}
              id="txpayTransactionType"
            >
              <div className={`${txPayLoading && "shimmer-effect rounded"} `}>
                <DropDownField
                  options={options?.map((item: any) => {
                    return { id: item.id, name: item.name };
                  })}
                  placeholder="Transaction type"
                  name="transaction_type"
                  value={layout.TXPayFilterHandle.transaction_type}
                  handleOnChange={(data: any) =>
                    layout.setTXPayFilterHandle((currentValue: any) => {
                      return {
                        ...currentValue,
                        [data?.target?.name]: data?.target?.value,
                      };
                    })
                  }
                />
              </div>
            </div>
          )}

          {/* Team */}
          {isGodAdmin &&
            layout.filterItems[`${getFilterIndexForCheckbox(3)}`]?.checked && (
              <div
                className={`w-full sm:w-1/2 lg2:w-1/6 lg:w-1/4 xl:w-[12.5%] px-[.313rem] order-${getFilterIndex(
                  3
                )} `}
                id="txpayTeam"
              >
                <div className={`${txPayLoading && "shimmer-effect rounded"} `}>
                  <DropDownField
                    isCapital={true}
                    isValueCapital={true}
                    options={teamsOptions}
                    placeholder={
                      layout.TXPayFilterHandle.team_id ? "Teams" : "All Teams"
                    }
                    isClearable={true}
                    name="team_id"
                    handleOnChange={(data: any) => {
                      layout.setTXPayFilterHandle((currentValue: any) => {
                        return {
                          ...currentValue,
                          [data?.target?.name]: data?.target?.value,
                        };
                      });
                    }}
                    value={layout.TXPayFilterHandle.team_id}
                    label="Teams"
                  />
                </div>
              </div>
            )}
        </div>

        <div className="bottomBtns hidden max-md:flex sticky bottom-0 left-0 w-full px-5 py-2.5 shadow-3xl">
          <button
            className={`border  hover:border-indigo-500  hover:text-white  hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5 ${
              clearDisable
                ? "bg-white text-violet-800 border-gray-300"
                : "bg-gray-100 text-gray-400 pointer-events-none border-gray-100"
            }`}
            onClick={handleRefresh}
          >
            Clear
          </button>

          <button
            className="border border-violet-500 hover:border-indigo-500 text-white bg-violet-500 hover:bg-indigo-500 transition rounded text-sm13 py-0.5 px-2.5 mr-2.5 my-0.5"
            onClick={() => {
              ToggleElemClose();
              handleEventSearch();
            }}
          >
            Apply
          </button>
        </div>
      </div>
      <div className="flex flex-wrap customStyle relative bg-white max-sm:border-t max-sm:justify-between">
        <div className="py-4 px-5 font-semibold text-sm13 sm:border-r text-center w-full sm:w-auto flex items-center max-sm:w-[calc(100%-9rem)]">
          {txPayLoading ? (
            <div className="shimmer-effect inline-block ">
              <span className="opacity-0">
                {paginateData?.total > 0
                  ? `${paginateData?.total} transactions`
                  : "0 transaction"}
              </span>
            </div>
          ) : (
            <>
              {paginateData?.total > 0
                ? `${addCommaInNumber(paginateData?.total)} transactions`
                : "0 transaction"}
            </>
          )}
        </div>
        {((query !== undefined && debouncedSearch) ||
          layout.TXPayFilterHandle?.query ||
          layout.TXPayFilterHandle?.transaction_type ||
          layout.TXPayFilterHandle?.transaction_date ||
          layout.TXPayFilterHandle?.team_id) && (
          <div className="flex items-center justify-center sm:justify-start px-5 py-3 gap-x-2.5 flex-1 filterChipArea overflow-auto flex-nowrap whitespace-nowrap scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full md:min-h-[3.375rem] max-sm:flex-1 max-sm:order-3 max-sm:border-t max-sm:justify-start">
            {((query !== undefined && debouncedSearch) ||
              layout.TXPayFilterHandle?.transaction_type ||
              layout.TXPayFilterHandle?.transaction_date ||
              layout.TXPayFilterHandle?.team_id) &&
              txPayLoading && (
                <div className="flex gap-5">
                  <div
                    className={`flex !rounded overflow-hidden ${
                      txPayLoading && "shimmer-effect"
                    }`}
                    style={{ height: "1.625rem", width: "9.375rem" }}
                  >
                    &nbsp;
                  </div>
                </div>
              )}
            {layout.TXPayFilterHandle?.query && (
              <div
                className={`${
                  query
                    ? "flex py-1 px-2.5 text-xs border rounded-full"
                    : "hidden"
                } ${txPayLoading && "hidden"}`}
              >
                {layout.TXPayFilterHandle?.query}
                <button
                  type="button"
                  className="ml-2"
                  onClick={() => {
                    // inputRef.current.value = "";
                    handleQuery("");
                    setInputChange(undefined);
                    setAlgoliaOptions([]);
                    layout?.setTXPayFilterHandle((curr: any) => {
                      return {
                        ...curr,
                        query: "",
                      };
                    });
                  }}
                >
                  <KTSVG
                    path="standard_icons/cross.svg"
                    svgClassName="h-2 w-2 hover:fill-indigo-500"
                  />
                </button>
              </div>
            )}

            {/* TEAM */}
            {layout.TXPayFilterHandle?.team_id && (
              <div
                className={`flex py-1 px-2.5 text-xs border rounded-full ${
                  txPayLoading && "hidden"
                }`}
              >
                {layout.TXPayFilterHandle?.team_id?.name}
                <button
                  type="button"
                  className="ml-2"
                  onClick={() =>
                    layout?.setTXPayFilterHandle((current: any) => {
                      return {
                        ...current,
                        team_id: "",
                      };
                    })
                  }
                >
                  <KTSVG
                    path="standard_icons/cross.svg"
                    svgClassName="h-2 w-2 hover:fill-indigo-500"
                  />
                </button>
              </div>
            )}
            {/* TRANSACTION DATE*/}
            {layout.TXPayFilterHandle?.transaction_date && (
              <div
                className={`flex py-1 px-2.5 text-xs border rounded-full ${
                  txPayLoading && "hidden"
                }`}
              >
                {moment(
                  layout.TXPayFilterHandle?.transaction_date?.startDate
                ).format("DD/MM/YYYY")}{" "}
                -{" "}
                {moment(
                  layout.TXPayFilterHandle?.transaction_date?.endDate
                ).format("DD/MM/YYYY")}
                <button
                  type="button"
                  className="ml-2"
                  onClick={() =>
                    layout?.setTXPayFilterHandle((current: any) => {
                      return {
                        ...current,
                        transaction_date: "",
                      };
                    })
                  }
                >
                  <KTSVG
                    path="standard_icons/cross.svg"
                    svgClassName="h-2 w-2 hover:fill-indigo-500"
                  />
                </button>
              </div>
            )}

            {/* TRANSACTION TYPE*/}
            {/* VENUE */}
            {layout.TXPayFilterHandle?.transaction_type && (
              <div
                className={`flex py-1 px-2.5 text-xs border rounded-full ${
                  txPayLoading && "hidden"
                }`}
              >
                {layout.TXPayFilterHandle?.transaction_type?.name}
                <button
                  type="button"
                  className="ml-2"
                  onClick={() => {
                    layout?.setTXPayFilterHandle((current: any) => {
                      return {
                        ...current,
                        transaction_type: "",
                      };
                    });
                  }}
                >
                  <KTSVG
                    path="standard_icons/cross.svg"
                    svgClassName="h-2 w-2 hover:fill-indigo-500"
                  />
                </button>
              </div>
            )}

            {((query !== undefined && debouncedSearch) ||
              layout.TXPayFilterHandle?.transaction_type ||
              layout.TXPayFilterHandle?.transaction_date ||
              layout.TXPayFilterHandle?.team_id) && (
              <button
                type="button"
                className={`max-md:flex-none group border text-center bg-white rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition ${
                  txPayLoading && "hidden"
                }`}
                onClick={() => {
                  handleRefresh();
                }}
                title="Reset"
              >
                <KTSVG
                  path="other_icons/refresh.svg"
                  className="fill-violet-500 group-hover:fill-white transition"
                  svgClassName="w-3 h-3 m-auto"
                />
              </button>
            )}
          </div>
        )}
        {/* <Pagination paginateData={paginateData} /> */}
        <div
          className="filterBtn max-md:flex max-md:items-center hidden p-2.5 text-sm12 border-l max-sm:order-2 max-sm:max-w-[9rem]"
          id="filterBtn"
          onClick={ToggleElemOpen}
        >
          <button className="group inline-flex group items-center text-white hover:text-white bg-violet-500  hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5">
            <IKTSVG
              path={filter}
              className="fill-white mr-1.5"
              svgClassName="w-4 h-4"
            />
            Filter results
          </button>
        </div>
      </div>
    </>
  );
};

export default TXFilters;
