/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useRef, useState } from "react";
import Select, { ControlProps, NoticeProps, components } from "react-select";
// import AsyncSelect from "react-select/async";
import crossIcon from "../../assets/media/icons/standard_icons/SearchR.svg";
import searchIcon from "../../assets/media/icons/standard_icons/search-violet.svg";

import { Spinner } from "assets/media/icons/other_icons/Spinner";
import Calender from "assets/media/icons/standard_icons/Calender";
import ClockOutline from "assets/media/icons/standard_icons/ClockOutline";
import GeoIcon from "assets/media/icons/standard_icons/GeoIcon";
import ComponentSVG from "components/ui/typography/ComponentSVG";
import React from "react";
import SVG from "react-inlinesvg";
import { isMobileView } from "../../helpers/Functions";
import { MULTI_SELECT_INPUT } from "../../helpers/const";
import {
  convertDate,
  convertTime,
} from "../../pages/add_inventory/core/_functions";

// STYLING
const customStyle = {
  control: (base: any, state: any) => {
    return {
      ...base,
      display: "flex",
      flexDirection: "row-reverse",
      border: state.isFocused
        ? "2px solid #6f6cff"
        : state.isDisabled
        ? "none"
        : "2px solid lightgrey",
      minHeight: "1px",
      backgroundColor: state.isFocused
        ? "#E9E9FF"
        : state.isDisabled
        ? "#F2F2F2"
        : "white",
      height: "2.5rem",
      padding: "1px 8px",
      borderWidth: "2px",
      borderRadius: "4px",
      boxShadow: "none",
      "&:hover": {
        border: "1px solid",
        borderColor: "#6f6cff ",
        borderWidth: "2px",
      },
    };
  },
  container: (provided: any, state: any) => {
    return {
      ...provided,

      // marginTop: 50,
      // width:
      //   state.selectProps.name === "upcomingEvents" && state.isFocused
      //     ? "100%"
      //     : state.selectProps.name === "tableSearchField"
      //     ? "100%"
      //     : "70%",
      // transition: "all 0.3s ease",
    };
  },
  option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
    return {
      // ...base,
      // padding:'6px',
      // paddingLeft:'8px',
      // paddingRight:'8px'
      // backgroundColor: isFocused ? "#E9E9FF" : "white",
      // color: isFocused ? "black" : "black",
      // "&:hover": {
      //   color: "black",
      // },
    };
  },
  valueContainer: (provided: any, state: any) => {
    return {
      ...provided,
      overflow: "visible",
    };
  },
  menuPortal: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
  placeholder: (base: any) => {
    if (isMobileView) {
      return {
        ...base,
        pointerEvents: "none",
        userSelect: "none",
        MozUserSelect: "none",
        WebkitUserSelect: "none",
        msUserSelect: "none",
      };
    } else {
      return base;
    }
  },
  input: (base: any) => {
    if (isMobileView) {
      return {
        ...base,
        ...MULTI_SELECT_INPUT,
      };
    } else {
      return { ...base, ...MULTI_SELECT_INPUT };
    }
  },
};

// CONTROL - COMPONENT
const Control = ({ children, ...props }: ControlProps<any, false>) => {
  // @ts-ignore
  const { onButtonClick } = props.selectProps;

  return (
    <components.Control {...props} className="pl-2">
      <button
        className="search_btn"
        type="button"
        onClick={(e: any) =>
          props.selectProps.inputValue ? onButtonClick(e) : null
        }
        onTouchStart={(e: any) =>
          props.selectProps.inputValue ? onButtonClick(e) : null
        }
        onMouseDown={(e: any) =>
          !props.selectProps.inputValue && onButtonClick(e)
        }
      >
        <SVG
          className="w-3.5"
          id={props.selectProps.inputValue ? "cross" : "search"}
          src={props.selectProps.inputValue ? crossIcon : searchIcon}
        />
      </button>
      {children}
    </components.Control>
  );
};

// LOADING MESSAGE COMPONENT
const LoadingMessage = () => {
  return (
    <React.Fragment>
      <ComponentSVG>
        <Spinner className="fill-indigo-500 h-4 w-4 center mb-4" />
      </ComponentSVG>
    </React.Fragment>
  );
};

// OPTIONS COMPONENT
const InputOption = ({
  getStyles,
  Icon,
  isDisabled,
  isFocused,
  isSelected,
  children,
  innerProps,
  ...rest
}: any) => {
  const [isActive, setIsActive] = useState(false);
  const onMouseDown = () => setIsActive(true);
  const onMouseUp = () => setIsActive(false);
  const onMouseLeave = () => setIsActive(false);

  // prop assignment
  const props = {
    ...innerProps,
    onMouseDown,
    onMouseUp,
    onMouseLeave,
  };

  return (
    <components.Option
      {...rest}
      isDisabled={isDisabled}
      isFocused={isFocused}
      isSelected={isSelected}
      getStyles={getStyles}
      innerProps={props}
    >
      <ul className="p-0 list-none">
        <li>
          <span
            className={`${
              isSelected && "checked"
            } search__item mb-2.5 flex flex-wrap m-1 border rounded-sm duration-150 cursor-pointer`}
          >
            <div className="flex flex-wrap w-full">
              <div
                className={`flex flex-wrap flex-1 max-w-${
                  rest.isMulti ? "[calc(100%-1.875rem)]" : "100%"
                }`}
              >
                <span className="search__item__title px-2 py-1 text-xs first:w-full first:border-r-0 first:border-b border-r">
                  {rest.data.name}
                </span>
                <span className="inline-flex items-center px-2 py-1 text-xs border-r text-xxs">
                  <ComponentSVG className="pr-2">
                    <Calender />
                  </ComponentSVG>
                  <span className="">
                    {rest.data.event_date_local
                      ? convertDate(rest.data.event_date_local)
                      : "N/A"}{" "}
                  </span>
                </span>
                <span className="inline-flex items-center px-2 py-1 text-xs border-r text-xxs">
                  <ComponentSVG className="pr-2">
                    <ClockOutline />
                  </ComponentSVG>
                  <span className="">
                    {rest.data.event_date_local
                      ? convertTime(rest.data.event_date_local)
                      : "N/A"}
                  </span>
                </span>
                <span className="inline-flex items-center flex-1 px-2 py-1 text-xs border-r-0 text-xxs ellips-text max-w-[7.875rem]">
                  <ComponentSVG className="pr-2">
                    <GeoIcon />
                  </ComponentSVG>
                  <span className="ellips-text">
                    <p title={rest.data.venue_name}>{rest.data.venue_name}</p>
                  </span>
                </span>
              </div>
              {rest?.isMulti && (
                <div className="w-[1.875rem] flex items-center justify-center border-l flex-[0_0_30px]">
                  <input
                    type="checkbox"
                    checked={isSelected}
                    readOnly
                    className="w-3 h-3 text-violet-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0   dark:bg-gray-700 dark:border-gray-600 peer form-check-input checked:bg-indigo-500 transition  cursor-pointer border checked:border-indigo-500 hover:border-indigo-500"
                  />
                </div>
              )}
            </div>
          </span>
        </li>
      </ul>
    </components.Option>
  );
};

// MENU LIST - COMPONENT
const MenuList = (props: any, notice: NoticeProps) => {
  const { onButtonClick } = props.selectProps;
  return (
    <components.MenuList
      {...props}
      className="scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full"
    >
      {/* <div className="rounded-b-sm mb-2.5">
        <span className="text-xs pl-1 font-semibold">Your events</span>
      </div> */}
      {props.children}
    </components.MenuList>
  );
};

const MultiSelect = React.memo(
  ({
    name,
    placeholder,
    options,
    handleOnChange,
    value,
    className,
    classNamePrefix,
    handleInputChange,
    inputValue,
    onSearch,
    handleKeyDown,
    isLoading,
    onMenuScrollToBottom,
    isMulti = true,
    isMenuPortalTarget = undefined,
  }: any) => {
    const selectRef: any = useRef();

    // SEARCH ON CLICK - BUTTON
    const onButtonClick = (e: any) => {
      onSearch(e);
    };

    const [isMenuOpen, setIsMenuOpen] = useState<any>(false);
    const [algoliaOptions, setAlgoliaOptions] = useState<any>();
    useEffect(() => {
      if (options && options?.length > 0) {
        setAlgoliaOptions(options);
      } else {
        setAlgoliaOptions([]);
      }
    }, [options, inputValue]);

    const getData = useCallback(() => {
      return options;
    }, [options]);

    const [menuPlacement, setMenuPlacement] = useState<any>("auto");

    useEffect(() => {
      const adjustMenuPlacement = () => {
        const selectElement = selectRef.current?.controlRef; // Accessing the DOM element here
        if (selectElement) {
          const boundingRect = selectElement.getBoundingClientRect();
          const spaceAbove = boundingRect.top;
          const spaceBelow = window.innerHeight - boundingRect.bottom;

          if (spaceBelow < 200 && spaceAbove > spaceBelow) {
            setMenuPlacement("top");
          } else {
            setMenuPlacement("bottom");
          }
        }
      };

      adjustMenuPlacement();
      window.addEventListener("resize", adjustMenuPlacement);

      return () => {
        window.removeEventListener("resize", adjustMenuPlacement);
      };
    }, []);

    return (
      <>
        <Select
          // @ts-ignore
          onButtonClick={(e: any) => onButtonClick(e)}
          components={{
            Control,
            MenuList,
            Option: InputOption,
            LoadingMessage,
          }}
          ref={selectRef}
          name={name}
          placeholder={placeholder}
          options={getData() !== false && getData()}
          // options={algoliaOptions && algoliaOptions?.length > 0 && algoliaOptions}
          // @ts-ignore
          isMulti={isMulti}
          value={value !== null ? value : value === null ? null : undefined}
          inputValue={inputValue}
          classNamePrefix={classNamePrefix}
          className={className}
          isLoading={isLoading}
          onChange={(selectedOption: any, e: any) => {
            let event = { target: { name: name, value: selectedOption } };
            handleOnChange(event, e);
            !isMulti && setIsMenuOpen(false);
          }}
          onFocus={() => setIsMenuOpen(true)}
          onBlur={() => setIsMenuOpen(false)}
          menuIsOpen={!isMulti ? isMenuOpen : undefined}
          noOptionsMessage={() =>
            (!algoliaOptions || algoliaOptions?.length === 0) && null
          }
          onKeyDown={(event: any) => {
            handleKeyDown(event);
          }}
          onInputChange={handleInputChange}
          onMenuScrollToBottom={() => onMenuScrollToBottom(true)}
          getOptionLabel={(option: any) => option.name}
          getOptionValue={(option: any) => option.id}
          isSearchable
          closeMenuOnSelect={false}
          isClearable={true}
          // menuIsOpen={true}
          hideSelectedOptions={false}
          controlShouldRenderValue={false}
          menuShouldScrollIntoView={false}
          // menuPlacement="top"
          menuPlacement={menuPlacement}
          styles={customStyle}
          filterOption={null}
          tabSelectsValue={false}
          isMenuPortalTarget={document.body}
          menuPosition="absolute"
        />
      </>
    );
  }
);

export default MultiSelect;
