import PageTitle from "../../../components/core/PageTitle";
import { PersonalDetailsProvider } from "./core/PersonalDetailsProvider";
import PersonalDetails from "./PersonalDetails";

const PersonalDetailsWrapper = () => {
  return (
    <PersonalDetailsProvider>
      <PageTitle title="Personal details" />
      <PersonalDetails />
    </PersonalDetailsProvider>
  );
};

export default PersonalDetailsWrapper;
