import { memo, useContext } from "react";
import restrictionIcon from "../../../../../assets/media/icons/other_icons/benefits_restrictions.svg";
import handCreoss from "../../../../../assets/media/icons/other_icons/cross-hand-icon.svg";
import handIcon from "../../../../../assets/media/icons/other_icons/hand-icon.svg";

import EticketSVG from "../../../../../assets/media/icons/other_icons/pin.svg";
import replaceIcon from "../../../../../assets/media/icons/other_icons/replaceIcon.svg";
import PaperSVG from "../../../../../assets/media/icons/standard_icons/ticket-1.svg";
import MobileSVG from "../../../../../assets/media/icons/standard_icons/ticket-2.svg";

import Upload from "../../../../../assets/media/icons/other_icons/Upload.svg";
import { IKTSVG } from "../../../../../components/ui/IKTSVG";
import { SYMBOLS, TICKET_TYPE_MAP } from "../../../../../helpers/const";
import {
  decimalNumber,
  ticketTypeRenameForSalesReport,
} from "../../../../../helpers/Functions";
import { benefitsRestrictions } from "../../../../tx_trade/helper/Assist";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import { SalesContext } from "../../../core/SalesProvider";
import DataCell from "../../../table/component/DataCell";

const ItemContentReplacement = memo(({ index, list, setListing }: any) => {
  const layout = useContext(SalesContext);

  let allValues: any[] | undefined;

  if (list?.restrictions_benefits) {
    const { options, benefits } = list.restrictions_benefits;
    allValues = [...(options || []), ...(benefits || [])];
  }

  let benefitRestrictionContnet = benefitsRestrictions(allValues, 0, 0);

  return (
    <>
      <DataCell
        padding="pl-5"
        value={list?.zone_label}
        classname="block"
        width="w-[9.875rem]"
      />
      <DataCell
        // padding="pl-5"
        value={list?.section_label}
        classname="block"
        width="w-[9.875rem]"
      />
      <DataCell
        // padding="pl-5"
        value={list?.row}
        classname="block"
        width="w-[3rem]"
      />
      <DataCell
        // padding="pl-5"
        value={list?.first_seat}
        classname="block"
        width="w-[3rem]"
      />
      <DataCell
        // padding="pl-5"
        value={ticketTypeRenameForSalesReport(
          list?.ticket_type,
          list?.sub_ticket_type
        )}
        classname="block"
        width="w-[6.125rem]"
      />
      <DataCell
        // padding="pl-5"
        value={list?.quantity_available}
        classname="block"
        width="w-[3rem]"
      />
      <DataCell
        // padding="pl-5"
        value={`${SYMBOLS[list?.sell_price_currency]}${decimalNumber(
          list?.sell_price
        )}`}
        classname="block"
        width="w-[6.125rem]"
      />
      <DataCell
        // padding="pl-5"
        value={list?.split_type}
        classname="block"
        width="w-[6.125rem]"
      />
      <DataCell
        // padding="pl-5"
        value={list?.quantity_display}
        classname="block"
        width="w-[6.125rem]"
      />
      <DataCell
        // padding="pl-5"
        value={list?.team_name + " - " + list?.broker_name}
        classname="block"
        // width="w-[7.75rem]"
      />

      <td className={`md:sticky relative right-0 ml-auto p-0 icon_td`}>
        <div>
          <div
            className="flex left-shad relative w-full h-full border-l"
            id="left-shad-list"
          >
            {list?.ticker_uploaded === "yes" && (
              <button className="absolute top-1/2 -translate-y-1/2 right-full text-white text-xxs bg-violet-500 px-[.4375rem] py-1 leading-3 rounded-l whitespace-nowrap cursor-default">
                Tickets uploaded
              </button>
            )}
            {/* VIEW ORDER */}
            {/* <button
              className={`group my-buttonflex items-center justify-center p-1 md:p-1 w-8 md:w-10 md:flex-auto flex-1 `}
              type="button"
              id={`record-view-tooltip${index}`}
              onClick={() =>
                layout.setOrderAssign((current: any) => {
                  return {
                    ...current,
                    backToOrderListingStatus: true,
                    replacementList: list,
                  };
                })
              }
            >
              <IKTSVG
                className={`flex items-center justify-center 
                ${matchedView ? "fill-indigo-500" : "fill-violet-500"} ${
                  matchedView
                    ? "group-hover:fill-indigo-500"
                    : "group-hover:fill-indigo-500"
                } transition`}
                svgClassName="w-4 h-[.6875rem]"
                path={EyeSVG}
              />
            </button> */}
            <div className="flex items-center justify-between w-full px-3 min-w-[3.6875rem]">
              {/* TICKET TYPE */}
              <button
                type="button"
                data-tooltip-id={`ticket-type-${index}`}
                className="flex items-center justify-center group"
              >
                <TooltipPortal
                  id={`ticket-type-${index}`}
                  content={ticketTypeRenameForSalesReport(
                    list?.ticket_type,
                    list?.sub_ticket_type
                  )}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-100 "
                />
                <IKTSVG
                  path={
                    TICKET_TYPE_MAP[list?.ticket_type] === "E-ticket"
                      ? EticketSVG
                      : TICKET_TYPE_MAP[list?.ticket_type] === "Paper"
                      ? PaperSVG
                      : MobileSVG
                  }
                  svgClassName="w-[.6875rem] h-3.5 fill-violet-500 group-hover:fill-indigo-500"
                />
              </button>

              {/* TICKET IN HAND */}
              <button
                type="button"
                data-tooltip-id={`ticket-in-hand-${index}`}
                className="flex items-center justify-center group"
              >
                <TooltipPortal
                  id={`ticket-in-hand-${index}`}
                  content={
                    list?.ticker_uploaded === "yes"
                      ? "Ticket uploaded"
                      : list?.in_hand === "yes"
                      ? "Ticket in hand"
                      : ""
                  }
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-100 "
                />
                <IKTSVG
                  path={
                    list?.ticker_uploaded === "yes"
                      ? Upload
                      : list?.in_hand === "yes"
                      ? handIcon
                      : handCreoss
                  }
                  svgClassName={` ${
                    list?.ticker_uploaded === "yes"
                      ? "w-3.5 h-3 "
                      : (list?.in_hand === "yes" || list?.in_hand === "no") &&
                        "w-[.6875rem] h-3.5 "
                  } fill-violet-500 group-hover:fill-indigo-500`}
                />
              </button>

              {/* RESTRICTION - ICON */}
              <button
                type="button"
                data-tooltip-id={`ticket-type-Restriction-${index}`}
                className="flex items-center justify-center  group"
              >
                <TooltipPortal
                  id={`ticket-type-Restriction-${index}`}
                  content={
                    benefitRestrictionContnet
                      ? benefitRestrictionContnet
                      : "Unavailable benefits and restrictions"
                  }
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-100"
                />
                <IKTSVG
                  path={restrictionIcon}
                  svgClassName="w-[.9375rem] h-3 fill-violet-500 group-hover:fill-indigo-500"
                />
              </button>
            </div>

            {/* REPALCE BUTTON */}
            <div className="flex items-center border-r  border-l ml-auto justify-center px-1.5 min-w-[2.5rem]">
              <button
                type="button"
                data-tooltip-id={`replace-icon-${index}`}
                className="flex items-center justify-center p-1 md:p-1 group"
                onClick={() =>
                  layout.setOrderAssign((current: any) => {
                    return {
                      ...current,
                      backToOrderListingStatus: true,
                      replacementList: list,
                    };
                  })
                }
              >
                <TooltipPortal
                  id={`replace-icon-${index}`}
                  content={"Replacement order"}
                  place="top"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-100 mt-2.5"
                />
                <IKTSVG
                  path={replaceIcon}
                  svgClassName="w-4 h-3.5 fill-violet-500 group-hover:fill-indigo-500"
                />
              </button>
            </div>
          </div>
        </div>
      </td>
    </>
  );
});

export default ItemContentReplacement;
