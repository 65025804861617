/* eslint-disable @typescript-eslint/no-unused-vars */
import { useFormik } from "formik";
import moment from "moment";
import { useRef, useState } from "react";
import * as Yup from "yup";
import DatePickerField from "../../../components/formComponent/DatePickerField";
import TextInput from "../../../components/formComponent/TextInput";
import TimePicker from "../../../components/formComponent/TimePicker";
import { KTSVG } from "../../../components/ui/KTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { createObjectArrayWithKeys } from "../../../helpers/AssetHelpers";
import Messages from "../../../helpers/Messages";
import { showAlert } from "../../../helpers/ShowAlert";
import { v2EngineFeatureAPI } from "../../settings/core/_request";

const MyTextField = ({
  name,
  value,
  handleOnChange,
  placeholder,
  isIcon = false,
  children,
}: any) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <div
        className={`mb-3 w-full  text-xs relative group ${
          focus && `dp-focused`
        }`}
      >
        {isIcon && (
          <span className="table-date-icon h-[1.9375rem] absolute left-0 top-0 flex items-center justify-center fill-violet-500 rounded-l border-r border-gray-300 w-8  pointer-events-none group-[.dp-focused]:bg-indigo-500 group-[.dp-focused]:z-10 group-[.dp-focused]:!fill-white group-[.dp-focused]:border-indigo-500">
            <KTSVG svgClassName="h-3 w-3" path="standard_icons/geo_icon.svg" />
          </span>
        )}
        <input
          name={name}
          value={value}
          placeholder={placeholder}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          className={`block px-2.5 ${
            isIcon && "pl-10"
          } font-medium w-full  h-8 text-xs transition bg-transparent border border-gray-300 appearance-none rounded focus:outline-none focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 cursor-pointer`}
          onChange={(e: any) =>
            handleOnChange({ name: e.target.name, value: e.target.value })
          }
        />
        {/* ERROR FIELDS */}
        {children}
      </div>
    </>
  );
};

// VALIDATION SCHEMA
const SignupSchema = Yup.object().shape({
  event_name: Yup.string().required("Event name is required"),
  event_location: Yup.string().required("Event location is required"),
  event_date: Yup.string().required("Event date is required"),
  event_time: Yup.string().required("Event time is required"),
  apiError: Yup.string(),
});

const RequestEvent = (props: any) => {
  const ref = useRef<any>("");
  const [loader, setLoader] = useState(false);

  // INITIAL VALUES
  let initialValues = {
    event_name: "",
    event_location: "",
    event_date: "",
    event_time: "",
    apiError: "",
  };

  // FORMIk
  const formik = useFormik({
    initialValues,
    validationSchema: SignupSchema,
    onSubmit: (values: any) => {
      setLoader(true);
      const skipKeys = ["apiError"];
      let date = moment(values?.event_date).format("DD/MM/YYYY");
      let time = moment(values?.event_time).format("HH:mm");
      let tempValue = {
        section: "Inventory",
        request_data: [
          ...createObjectArrayWithKeys(
            { ...values, event_date: date, event_time: time },
            skipKeys
          ),
        ],
      };

      v2EngineFeatureAPI(tempValue).then((response: any) => {
        if (response?.data?.status) {
          ref.current.click();
          setLoader(false);
          showAlert(response?.data?.message, false);
          if (ref?.current) {
            ref?.current?.click();
          }
        } else {
          setLoader(false);
          showAlert(
            Array.isArray(response?.errors)
              ? response?.errors.join("<br/>")
              : Messages?.someWrong,
            true
          );
        }
      });
    },
  });

  return (
    <>
      <div
        className="offcanvas offcanvas-end border-l fixed bottom-0 flex flex-col invisible bg-clip-padding outline-none transition duration-300 ease-in-out text-gray-700 top-[3.75rem] right-0 ml-20 md:ml-0   shadow-lg       w-full upload-ticket-popup z-40"
        tabIndex={-1}
        id="offcanvasRight"
        aria-labelledby="offcanvasRightLabel"
        data-bs-backdrop="false"
      >
        <div className="h-full md:max-w-[43.125rem] ml-auto">
          <div className="  bg-white shadow-3xl w-full h-full ml-auto relative flex flex-col">
            <div className="offcanvas-header flex items-center justify-between border-t border-b">
              <h5
                className="offcanvas-title mb-0 leading-normal font-semibold py-2 md:px-5 px-[.9375rem]"
                id="offcanvasRightLabel"
              >
                Request an event
              </h5>
              <button
                type="button"
                className="flex items-center justify-center h-10 w-10 border-l box-content"
                data-bs-dismiss="offcanvas"
                aria-label="Close"
                ref={ref}
                onClick={() => formik.resetForm()}
              >
                <span className="btn-close w-3 h-3 text-black"></span>
              </button>
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="flex flex-wrap h-full shadow-md"
            >
              <div className="offcanvas-body h-[27.5rem] md:pt-[1.875rem] md:p-[1.875rem] p-[.9375rem] flex flex-col overflow-x-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
                <div className="new-inp">
                  <p className="text-sm13 font-medium mb-5 text-gray-600">
                    If your event is not listed please first ensure it is
                    supported by one of your connected marketplaces, you can
                    then request the event to be added to Tixstock.
                  </p>
                  <div className="flex flex-wrap sm:flex-nowrap gap-0 sm:gap-5 justify-between sm:mb-3 new-inp req-icon-block">
                    {/* EVENT NAME */}
                    <div className="mb-3 w-full  text-xs date-cus relative">
                      <TextInput
                        name="event_name"
                        type="text"
                        value={formik.values.event_name}
                        required={false}
                        errorClass={
                          ((formik.touched.event_name &&
                            formik.errors.event_name) ||
                            formik?.errors?.apiError) &&
                          "border-rose-500"
                        }
                        labelErrorClass={
                          formik.touched.event_name &&
                          formik.errors.event_name &&
                          "text-rose-500 "
                        }
                        apiError={formik.errors?.apiError && "!text-rose-500"}
                        label="Event name"
                        id="event_name"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("event_name", "")
                        }
                      />
                      {formik.touched.event_name &&
                        formik.errors.event_name && (
                          <div className="text-xxs text-rose-500 italic error-msg left-2">
                            {formik.errors.event_name as string}
                          </div>
                        )}
                    </div>
                    {/* EVENT LOCATION */}
                    <div className=" mb-3 w-full  text-xs date-cus relative">
                      <TextInput
                        name="event_location"
                        type="text"
                        value={formik.values.event_location}
                        required={false}
                        errorClass={
                          ((formik.touched.event_location &&
                            formik.errors.event_location) ||
                            formik?.errors?.apiError) &&
                          "border-rose-500"
                        }
                        labelErrorClass={
                          formik.touched.event_location &&
                          formik.errors.event_location &&
                          "text-rose-500 "
                        }
                        width="!w-10"
                        isIcon={true}
                        apiError={formik.errors?.apiError && "!text-rose-500"}
                        label="Event location"
                        id="event_location"
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data?.target?.name,
                            data?.target?.value
                          )
                        }
                        handleClearValue={() =>
                          formik.setFieldValue("event_location", "")
                        }
                      />
                      {formik.touched.event_location &&
                        formik.errors.event_location && (
                          <div className="text-xxs text-rose-500 italic error-msg left-12">
                            {formik.errors.event_location as string}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="flex flex-wrap sm:flex-nowrap gap-0 sm:gap-5 justify-between new-inp req-icon-block relative">
                    {/* EVENT DATE */}
                    <div className="mb-3 w-full  text-xs date-cus relative">
                      <DatePickerField
                        name="event_date"
                        isDateRange={false}
                        placeholder="Event date"
                        disabledKeyboardNavigation={false}
                        height="!h-10"
                        width="!w-10"
                        value={formik.values.event_date}
                        handleOnChange={(data: any) =>
                          formik.setFieldValue(
                            data.target.name,
                            data.target.value
                          )
                        }
                      />
                      {formik.touched.event_date &&
                        formik.errors.event_date && (
                          <div className="text-xxs text-rose-500 italic error-msg left-12">
                            {formik.errors.event_date as string}
                          </div>
                        )}
                    </div>

                    {/* EVENT TIME */}
                    <div className="date-cus w-full relative">
                      <TimePicker
                        name="event_time"
                        value={formik.values.event_time}
                        placeholder="Event time"
                        height="!h-10"
                        width="!w-10"
                        disabledKeyboardNavigation={false}
                        handleOnChange={(data: any) =>
                          formik.setFieldValue("event_time", data.value)
                        }
                      >
                        {formik.touched.event_time &&
                          formik.errors.event_time && (
                            <div className="text-xxs text-rose-500 italic error-msg left-12">
                              {formik.errors.event_time as string}
                            </div>
                          )}
                      </TimePicker>
                    </div>
                  </div>
                </div>
              </div>
              <div className="border-t text-right mt-auto py-3 px-5 w-full inline-flex justify-start text-xs">
                <button
                  type="button"
                  className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
            bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 mr-2"
                  data-bs-dismiss="offcanvas"
                  aria-label="Close"
                  onClick={() => formik.resetForm()}
                >
                  Cancel
                </button>

                <button
                  type="submit"
                  className=" transition p-1 border border-green-500 bg-green-500 text-white hover:bg-white hover:text-green-500 rounded"
                >
                  Submit request
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      {loader && <ProgressBardLoader secondCounter={5} />}
    </>
  );
};

export default RequestEvent;
