const ReportCardSub = ({
  title,
  reportsCardData,
  buttonText = "",
  handleOnButtonClick,
}: any) => {
  return (
    <div className="flex justify-between items-start outline outline-1 outline-gray-200 rounded max-sm:w-auto max-sm:flex-[0_0_auto] max-sm:min-w-[50%] sm:w-1/2 px-4 py-2.5">
      <div>
        <h3 className="text-2xl  md:text-boxtitle md:leading-9">
          {reportsCardData}
        </h3>
        <p className="text-xs font-medium text-gray-500 py-1">{title}</p>
      </div>
      {buttonText && reportsCardData !== 0 && (
        <button
          type="button"
          className={`${
            buttonText === "Details required" ? "bg-rose-500" : "bg-violet-500"
          }  text-white text-xxs font-medium px-2 py-0.5 rounded hover:bg-indigo-500 ml-3`}
          onClick={handleOnButtonClick}
        >
          {buttonText}
        </button>
      )}
    </div>
  );
};

export default ReportCardSub;
