import { useContext, useRef } from "react";
import DeleteSVG from "../../../../assets/media/icons/other_icons/Delete.svg";
import crossIcon from "../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { ConnectorContext } from "../../core/ConnectorProvider";

const ViewMapping = ({ handleShow }: any) => {
  const layout = useContext(ConnectorContext);

  const handleRemoveMapping = (key: any) => {
    layout.setMapping((mapping: any) => {
      const oldMapping = { ...mapping };

      delete oldMapping[key];
      return oldMapping;
    });
  };

  const canvasRef: any = useRef();

  return (
    <div
      data-te-modal-init
      className={`show popupinput-style2 upload-ticket-popup  offcanvas s_ticket offcanvas-end fixed bottom-0 top-[3.75rem] right-0 shadow-3xl bg-clip-padding outline-none transition duration-300 ease-in-out font-medium z-60 md:max-w-[43.125rem] w-full`}
      tabIndex={-1}
      id="singleTicket"
      ref={canvasRef}
      aria-labelledby="singleTicketLabel"
    >
      <div className="bg-white border-t w-full h-full ml-auto flex flex-col">
        <div className="flex justify-between pl-5 items-center border-b">
          <div>
            <span className="md:text-sm15 text-sm12 block font-semibold">
              {" "}
              Mappings
            </span>
          </div>
          <div className="flex flex-wrap">
            <button
              type="button"
              className="group relative mail flex flex-auto items-center justify-center p-1 md:p-1 w-7 md:w-10 h-[2.562rem] border-l"
              // data-bs-dismiss="offcanvas"
              aria-label="Close"
              onClick={() => {
                handleShow({
                  show: false,
                  data: "",
                });
                // display(false);
              }}
            >
              <IKTSVG
                className="flex items-center justify-center fill-violet-500 group-hover:fill-indigo-500 transition"
                path={crossIcon}
                svgClassName="w-2.5 h-2.5"
              />
            </button>
          </div>
        </div>
        <div className="h-full p-4 flex flex-col gap-4 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
          {layout.mapping && Object.keys(layout.mapping)?.length > 0 ? (
            Object.keys(layout.mapping)?.map((key: any, index: any) => {
              return (
                <div className="flex gap-2">
                  <p className="flex-1 bg-violet-80 px-2 py-0.5 rounded-md relative text-sm14">
                    {key}
                  </p>
                  <p className="flex-1 bg-violet-50 px-2 py-0.5 rounded-md  text-sm14">
                    {layout.mapping[key]}
                  </p>
                  <button
                    type="button"
                    data-tooltip-id={`delete-${index}`}
                    className={`mr-2 border group inline-flex group items-center font-medium rounded text-sm13 px-3 py-0.5`}
                    onClick={() => handleRemoveMapping(key)}
                  >
                    <TooltipPortal
                      id={`delete-${index}`}
                      content="Delete"
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium text-xs z-[9999]"
                    />
                    <IKTSVG
                      className="w-3 h-[.9375rem] flex items-center justify-center fill-violet-500 hover:fill-indigo-500 rounded-full cursor-pointer"
                      path={DeleteSVG}
                      svgClassName="w-2.5 h-[.9375rem]"
                    />
                  </button>
                </div>
              );
            })
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <p>No mappings yet!</p>
            </div>
          )}
        </div>

        {/* NAVIGATION BUTTONS */}
        {/* <div
          className={` bottom-0 right-0 w-full flex ${"justify-between"} px-5 py-3 bg-white z-10 border-t min-h-[3.1875rem]`}
        >
          <div className="btns w-full flex flex-wrap items-center justify-between tx-pay-module gap-3.5">
            <button
              type="button"
              className="btn text-sm13 font-medium py-1 px-2.5"
              onClick={() => {
                handleShow({
                  show: false,
                  data: "",
                });
                //   display(false);
              }}
            >
              Cancel
            </button>

            <button
              // type="button"
              type="submit"
              className={`text-sm13 font-medium py-1 px-2.5 bg-green-600 hover:bg-indigo-500 !text-white rounded border-hidden	 hover:border-hidden	 flex`}
              onClick={() => {}}
            >
              
              Update
            </button>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default ViewMapping;
