import { size } from "lodash";
import moment from "moment";
import axios from "../../../axois/SetupAxios";
import {
  REACT_APP_ALGOLIA_API_KEY,
  REACT_APP_ALGOLIA_APPLICATION_ID,
} from "../../../helpers/const";
const algoliasearch = require("algoliasearch");

// TX PAY LISTING
const getTxPayListing = (data: any): Promise<any> => {
  let transaction_date = data?.filters?.transaction_date;
  let transaction_type = data?.filters?.transaction_type;
  let query = data?.filters?.query;
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let export_to_csv = data?.export_to_csv;
  let id = data?.id;
  let team_id = data?.filters?.team_id?.id;

  let payload = {
    ...data.pagination,
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(transaction_date && {
      from_transaction_date: moment(transaction_date?.startDate).format(
        "YYYY-MM-DD"
      ),
      to_transaction_date: moment(transaction_date?.endDate).format(
        "YYYY-MM-DD"
      ),
    }),
    ...(transaction_type && { transaction_type: transaction_type.id }),
    ...(data?.debouncedSearch && { query: data?.debouncedSearch }),
    ...(export_to_csv && {
      export_to_csv: 1,
      page: undefined,
      per_page: undefined,
    }),
    ...(id && { id: id }),
    ...(team_id && { team_id }),
    ...(query && { query }),
  };
  return axios
    .post(`tx-pay/transactions`, payload)
    .then((response) => response.data);
};

// TX PAY Overview
const getTxPayOverviewData = (data: any): Promise<any> => {
  let transaction_date = data?.filters?.transaction_date;
  let transaction_type = data?.filters?.transaction_type;
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let export_to_csv = data?.export_to_csv;
  let id = data?.id;
  let team_id = data?.filters?.team_id?.id;

  let payload = {
    ...data.pagination,
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(transaction_date && {
      transaction_date: moment(transaction_date).format("YYYY-MM-DD"),
    }),
    ...(transaction_type && { transaction_type: transaction_type.id }),
    ...(data?.debouncedSearch && { query: data?.debouncedSearch }),
    ...(export_to_csv && { export_to_csv: 1 }),
    ...(id && { id: id }),
    ...(team_id && { team_id }),
  };
  return axios.post(`tx-pay/counts`, payload).then((response) => response.data);
};

// WITHDRAW FUNDS API
const withdrawFunds = (data: any): Promise<any> => {
  let payload = {
    amount: data?.amount,
    date: moment(data?.date).format("YYYY-MM-DD"),
  };
  return axios
    .post(`tx-pay/withdraw-funds`, payload)
    .then((response) => response.data);
};

// ALGOLIA
const algoliaSearch = (data: any): Promise<any> => {
  let query = data?.inputChange;
  let payload = {
    ...(data?.salesFilter?.algoliaPage && {
      page: data?.salesFilter?.algoliaPage,
    }),
  };

  // Connect and authenticate with your Algolia app
  const client = algoliasearch(
    REACT_APP_ALGOLIA_APPLICATION_ID,
    REACT_APP_ALGOLIA_API_KEY
  );

  // SEARCH WITH EVENTS
  const index = client.initIndex("events");

  // EXECUTE THE API WITH PAYLOAD
  return index.search(query, payload).then((response: any) => {
    return response;
  });
};

// CANCEL WITHDRAWL REQUEST
const cancelWithdrawlRequest = async (data: any): Promise<any> => {
  const response = await axios.get(`tx-pay/cancel-withdraw-request`, {
    ...(size(data) > 0 && { params: { withdraw_request_id: data } }),
  });
  return response.data;
};

// VIEW DETAIL
const txPayDetailsView = (data: any): Promise<any> => {
  let payload = {
    ...(data?.id && { id: data?.id }),
  };
  return axios
    .get(`tx-pay/view`, { params: payload })
    .then((response) => response.data);
};

const getTrasnferFundsData = (data: any): Promise<any> => {
  let params = {
    ...(data && { account_id: data }),
  };
  return axios
    .get(`tx-pay/transfer`, {
      params: params,
    })
    .then((response) => response.data);
};

const postTrasnferFundsData = (data: any): Promise<any> => {
  return axios.post(`tx-pay/transfer`, data).then((response) => response.data);
};

export {
  algoliaSearch,
  cancelWithdrawlRequest,
  getTrasnferFundsData,
  getTxPayListing,
  getTxPayOverviewData,
  postTrasnferFundsData,
  txPayDetailsView,
  withdrawFunds,
};
