import _, { find, includes, isArray, size } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Tooltip } from "react-tooltip";
import PPPIcon from "../../../../assets/media/icons/other_icons/PPPIcon.svg";
import Upload from "../../../../assets/media/icons/other_icons/Upload.svg";
import Check from "../../../../assets/media/icons/other_icons/check.svg";
import handCross from "../../../../assets/media/icons/other_icons/cross-hand-icon.svg";
import handIcon from "../../../../assets/media/icons/other_icons/hand-icon.svg";
import infoIcon from "../../../../assets/media/icons/other_icons/info.svg";
import Refresh from "../../../../assets/media/icons/other_icons/refresh.svg";
import Cross from "../../../../assets/media/icons/standard_icons/cross.svg";

import { checkPPEExistForTableLevelNEw } from "helpers/AssetHelpers";
import Warning1 from "../../../../assets/media/icons/standard_icons/warning1.svg";
import { LayoutContext } from "../../../../components/core/LayoutProvider";
import FormDatePickerField from "../../../../components/formComponent/FormDatePickerField";
import PPEComponent from "../../../../components/formComponent/PPEComponent";
import PriceField from "../../../../components/formComponent/PriceField";
import TableDropdown from "../../../../components/tableComponent/TableDropdown";
import TableMultiDropdown from "../../../../components/tableComponent/TableMultiDropdown";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import {
  addCommaInNumber,
  addSelectAll,
  colorForMeter,
  decimalNumber,
  handleKeyDown,
  handleShift,
  isValidPriceFieldNumber,
  priceCalculation,
  removeCommaFromNumber,
  removeSelectAll,
  resetPPEFields,
  tooltipForListingMeter,
  uniqueByNameWithPriority,
  updatePPEPartners,
} from "../../../../helpers/Functions";
import { deliveryTypeOrders, isMobile } from "../../../../helpers/const";
import { priceFieldInputKeyDown } from "../../../inventory/core/_functions";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { AddInventoryContext } from "../../core/AddInventoryProvider";
import {
  maxFileCondition,
  TicketError,
  visibilityMeterCalculationForKeyBoard,
  visiblityMeterForBelowListing,
} from "../../core/_functions";

import Messages from "helpers/Messages";
import useDebounce from "../../core/useDebounce";

const ItemContent = React.memo(
  ({
    event,
    eIndex,
    rIndex,
    tempEventRecord,
    // isTableEditable,
    target_currency,
    forRowDisabled,
    seletedDeliveryTypes,
    deliveryTypeExist,
    paperTypeExist,
    focusedRow,
    setPPE,
    resetPPEValues,
    handleBlur,
    handleFocus,
    firstSelectedIndex,
    handleEticket,
    unSetPPE,
    getDisplayPriceCurrency,
    handlePPETableChange,
    triggered,
    setTriggered,
    list,
    setList,
    setIsOpenListingQualityPopup,
    priceFieldToggle,
  }: any) => {
    const myIndex = firstSelectedIndex;
    const layout = useContext(AddInventoryContext);
    const coreLayout = useContext(LayoutContext);
    const isTablet = window.innerWidth < 1000;
    const [eventRecord, setEventRecord] = useState(tempEventRecord);
    const [updateChecker, setUpdateChecker] = useState<any>();
    const isTableEditable = layout.isTableEditable;
    const conversation_rate = coreLayout?.conversationRate;
    const priceFactor = Number(
      coreLayout?.allowedAccess?.price_suggestion_factor
    );
    let eventRecordPrice = removeCommaFromNumber(
      decimalNumber(eventRecord?.proceedValue?.value)
    );

    useEffect(() => {
      // console.log(tempEventRecord);
      setEventRecord(tempEventRecord);
    }, [tempEventRecord]);

    function calculateAvgPriceForProceedValueNew(
      sectionname: any,
      markplaceArry: any
    ) {
      let sectionValue =
        sectionname?.originalName?.trim()?.toLowerCase().split(" ").join("") ||
        sectionname?.name?.trim()?.toLowerCase().split(" ").join("");
      let markeplaceValueWithAvg: any =
        markplaceArry &&
        Object.entries(markplaceArry)?.map((item: any) => {
          return {
            id: item?.[0],
            name: item?.[0]?.trim()?.toLowerCase().split(" ").join(""),
            avgPrice: item?.[1]?.total?.price_avg,
          };
        });

      let avgPrice = markeplaceValueWithAvg?.find(
        (obj: any) => obj.name === sectionValue
      )?.avgPrice;

      let finalAvgRate = avgPrice ? avgPrice * conversation_rate : undefined;
      return finalAvgRate;
    }

    const MemoizedHandleTableFieldOld = useMemo(() => {
      return (data: any) => {
        let tempEvents = list;
        // If table is not edit-able
        if (isTableEditable.length === 0) {
          tempEvents[eIndex]["eventRecords"][rIndex][data.target.name] =
            data.target.value !== undefined && Array.isArray(data.target.value)
              ? data.target.value
              : typeof data.target.value === "object"
              ? data.target.value
              : typeof data.target.value === "string" &&
                data.target.type !== "checkbox"
              ? data.target.value
              : data.target.type === "checkbox"
              ? data.target.checked
              : data.target.value;
          if (data?.parentName === "ppePartners") {
            tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
              updatePPEPartners(
                tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                data?.id,
                data?.target?.value,
                "ppePartners",
                tempEvents[eIndex]["eventRecords"][rIndex]["proceedValue"]
              );
          }

          if (data.target.name === "proceedValue") {
            if (
              tempEvents[eIndex]["eventRecords"][rIndex]["ppe"] === 1 ||
              tempEvents[eIndex]["eventRecords"][rIndex]["ppe"] === true
            ) {
              tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
                updatePPEPartners(
                  tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                  null,
                  null,
                  "ppePartners",
                  data.target.value
                );
            } else {
              tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
                resetPPEFields(
                  tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                  data.target.value,
                  "ppePartners"
                );
            }
          }
          if (data?.target?.name === "ppe") {
            tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
              resetPPEFields(
                tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                tempEvents[eIndex]["eventRecords"][rIndex]["proceedValue"],
                "ppePartners"
              );
          }

          // ppe new code in common function 04-10
          if (
            !tempEvents[eIndex]["eventRecords"][rIndex]["ppe"] &&
            !isNaN(
              Number(
                removeCommaFromNumber(
                  tempEvents[eIndex]["eventRecords"][rIndex]["proceedValue"]
                    .value
                )
              )
            ) &&
            Number(
              removeCommaFromNumber(
                tempEvents[eIndex]["eventRecords"][rIndex]["proceedValue"].value
              )
            ) > 0
          ) {
            let ppeRecords =
              tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"];
            let proceedPrice =
              tempEvents[eIndex]["eventRecords"][rIndex]["proceedValue"];
            tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
              ppeRecords?.map((obj: any) => {
                return {
                  ...obj,
                  value: {
                    ...obj?.value,
                    symbol: proceedPrice?.symbol,
                    value: decimalNumber(proceedPrice?.value),
                  },
                };
              });
          }

          //for split type
          if (
            data.target.name === "splitType" &&
            data.target.value?.id !== "MULTIPLES"
          ) {
            tempEvents[eIndex]["eventRecords"][rIndex]["MULTIPLES"] = "";
          }

          //for the category change
          if (data.target.name === "category") {
            tempEvents[eIndex]["eventRecords"][rIndex]["section"] = null;
          }

          if (data.target.name === "section") {
            tempEvents[eIndex]["eventRecords"][rIndex]["avg_price"] =
              calculateAvgPriceForProceedValueNew(
                data?.target?.value,
                tempEvents[eIndex]["markeInsightsForAvgPrice"]
              );
          }

          if (data.target.name === "proceedValue") {
            tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"] =
              updatePPEPartners(
                tempEvents[eIndex]["eventRecords"][rIndex]["ppePartners"],
                null,
                null,
                "ppePartners",
                // tempEvents[eIndex]["eventRecords"][rIndex]["avg_price"]
                data.target.value
              );
          }

          // TICKETFILES EMPTY OBJECT ON QUNTITY CHANGE
          // if (data.target.name === "quantity") {
          //   const quantity =
          //     data?.target?.name === "quantity"
          //       ? parseInt(data.target.value, 10)
          //       : 0;
          //   tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
          //     quantity > 0 ? Array.from({ length: quantity }, () => ({})) : [];
          // }

          if (
            data?.target?.name === "ticketType" &&
            data.target.value !==
              tempEvents[eIndex]["eventRecords"][rIndex]["ticketType"]
          ) {
            let q = tempEvents[eIndex]["eventRecords"][rIndex]["quantity"];

            if (data.target?.name === "quantity") {
              q = data?.target?.value;
            }

            let ticketFiles: any = [];
            for (let i = 0; i < q; i++) {
              ticketFiles.push({});
            }
            tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
              ticketFiles;
          }

          if (data.target.name === "quantity") {
            const quantity = parseInt(data.target.value, 10) || 0; // Ensure quantity is a number, default to 0 if NaN
            const existingTicketFiles = tempEvents[eIndex]["eventRecords"][
              rIndex
            ]["ticketFiles"]?.filter(
              (obj: any) => JSON.stringify(obj) !== "{}"
            );

            if (existingTicketFiles) {
              // If ticketFiles already exists, add or remove elements based on quantity
              const currentCount = existingTicketFiles.length;
              const diff = quantity - currentCount;

              if (diff > 0) {
                // Add elements
                tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                  existingTicketFiles.concat(
                    Array.from({ length: maxFileCondition(diff) }, () => ({}))
                  );
              } else if (diff === 0) {
                tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                  existingTicketFiles?.filter(
                    (obj: any) => JSON.stringify(obj) !== "{}"
                  );
              }
              // else if (diff < 0) {
              //   console.log("2");
              //   // Remove elements
              //   tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
              //     existingTicketFiles.slice(0, quantity);
              // }
              // If diff is 0, no action needed as the count is already correct
            } else {
              // If ticketFiles doesn't exist, create a new array with the specified length
              tempEvents[eIndex]["eventRecords"][rIndex]["ticketFiles"] =
                quantity > 0
                  ? Array.from(
                      { length: maxFileCondition(quantity) },
                      () => ({})
                    )
                  : [];
            }
          }

          //for the eticket change
          if (data.target.name === "ticketType") {
            tempEvents[eIndex]["eventRecords"][rIndex]["sub_ticket_type"] =
              data.target.value?.subtypes?.[0];
            //for delivery type field
            if (data.target.value?.id !== "paper") {
              tempEvents[eIndex]["eventRecords"][rIndex]["deliveryType"] = [];
            }
          }

          if (data.target.name === "ticketsInHands" && data.target?.checked) {
            tempEvents[eIndex]["eventRecords"][rIndex]["dateToShip"] =
              new Date();
          }

          // VISIBILITY LOGIC
          // let visibility: number = 0;
          // let fieldCount: number = 8;

          // NEW VISIBILITY LOGIC
          let visibility: any = visibilityMeterCalculationForKeyBoard(
            tempEvents[eIndex],
            tempEvents[eIndex]["eventRecords"][rIndex]
          );

          const currentVisibility =
            tempEvents[eIndex]["eventRecords"][rIndex]["visibility"];
          const oldVisibility =
            currentVisibility === visibility
              ? tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"]
              : currentVisibility;

          tempEvents[eIndex]["eventRecords"][rIndex]["oldVisibility"] =
            oldVisibility;
          tempEvents[eIndex]["eventRecords"][rIndex]["visibility"] = visibility;

          setList(tempEvents);
          // If table is edit-able
        } else {
          // handleInputChangeDebounced(data);
          setList((events: any) => {
            return events.map((event: any, eventIndex: any) => {
              if (eventIndex === eIndex) {
                let eventRecords = Array.isArray(event["eventRecords"])
                  ? event["eventRecords"]?.map(
                      (eventRecord: any, recordIndex: any) => {
                        if (eventRecord.selected === true) {
                          let newArrDelivery =
                            eventRecord?.deliveryType?.reduce(
                              (count: any, item: any) => {
                                count[item.id] = item.type;
                                return count;
                              },
                              {}
                            );

                          layout.setTableDataEditChanging((pre: any[]) => {
                            const eventPre = pre[eIndex] || [];
                            const conditions: any = {
                              ...newArrDelivery,
                              sub_ticket_type: "Sub ticket type",
                            };
                            const newName =
                              conditions[data.target.name] || null;
                            let updatedEventPre = [...eventPre];

                            if (
                              newName &&
                              updatedEventPre.indexOf(newName) === -1 &&
                              data.target.name
                            ) {
                              updatedEventPre = [
                                ...updatedEventPre,
                                newName,
                                data.target.name,
                              ];
                            } else if (
                              !newName &&
                              updatedEventPre.indexOf(data.target.name) ===
                                -1 &&
                              data.target.name
                            ) {
                              const eventToAdd = data.target.name;

                              updatedEventPre = _.uniq([
                                ...updatedEventPre,
                                eventToAdd,
                              ]);
                            }

                            const newPre = [...pre];
                            newPre[eIndex] = updatedEventPre;
                            return newPre;
                          });

                          if (
                            data?.target?.name === "ticketType" &&
                            data.target.value !== eventRecord["ticketType"]
                          ) {
                            let q = eventRecord["quantity"];

                            if (data.target?.name === "quantity") {
                              q = data?.target?.value;
                            }

                            let ticketFiles: any = [];
                            for (let i = 0; i < q; i++) {
                              ticketFiles.push({});
                            }
                            eventRecord["ticketFiles"] = ticketFiles;
                          }

                          if (data.target.name === "quantity") {
                            const quantity =
                              parseInt(data.target.value, 10) || 0; // Ensure quantity is a number, default to 0 if NaN
                            const existingTicketFiles = eventRecord[
                              "ticketFiles"
                            ].filter(
                              (obj: any) => JSON.stringify(obj) !== "{}"
                            );

                            if (existingTicketFiles) {
                              // If ticketFiles already exists, add or remove elements based on quantity
                              const currentCount = existingTicketFiles.length;
                              const diff = quantity - currentCount;

                              if (diff > 0) {
                                // Add elements
                                eventRecord["ticketFiles"] =
                                  existingTicketFiles.concat(
                                    Array.from(
                                      { length: maxFileCondition(diff) },
                                      () => ({})
                                    )
                                  );
                              } else if (diff === 0) {
                                eventRecord["ticketFiles"] =
                                  existingTicketFiles?.filter(
                                    (obj: any) => JSON.stringify(obj) !== "{}"
                                  );
                              }
                              //  else if (diff < 0) {
                              //   // Remove elements
                              //   eventRecord["ticketFiles"] =
                              //     existingTicketFiles.slice(0, quantity);
                              // }
                              // If diff is 0, no action needed as the count is already correct
                            } else {
                              // If ticketFiles doesn't exist, create a new array with the specified length
                              eventRecord["ticketFiles"] =
                                quantity > 0
                                  ? Array.from(
                                      { length: maxFileCondition(quantity) },
                                      () => ({})
                                    )
                                  : [];
                            }
                          }

                          //for the category change
                          let section: any = {};
                          if (data.target.name === "category") {
                            section.section = "";
                          }

                          //for split type
                          let multipleData = eventRecord?.MULTIPLES;
                          if (
                            data.target.name === "splitType" &&
                            data.target.value?.id !== "MULTIPLES"
                          ) {
                            multipleData = "";
                          }

                          let final = {
                            ...eventRecord,
                            MULTIPLES: multipleData,
                            [data.target.name]:
                              data.target.value !== undefined &&
                              Array.isArray(data.target.value)
                                ? data.target.value
                                : typeof data.target.value === "object"
                                ? data.target.value
                                : typeof data.target.value === "string" &&
                                  data.target.type !== "checkbox"
                                ? data.target.value
                                : data.target.type === "checkbox"
                                ? data.target.checked
                                : data.target.value,

                            ...(data.target.name === "proceedValue" &&
                              (eventRecord["ppe"] === 1 ||
                                eventRecord["ppe"] === false) && {
                                ppePartners: updatePPEPartners(
                                  eventRecord["ppePartners"],
                                  null,
                                  null,
                                  "ppePartners",
                                  data.target.value
                                ),
                              }),

                            ...(data.target.name === "proceedValue" &&
                              (eventRecord["ppe"] === 0 ||
                                eventRecord["ppe"] === false) && {
                                ppePartners: resetPPEFields(
                                  eventRecord?.["ppePartners"],
                                  data.target.value,
                                  "ppePartners"
                                ),
                              }),

                            ...(data?.target?.name === "ppe" && {
                              ppePartners: resetPPEFields(
                                eventRecord?.["ppePartners"],
                                eventRecord.proceedValue,
                                "ppePartners"
                              ),
                            }),

                            ...(data.target.name === "ppe" &&
                              data.target.checked === true && {
                                ppeIsOpen: true,
                              }),

                            ...(data.target.name === "ppe" &&
                              recordIndex !== myIndex &&
                              data.target.checked === false && {
                                ppeIsOpen: false,
                              }),
                            ...section,
                          };
                          let visibility: any =
                            visibilityMeterCalculationForKeyBoard(event, final);
                          const avg_price = calculateAvgPriceForProceedValueNew(
                            final?.section,
                            event?.markeInsightsForAvgPrice
                          );

                          // ppe new code in common function 04-10
                          if (!final?.ppe && final?.proceedValue) {
                            let ppeRecords = final["ppePartners"];
                            final["ppePartners"] = ppeRecords?.map(
                              (obj: any) => {
                                return {
                                  ...obj,
                                  value: {
                                    ...obj?.value,
                                    symbol: final?.proceedValue?.symbol,
                                    value: decimalNumber(
                                      final?.proceedValue?.value
                                    ),
                                  },
                                };
                              }
                            );
                          }

                          return {
                            ...final,
                            ppePartners: updatePPEPartners(
                              final["ppePartners"],
                              data?.id,
                              data?.target?.value,
                              "ppePartners",
                              final?.proceedValue
                            ),
                            ...(final?.ticketsInHands
                              ? { dateToShip: new Date() }
                              : { dateToShip: final?.dateToShip }),
                            visibility: visibility && visibility,
                            oldVisibility:
                              visibility && final?.visibility === visibility
                                ? final?.oldVisibility
                                : visibility && final?.visibility,
                            avg_price: avg_price,
                          };
                        } else {
                          return {
                            ...eventRecord,
                          };
                        }
                      }
                    )
                  : [];
                // setEventRecord(eventRecords);

                let firstSelectedIndex = -1;

                let final = eventRecords?.map((record: any, index: any) => {
                  let newArrDelivery = eventRecord?.deliveryType?.reduce(
                    (count: any, item: any) => {
                      count[item.id] = item.type;
                      return count;
                    },
                    {}
                  );
                  if (record?.selected) {
                    // hide if any impact on other code
                    if (record.section?.id) {
                      const matchingId = record?.section?.id;
                      const matchingSections =
                        record.category?.sections?.filter(
                          (innerObj: any) => innerObj?.id === matchingId
                        );

                      if (!matchingSections || matchingSections?.length === 0) {
                        record.section = {};
                      }
                    }

                    if (firstSelectedIndex === -1) {
                      // This is the first selected record, store its index
                      firstSelectedIndex = index;
                      return {
                        ...record,
                      };
                    } else {
                      // Check if ticketType is "paper"
                      if (record.ticketType?.id === "paper") {
                        return {
                          ...record,
                        };
                      } else {
                        // Create a copy of the record without the specific key
                        // const {
                        //   "01fg98zqgrtfe7avqvv575e970": _,
                        //   "01fnv9gwfd8wmnn27aw196pj37": __,
                        //   "01fnv9ja884j9empp7a4z663wj": ___,
                        //   ...rest
                        // } = record;
                        const keysToDelete = Object.keys(newArrDelivery);
                        let rest = record;

                        keysToDelete.forEach((key: any) => {
                          if (rest.hasOwnProperty(key)) {
                            delete rest[key];
                          }
                        });
                        return {
                          ...rest,
                          deliveryType: [],
                        };
                      }
                    }
                  } else {
                    return record;
                  }
                });

                return {
                  ...event,
                  eventRecords: final,
                };
              } else {
                return event;
              }
            });
          });
          // setList(tempEvents([...events]));
        }
      };
    }, [eIndex, isTableEditable, layout, list, rIndex, setList]);

    const MemoizedHandleTableField = useMemo(() => {
      return (data: any, eIndex: number, rIndex: number) => {
        setEventRecord((prev: any) => {
          if (
            data?.target?.name === "ticketType" &&
            data.target.value !== prev.ticketType
          ) {
            let q = prev?.quantity;

            if (data.target?.name === "quantity") {
              q = data?.target?.value;
            }

            let ticketFiles: any = [];
            for (let i = 0; i < q; i++) {
              ticketFiles.push({});
            }
            prev["ticketFiles"] = ticketFiles;
          }

          if (data?.parentName === "ppePartners") {
            if (data.target.name === "proceedValue") {
              return { ...prev, [data?.target?.name]: data.target.value };
            }
            return {
              ...prev,
              ppePartners: updatePPEPartners(
                prev?.["ppePartners"],
                data?.id,
                data?.target?.value,
                "ppePartners",
                prev.proceedValue
              ),
            };
          } else {
            return {
              ...prev,
              [data?.target?.name]:
                data.target.value !== undefined &&
                Array.isArray(data.target.value)
                  ? data.target.value
                  : typeof data.target.value === "object"
                  ? data.target.value
                  : typeof data.target.value === "string" &&
                    data.target.type !== "checkbox"
                  ? data.target.value
                  : data.target.type === "checkbox"
                  ? data.target.checked
                  : data.target.value,

              // ...(data.target.name === "proceedValue" && {
              //   ppePartners: updatePPEPartners(
              //     prev.ppePartners,
              //     null,
              //     null,
              //     "ppePartners",
              //     data.target.value
              //   ),
              // }),

              ...(data?.target?.name === "proceedValue" &&
                (prev?.["ppe"] === 1 || prev?.["ppe"] === true) && {
                  ppePartners: updatePPEPartners(
                    prev?.["ppePartners"],
                    null,
                    null,
                    "ppePartners",
                    data.target.value
                  ),
                }),

              ...(data?.target?.name === "proceedValue" &&
                (prev?.["ppe"] === 0 || prev?.["ppe"] === false) && {
                  ppePartners: resetPPEFields(
                    prev?.["ppePartners"],
                    data.target.value,
                    "ppePartners"
                  ),
                }),

              ...(data?.target?.name === "ppe" && {
                ppePartners: resetPPEFields(
                  prev?.["ppePartners"],
                  prev?.proceedValue,
                  "ppePartners"
                ),
              }),
            };
          }
        });
        if (layout.isTableEditable.length === 0) {
          MemoizedHandleTableFieldOld(data);
          setUpdateChecker({
            eventId: eIndex,
            eventRecordId: rIndex,
          });
        } else {
          setUpdateChecker(data);
        }
      };
    }, [list, layout.isTableEditable]);

    useEffect(() => {
      if (layout.isTableEditable.length === 0) {
        setTimeout(() => {
          updateChecker?.eventId !== undefined &&
            setEventRecord(
              list?.[updateChecker?.eventId]?.eventRecords?.[
                updateChecker?.eventRecordId
              ]
            );
        }, 500);
      }
    }, [updateChecker]);

    // MULTI EDIT
    let eventRecordMemo: any = useMemo(() => {
      return [updateChecker];
    }, [updateChecker]);

    const debounceHandler = useDebounce(eventRecordMemo, 0);

    useEffect(() => {
      if (
        updateChecker &&
        updateChecker?.eventId === undefined &&
        layout.isTableEditable.length > 0
      ) {
        MemoizedHandleTableFieldOld(updateChecker);
      }
    }, [debounceHandler]);

    //send response to eventField component
    function refreshEventsWhileActionPerform() {
      //send response to eventField component
      const tempEvents = (events: any) => {
        return events.map((event: any) => {
          return {
            ...event,
            eventRecords:
              Array.isArray(event["eventRecords"]) &&
              event["eventRecords"].length > 0
                ? event["eventRecords"].map((eventRecord: any) => {
                    return {
                      ...eventRecord,
                    };
                  })
                : [],
          };
        });
      };
      setList(tempEvents([...list]));
    }

    // let eventRecordMemo: any = useMemo(() => {
    //   // return [updateChecker];
    //   console.log(updateChecker);
    //   console.log(eventRecord);
    //   setEventRecord({

    //   })
    // }, [updateChecker]);

    // const debounceHandler = useDebounce(eventRecordMemo, 0);
    // useEffect(() => {
    //   if (updateChecker) {
    //     //solve tab fast issue
    //     // updateSetList();
    //     MemoizedHandleTableFieldOld(updateChecker);
    //   }
    // }, [debounceHandler]);

    if (eventRecord?.newRow && triggered === false) {
      setTriggered(true);
      setTimeout(() => {
        eventRecord.newRow = false;
        setTriggered(false);
      }, 2000);
    }

    let isDisabled = "";
    let disabledForPPE = false;

    if (eventRecord?.selected === false && isTableEditable.length > 0) {
      isDisabled = "row-disabled";
      forRowDisabled = "";
      disabledForPPE = true;
    } else if (
      eventRecord?.selected === false &&
      isTableEditable.length === 0
    ) {
      isDisabled = "";
      forRowDisabled = "";
    } else if (eventRecord?.selected === true && isTableEditable.length === 0) {
      isDisabled = "";
      forRowDisabled = "";
    } else if (eventRecord?.selected === true && isTableEditable.length > 0) {
      isDisabled = "row-indigo-light row-indigo-light -selected";
      forRowDisabled = "pointer-events-none";
      disabledForPPE = true;
    }

    const isAllFilesUploaded =
      eventRecord?.ticketFiles &&
      Object.keys(eventRecord?.ticketFiles).length &&
      Object.keys(
        eventRecord?.ticketFiles?.filter(
          (obj: any) => JSON.stringify(obj) !== "{}"
        )
      ).length >= (eventRecord?.quantity ? Number(eventRecord?.quantity) : 0) &&
      Object.keys(
        eventRecord?.ticketFiles?.filter(
          (obj: any) => JSON.stringify(obj) !== "{}"
        )
      ).length > 0
        ? true
        : false;

    const ticketInHandFromListings = (eIndex: any, rIndex: any) => {
      setList((prevList: any) => {
        return prevList?.map((obj: any, index: any) => {
          if (eIndex === index) {
            return {
              ...obj,
              eventRecords: obj?.eventRecords?.map(
                (innerObj: any, innerIndex: any) => {
                  if (rIndex === innerIndex) {
                    let visibility: any = visibilityMeterCalculationForKeyBoard(
                      obj,
                      {
                        ...innerObj,
                        ticketsInHands: !innerObj?.ticketsInHands,
                      }
                    );
                    return {
                      ...innerObj,
                      visibility: visibility,
                      oldVisibility:
                        visibility && innerObj?.visibility === visibility
                          ? innerObj?.oldVisibility
                          : visibility && innerObj?.visibility,
                      ticketsInHands: !innerObj?.ticketsInHands,
                    };
                  }
                  return innerObj;
                }
              ),
            };
          }
          return obj;
        });
      });
    };

    const handleOnSelection = (
      data: any,
      eIndex: any,
      rIndex: any,
      option: any,
      filedName: any,
      visibleOptions: any,
      currentOptionsSelected: any
    ) => {
      let updatedOptions =
        visibleOptions && size(visibleOptions) > 0
          ? visibleOptions
          : data?.target?.id === "select-all"
          ? option
          : null;
      if (data?.target?.id === "select-all") {
        let target = {
          name: filedName,
          value: addSelectAll(currentOptionsSelected, updatedOptions),
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      } else if (data?.target?.id === "deselect-all") {
        let target = {
          name: filedName,
          value: removeSelectAll(currentOptionsSelected, updatedOptions),
        };
        MemoizedHandleTableField({ target }, eIndex, rIndex);
      }
    };

    const [priceFiledFocused, setPriceFieldFocused] = useState<any>();
    const [avgPrice, setAvgPrice] = useState<any>();
    const focusOnPriceField = (data: any, eIndex: any, rIndex: any) => {
      setPriceFieldFocused({ data: data, eIndex: eIndex, rIndex: rIndex });
    };

    function categoryExist(event: any, record: any, type: any) {
      const hasProceedValue =
        _.size(record?.proceedValue) > 0 &&
        record?.proceedValue?.value !== "0.00" &&
        record?.proceedValue?.value !== "";
      const hasNoCategory = _.size(record?.category) === 0;
      const hasNoSection = _.size(record?.section) === 0;
      if (type === "withoutcategory") {
        return (hasProceedValue && (hasNoCategory || hasNoSection)) || false;
      }
      if (type === "withcategory") {
        return (
          (hasProceedValue &&
            _.size(record?.category) > 0 &&
            _.size(record?.section) > 0) ||
          false
        );
      }
    }

    // const updatePPEPartners = (ppePartners: any[], id: any, value: any) => {
    //   let partnerExists = false;

    //   const updatedPartners: any = ppePartners
    //     ? ppePartners.map((partner) => {
    //         if (partner.id === id) {
    //           partnerExists = true;
    //           return { ...partner, value: value };
    //         }
    //         return partner;
    //       })
    //     : [];

    //   if (!partnerExists) {
    //     updatedPartners.push({ id: id, value: value });
    //   }

    //   let minValue = Number.MAX_VALUE;
    //   let minValueSymbol = "";

    //   updatedPartners.forEach((partner: any) => {
    //     const partnerValue = Number(partner?.value?.value);

    //     if (!partner.is_hidden && !isNaN(partnerValue)) {
    //       if (partnerValue < minValue) {
    //         minValue = partnerValue;
    //         minValueSymbol = partner?.value?.symbol;
    //       }
    //     }
    //   });

    //   const arrayWithTxtradePrice = updatedPartners.map((partner: any) => {
    //     if (partner.is_hidden === true) {
    //       return {
    //         ...partner,
    //         value: {
    //           ...partner.value,
    //           value: minValue,
    //           symbol: minValueSymbol,
    //         },
    //       };
    //     }
    //     return partner;
    //   });

    //   return arrayWithTxtradePrice;
    // };

    const handlePPEOpen = () => {
      // if (!eventRecord.ppeIsOpen && !eventRecord?.ppe) {
      //   console.log("first");
      //   let ppeEvent = {
      //     target: {
      //       name: "ppePartners",
      //       value: eventRecord?.ppePartners?.map((partner: any) => {
      //         return {
      //           ...partner,
      //           value: {
      //             ...partner?.value,
      //             value: decimalNumber(eventRecord.proceedValue?.value),
      //             symbol: eventRecord.proceedValue?.symbol,
      //           },
      //         };
      //       }),
      //     },
      //   };
      //   MemoizedHandleTableField(ppeEvent, eIndex, rIndex);
      // }

      // const ppeIsOpenEvent = {
      //   target: {
      //     name: "ppeIsOpen",
      //     value: !eventRecord.ppeIsOpen,
      //   },
      // };

      // MemoizedHandleTableField(ppeIsOpenEvent, eIndex, rIndex);

      setTimeout(() => {
        setList((events: any) => {
          return events.map((event: any, eventIndex: any) => {
            if (eventIndex === eIndex) {
              return {
                ...event,
                eventRecords: Array.isArray(event["eventRecords"])
                  ? event["eventRecords"]?.map((eventRecord: any, erIndex) => {
                      if (
                        erIndex === rIndex ||
                        (layout.isTableEditable.length > 0 &&
                          eventRecord.selected === true &&
                          (eventRecord?.ppe === 1 || eventRecord?.ppe === true))
                      ) {
                        const final = {
                          ...eventRecord,
                          ppeIsOpen: !eventRecord.ppeIsOpen,
                        };

                        return final;
                      } else {
                        return {
                          ...eventRecord,
                        };
                      }
                    })
                  : [],
              };
            } else {
              return event;
            }
          });
        });
      }, 500);
    };

    const ppeAction: any = useMemo(() => {
      return layout.isTableEditable?.length > 0
        ? checkPPEExistForTableLevelNEw(event, "")
        : "";
    }, [event, layout.isTableEditable?.length]);

    let ppeErrosValidation =
      layout?.isTableEditable?.length > 0 &&
      !ppeAction?.allPPE &&
      ppeAction?.somePPE;

    if (eventRecord?.status !== "published") {
      return (
        <React.Fragment key={`${event?.id}-${rIndex}`}>
          {/* {JSON.stringify(new Date())} */}
          <div
            className={`parList 
            ${eventRecord?.status === "failed" && "row-error-new"}
            ${eventRecord?.status === "partPublished" && "row-processing-new"}`}
          >
            <div
              className={`first_row addinventoryRow 
            flex items-center border-b ${
              includes(["failed", "partPublished"], eventRecord?.status) &&
              "!border-b withError"
            } 
            ${eventRecord?.bulkLoader ? "row-disabled" : ""}
            left-shad-list
            ${event.value === "" && "row-disabled"} 
            ${
              focusedRow === `${event?.id}-${rIndex}`
                ? ""
                : // "bg-indigo-500 bg-opacity-[7%]"
                  ""
            }
            ${eventRecord?.selected ? "bg-indigo-500 bg-opacity-[7%]" : ""}
            ${isDisabled}
            ${eventRecord?.newRow && "row-indigo-light ease-out duration-500"}
            ${
              rIndex === firstSelectedIndex && eventRecord?.selected
                ? "first"
                : ""
            }
            ${
              ((eventRecord?.errors &&
                Object.keys(eventRecord?.errors ? eventRecord?.errors : [])
                  .length > 0) ||
                eventRecord?.status === "failed") &&
              "error-row1 row-error1 relative row-error-new bg-rose-500/5 hover:bg-rose-500/5"
              //bg-rose-500/5  shadow-[0_-1px_0_0] shadow-rose-500
            }
            ${
              eventRecord?.status === "partPublished" && "row-processing-new"
              // "bg-orange-500/5 hover:bg-orange-500/5"
            }  
            ${eventRecord?.selected && layout.confirmRowDelete && "fade"}
            ${layout.isDeleteActive && "pointer-events-none"} 
            table_row
            `}
              key={`eventFieldTr${rIndex}`}
              id={`${event?.id}-${rIndex}`}
            >
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className=" flex 
              items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] text-center sticky left-0 no-scroll p-0 checkbox_td z-[2] td_block"
                id={`checkbox_shad`}
              >
                <label
                  className={`min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] flex items-center absolute top-0  justify-center select-none p-1 border-r ${forRowDisabled} ${
                    layout.isPublish && "pointer-events-none !border-gray-300"
                  } ${layout.isDeleteActive && "pointer-events-none"}
                ${
                  ((eventRecord?.errors &&
                    Object.keys(eventRecord?.errors ? eventRecord?.errors : [])
                      .length > 0) ||
                    eventRecord?.status === "failed") &&
                  "error-row1"
                  // bg-rose-500/5 hover:bg-rose-500/5
                }
                ${eventRecord?.selected ? "bg-indigo-500 bg-opacity-[7%]" : ""}
                ${
                  eventRecord?.newRow &&
                  "row-indigo-light ease-out duration-500"
                }
                `}
                  htmlFor={`checkbox-all-${event?.id}-${rIndex}`}
                ></label>
                <input
                  name="selected"
                  id={`checkbox-all-${event?.id}-${rIndex}`}
                  key={`checkbox-all-${event?.id}-${rIndex}`}
                  type="checkbox"
                  // defaultChecked={eventRecord?.selected}
                  checked={eventRecord?.selected}
                  className={`${forRowDisabled} ${
                    layout.isDeleteActive && "pointer-events-none "
                  } w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-0 focus:ring-offset-0 relative`}
                  onChange={(event: any) => {
                    MemoizedHandleTableField(event, eIndex, rIndex);
                  }}
                  onClick={(e) => {
                    handleShift(e, rIndex, event?.eventRecords);
                    setTimeout(() => {
                      refreshEventsWhileActionPerform();
                    }, 70);
                  }}
                />
              </div>
              {/* <div
              onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
              onBlur={handleBlur}
              className="p-1.5 font-medium"
            >
              <span
              //className="hidden"
              >
                LstingID: {eventRecord?.listingId}- CardID:{" "}
                {eventRecord?.card}- Status:{" "}
                {eventRecord?.status}-
              </span>
              <TableMultiDropdown
                name="marketplaces"
                options={event?.event_exchanges}
                value={eventRecord?.marketplaces}
                classNamePrefix={"table_multiDropdown"}
                placeholder={"Marketplaces"}
                handleOnChange={(data: any) =>
                  MemoizedHandleTableField(data, eIndex, rIndex)
                }
                getOptionLabel={"title"}
                getOptionValue={"exchange_id"}
              />
            </div> */}
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "ticketType"
                  ) && "edited"
                }`}
                id={`ticket_type-${eIndex}-${rIndex}`}
              >
                <TableDropdown
                  value={eventRecord?.ticketType}
                  options={layout.ticketType}
                  isCapital={true}
                  isValueCapital={true}
                  errorClassName={
                    eventRecord?.errors?.ticket_type && "error-field"
                  }
                  isClearable={false}
                  errorEventIndex={
                    eventRecord?.errors?.ticket_type &&
                    `ticketType-${eventRecord?.card}`
                  }
                  errorMessage={
                    eventRecord?.errors?.ticket_type
                      ? eventRecord?.errors?.ticket_type
                      : ""
                  }
                  placeholder={"Ticket type"}
                  name="ticketType"
                  handleOnChange={(data: any) => {
                    MemoizedHandleTableField(data, eIndex, rIndex);
                    refreshEventsWhileActionPerform();
                  }}
                />
              </div>

              {paperTypeExist && (
                <div
                  onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                  onBlur={handleBlur}
                  className={`p-1.5 font-medium min-w-[7.875rem] td_block ${
                    layout.tableDataEditChanging?.[eIndex]?.includes(
                      "deliveryType"
                    ) && "edited"
                  }`}
                >
                  <TableMultiDropdown
                    name="deliveryType"
                    options={layout.deliveryType}
                    value={eventRecord?.deliveryType}
                    classNamePrefix={"table_multiDropdown"}
                    placeholder={
                      eventRecord?.deliveryType &&
                      size(eventRecord?.deliveryType) > 0
                        ? ""
                        : "Delivery type"
                    }
                    handleOnChange={(data: any) => {
                      MemoizedHandleTableField(data, eIndex, rIndex);
                      refreshEventsWhileActionPerform();
                    }}
                    getOptionLabel="type"
                    isDisabled={eventRecord?.ticketType?.id !== "paper"}
                    errorClassName={
                      eventRecord?.errors?.delivery_type && "error-field"
                    }
                    errorEventIndex={
                      eventRecord?.errors?.delivery_type &&
                      `delivery-type-${eventRecord?.card}`
                    }
                    errorMessage={
                      eventRecord?.errors?.delivery_type
                        ? eventRecord?.errors?.delivery_type
                        : ""
                    }
                  />
                </div>
              )}

              {paperTypeExist &&
                isArray(seletedDeliveryTypes) &&
                seletedDeliveryTypes.length > 0 &&
                _.orderBy(seletedDeliveryTypes, deliveryTypeOrders, [
                  "asc",
                ]).map((selected: any) => {
                  let delivery = find(eventRecord?.deliveryType, {
                    type: selected,
                  });
                  return delivery ? (
                    <div
                      id={`${delivery?.id}-${eIndex}-${rIndex}`}
                      onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                      onBlur={handleBlur}
                      className={`p-1.5 font-medium td_block ${
                        layout.tableDataEditChanging?.[eIndex]?.includes(
                          delivery?.id
                        ) && "edited"
                      }`}
                    >
                      <div className="relative">
                        <input
                          type="text"
                          name={delivery?.id}
                          className={`
                truncate h-6 max-w-[6.875rem] text-xs font-medium py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500
               ${
                 eventRecord?.errors?.[delivery?.id] &&
                 "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
               }
                `}
                          value={eventRecord?.[delivery?.id]}
                          placeholder={delivery?.type}
                          onChange={(event: any) =>
                            MemoizedHandleTableField(event, eIndex, rIndex)
                          }
                          onKeyDown={priceFieldInputKeyDown}
                        />
                        {eventRecord?.[delivery?.id]
                          ? ""
                          : eventRecord?.errors?.[delivery?.id] && (
                              <>
                                <TooltipPortal
                                  id={`delivery-type-${delivery.id}-error-${event?.id}-${rIndex}`}
                                  content={eventRecord?.errors?.[delivery?.id]}
                                  className="text-center !bg-white !opacity-100 shadow !px-1.5 py-0.5 font-medium text-xs z-10 max-w-[16rem] !text-rose-500"
                                />
                                <div
                                  data-tooltip-id={`delivery-type-${delivery.id}-error-${event?.id}-${rIndex}`}
                                  className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                                >
                                  <IKTSVG
                                    className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                    path={Warning1}
                                  />
                                </div>
                              </>
                            )}
                      </div>
                    </div>
                  ) : (
                    <div className="p-1.5 font-medium td_block">
                      <div className="relative">
                        <input
                          type="number"
                          className={`bg-gray-100 placeholder:truncate pointer-events-none truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50`}
                          placeholder={selected}
                          disabled={true}
                          value={``}
                        />
                      </div>
                    </div>
                  );
                })}

              {/* {eventRecord?.ticketType?.subtypes &&
            Object.keys(
              eventRecord?.ticketType?.subtypes
                ? eventRecord?.ticketType?.subtypes
                : []
            ).length > 0 ? (
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes("sub_ticket_type") &&
                  "edited"
                }`}
              >
                <TableDropdown
                  value={eventRecord?.sub_ticket_type}
                  options={eventRecord?.ticketType?.subtypes}
                  placeholder={"Sub ticket type"}
                  isCapital={true}
                  isValueCapital={true}
                  name="sub_ticket_type"
                  isClearable={false}
                  errorClassName={
                    eventRecord?.errors?.sub_ticket_type && "error-field"
                  }
                  errorEventIndex={
                    eventRecord?.errors?.sub_ticket_type &&
                    `ticketType-${eventRecord?.card}`
                  }
                  errorMessage={
                    eventRecord?.errors?.sub_ticket_type
                      ? eventRecord?.errors?.sub_ticket_type
                      : ""
                  }
                  handleOnChange={(data: any) =>
                    MemoizedHandleTableField(data, eIndex, rIndex)
                  }
                />
              </div>
            ) : (
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes("sub_ticket_type") &&
                  "edited"
                }`}
              >
                <TableDropdown
                  isDisabled={true}
                  value={""}
                  options={eventRecord?.ticket_type?.sub_ticket_type}
                  placeholder={"Sub ticket "}
                  name="sub_ticket_type"
                  handleOnChange={(data: any) =>
                    MemoizedHandleTableField(data, eIndex, rIndex)
                  }
                />
              </div>
            )} */}
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "quantity"
                  ) && "edited"
                }`}
                id={`quantity_available-${eIndex}-${rIndex}`}
              >
                <div className="relative">
                  <input
                    type="text"
                    name="quantity"
                    className={`
                  truncate h-6 md:max-w-[6.875rem] max-w-[5rem] text-xs font-weight-500 py-0 border-gray-300 rounded  focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5
                 ${
                   eventRecord?.errors?.quantity_available &&
                   "border-rose-500 focus:border-rose-500 #{!important} placeholder:text-rose-500"
                 }
                  `}
                    value={eventRecord?.quantity}
                    placeholder="Quantity"
                    onChange={(event: any) =>
                      MemoizedHandleTableField(event, eIndex, rIndex)
                    }
                    // onWheel={(e: any) => {
                    //   e.target.blur();
                    // }}
                    onKeyDown={handleKeyDown}
                  />
                  {eventRecord?.quantity &&
                  !eventRecord?.errors?.quantity_available
                    ? ""
                    : eventRecord?.errors?.quantity_available && (
                        <>
                          {/* <Tooltip
                          anchorId={`quantity-error-${event?.id}-${rIndex}`}
                          content={eventRecord?.errors?.quantity_available}
                          place={rIndex === 0 ? "right" : "top"}
                          variant="light"
                          className="text-center  bg-white shadow !px-1.5 py-0.5 font-medium text-xs z-10 max-w-[16rem] !text-rose-500"
                        /> */}
                          <TooltipPortal
                            id={`quantity-error-${event?.id}-${rIndex}`}
                            content={eventRecord?.errors?.quantity_available}
                            className="text-center !bg-white !opacity-100 shadow !px-1.5 py-0.5 font-medium text-xs z-20 max-w-[16rem] !text-rose-500"
                          />
                          <div
                            id={`quantity-error-${event?.id}-${rIndex}`}
                            data-tooltip-id={`quantity-error-${event?.id}-${rIndex}`}
                            className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                          >
                            <IKTSVG
                              className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                              path={Warning1}
                            />
                          </div>
                        </>
                      )}
                </div>
              </div>

              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "splitType"
                  ) && "edited"
                }`}
              >
                <TableDropdown
                  value={
                    eventRecord?.splitType?.id === "ANY"
                      ? { id: "ANY", name: "None" }
                      : eventRecord?.splitType
                  }
                  options={layout.splitType}
                  placeholder={"Split type"}
                  name="splitType"
                  isClearable={false}
                  handleOnChange={(data: any) =>
                    MemoizedHandleTableField(data, eIndex, rIndex)
                  }
                  errorClassName={
                    eventRecord?.errors?.splitType && "error-field"
                  }
                  errorEventIndex={
                    eventRecord?.errors?.splitType &&
                    `SplitType-${eventRecord?.card}`
                  }
                  errorMessage={
                    eventRecord?.errors?.splitType &&
                    eventRecord?.errors?.splitType
                  }
                  errorIndex={rIndex}
                />
              </div>

              {eventRecord?.splitType?.id === "MULTIPLES" ? (
                <div
                  onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                  onBlur={handleBlur}
                  className={`p-1.5 font-medium td_block ${
                    layout.tableDataEditChanging?.[eIndex]?.includes(
                      "MULTIPLES"
                    ) && "edited"
                  }`}
                  id={`MULTIPLES-${eIndex}-${rIndex}`}
                >
                  <div className="relative">
                    <input
                      type="text"
                      className={`${
                        eventRecord?.errors?.MULTIPLES &&
                        "focus:border-rose-500 border-rose-500 text-rose-500"
                      } truncate h-6 md:max-w-[6.875rem] max-w-[5.5rem] text-xs font-weight-500 py-0 border-gray-300 rounded hover:placeholder:text-indigo-500 focus:border-indigo-500 focus:bg-violet-300/50 `}
                      value={eventRecord?.MULTIPLES}
                      placeholder="Sell in multiple"
                      name="MULTIPLES"
                      onChange={(event: any) =>
                        MemoizedHandleTableField(event, eIndex, rIndex)
                      }
                    />
                    {eventRecord?.errors?.MULTIPLES && (
                      <>
                        {/* <Tooltip
                        anchorId={`MULTIPLES-error-${event?.id}-${rIndex}`}
                        content={eventRecord?.errors?.MULTIPLES}
                        place="top"
                        variant="light"
                        className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[9rem] !text-rose-500"
                      /> */}
                        <TooltipPortal
                          id={`MULTIPLES-error-${event?.id}-${rIndex}`}
                          content={eventRecord?.errors?.MULTIPLES}
                          className="text-center !bg-white !opacity-100 shadow !px-1.5 py-0.5 font-medium text-xs z-20 max-w-[9rem] !text-rose-500"
                        />
                        <div
                          id={`MULTIPLES-error-${event?.id}-${rIndex}`}
                          data-tooltip-id={`MULTIPLES-error-${event?.id}-${rIndex}`}
                          className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                        >
                          <IKTSVG
                            className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                            path={Warning1}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              ) : (
                <div
                  onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                  onBlur={handleBlur}
                  className={`p-1.5 font-medium td_block ${
                    layout.tableDataEditChanging?.[eIndex]?.includes(
                      "MULTIPLES"
                    ) && "edited"
                  }`}
                >
                  <div className="relative">
                    <input
                      type="text"
                      className="disable bg-gray-100 border-none text-gray-400 placeholder:!text-gray-400 placeholder:truncate pointer-events-none truncate h-6 md:max-w-[6.875rem] max-w-[5.5rem] text-xs font-weight-500 py-0  rounded focus:border-indigo-500 focus:bg-violet-300/50 px-2.5"
                      placeholder="Sell in multiple"
                      value={""}
                      name="MULTIPLES"
                    />
                  </div>
                </div>
              )}

              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "maxDisplayQuantity"
                  ) && "edited"
                }`}
              >
                <input
                  type="text"
                  className="truncate h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5"
                  value={eventRecord?.maxDisplayQuantity}
                  placeholder="Max display quantity"
                  name="maxDisplayQuantity"
                  onKeyDown={handleKeyDown}
                  onChange={(event: any) =>
                    MemoizedHandleTableField(event, eIndex, rIndex)
                  }
                />
              </div>
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium auto_width_row td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "category"
                  ) && "edited"
                }`}
                id={`category-${eIndex}-${rIndex}`}
              >
                <TableDropdown
                  value={
                    eventRecord?.category && _.size(eventRecord?.category) > 0
                      ? eventRecord?.category
                      : undefined
                  }
                  isCapital={true}
                  isValueCapital={true}
                  options={uniqueByNameWithPriority(event?.categories, "")}
                  errorClassName={
                    eventRecord?.errors?.category && "error-field"
                  }
                  errorEventIndex={
                    eventRecord?.errors?.category &&
                    `category-${eventRecord?.card}`
                  }
                  errorMessage={
                    eventRecord?.errors?.category
                      ? eventRecord?.errors?.category
                      : ""
                  }
                  isClearable={true}
                  placeholder={"Category"}
                  name="category"
                  handleOnChange={(data: any) => {
                    MemoizedHandleTableField(data, eIndex, rIndex);

                    // calculateAvgPriceForProceedValue(
                    //   data,
                    //   event?.markeInsights,
                    //   event?.id,
                    //   rIndex
                    // );
                    refreshEventsWhileActionPerform();
                  }}
                  errorindex={rIndex}
                  className={`focus:border-rose-500 border-rose-500 text-rose-500`}
                />
              </div>
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium auto_width_row td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes("section") &&
                  "edited"
                }`}
              >
                <TableDropdown
                  value={
                    eventRecord?.section &&
                    Object.keys(eventRecord?.section)?.length > 0
                      ? {
                          ...eventRecord?.section,
                          name:
                            eventRecord?.category?.name !==
                            eventRecord?.section?.name
                              ? eventRecord?.section?.name?.replace(
                                  eventRecord?.category?.name,
                                  ""
                                )
                              : eventRecord?.section?.name,
                        }
                      : undefined
                  }
                  options={uniqueByNameWithPriority(
                    eventRecord?.category?.sections,
                    ""
                  )?.map((item: any) => {
                    return {
                      id: item.id,
                      name:
                        eventRecord?.category?.name !== item.name
                          ? item.name.replace(eventRecord?.category?.name, "")
                          : item?.name,
                      originalName: item?.name,
                    };
                  })}
                  errorClassName={eventRecord?.errors?.section && "error-field"}
                  errorEventIndex={
                    eventRecord?.errors?.section &&
                    `section-${eventRecord?.card}`
                  }
                  errorMessage={
                    eventRecord?.errors?.section && eventRecord?.errors?.section
                  }
                  isCapital={true}
                  isValueCapital={true}
                  placeholder={"Section/block"}
                  name="section"
                  handleOnChange={(data: any) =>
                    MemoizedHandleTableField(data, eIndex, rIndex)
                  }
                />
              </div>
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes("row") &&
                  "edited"
                }`}
              >
                <input
                  type="text"
                  className="h-6 md:max-w-[6.875rem] max-w-[5.5rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5"
                  value={eventRecord?.row ? eventRecord?.row : ""}
                  placeholder="Row"
                  name="row"
                  onChange={(event: any) =>
                    MemoizedHandleTableField(event, eIndex, rIndex)
                  }
                />
              </div>
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "firstSeat"
                  ) && "edited"
                }`}
              >
                <input
                  type="text"
                  className="h-6 md:max-w-[6.875rem] max-w-[5.5rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500 focus:bg-violet-300/50 hover:placeholder:text-indigo-500 px-2.5"
                  value={eventRecord?.firstSeat && eventRecord?.firstSeat}
                  placeholder="First seat"
                  name="firstSeat"
                  onKeyDown={handleKeyDown}
                  onChange={(event: any) =>
                    MemoizedHandleTableField(event, eIndex, rIndex)
                  }
                />
              </div>
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "faceValue"
                  ) && "edited"
                }`}
              >
                <div id={`face_value-${eIndex}-${rIndex}`}>
                  <PriceField
                    value={
                      eventRecord?.faceValue?.value
                        ? eventRecord?.faceValue?.value
                        : ""
                    }
                    currenncySymbol={
                      // eventRecord?.faceValue &&
                      // eventRecord?.faceValue?.symbol
                      target_currency
                    }
                    placeholder={
                      eventRecord?.errors ? "Face v.." : "Face value"
                    }
                    // errorClass={`${
                    //   eventRecord?.errors?.face_value !== undefined ?
                    //   "focus:border-rose-500 border-rose-500 text-rose-500 error-field"
                    // : undefined}`}
                    textFielClassName={`truncate`}
                    errorClass={`${
                      eventRecord?.errors?.face_value
                        ? eventRecord?.errors?.face_value
                        : ""
                    }`}
                    errorMessage={`${
                      eventRecord?.errors?.face_value
                        ? eventRecord?.errors?.face_value
                        : ""
                    }`}
                    errorEventIndex={
                      eventRecord?.errors?.face_value &&
                      `faceValue-${event?.id}-${rIndex}`
                    }
                    name="faceValue"
                    handleOnChange={(data: any) => {
                      MemoizedHandleTableField(data, eIndex, rIndex);
                    }}
                    handleOnBlur={(data: any) => {
                      const value = decimalNumber(data.target?.value?.value);
                      let event = {
                        target: {
                          name: data.target.name,
                          value: {
                            name: data.target.name,
                            value: addCommaInNumber(value),
                            symbol: data?.target?.value?.symbol,
                          },
                        },
                      };

                      MemoizedHandleTableField(event, eIndex, rIndex);
                    }}
                    errorIndex={rIndex}
                  />
                </div>
              </div>
              {priceFieldToggle && (
                <div
                  onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                  onBlur={handleBlur}
                  className={`p-1.5 font-medium td_block ${
                    layout.tableDataEditChanging?.[eIndex]?.includes(
                      "proceedValue"
                    ) && "edited"
                  }`}
                  id={`sell_price-${eIndex}-${rIndex}`}
                >
                  <div
                    className={` proceed-price addinv_price relative ${
                      eventRecord?.ppe ? "disable" : ""
                    }`}
                  >
                    <PriceField
                      value={
                        Object.keys(
                          eventRecord?.proceedValue
                            ? eventRecord?.proceedValue
                            : []
                        ).length > 0
                          ? eventRecord?.proceedValue?.value
                          : ""
                      }
                      isDisabled={
                        eventRecord?.ppe ||
                        (layout?.isTableEditable?.length > 0 &&
                          ppeAction?.somePPE)
                      }
                      currenncySymbol={
                        // eventRecord?.proceedValue &&
                        // eventRecord?.proceedValue?.symbol
                        target_currency
                      }
                      placeholder={
                        eventRecord?.errors ? "Procee.." : "Proceed price"
                      }
                      className={`${
                        eventRecord?.errors?.sell_price &&
                        "focus:border-rose-500 border-rose-500 text-rose-500"
                      }`}
                      textFielClassName={`${
                        eventRecord?.errors?.sell_price &&
                        "focus:border-rose-500 border-rose-500 text-rose-500"
                      } truncate`}
                      name="proceedValue"
                      handleOnChange={
                        (data: any) =>
                          MemoizedHandleTableField(data, eIndex, rIndex)

                        // if (!eventRecord?.ppe) {
                        //   let ppeEvent = {
                        //     target: {
                        //       name: "ppePartners",
                        //       value: eventRecord?.ppePartners?.map(
                        //         (partner: any) => {
                        //           return {
                        //             ...partner,
                        //             value: {
                        //               ...partner?.value,
                        //               value: decimalNumber(
                        //                 data.target?.value?.value
                        //               ),
                        //               symbol: data?.target?.value?.symbol,
                        //             },
                        //           };
                        //         }
                        //       ),
                        //     },
                        //   };
                        //   MemoizedHandleTableField(ppeEvent, eIndex, rIndex);
                        // }
                      }
                      handleOnFocus={(data: any) => {
                        focusOnPriceField(data, eIndex, rIndex);
                      }}
                      handleOnBlur={(data: any) => {
                        const value = decimalNumber(data.target?.value?.value);
                        let event = {
                          target: {
                            name: data.target.name,
                            value: {
                              name: data.target.name,
                              value: !isValidPriceFieldNumber(
                                data.target.value.value
                              )
                                ? decimalNumber(0)
                                : value,
                              symbol: data?.target?.value?.symbol,
                            },
                          },
                        };
                        MemoizedHandleTableField(event, eIndex, rIndex);
                      }}
                      errorClass={`${
                        eventRecord?.errors?.sell_price
                          ? eventRecord?.errors?.sell_price
                          : ""
                      }`}
                      errorMessage={`${
                        eventRecord?.errors?.sell_price
                          ? eventRecord?.errors?.sell_price
                          : ""
                      }`}
                      errorEventIndex={
                        eventRecord?.errors?.sell_price &&
                        `proceedValue-${eventRecord?.card}`
                      }
                      // isDisabled={eventRecord?.ppe}
                      errorIndex={rIndex}
                      isPriceField={
                        priceFiledFocused?.data &&
                        priceFiledFocused?.eIndex === eIndex &&
                        priceFiledFocused?.rIndex === rIndex
                          ? {
                              withoutCategory: categoryExist(
                                event,
                                eventRecord,
                                "withoutcategory"
                              ),
                              withCategory: categoryExist(
                                event,
                                eventRecord,
                                "withcategory"
                              ),
                              isTableField: false,
                              isFocused: priceFiledFocused?.data,
                            }
                          : false
                      }
                      fieldMessage={
                        avgPrice?.id === event?.id || eventRecord?.avg_price
                          ? priceCalculation(
                              eventRecord?.proceedValue?.value,
                              eventRecord?.avg_price
                                ? Number(eventRecord?.avg_price)
                                : null,
                              priceFactor
                            )
                          : false
                      }
                      isValidationPopupVisible={
                        categoryExist(event, eventRecord, "withcategory") &&
                        !eventRecord?.avg_price
                          ? true
                          : false
                      }
                      isPriceTable={true}
                      indexPlusLength={{
                        rIndex: rIndex,
                        recordLength: event?.eventRecords?.length,
                      }}
                      avgPrice={eventRecord?.avg_price}
                    />
                    {eventRecord?.ppe}
                    {ppeErrosValidation && myIndex === rIndex && (
                      <>
                        <TooltipPortal
                          id={`ppe-error-${rIndex}`}
                          content={Messages?.bulkEditRestrictPPE}
                          className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-[999999999] max-w-[9rem] cursor-default !text-rose-500"
                        />
                        <div
                          id={`ppe-error-${rIndex}`}
                          data-tooltip-id={`ppe-error-${rIndex}`}
                          className="absolute top-1/2 -translate-y-1/2 right-4 "
                        >
                          <IKTSVG
                            className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                            path={Warning1}
                          />
                        </div>
                      </>
                    )}
                  </div>
                </div>
              )}

              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium auto_width_row td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "benefits"
                  ) && "edited"
                }`}
              >
                <TableMultiDropdown
                  name="benefits"
                  options={layout.benefits}
                  value={eventRecord?.benefits}
                  classNamePrefix={"table_multiDropdown"}
                  placeholder={
                    eventRecord?.benefits && size(eventRecord?.benefits) > 0
                      ? ""
                      : "Benefits"
                  }
                  handleOnChange={(data: any) => {
                    MemoizedHandleTableField(data, eIndex, rIndex);
                  }}
                  handleOnSelection={(data: any, visibleOptions: any) => {
                    handleOnSelection(
                      data,
                      eIndex,
                      rIndex,
                      layout.benefits,
                      "benefits",
                      visibleOptions,
                      eventRecord?.benefits
                    );
                  }}
                />
              </div>
              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`p-1.5 font-medium auto_width_row td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "restrictions"
                  ) && "edited"
                }`}
              >
                <TableMultiDropdown
                  name="restrictions"
                  options={layout.restrictions}
                  value={eventRecord?.restrictions}
                  classNamePrefix={"table_multiDropdown"}
                  placeholder={
                    eventRecord?.restrictions &&
                    size(eventRecord?.restrictions) > 0
                      ? ""
                      : "Restrictions"
                  }
                  handleOnChange={(data: any) =>
                    MemoizedHandleTableField(data, eIndex, rIndex)
                  }
                  handleOnSelection={(data: any, visibleOptions: any) => {
                    handleOnSelection(
                      data,
                      eIndex,
                      rIndex,
                      layout.restrictions,
                      "restrictions",
                      visibleOptions,
                      eventRecord?.restrictions
                    );
                  }}
                />
              </div>

              <div
                onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                onBlur={handleBlur}
                className={`relative p-1.5 font-medium td_block ${
                  layout.tableDataEditChanging?.[eIndex]?.includes(
                    "dateToShip"
                  ) && "edited"
                }`}
              >
                {/* Datepicker */}
                <div
                  className={`${
                    eventRecord?.ticketsInHands &&
                    "pointer-events-none disable_date "
                  } w-[7.5rem] relative`}
                  id={`date_to_ship-${eIndex}-${rIndex}`}
                >
                  <FormDatePickerField
                    name="dateToShip"
                    maxDate={new Date(event?.date)}
                    placeholder={`Date to ship`}
                    value={eventRecord?.dateToShip}
                    handleOnChange={(data: any) =>
                      MemoizedHandleTableField(data, eIndex, rIndex)
                    }
                    isDisabled={eventRecord?.ticketsInHands ? true : false}
                    disabledKeyboardNavigation={false}
                    error={eventRecord?.errors?.date_to_ship ? true : false}
                  />
                  {eventRecord?.errors?.date_to_ship && (
                    <>
                      {/* <Tooltip
                      anchorId={`dateship-error-${event?.id}-${rIndex}`}
                      content={eventRecord?.errors?.date_to_ship}
                      place={rIndex === 0 ? "right" : `top`}
                      variant="light"
                      className="text-center  bg-white shadow px-1.5 py-0.5 font-medium text-xs z-10 max-w-[10rem] !text-rose-500"
                      positionStrategy="fixed"
                    /> */}
                      <TooltipPortal
                        id={`dateship-error-${event?.id}-${rIndex}`}
                        content={eventRecord?.errors?.date_to_ship}
                        className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium text-xs z-20 max-w-[10rem] !text-rose-500"
                      />
                      <div
                        id={`dateship-error-${event?.id}-${rIndex}`}
                        data-tooltip-id={`dateship-error-${event?.id}-${rIndex}`}
                        className="absolute top-1/2 -translate-y-1/2 right-3 cursor-default"
                      >
                        <IKTSVG
                          className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                          path={Warning1}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>

              <div
                className={`p-0 z-[2] focus-within:z-50 md:sticky right-0 min-w-[10.01rem] max-w-[10.01rem] bg-white accordion-right-sticky ml-auto ${
                  !priceFieldToggle &&
                  " max-md:!min-w-[12rem]  md:!min-w-[18.25rem] md:!max-w-[18.25rem] z-[9]"
                }`}
              >
                <React.Fragment key={`${event?.id}-${rIndex}`}>
                  <div
                    className={`
                    ${event.value === "" && "row-disabled"} 
                    ${eventRecord?.bulkLoader ? "row-disabled" : ""} 
                    ${isDisabled} 
                    bg-white border-l left-shad st_icons 
                    ${
                      eventRecord?.selected
                        ? "!bg-indigo-500 !bg-opacity-[7%]"
                        : ""
                    }
                    ${
                      ((eventRecord?.errors &&
                        Object.keys(
                          eventRecord?.errors ? eventRecord?.errors : []
                        ).length > 0) ||
                        eventRecord?.status === "failed") &&
                      " error-row1 row-error-new"
                      // !bg-rose-500/5 hover:bg-rose-500/5
                    }
                    ${
                      rIndex === firstSelectedIndex && eventRecord?.selected
                        ? " first "
                        : ""
                    }
                    ${
                      eventRecord?.newRow &&
                      "row-indigo-light ease-out duration-500"
                    }`}
                    id={`left-shad-list`}
                  >
                    <div
                      className={`flex 
                    ${
                      eventRecord?.status === "failed" && layout.isPublish && ""
                      // bg-rose-500/5
                    }
                    ${
                      eventRecord?.status === "partPublished" &&
                      layout.isPublish &&
                      ""
                      // bg-orange-500/10
                    } 
                    `}
                    >
                      {!priceFieldToggle && !isMobile && (
                        <div
                          onFocus={() => handleFocus(`${event?.id}-${rIndex}`)}
                          onBlur={handleBlur}
                          className={`flex items-center proceed-price border-r px-2.5 max-w-[8.1875rem] ${
                            layout.tableDataEditChanging?.[eIndex]?.includes(
                              "proceedValue"
                            ) && "edited"
                          }`}
                          id={`sell_price-${eIndex}-${rIndex}`}
                        >
                          <div
                            className={` proceed-price addinv_price relative ${
                              eventRecord?.ppe ? "disable" : ""
                            }`}
                          >
                            <PriceField
                              value={
                                Object.keys(
                                  eventRecord?.proceedValue
                                    ? eventRecord?.proceedValue
                                    : []
                                ).length > 0
                                  ? eventRecord?.proceedValue?.value
                                  : ""
                              }
                              isDisabled={
                                eventRecord?.ppe ||
                                (layout?.isTableEditable?.length > 0 &&
                                  ppeAction?.somePPE)
                              }
                              currenncySymbol={
                                // eventRecord?.proceedValue &&
                                // eventRecord?.proceedValue?.symbol
                                target_currency
                              }
                              placeholder={
                                eventRecord?.errors
                                  ? "Procee.."
                                  : "Proceed price"
                              }
                              className={`${
                                eventRecord?.errors?.sell_price &&
                                "focus:border-rose-500 border-rose-500 text-rose-500"
                              }`}
                              textFielClassName={`${
                                eventRecord?.errors?.sell_price &&
                                "focus:border-rose-500 border-rose-500 text-rose-500"
                              } truncate`}
                              name="proceedValue"
                              handleOnChange={(data: any) => {
                                MemoizedHandleTableField(data, eIndex, rIndex);
                                // if (!eventRecord?.ppe) {
                                //   let ppeEvent = {
                                //     target: {
                                //       name: "ppePartners",
                                //       value: eventRecord?.ppePartners?.map(
                                //         (partner: any) => {
                                //           return {
                                //             ...partner,
                                //             value: {
                                //               ...partner?.value,
                                //               value: decimalNumber(
                                //                 data.target?.value?.value
                                //               ),
                                //               symbol:
                                //                 data?.target?.value?.symbol,
                                //             },
                                //           };
                                //         }
                                //       ),
                                //     },
                                //   };
                                //   MemoizedHandleTableField(
                                //     ppeEvent,
                                //     eIndex,
                                //     rIndex
                                //   );
                                // }
                              }}
                              handleOnFocus={(data: any) => {
                                focusOnPriceField(data, eIndex, rIndex);
                              }}
                              handleOnBlur={(data: any) => {
                                const value = decimalNumber(
                                  data.target?.value?.value
                                );
                                let event = {
                                  target: {
                                    name: data.target.name,
                                    value: {
                                      name: data.target.name,
                                      value: !isValidPriceFieldNumber(
                                        data.target.value.value
                                      )
                                        ? decimalNumber(0)
                                        : value,
                                      symbol: data?.target?.value?.symbol,
                                    },
                                  },
                                };
                                MemoizedHandleTableField(event, eIndex, rIndex);
                              }}
                              errorClass={`${
                                eventRecord?.errors?.sell_price
                                  ? eventRecord?.errors?.sell_price
                                  : ""
                              }`}
                              errorMessage={`${
                                eventRecord?.errors?.sell_price
                                  ? eventRecord?.errors?.sell_price
                                  : ""
                              }`}
                              errorEventIndex={
                                eventRecord?.errors?.sell_price &&
                                `proceedValue-${eventRecord?.card}`
                              }
                              // isDisabled={eventRecord?.ppe}
                              errorIndex={rIndex}
                              isPriceField={
                                priceFiledFocused?.data &&
                                priceFiledFocused?.eIndex === eIndex &&
                                priceFiledFocused?.rIndex === rIndex
                                  ? {
                                      withoutCategory: categoryExist(
                                        event,
                                        eventRecord,
                                        "withoutcategory"
                                      ),
                                      withCategory: categoryExist(
                                        event,
                                        eventRecord,
                                        "withcategory"
                                      ),
                                      isTableField: false,
                                      isFocused: priceFiledFocused?.data,
                                    }
                                  : false
                              }
                              fieldMessage={
                                avgPrice?.id === event?.id ||
                                eventRecord?.avg_price
                                  ? priceCalculation(
                                      eventRecord?.proceedValue?.value,
                                      eventRecord?.avg_price
                                        ? Number(eventRecord?.avg_price)
                                        : null,
                                      priceFactor
                                    )
                                  : false
                              }
                              isValidationPopupVisible={
                                categoryExist(
                                  event,
                                  eventRecord,
                                  "withcategory"
                                ) && !eventRecord?.avg_price
                                  ? true
                                  : false
                              }
                              isPriceTable={true}
                              indexPlusLength={{
                                rIndex: rIndex,
                                recordLength: event?.eventRecords?.length,
                              }}
                              avgPrice={eventRecord?.avg_price}
                              priceFieldToggle={priceFieldToggle}
                            />

                            {eventRecord?.ppe}

                            {ppeErrosValidation && rIndex === myIndex && (
                              <>
                                <TooltipPortal
                                  id={`ppe-error-${rIndex}`}
                                  content={Messages?.bulkEditRestrictPPE}
                                  className="text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium  text-xs z-[999999999] max-w-[9rem] cursor-default !text-rose-500"
                                />
                                <div
                                  id={`ppe-error-${rIndex}`}
                                  data-tooltip-id={`ppe-error-${rIndex}`}
                                  className="absolute top-1/2 -translate-y-1/2 right-4 "
                                >
                                  <IKTSVG
                                    className="flex items-center justify-center h-3.5 w-3.5 fill-rose-500 hover:fill-rose-500 transition"
                                    path={Warning1}
                                  />
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      )}
                      {/* <Tooltip
                      anchorId={`pricePerExchange-tooltip${event?.id}-${rIndex}`}
                      content="Price per Exchange"
                      place="top"
                      variant="light"
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
                    />
                    <button
                      className="relative price_exchange_btn flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-t group collapsed"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapsePriceExchange${event?.id}-${rIndex}`}
                      aria-expanded="true"
                      aria-controls={`collapsePriceExchange${event?.id}-${rIndex}`}
                      id={`pricePerExchange-tooltip${event?.id}-${rIndex}`}
                    >
                      <IKTSVG
                        className={`flex items-center justify-center  hover:fill-indigo-500 transition ${
                          eventRecord?.ppe
                            ? `fill-sky-blue-500`
                            : `fill-violet-500`
                        }`}
                        path={PPE}
                      />
                      <IKTSVG
                        className="absolute -bottom-2 [&_svg]:w-2.5 [&_svg]:h-2.5 left-1/2 -translate-x-1/2 transition block group-[.collapsed]:hidden"
                        path={SelectAngle}
                      />
                    </button> */}
                      <div className="icon-wrap flex">
                        {/* PPE */}
                        <button
                          className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem]  ${
                            ppeErrosValidation && "pointer-events-none"
                          } `}
                          type="button"
                          id={`price-per-hand-tooltip${eIndex}${eventRecord?.card}`}
                          // data-bs-toggle="collapse"
                          data-bs-target={`#collapsePPE${event?.id}-${eventRecord?.card}`}
                          aria-expanded="true"
                          aria-controls={`collapsePPE${event?.id}-${eventRecord?.card}`}
                          onClick={() => {
                            handlePPEOpen();
                          }}
                        >
                          <Tooltip
                            anchorId={`price-per-hand-tooltip${eIndex}${eventRecord?.card}`}
                            content={"Price per partner"}
                            place={
                              rIndex === 0 || rIndex === 1 ? "left" : "top"
                            }
                            variant="light"
                            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 ${
                              rIndex === 0 || rIndex === 1
                                ? "mt-0 ml-2"
                                : "mt-2"
                            }`}
                          />

                          <IKTSVG
                            path={PPPIcon}
                            className={`${
                              eventRecord?.errors?.PPP
                                ? "fill-rose-500"
                                : eventRecord?.ppe
                                ? "fill-green-500"
                                : "fill-violet-400"
                            }
                            ${ppeErrosValidation && "!fill-gray-500"}

`}
                            svgClassName={`w-[.9688rem] h-[.9688rem] ${
                              !isTablet ? "group-hover:fill-indigo-500" : ""
                            } `}
                          />
                        </button>
                        {/* TICKET IN HAND */}
                        <button
                          className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] border-l  ${
                            isAllFilesUploaded && "pointer-events-none"
                          }`}
                          type="button"
                          id={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                          onClick={() => {
                            // ticketInHandFromListings(eIndex, rIndex);
                            let target = {
                              name: "ticketsInHands",
                              checked: !eventRecord?.ticketsInHands,
                              type: "checkbox",
                            };
                            MemoizedHandleTableField(
                              { target },
                              eIndex,
                              rIndex
                            );
                          }}
                        >
                          <Tooltip
                            anchorId={`tickets-in-hand-tooltip${eIndex}${rIndex}`}
                            content={
                              eventRecord?.ticketsInHands
                                ? "Remove tickets in hand"
                                : "Tickets in hand"
                            }
                            place={
                              rIndex === 0 || rIndex === 1 ? "left" : "top"
                            }
                            variant="light"
                            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 ${
                              rIndex === 0 || rIndex === 1
                                ? "mt-0 ml-2"
                                : "mt-2"
                            }`}
                          />
                          {eventRecord?.ticketsInHands ? (
                            <>
                              <IKTSVG
                                path={handIcon}
                                svgClassName={`w-3 group-hover:fill-indigo-500 group-hover:hidden  ${
                                  isAllFilesUploaded
                                    ? "pointer-events-none fill-gray-400 "
                                    : "fill-green-600 "
                                } `}
                              />
                              <IKTSVG
                                path={handCross}
                                svgClassName="fill-violet-500 w-3 group-hover:fill-indigo-500 hover_icon group-hover:block hidden"
                              />
                            </>
                          ) : (
                            <>
                              <IKTSVG
                                path={handCross}
                                svgClassName="fill-violet-500 w-3  group-hover:fill-indigo-500 group-hover:hidden"
                              />
                              <IKTSVG
                                path={handIcon}
                                svgClassName="fill-green-600 w-3 group-hover:fill-indigo-500 hover_icon group-hover:block hidden"
                              />
                            </>
                          )}
                        </button>

                        {/* TICKET UPLOAD */}
                        <button
                          className={`group flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] border-l ${
                            // ((eventRecord?.ticketType?.id !== "eticket" &&
                            //   eventRecord?.ticketType?.id !== "mobile-link") ||
                            (_.size(layout?.isTableEditable) > 0 ||
                              layout.isDeleteActive) &&
                            `  pointer-events-none cursor-not-allowed`
                          }`}
                          type="button"
                          // data-bs-toggle="offcanvas"
                          // data-bs-target="#ppeRightCanvas"
                          // aria-controls="ppeRightCanvas"
                          onClick={(e: any) => {
                            if (
                              eventRecord?.ticketType?.id !== "eticket" &&
                              eventRecord?.ticketType?.id !== "pdf" &&
                              eventRecord?.ticketType?.id !== "mobile-qr" &&
                              eventRecord?.ticketType?.id !== "mobile-link"
                            ) {
                              e.preventDefault();
                            } else {
                              handleEticket(event, eventRecord);
                            }

                            //layout.setGetSelectedCount(true);
                          }}
                          id={`eTicket-upload-tooltip${eIndex}${rIndex}`}
                        >
                          <Tooltip
                            anchorId={`eTicket-upload-tooltip${eIndex}${rIndex}`}
                            content={
                              eventRecord?.ticketType?.id !== "eticket" &&
                              eventRecord?.ticketType?.id !== "pdf" &&
                              eventRecord?.ticketType?.id !== "mobile-qr" &&
                              eventRecord?.ticketType?.id !== "mobile-link"
                                ? "Not available"
                                : isAllFilesUploaded
                                ? "Tickets uploaded"
                                : "Upload ticket"
                            }
                            place={
                              rIndex === 0 || rIndex === 1 ? "left" : "top"
                            }
                            variant="light"
                            className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10 ${
                              rIndex === 0 || rIndex === 1
                                ? "mt-0 ml-2"
                                : "mt-2"
                            }`}
                          />
                          <IKTSVG
                            className={`flex items-center justify-center group-hover:fill-indigo-500 transition ${
                              eventRecord?.ticketType?.id !== "eticket" &&
                              eventRecord?.ticketType?.id !== "pdf" &&
                              eventRecord?.ticketType?.id !== "mobile-qr" &&
                              eventRecord?.ticketType?.id !== "mobile-link"
                                ? // && eventRecord?.ticketType?.id !== "mobile-link"
                                  "!fill-gray-400 "
                                : "fill-violet-500"
                            } ${
                              eventRecord?.errors?.["quantity_available"] ===
                                TicketError && "!fill-rose-500"
                            }
                        
                        ${
                          isAllFilesUploaded &&
                          (eventRecord?.ticketType?.id === "eticket" ||
                            eventRecord?.ticketType?.id === "pdf" ||
                            eventRecord?.ticketType?.id === "mobile-qr" ||
                            eventRecord?.ticketType?.id === "mobile-link") &&
                          "!fill-green-600"
                        } 
                        `}
                            path={Upload}
                            svgClassName="w-[.8125rem] h-[.6875rem]"
                          />
                        </button>

                        <button
                          className={`flex flex-auto items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.5rem] border-l`}
                          type="button"
                          id="visibility_addInventory"
                          onClick={() => {
                            setIsOpenListingQualityPopup(true);
                          }}
                        >
                          {/* <Tooltip
                          anchorId={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                          html={
                            // eventRecord?.visibility === 1
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 2
                            //   ? `Listing Quality Low`
                            //   : eventRecord?.visibility === 3
                            //   ? `Listing Quality Level 1`
                            //   : eventRecord?.visibility === 4
                            //   ? `Listing Quality Level 2`
                            //   : eventRecord?.visibility === 5
                            //   ? `Listing Quality Level 3`
                            //   : eventRecord?.visibility === 6
                            //   ? `Listing Quality Level 4`
                            //   : ""
                            tooltipForListingMeter(
                              eventRecord?.visibility,
                              eventRecord?.avg_price
                                ? priceCalculation(
                                    eventRecord?.proceedValue?.value,
                                    eventRecord?.avg_price
                                      ? Number(eventRecord?.avg_price)
                                      : null,
                                    priceFactor
                                  )
                                : false,
                              event
                            )
                          }
                          positionStrategy="fixed"
                          place={rIndex === 0 || rIndex === 1 ? "left" : "top"}
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                        /> */}

                          <TooltipPortal
                            id={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                            content={
                              // eventRecord?.visibility === 1
                              //   ? `Listing Quality Low`
                              //   : eventRecord?.visibility === 2
                              //   ? `Listing Quality Low`
                              //   : eventRecord?.visibility === 3
                              //   ? `Listing Quality Level 1`
                              //   : eventRecord?.visibility === 4
                              //   ? `Listing Quality Level 2`
                              //   : eventRecord?.visibility === 5
                              //   ? `Listing Quality Level 3`
                              //   : eventRecord?.visibility === 6
                              //   ? `Listing Quality Level 4`
                              //   : ""
                              tooltipForListingMeter(
                                eventRecord?.visibility,
                                eventRecord?.avg_price
                                  ? priceCalculation(
                                      eventRecord?.proceedValue?.value,
                                      eventRecord?.avg_price
                                        ? Number(eventRecord?.avg_price)
                                        : null,
                                      priceFactor
                                    )
                                  : false,
                                event
                              )
                            }
                            positionStrategy="fixed"
                            place={
                              rIndex === 0 || rIndex === 1 ? "left" : "top"
                            }
                            variant="light"
                            className="text-center !bg-white !opacity-100 shadow !px-1.5 !py-0.5 font-medium  !text-xs z-10 max-w-[9rem] cursor-default"
                          />

                          <div
                            id={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                            data-tooltip-id={`eTicket-upload-tooltip-${event?.id}-${rIndex}`}
                            // className={`table-meter flex gap-[.0875rem] svg-wrap visibility-case${
                            //   eventRecord?.visibility > 2 &&
                            //   eventRecord?.oldVisibility
                            // }-${visiblityMeterForBelowListing(
                            //   eventRecord?.visibility
                            // )}`}

                            className={`table-meter flex gap-[.0875rem] svg-wrap   ${
                              // categoryExist(event, eventRecord, "withcategory")
                              //   ? colorForMeter(
                              colorForMeter(
                                eventRecord?.avg_price
                                  ? priceCalculation(
                                      eventRecord?.proceedValue?.value,
                                      eventRecord?.avg_price
                                        ? Number(eventRecord?.avg_price)
                                        : null,
                                      priceFactor
                                    )
                                  : undefined,
                                eventRecord
                              )
                              //   )
                              // : ""
                            } 
                          
                          visibility-case${
                            eventRecord?.oldVisibility > 2
                              ? eventRecord?.oldVisibility - 2
                              : eventRecord?.oldVisibility
                          }-${visiblityMeterForBelowListing(
                              eventRecord?.oldVisibility,
                              eventRecord?.visibility
                            )}`}
                          >
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-l-[1px]"></span>
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span>
                            {/* <span className="s_block w-[.1563rem] h-3 bg-gray-300"></span> */}
                            <span className="s_block w-[.1563rem] h-3 bg-gray-300 rounded-r-[1px]"></span>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div
                      id={`collapsePriceExchange${event?.id}-${rIndex}`}
                      className="accordion-collapse collapse w-full h-full border-t rounded-none"
                      aria-labelledby="bodyfour"
                    >
                      <div className="accordion-body  relative inline-flex items-center w-full justify-evenly py-7 pt-[1.9375rem] pb-[2rem] rounded-none #{!important} ">
                        <button
                          type="button"
                          className="border group mx-1 rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center"
                          onClick={() => resetPPEValues(rIndex)}
                        >
                          <IKTSVG
                            path={Refresh}
                            className="fill-violet-500 group-hover:fill-white transition"
                            svgClassName="ml-[.44rem]"
                          />
                        </button>
                        <button
                          type="button"
                          className="group flex mx-1 items-center justify-center h-7 w-7 border border-gray-300 hover:border-indigo-500 text-white bg-white hover:bg-indigo-500 transition rounded"
                          onClick={() => unSetPPE(rIndex)}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapsePriceExchange${event?.id}-${rIndex}`}
                          aria-expanded="true"
                          aria-controls={`collapsePriceExchange${event?.id}-${rIndex}`}
                        >
                          <IKTSVG
                            className="fill-violet-500 group-hover:fill-white transition"
                            path={Cross}
                          />
                        </button>
                        <Tooltip
                          anchorId={`confirm-tooltip${event?.id}-${rIndex}`}
                          content="Confirm"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium text-xs z-10"
                        />
                        <button
                          id={`confirm-tooltip${event?.id}-${rIndex}`}
                          type="button"
                          className="border group mx-1 rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center"
                          onClick={() => {
                            setPPE(rIndex);
                          }}
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapsePriceExchange${event?.id}-${rIndex}`}
                          aria-expanded="true"
                          aria-controls={`collapsePriceExchange${event?.id}-${rIndex}`}
                        >
                          <IKTSVG
                            path={Check}
                            className="fill-violet-500 group-hover:fill-white transition"
                            svgClassName="ml-[.44rem]"
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              </div>
            </div>
            {/* ((layout.isTableEditable.length > 0 && eventRecord?.selected
              ? rIndex === myIndex && eventRecord?.ppeIsOpen
              : eventRecord?.ppeIsOpen) ||
              (eventRecord.ppe && eventRecord.ppeIsOpen))  */}
            {(eventRecord?.ppeIsOpen ||
              (eventRecord.ppe && eventRecord.ppeIsOpen)) && (
              <div
                id={`collapsePPE${event?.id}-${eventRecord?.card}`}
                className={`flex w-full ppeBlock border-b ${
                  eventRecord?.selected === true &&
                  layout?.isTableEditable.length > 0 &&
                  "bg-indigo-500 bg-opacity-[7%]"
                }  ${
                  layout.isTableEditable.length > 0 &&
                  !eventRecord?.selected &&
                  "row-disabled"
                } ${
                  rIndex !== firstSelectedIndex &&
                  layout.isTableEditable.length > 0 &&
                  "row-disabled"
                } ${ppeErrosValidation && "row-disabled"}`}
                aria-labelledby="bodyfour"
              >
                <div className=" w-[2.5rem]  min-h-[1.9375rem] sticky left-0"></div>
                <div className="p-0 equal-td-height flex items-start sticky left-[2.5rem] PPEList py-2 px-[.9375rem] gap-x-2.5 z-[1] focus-within:z-[3]">
                  <div className="singleList toggle bg-white rounded flex items-center px-3 py-5">
                    <div className="innerFieldPPE flex items-center">
                      PPP
                      <IKTSVG
                        className="ml-[.3125rem] mr-2"
                        svgClassName="w-3.5 h-3.5  fill-gray-400 hover:fill-indigo-500 cursor-pointer"
                        path={infoIcon}
                        dataTooltipID={`${eIndex}-${rIndex}-PPP`}
                      />
                      <TooltipPortal
                        id={`${eIndex}-${rIndex}-PPP`}
                        content={Messages.pppToggleTooltipMessage}
                        place="top"
                        variant="light"
                        className={`text-center !bg-white !opacity-100 shadow px-1.5 py-0.5 font-medium max-w-[9rem]  text-xs z-[999999999] cursor-default`}
                      />
                      <div className="flex">
                        <label
                          className={`relative inline-flex items-center cursor-pointer `}
                        >
                          <input
                            type="checkbox"
                            name="ppe"
                            className="sr-only peer"
                            checked={eventRecord?.ppe}
                            onChange={(e) => {
                              const data = {
                                target: {
                                  name: "ppe",
                                  checked: e?.target?.checked,
                                  type: "checkbox",
                                },
                              };
                              MemoizedHandleTableField(data, eIndex, rIndex);
                            }}
                          />
                          <div
                            className={`bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500 w-7 h-3  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0.5 after:-left-0.5  after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100 after:bg-center after:bg-no-repeat after:bg-8`}
                          ></div>
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* TX NETWORK */}
                  <PPEComponent
                    id={`tx-network-${eIndex}-${rIndex}`}
                    label="TX Network"
                    name={`proceedValue`}
                    value={eventRecord?.proceedValue?.value}
                    currencySymbol={target_currency}
                    isTooltip={true}
                    tooltipContent={Messages.txNetworkTooltipMessage}
                    ListPPE={true}
                    errors={eventRecord?.errors?.sell_price ? true : false}
                    handleOnChange={(data: any) => {
                      MemoizedHandleTableField(data, eIndex, rIndex);
                    }}
                    //  ={eventRecord?.ppe}
                    // disabled={eventRecord?.ppe}
                    // txNetwork={true}
                    // isPPEActive={true}
                    isPPEActive={eventRecord?.ppe}
                    errorClass={`${
                      eventRecord?.ppe && eventRecord?.errors?.sell_price
                        ? eventRecord?.errors?.sell_price
                        : ""
                    }`}
                    errorMessage={`${
                      eventRecord?.ppe && eventRecord?.errors?.sell_price
                        ? eventRecord?.errors?.sell_price
                        : ""
                    }`}
                    // disabled={
                    //   !(rIndex === firstSelectedIndex && eventRecord?.selected)
                    // }
                    edited={
                      layout.tableDataEditChanging?.[eIndex]?.includes(
                        "proceedValue"
                      ) &&
                      rIndex !== firstSelectedIndex &&
                      eventRecord?.selected
                    }
                    txNetwork={true}
                    isPriceField={{
                      withoutCategory: categoryExist(
                        event,
                        eventRecord,
                        "withoutcategory"
                      ),
                      withCategory: categoryExist(
                        event,
                        eventRecord,
                        "withcategory"
                      ),
                      isTableField: false,
                      isFocused: priceFiledFocused?.data,
                    }}
                    fieldMessage={
                      avgPrice?.id === event?.id || eventRecord?.avg_price
                        ? priceCalculation(
                            eventRecord?.proceedValue?.value,
                            eventRecord?.avg_price
                              ? Number(eventRecord?.avg_price)
                              : null,
                            priceFactor
                          )
                        : false
                    }
                    isValidationPopupVisible={
                      categoryExist(event, eventRecord, "withcategory") &&
                      !eventRecord?.avg_price
                        ? true
                        : false
                    }
                    indexPlusLength={{
                      rIndex: rIndex,
                      recordLength: event?.eventRecords?.length,
                    }}
                    avgPrice={eventRecord?.avg_price}
                  />

                  {eventRecord?.ppePartners?.map((partner: any) => {
                    if (partner?.name && !partner?.is_hidden) {
                      return (
                        <PPEComponent
                          id={partner?.id}
                          label={partner?.name}
                          name={partner?.name}
                          value={partner?.value?.value}
                          currencySymbol={target_currency}
                          errors={eventRecord?.errors?.[partner?.name]}
                          isPPEActive={eventRecord?.ppe}
                          tooltipContent={`Your default proceed price across 10+ partners in the TX Network`}
                          handleOnChange={(data: any) => {
                            MemoizedHandleTableField(data, eIndex, rIndex);
                          }}
                          ListPPE={true}
                          isMatchedValue={
                            eventRecord?.ppe &&
                            removeCommaFromNumber(
                              decimalNumber(eventRecord?.proceedValue?.value)
                            ) !==
                              removeCommaFromNumber(
                                decimalNumber(partner?.value?.value)
                              )
                          }
                          errorClass={`${
                            eventRecord?.errors?.[partner?.name]
                              ? eventRecord?.errors?.[partner?.name]
                              : ""
                          }`}
                          errorMessage={`${
                            eventRecord?.errors?.[partner?.name]
                              ? eventRecord?.errors?.[partner?.name]
                              : ""
                          }`}
                          // disabled={
                          //   !(
                          //     rIndex === firstSelectedIndex &&
                          //     eventRecord?.selected
                          //   )
                          // }
                          edited={
                            layout.tableDataEditChanging?.[eIndex]?.includes(
                              partner?.name
                            ) &&
                            rIndex !== firstSelectedIndex &&
                            eventRecord?.selected
                          }
                        />
                      );
                    } else return <></>;
                  })}
                </div>
              </div>
            )}

            <div
              id={`collapsePriceExchange${event?.id}-${rIndex}`}
              className="accordion-collapse collapse border-b"
              aria-labelledby="bodyfour"
            >
              <div className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[5.625rem] border-r p-1 text-center"></div>
              {Object.keys(
                eventRecord?.marketplaces ? eventRecord?.marketplaces : []
              ).length > 0 ? (
                eventRecord?.marketplaces.map(
                  (marketplace: any, marketPlaceIndex: number) => {
                    return (
                      <div className="border-r" key={marketPlaceIndex}>
                        <div className="w-full ">
                          <div>
                            <div>
                              <div className="px-1.5 py-1 font-medium whitespace-nowrap">
                                {marketplace.title}
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="text-gray-300">
                              <div className="px-1.5 text-gray-400 font-medium whitespace-nowrap">
                                Proceed price
                              </div>
                              <div className="px-1.5 text-gray-400 font-medium whitespace-nowrap">
                                Display price
                              </div>
                            </div>
                          </div>
                          <div>
                            <div>
                              <div className="p-1.5 font-medium w-0">
                                <PriceField
                                  name={marketplace.exchange_id}
                                  currenncySymbol={target_currency}
                                  value={
                                    eventRecord["ppeExchanges"]?.[
                                      marketplace.exchange_id
                                    ]
                                      ? eventRecord["ppeExchanges"][
                                          marketplace.exchange_id
                                        ]["proceed_price"]
                                      : ""
                                  }
                                  handleOnChange={(data: any) =>
                                    handlePPETableChange(data, eIndex, rIndex)
                                  }
                                />
                              </div>

                              <div className="p-1.5 font-medium w-0 pointer-events-none">
                                <PriceField
                                  disabled
                                  name={marketplace.exchange_id}
                                  value={
                                    eventRecord["ppeExchanges"]?.[
                                      marketplace.exchange_id
                                    ]
                                      ? eventRecord["ppeExchanges"][
                                          marketplace.exchange_id
                                        ]["display_price"]
                                      : ""
                                  }
                                  defaultSymbol={getDisplayPriceCurrency(
                                    marketplace.exchange_id,
                                    eIndex
                                  )}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  }
                )
              ) : (
                <div>
                  <div className="flex items-center py-3 px-4 text-rose-500">
                    <p>{Messages.ppeSelectExchange}</p>
                  </div>{" "}
                </div>
              )}
            </div>

            {includes(["failed", "partPublished"], eventRecord?.status) && (
              <div className={`second_row flex border-b bg-gray-100`}>
                <div className="min-w-[2.5rem] max-w-[2.5rem] min-h-[1.9375rem]"></div>
                <div className="p-0 equal-td-height flex items-start sticky left-[2.5rem]">
                  <div
                    className={`error-box border-b border-l border-r ml-1.5 -mt-[1px] rounded-b mb-2 
                    ${eventRecord?.status === "failed" && "row-error-new"}
                    ${
                      eventRecord?.status === "partPublished" &&
                      "row-processing-new"
                    }
                  `}
                  >
                    {Object.keys(
                      eventRecord?.marketplaces ? eventRecord?.marketplaces : []
                    ).length > 0 &&
                      eventRecord?.marketplaces.map(
                        (exchange: any, exchangeIndex: number) => {
                          return (
                            exchange?.error && (
                              <div
                                key={exchangeIndex}
                                className="px-2.5 pb-[.3125rem] pt-0 flex"
                              >
                                <span
                                  className={`
                                text-xs inline-block font-semibold
                                ${
                                  eventRecord?.status === "failed" &&
                                  "text-rose-500"
                                }
                                ${
                                  eventRecord?.status === "partPublished" &&
                                  "text-orange-500"
                                }  
                                `}
                                >
                                  {exchange.title} Error:
                                </span>{" "}
                                <p className="text-gray-600 inline font-normal">
                                  {exchange?.error}
                                </p>
                              </div>
                            )
                          );
                        }
                      )}

                    {eventRecord?.errors?.commonErrors &&
                      isArray(eventRecord?.errors?.commonErrors) &&
                      eventRecord?.errors?.commonErrors.length > 0 && (
                        <p className="text-gray-600 inline font-normal px-2.5 pb-[.3125rem] pt-0 flex">
                          {
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  eventRecord?.errors?.commonErrors.join(
                                    "<br/>"
                                  ),
                              }}
                            />
                          }
                        </p>
                      )}
                  </div>
                </div>
              </div>
            )}
          </div>
        </React.Fragment>
      );
    } else {
      return <></>;
    }
  }
);

export default ItemContent;
