/* eslint-disable @typescript-eslint/no-unused-vars */
import Cookies from "js-cookie";
import { useContext, useEffect, useRef, useState } from "react";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import MultiDropDownField from "../../../components/formComponent/MultiDropDownField";
import AddInventoryButton from "../../../components/ui/AddInventoryButton";
import { handleSelectDeselectAll } from "../../../helpers/Functions";

interface HeaderComponentProps {
  isLoading?: boolean;
  teamOption: any;
  selectedTeams: any;
  setSelectedTeams: any;
  isGodAdmin: any;
  inventoryAccess: any;
}

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  teamOption,
  setSelectedTeams,
  selectedTeams,
  isGodAdmin,
  inventoryAccess
}) => {
  const layout = useContext(LayoutContext);
  const teamMemberSelectedRef: any = useRef<any>();

  var authDataString = Cookies.get("authData") as any;
  authDataString = authDataString ? JSON.parse(authDataString) : {};

  const [teamOptions, setTeamOptions] = useState<any>();

  useEffect(() => {
    if (teamOption) {
      setTeamOptions(teamOption);
    }
  }, [teamOption]);

  return (
    <div className="flex flex-wrap items-start">
      <div className="flex md:flex-nowrap flex-wrap items-center flex-1 justify-center md:justify-between bg-white/40 py-4 px-5 md:-mx-5">
        {isGodAdmin && (
          <div className=" w-full sm:w-auto sm:max-w-[11.4375rem] md:w-full">
            <MultiDropDownField
              isCapital={true}
              isValueCapital={true}
              options={teamOptions}
              classNamePrefix={"form_multiDropDown"}
              selectedRef={teamMemberSelectedRef}
              placeholder={selectedTeams?.length > 0 ? "Teams" : "All Teams"}
              isMultiSelection={true}
              isClearable={true}
              name="Teams"
              handleOnChange={(data: any) => {
                setSelectedTeams(data?.target?.value);
              }}
              handleOnSelection={(data: any, visibleOptions: any) => {
                if (teamMemberSelectedRef && teamMemberSelectedRef?.current) {
                  teamMemberSelectedRef.current.blur();
                }
                handleSelectDeselectAll(
                  data,
                  "Teams",
                  teamOptions,
                  visibleOptions,
                  setSelectedTeams,
                  true
                );
              }}
              value={selectedTeams}
              label="Teams"
            />
          </div>
        )}

        {/* /DropDown  */}
        {!isGodAdmin && inventoryAccess && (
          <div className="flex sm:flex-nowrap flex-wrap md:justify-none justify-center md:w-auto w-full items-center lg:max-w-[32%] md:max-w-[34%] ml-auto md:order-none order-3">
            <div>
              {layout.userDetailShow && (
                <AddInventoryButton title="Add Inventory" />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HeaderComponent;
