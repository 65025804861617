import _ from "lodash";
import { useContext } from "react";
import NoJSXComponent from "../../../../../components/noJSX/NoJSXComponent";
import {
  INVENTORY_VALIDATE_COLUMN,
  pngImagePath,
} from "../../../../../helpers/const";
import TooltipPortal from "../../../../tx_trade/table/component/TooltipPortal";
import { getFieldErrorColumns } from "../../../core/_functions";
import { Messages } from "../../../core/_messages";
import InventoryNoJSX from "../../../core/InventoryNoJSX";
import { InventoryContext } from "../../../core/InventoryProvider";
import Image from "../mobileViewComponent/Image";

type TEventRecordStatus = {
  event: Record<string, any>;
  eventRecord: Record<string, any>;
  eIndex: string | number;
  rIndex: string | number;
  tooltipRequired?: boolean;
};

const EventRecordStatus = ({
  event,
  eventRecord,
  eIndex,
  rIndex,
  tooltipRequired,
}: TEventRecordStatus) => {
  const layout = useContext(InventoryContext);
  const { isGodAdmin } = NoJSXComponent();
  const {
    totalHold,
    isEventRecordError,
    isEventRecordBEError,
    isEventRecordHoldTickets,
    isALLTicketUploadedWithPublish,
  } = InventoryNoJSX({
    event,
    eventRecord,
  });

  const isPublishedWithGod =
    (isALLTicketUploadedWithPublish && !isGodAdmin) ||
    (isGodAdmin &&
      isALLTicketUploadedWithPublish &&
      eventRecord?.isLock === false);

  let fieldErrors: any = [];

  let allErrors =
    isEventRecordError ||
    isEventRecordHoldTickets ||
    isALLTicketUploadedWithPublish ||
    _.size(fieldErrors) > 0 ||
    isEventRecordBEError ||
    eventRecord?.processing;

  return allErrors ? (
    <div className={`second_row flex !p-l-[2.5rem] w-full bg-gray-100`}>
      <div
        className={`border-b ${
          isGodAdmin ? "w-[5rem]" : "w-[2.5rem]"
        }  min-h-[1.9375rem] sticky left-0`}
      ></div>
      <div className="p-0 equal-td-height border-b flex items-start sticky left-[2.5rem]">
        <div
          className={`error-box border-b border-l border-r ml-1.5 -mt-[1px] rounded-b mb-2 
                  ${
                    isEventRecordHoldTickets &&
                    "row-hold-new group-hover:bg-sky-blue-300"
                  }
                  ${
                    isEventRecordError ||
                    (isEventRecordBEError &&
                      "row-error-new group-hover:bg-rose-550")
                  }
                  ${
                    isGodAdmin &&
                    eventRecord?.isLock === true &&
                    "bg-gray-300/40"
                  } ${isPublishedWithGod && "bg-white"}
                `}
        >
          {fieldErrors && _.size(fieldErrors) > 0 && (
            <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500 restriction">
              Error:
              <div>
                <div className="text-violet-200 text-xs inline-block font-semibold flex pl-1">
                  There are some errors in {fieldErrors?.join(",")}
                </div>
              </div>
            </div>
          )}
          {isEventRecordBEError &&
            eventRecord?.errors?.map((error: any, errorIndex: number) => {
              // Filter out errors that are included in INVENTORY_VALIDATE_COLUMN
              const filteredErrors = error?.errors?.filter(
                (e: string) =>
                  ![
                    ...INVENTORY_VALIDATE_COLUMN,
                    ...layout.deliveryType?.map((item: any) => item.id),
                    ...(eventRecord?.ppePartners &&
                    _.size(eventRecord?.ppePartners) > 0
                      ? eventRecord?.ppePartners?.map(
                          (partner: any) => partner?.name
                        )
                      : []),
                  ]?.includes(e)
              );

              const uniqueKey = `${event?.id}-${eIndex}-${rIndex}-${eventRecord?.id}-${errorIndex}`;

              // Render only if there are non-excluded errors
              if (filteredErrors && filteredErrors.length > 0) {
                return (
                  <div
                    className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500"
                    key={uniqueKey}
                  >
                    Error&nbsp;
                    <div>
                      {error.exchange && (
                        <span className="text-rose-500 text-xs inline-block font-semibold">
                          {error.exchange}: &nbsp;
                        </span>
                      )}
                      <p className="!text-gray-600 inline font-normal">
                        {filteredErrors.join(",")}
                      </p>
                    </div>
                  </div>
                );
              }

              // If no errors to render, return null or an empty fragment
              return null;
            })}
          {isEventRecordError &&
            _.size(getFieldErrorColumns(eventRecord, layout?.deliveryType)) >
              0 && (
              <div className="px-2.5 pb-[.3125rem] pt-0 flex text-rose-500">
                Error:&nbsp;
                <div>
                  <p className="text-gray-600 inline font-normal">
                    There are some errors in{" "}
                    {getFieldErrorColumns(
                      eventRecord,
                      layout?.deliveryType
                    )?.join(", ")}
                    {_.size(
                      getFieldErrorColumns(eventRecord, layout?.deliveryType)
                    ) > 1
                      ? " fields"
                      : " field"}
                  </p>
                </div>
              </div>
            )}
          {isALLTicketUploadedWithPublish && (
            <div className="px-2.5 pb-[.3125rem] pt-0 flex text-green-500 restriction">
              Tickets uploaded:
              <div>
                {"   "}
                <div className="text-gray-600 text-xs inline-block font-medium flex pl-1">
                  {"   "} Editing restricted{" "}
                  {tooltipRequired ? (
                    <TooltipPortal
                      id={`${eventRecord?.id}-ticketsUploaded`}
                      content={Messages?.ticketRestricted}
                      className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10 mt-1.5 max-w-xs text-center"
                    />
                  ) : null}
                  <div
                    className="flex group pl-1"
                    data-tooltip-id={`${eventRecord?.id}-ticketsUploaded`}
                  >
                    <Image
                      path={`${pngImagePath}info-gray.png`}
                      className="w-[.875rem] h-[.875rem] group-hover:hidden"
                    />
                    <Image
                      path={`${pngImagePath}info-purple.png`}
                      className="w-[.875rem] h-[.875rem] hover-icon hidden group-hover:block"
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
          {isEventRecordHoldTickets && (
            <div className="px-2.5 pb-[.3125rem] pt-0">
              <div className="">
                <p className="text-sky-blue-500 text-xs flex flex-inline font-semibold">
                  {Messages?.ticketHoldLine1_new}
                  <span className="text-gray-600 font-normal flex flex-inline">
                    &nbsp;{totalHold} of{" "}
                    {totalHold + eventRecord?.quantity_available} tickets on
                    hold for 30 mins. Editing is restricted
                    <span
                      className="ml-2 mt-0.5"
                      id={`${eventRecord?.id}-OnHoldMessageIcon`}
                      data-tooltip-id={`${eventRecord?.id}-OnHoldMessageIcon`}
                    >
                      {tooltipRequired ? (
                        <TooltipPortal
                          id={`${eventRecord?.id}-OnHoldMessageIcon`}
                          content={`${
                            isEventRecordHoldTickets &&
                            eventRecord?.quantity_available <
                              eventRecord?.listing_ticket_files?.length
                              ? Messages?.ticketHoldLine3_new
                              : Messages?.ticketHoldLine2_new
                          }`}
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-100 mt-1.5 max-w-xs text-center"
                        />
                      ) : null}
                      <Image
                        path={`${pngImagePath}info-gray.png`}
                        className="w-[.875rem] h-[.875rem]"
                        alt="infor-circle"
                      />
                    </span>
                  </span>
                </p>
              </div>
            </div>
          )}
          {eventRecord?.processing ? (
            <div className="p-1 h-[100%]">
              <p className="text-gray-600 inline font-normal">
                {Messages?.ticketProcessing}
              </p>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  ) : (
    <></>
  );
};

export default EventRecordStatus;
