import { useContext } from "react";
import { InventoryContext } from "../../core/InventoryProvider";

const TicketTypeExist = (event: any) => {
  const layout = useContext(InventoryContext);

  let selectedDeliveryTypesSet = new Set<string>();
  let paperTypeExist = false;

  for (const eventRecord of event?.eventRecords || []) {
    if (
      !paperTypeExist &&
      (eventRecord.ticket_type?.id === "paper" ||
        eventRecord.ticket_type === "paper")
    ) {
      paperTypeExist = true;
    }

    for (const dT of eventRecord?.delivery_options || []) {
      const deliveryType = layout.deliveryType.find(
        ({ id }: any) => id === dT.id
      )?.type;
      if (deliveryType) {
        selectedDeliveryTypesSet.add(deliveryType);
      }
    }

    if (
      paperTypeExist &&
      selectedDeliveryTypesSet.size === layout.deliveryType.length
    ) {
      break;
    }
  }

  const selectedDeliveryTypes = Array.from(selectedDeliveryTypesSet);
  const deliveryTypeExist = selectedDeliveryTypes.length;

  return { selectedDeliveryTypes, paperTypeExist, deliveryTypeExist };
};

export default TicketTypeExist;
