export const handleCategoryToggle =
  (index: any, setOpenModalIndex: any) => () => {
    setOpenModalIndex((prevState: any) => (prevState === index ? null : index));
  };

export const updateCategory = (
  setCategories: any,
  mainIndex: any,
  value: any = {}
) => {
  setCategories((prevState: any) =>
    prevState.map((category: any, index: any) =>
      mainIndex === index ? { ...category, ...value } : category
    )
  );
};

export const addNestedCategory = (setCategories: any, index: any) => () => {
  setCategories((prev: any) =>
    prev.map((prevItem: any, i: any) => ({
      ...prevItem,
      sub_menu:
        i === index
          ? prevItem.sub_menu.concat({
              id: "",
              name: "",
              type: "",
              type_id: "",
              isToggle: false,
              isEditable: false,
              sub_menu: [],
            })
          : prevItem.sub_menu,
    }))
  );
};

export const updateSubCategory = (
  mainIndex: any,
  subIndex: any,
  setCategories: any,
  value = {}
) => {
  setCategories((prevstate: any) =>
    prevstate.map((prevItem: any, index: any) => ({
      ...prevItem,
      sub_menu:
        index === mainIndex
          ? prevItem.sub_menu.map((subItem: any, subIdx: any) =>
              subIdx === subIndex
                ? {
                    ...subItem,
                    ...value,
                  }
                : subItem
            )
          : prevItem.sub_menu,
    }))
  );
};

export const updateNestedCategory = (
  mainIndex: any,
  subIndex: any,
  nestIndex: any,
  setCategories: any,
  value = {}
) => {
  setCategories((prevstate: any) =>
    prevstate.map((prevItem: any, i: any) => ({
      ...prevItem,
      sub_menu:
        i === mainIndex
          ? prevItem.sub_menu.map((subItem: any, subI: any) =>
              subI === subIndex
                ? {
                    ...subItem,
                    sub_menu: subItem.sub_menu.map(
                      (nestItem: any, nestIdx: any) =>
                        nestIdx === nestIndex
                          ? { ...nestItem, ...value }
                          : nestItem
                    ),
                  }
                : subItem
            )
          : prevItem.sub_menu,
    }))
  );
};

export const addSubNestedCategory =
  (setCategories: any, index: any, subIndex: any) => () => {
    setCategories((prevCategory: any) =>
      prevCategory.map((prevItem: any, i: any) => ({
        ...prevItem,
        sub_menu:
          i === index
            ? prevItem.sub_menu.map((subItem: any, subI: any) =>
                subI === subIndex
                  ? {
                      ...subItem,
                      sub_menu: subItem.sub_menu.concat({
                        id: "",
                        name: "",
                        type: "",
                        type_id: "",
                        isEditable: false,
                      }),
                    }
                  : subItem
              )
            : prevItem.sub_menu,
      }))
    );
  };
