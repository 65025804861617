import { size } from "lodash";
import { useContext, useState } from "react";
import addIcon from "../../../assets/media/icons/other_icons/add_icon.svg";
import fileSVG from "../../../assets/media/icons/other_icons/file.svg";
import addIconGray from "../../../assets/media/icons/other_icons/gray-add.svg";
import payoutGray from "../../../assets/media/icons/other_icons/payoutGray.svg";
import payoutIcon from "../../../assets/media/icons/other_icons/payoutIcon.svg";
import processSVG from "../../../assets/media/icons/other_icons/pure-process.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import ProgressBardLoader from "../../../components/ui/ProgressBardLoader";
import { showAlert } from "../../../helpers/ShowAlert";
import { TX_ACCOUNT_ORDER_PAYOUT_SELLER } from "../../../helpers/const";
import { TxAccountContext } from "../core/TxAccountProvider";
import { getTXAccountOrdersListings } from "../core/_request";
import { downloadFile } from "../core/functions";

const OrderFooter = ({ commonConfirmation, setCommonConfirmation }: any) => {
  const coreLayout = useContext(LayoutContext);
  const layout = useContext(TxAccountContext);
  const orderFilters = layout.orderFilters;
  const [loader, setLoader] = useState<boolean>();

  const exportToCsv = async (e: any) => {
    e.preventDefault();
    setLoader(true);
    const data: any = await getTXAccountOrdersListings({
      orderFilters,
      export_to_csv: 1,
    }).then((response: any) => {
      if (response?.data && size(response?.data) > 0) {
        let status = downloadFile({
          data: response?.data.replace(/\\n/g, "\n"),
          fileName: "txaccount_order.csv",
          fileType: "text/csv",
        });
        setLoader(status);
      } else if (response?.errors && size(response?.errors) > 0) {
        showAlert(response?.errors?.title, true);
        setLoader(false);
      } else {
        showAlert("Something went wrong!", true);
        setLoader(false);
      }
    });
  };

  //  DISABLED CONDITION
  const draftInvoicesDisabled = (disabledFor: string) => {
    const hasMarketplaceFilters = size(orderFilters?.marketplace) > 0;
    const hasCompletedOrderStatus =
      orderFilters?.order_status &&
      Array.isArray(orderFilters?.order_status) &&
      size(orderFilters?.order_status) > 0 &&
      (orderFilters?.order_status).every(
        (obj: any) =>
          obj?.description === "Completed" ||
          obj?.description === "Awaiting Delivery"
      );

    let marketplace_currency = orderFilters?.marketplace_currency;
    const isMarketplacesPaymentUnpaid =
      orderFilters?.marketplaces_payment_status?.id === "unpaid";
    const hasTeamsFilters = size(orderFilters?.teams) > 0;
    const hasPaymentsFilters =
      orderFilters?.payment_status &&
      Array.isArray(orderFilters?.payment_status) &&
      size(orderFilters?.payment_status) > 0 &&
      orderFilters?.payment_status?.every((item: any) => item?.id === "unpaid");

    switch (disabledFor) {
      case "draft":
        return (
          hasMarketplaceFilters &&
          hasCompletedOrderStatus &&
          isMarketplacesPaymentUnpaid &&
          size(layout.orderList) > 0 &&
          marketplace_currency
        );

      case "payout":
        return (
          hasTeamsFilters &&
          hasPaymentsFilters &&
          // (orderFilters?.order_status || [])?.length > 0 &&
          size(layout.orderList) > 0
        );
    }
  };

  //   HANDLE DRAFT INVOICE
  const handleOnDraftInvoice = () => {
    layout?.setCustomLoader(true);
    getTXAccountOrdersListings({
      orderFilters: layout?.orderFilters,
      get_teams: true,
    }).then((res: any) => {
      setCommonConfirmation((pre: any) => {
        layout?.setCustomLoader(false);
        return {
          ...pre,
          draft_invoice_initial_step: true,
          listing_data: {
            list: layout.orderList,
            filterData: {
              ...layout.orderFilters,
              orders: {
                value: layout.ordersOverViewDetails?.overview?.total_orders,
                excludeOrders: "",
              },
              invoiceValue: {
                value: res?.data?.invoice_value_total,
                excludeInvoice: res?.data?.orders_count_excluded,
                currency: res?.data?.marketplace_currency,
              },
              teamsOption: {
                options: res?.data?.teams,
                value: res?.data?.teams_count_total,
                excludeValue: "",
              },
            },
          },
        };
      });
    });
  };

  // PAYOUT SELLER
  const handlePayoutSeller = () => {
    layout?.setCustomLoader(true);

    getTXAccountOrdersListings({
      orderFilters: layout?.orderFilters,
      get_teams: true,
    }).then((res: any) => {
      let payoutResponse = res?.data?.team_payouts;
      let currency_payout_totals = res?.data?.currency_payout_totals;
      let teamsOptions = coreLayout?.allowedAccess?.teams;
      if (res?.data?.team_payouts && size(res?.data?.team_payouts) > 0) {
        payoutResponse = payoutResponse?.map((item: any) => {
          return {
            ...item,
            teamName: teamsOptions.find(
              (option: any) => option.id === item.team_id
            ),
          };
        });

        layout?.setOrderPayoutSeller((current: any) => {
          layout?.setCustomLoader(false);
          return {
            ...current,
            initial_step: true,
            listing_data: {
              list: layout?.orderList,
              filterData: {
                orders: {
                  value: layout.ordersOverViewDetails?.overview?.total_orders,
                  excludes: "",
                },
                eventDate: layout.orderFilters?.event_date,
                payoutResponse,
                currency_payout_totals,
                otherFilters: layout.orderFilters,
              },
            },
          };
        });
      } else {
        layout?.setCustomLoader(false);
      }
    });
  };

  // CONDITIONAL RENDERING COMPONENT
  let renderComponent = <></>;

  if (commonConfirmation?.draft_invoice_initial_step) {
    renderComponent = (
      <div
        className={`${
          loader ? "pointer-events-none" : ""
        } flex flex-nowrap items-center gap-2.5  text-sm13 anim-fade`}
      >
        <span>
          Do you want to <span className="font-semibold">DRAFT AN INVOICE</span>{" "}
          using these filters?
        </span>
        <button
          className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
         bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
          type="button"
          onClick={() => {
            setCommonConfirmation((pre: any) => {
              return {
                ...pre,
                draft_invoice_initial_step: false,
                draft_invoice_next_step: false,
              };
            });
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
        bg-violet-500 hover:bg-indigo-500 text-white hover:text-white
         "
          onClick={() => {
            setCommonConfirmation((pre: any) => {
              return {
                ...pre,
                draft_invoice_next_step: true,
              };
            });
          }}
        >
          Next step
        </button>
      </div>
    );
  } else if (layout?.orderPayoutSeller?.initial_step) {
    renderComponent = (
      <div
        className={`${
          loader ? "pointer-events-none" : ""
        } flex flex-nowrap items-center gap-2.5  text-sm13 anim-fade`}
      >
        <span>
          Do you want to <span className="font-semibold">PAYOUT</span> using
          these filters?
        </span>
        <button
          className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
 bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
          type="button"
          onClick={() => {
            layout.setOrderPayoutSeller(TX_ACCOUNT_ORDER_PAYOUT_SELLER);
          }}
        >
          Cancel
        </button>
        <button
          type="button"
          className="group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
bg-violet-500 hover:bg-indigo-500 text-white hover:text-white
"
          onClick={() => {
            layout?.setOrderPayoutSeller((current: any) => {
              return {
                ...current,
                next_step: true,
                authorize_pay_step: true,
              };
            });
          }}
        >
          Next step
        </button>
      </div>
    );
  } else {
    renderComponent = (
      <>
        <div
          className={`${
            loader ? "pointer-events-none" : ""
          } right-btns flex items-center gap-2.5 anim-fade`}
        >
          <button
            type="button"
            className={`group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
              draftInvoicesDisabled("draft")
                ? "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
                : "pointer-events-none bg-gray-100 text-gray-400"
            } `}
            onClick={() => {
              handleOnDraftInvoice();
            }}
          >
            <IKTSVG
              className="mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
              svgClassName="w-3 h-3"
              path={draftInvoicesDisabled("draft") ? addIcon : addIconGray}
            />
            Draft invoice
          </button>

          {/* PAYLOAD - BUTTON */}
          <button
            type="button"
            className={`group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 ${
              draftInvoicesDisabled("payout")
                ? // true
                  "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
                : "pointer-events-none bg-gray-100 text-gray-400"
            } `}
            onClick={() => {
              handlePayoutSeller();
            }}
          >
            <IKTSVG
              className="mr-2 flex items-center justify-center fill-white"
              svgClassName="w-3 h-3"
              path={draftInvoicesDisabled("payout") ? payoutIcon : payoutGray}
            />
            Payout to sellers
          </button>
        </div>
      </>
    );
  }

  return (
    <div
      className={`flex whitespace-nowrap items-center justify-between gap-2.5 my-2 w-full`}
    >
      <button
        type="button"
        className={`${
          loader || size(layout?.orderList) === 0
            ? "pointer-events-none bg-gray-100 text-gray-400"
            : "text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500"
        } group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5`}
        onClick={(event) => {
          !loader && exportToCsv(event);
        }}
      >
        {loader ? (
          <IKTSVG
            className={`flex items-center justify-center fill-violet-500 transition animate-spin group-hover:fill-white mr-1`}
            path={processSVG}
          />
        ) : (
          <IKTSVG
            className={`min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center ${
              size(layout?.orderList) === 0
                ? "fill-gray-300"
                : "fill-violet-500 group-hover:fill-white"
            } `}
            path={fileSVG}
            svgClassName="w-[.6875rem] h-[.8438rem]"
          />
        )}
        Export to CSV
      </button>{" "}
      {/* RENDER  */}
      {renderComponent}
      {/* LOADER */}
      {layout?.customLoader && <ProgressBardLoader secondCounter={1} />}
    </div>
  );
};

export default OrderFooter;
