import { useEffect } from "react";

const useScrollIntoViewOnFocus = () => {
  useEffect(() => {
    const handleFocus = (event: FocusEvent) => {
      setTimeout(() => {
        if (
          event.target instanceof HTMLElement &&
          window.matchMedia("(max-width: 767px)").matches
        ) {
          event.target.scrollIntoView({
            behavior: "smooth",
            block: "center",
          });
        }
      }, 300);
    };

    document.addEventListener("focus", handleFocus, true);

    return () => {
      document.removeEventListener("focus", handleFocus, true);
    };
  }, []);
};

export default useScrollIntoViewOnFocus;
