export const display = (isOpen: boolean) => {
  var pop_width = document.getElementsByClassName("s_ticket")?.[0]?.clientWidth;
  var body = document.querySelector("body");
  var html = document.querySelector("html");
  if (isOpen) {
    // @ts-ignore
    (document.getElementById("EventAccordion")?.style).marginRight = `${
      pop_width - 20
    }px`;
    // document.body.style.overflow = 'hidden';
    // document.body.classList.add("overflow-hidden");
    body?.classList.add("scrollbar-none");
    html?.classList.add("scrollbar-none");
  } else if (!isOpen) {
    // @ts-ignore
    (document.getElementById("EventAccordion")?.style).marginRight = `0px`;
    // @ts-ignore
    const singleTicket = document.getElementById("singleTicket");
    // document.body.classList.remove("overflow-hidden");
    body?.classList?.remove("scrollbar-none");
    html?.classList.remove("scrollbar-none");
    if (singleTicket) {
      singleTicket.style.visibility = "hidden";
    }
  }
};
