import PageTitle from "../../components/core/PageTitle";
import { CustomizeProvider } from "./core/CustomizeProvider";
import { Customize } from "./Customize";

export const CustomizeWrapper = () => {
  return (
    <CustomizeProvider>
      <PageTitle title="Customise" />
      <Customize />
    </CustomizeProvider>
  );
};
