import React from "react";

const PopUpShimmer = () => {
  return (
    <div className="p-5 h-full overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full">
      <div className="border rounded">
        <div className="border-b">
          <div className="flex flex-wrap px-3 py-3  -mx-2">
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
          </div>
        </div>
        <div className="border-b">
          <div className="flex flex-wrap px-3 py-3  -mx-2">
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
          </div>
        </div>

        <div className="border-b">
          <div className="flex flex-wrap px-3 py-3  -mx-2">
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
          </div>
        </div>
        <div className="border-b">
          <div className="flex flex-wrap px-3 py-3  -mx-2">
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
            <div className="md:w-1/2 p-2 w-full  h-1.875rem ">
              <div className="shimmer-effect w-full h-[30px]"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUpShimmer;
