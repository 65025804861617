import { Tooltip } from "react-tooltip";
import DeleteSVG from "../../../../assets/media/icons/other_icons/Delete.svg";
import EDITSVG from "../../../../assets/media/icons/other_icons/edit-square.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";

const TableActionButtons = ({
  list,
  sIndex,
  matchedView,
  handleOpenForm,
  listRefetch,
  tableAlert,
  setTableAlert,
  deleteLoader,
}: any) => {
  // DELETE TEMPLATE
  const handleDelete = (id: any) => {
    setTableAlert({ show: true, id: id });
  };

  return (
    <>
      <td
        className={` ${
          deleteLoader && "pointer-events-none"
        } md:sticky relative right-0 ml-auto p-0 icon_td w-[5rem] max-w-[5rem] min-w-[5rem]`}
      >
        <div
          className={` bg-opacity-[7%] ${
            matchedView && "bg-indigo-500 bg-opacity-[7%]"
          }`}
        >
          <div
            className="flex left-shad relative w-full h-full border-l"
            id="left-shad-list"
          >
            <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1">
              <button
                className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                type="button"
                id={`record-view-tooltip${sIndex}`}
                onClick={(e: any) => {
                  handleOpenForm(true, list);
                }}
              >
                <Tooltip
                  anchorId={`record-view-tooltip${sIndex}`}
                  content={`${"Edit"}`}
                  place={`${sIndex === 0 ? "left" : "top"}`}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                    sIndex === 0 ? "mt-0" : "mt-1.5"
                  }`}
                />
                <IKTSVG
                  className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                  svgClassName="w-4 h-[.6875rem]"
                  path={EDITSVG}
                />
              </button>
            </div>
            <div className="flex items-center justify-center p-1 md:p-1 w-8  md:flex-auto flex-1">
              <button
                className={` group inline-flex group items-center justify-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 gap-2 p-1 w-7 h-7  `}
                type="button"
                id={`record-delete-tooltip${sIndex}`}
                onClick={(e: any) => {
                  handleDelete(list?.id);
                }}
              >
                <Tooltip
                  anchorId={`record-delete-tooltip${sIndex}`}
                  content={`${"Delete"}`}
                  place={`${sIndex === 0 ? "left" : "top"}`}
                  variant="light"
                  className={`!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10  ${
                    sIndex === 0 ? "mt-0" : "mt-1.5"
                  }`}
                />
                <IKTSVG
                  className={`flex items-center justify-center fill-white group-hover:fill-white transition`}
                  svgClassName="w-4 h-[.6875rem]"
                  path={DeleteSVG}
                />
              </button>
            </div>
          </div>
        </div>
      </td>
    </>
  );
};

export default TableActionButtons;
