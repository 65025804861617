import { size } from "lodash";
import { useContext } from "react";
import DeleteSVG from "../../../../assets/media/icons/other_icons/Delete.svg";
import AddSVG from "../../../../assets/media/icons/other_icons/add_icon.svg";
import AddGreySVG from "../../../../assets/media/icons/other_icons/gray-add.svg";
import TextInput from "../../../../components/formComponent/TextInput";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { errorReflect } from "../../../../helpers/Functions";
import { showAlert } from "../../../../helpers/ShowAlert";
import { INITIAL_HEADERS } from "../../../../helpers/const";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { ConnectorContext } from "../../core/ConnectorProvider";
import { postVariable } from "../../core/requests";

const Headers = ({
  id,
  handleSubmit,
  refetch,
  isSubmitBtnShow = false,
}: any) => {
  const layout = useContext(ConnectorContext);
  const headers = layout?.headers;
  const sideTabOption = layout.sideOption;
  const setHeaders = layout.setHeaders;
  let isAnyFieldEmpty = false;

  // ADD HEADER
  const handleAddHeader = () => {
    setHeaders((current: any) => {
      return [...current, INITIAL_HEADERS];
    });
  };

  // REMOVE HEADER FROM STATE
  const removeHeaderFromState = (index: number) => {
    setHeaders((current: any) => {
      return current?.filter(
        (item: any, filterIndex: number) => filterIndex !== index
      );
    });
  };

  // REMVOE HEADER
  const handleRemoveHeader = (index: number) => {
    if (sideTabOption === "Variables") {
      const deleteId = headers?.find(
        (item: any, filterIndex: number) => filterIndex === index
      );
      if (deleteId?.id) {
        layout.setLoader(true);
        postVariable({ headers, id, deleteIndex: index }).then(
          (response: any) => {
            layout.setLoader(false);
            if (response?.data?.status) {
              showAlert(response?.message, false, 1500);
              setTimeout(() => {
                refetch();
              }, 1500);
            } else {
              errorReflect(response);
            }
          }
        );
      } else {
        removeHeaderFromState(index);
      }
    } else {
      removeHeaderFromState(index);
    }
  };

  // HANDLE ON CHANGE
  const handleOnChange = (data: any, index: number) => {
    let { name, value } = data?.target;
    setHeaders((current: any) => {
      return current?.map((item: any, sIndex: number) =>
        index === sIndex ? { ...item, [name]: value } : item
      );
    });
  };

  // CLEAR VALUE
  const handleClearValue = (data: any, index: number) => {
    setHeaders((current: any) => {
      return current?.map((item: any, sIndex: number) =>
        sIndex === index ? { ...item, [data]: "" } : item
      );
    });
  };

  return (
    <>
      <div className="flex flex-col mt-4">
        {/* HEADERS */}
        {headers?.map((header: any, index: any) => (
          <div key={index} className="w-full flex gap-4 mb-4">
            <TextInput
              name={`name`}
              autoComplete="off"
              type="text"
              label="Name"
              inputHeight="h-8"
              id={`${index}-name`}
              value={header?.name}
              handleOnChange={(data: any) => handleOnChange(data, index)}
              handleClearValue={(data: any) => handleClearValue("name", index)}
              className={"text-ellipsis pr-8"}
            />
            <TextInput
              name={`value`}
              autoComplete="off"
              type="text"
              label="Value"
              inputHeight="h-8"
              id={`${index}-value`}
              value={header?.value === null ? "" : header?.value}
              handleOnChange={(data: any) => handleOnChange(data, index)}
              handleClearValue={(data: any) => handleClearValue("value", index)}
              className={"text-ellipsis pr-8"}
            />

            {/* REMOVE HEADER */}
            <button
              type="button"
              data-tooltip-id={`delete-${index}`}
              className={`
                ${
                  size(headers) === 1
                    ? "pointer-events-none bg-gray-100 text-violet-800"
                    : " "
                }
                  mr-2 border group inline-flex group items-center font-medium rounded text-sm13 px-2.5 py-0.5`}
              onClick={() => handleRemoveHeader(index)}
            >
              <TooltipPortal
                id={`delete-${index}`}
                content="Delete"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 font-medium text-xs z-[9999]"
              />
              <IKTSVG
                className="w-3 h-[.9375rem] flex items-center justify-center fill-violet-500 hover:fill-indigo-500 rounded-full cursor-pointer"
                path={DeleteSVG}
                svgClassName="w-3 h-[.9375rem]"
              />
            </button>
          </div>
        ))}
      </div>

      {/* ADD HEADER */}
      <div className="flex gap-2">
        <button
          type="button"
          className={`
          ${
            isAnyFieldEmpty
              ? "text-gray-400 bg-gray-100"
              : "text-white hover:text-white bg-violet-500 hover:bg-indigo-500"
          }
            group inline-flex group items-center  transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
          onClick={() => handleAddHeader()}
          disabled={isAnyFieldEmpty}
        >
          <IKTSVG
            className={`flex items-center justify-center  transition mr-2 ${
              isAnyFieldEmpty ? "fill-black" : "fill-white"
            }`}
            path={isAnyFieldEmpty ? AddGreySVG : AddSVG}
            svgClassName="w-3.5 h-3"
          />
          Add
        </button>
      </div>
    </>
  );
};

export default Headers;
