import { useContext, useState } from "react";
import { SalesContext } from "../core/SalesProvider";
import { getSalesListing } from "../core/_requests";

import fileSVG from "../../../assets/media/icons/other_icons/file.svg";
import processSVG from "../../../assets/media/icons/other_icons/pure-process.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";

const CSVExport = () => {
  const layout = useContext(SalesContext);
  const [loader, setLoader] = useState<boolean>();
  let tabValue = layout.tabValue;
  let filters = layout.salesFilterHandle;

  const downloadFile = ({ data, fileName, fileType }: any) => {
    const blob = new Blob([data], { type: fileType });

    const a = document.createElement("a");
    a.download = fileName;
    a.href = window.URL.createObjectURL(blob);
    const clickEvt = new MouseEvent("click", {
      view: window,
      bubbles: true,
      cancelable: true,
    });
    a.dispatchEvent(clickEvt);
    a.remove();
    setLoader(false);
  };

  const exportToCsv = (e: any) => {
    e.preventDefault();
    setLoader(true);
    getSalesListing({ tabValue, filters, export_to_csv: 1 }).then(
      (response: any) => {
        downloadFile({
          data: response?.data.replace(/\\n/g, "\n"),
          fileName: "sales.csv",
          fileType: "text/csv",
        });
      }
    );
  };

  return (
    <div>
      <button
        type="button"
        className={`${
          loader && "pointer-events-none bg-gray-100 hover:bg-gray-100"
        }  group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false`}
        onClick={(event) => {
          !loader && exportToCsv(event);
        }}
      >
        <IKTSVG
          className="items-center justify-center fill-violet-500 group-hover:fill-white transition animate-spin hidden mr-1"
          path={processSVG}
        />
        {loader ? (
          <IKTSVG
            className="flex items-center justify-center fill-violet-500 group-hover:fill-white transition animate-spin mr-1"
            path={processSVG}
          />
        ) : (
          <IKTSVG
            className="min-w-[0.75rem] max-w-[0.75rem] mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
            path={fileSVG}
          />
        )}
        Export to CSV
      </button>
    </div>
  );
};

export default CSVExport;
