const Calender = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10.8"
      height="12"
      viewBox="0 0 10.8 12"
      {...props}
    >
      <path
        id="calendar-today"
        d="M5.4,6.4h3v3h-3m7.2,2.4H4.2V5.2h8.4m0-3H12V1H10.8V2.2H6V1H4.8V2.2H4.2A1.2,1.2,0,0,0,3,3.4v8.4A1.2,1.2,0,0,0,4.2,13h8.4a1.2,1.2,0,0,0,1.2-1.2V3.4A1.2,1.2,0,0,0,12.6,2.2Z"
        transform="translate(-3 -1)"
      />
    </svg>
  );
};

export default Calender;
