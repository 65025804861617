import moment from "moment";
import CollapseSVG from "../../../../assets/media/icons/other_icons/Collapse.svg";
import Calendar from "../../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutline from "../../../../assets/media/icons/standard_icons/clock-outline.svg";
import Geo from "../../../../assets/media/icons/standard_icons/geo_icon.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";

const ETicketAccordionHeader = ({ event, eventIndex }: any) => {
  return (
    <div
      className="flex accordion-header event__header bg-violet-700 rounded text-white mb-2.5 title-accordion"
      id=""
      data-te-collapse-init
      data-te-target={`#eTicketEvent${eventIndex}`}
      aria-expanded="true"
      aria-controls={`eTicketEvent${eventIndex}`}
    >
      <div className="flex w-full">
        <div className="flex flex-wrap md:flex-nowrap flex-auto">
          <div
            className="flex flex-auto items-center leading-4 md:w-auto md:max-w-[12.5rem] w-full py-2 md:py-3 px-4 text-sm md:border-r border-white/20 ellips-text"
            title={event && event.name}
          >
            <p title={event.name}>{event && event.name}</p>
          </div>
          <div className="flex w-full md:w-auto md:py-3 px-4">
            <div className="flex items-center pr-4 text-xs">
              <IKTSVG
                className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                svgClassName="w-[.675rem] h-[.75rem]"
                path={Calendar}
              />
              {event && moment(event.date).format("ddd, DD MMM YYYY")}
            </div>
            <div className="flex items-center pr-4 text-xs">
              <IKTSVG
                className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                svgClassName="w-[.75rem] h-[.75rem]"
                path={ClockOutline}
              />
              {event && event.time}
            </div>
            <div className="flex items-center flex-auto text-xs">
              <IKTSVG
                className="flex items-center justify-center min-w-[0.75] fill-white mr-2"
                svgClassName="w-[.7813rem] h-[.7813rem]"
                path={Geo}
              />
              {event && event.address}
            </div>
          </div>
        </div>
        <div className="flex ml-auto">
          <button
            className="group relative flex items-center p-3 px-4 text-base text-gray-800 text-left border-l border-white/20 !transition focus:outline-none [&[aria-expanded='false'] > .icon ]:rotate-180"
            type="button"
            data-te-collapse-init
            data-te-target={`#eTicketEvent${eventIndex}`}
            aria-expanded="true"
            aria-controls={`eTicketEvent${eventIndex}`}
          >
            <IKTSVG
              className="flex items-center justify-center fill-white transition group-[[data-te-collapse-collapsed]]:rotate-180"
              svgClassName="w-[.5625rem] h-[.5623rem]"
              path={CollapseSVG}
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ETicketAccordionHeader;
