
import { useLayoutEffect } from "react";
import { handleLogout } from "../../../helpers/Functions";

const Logout = () => {

    useLayoutEffect(() => {
        handleLogout()
    }, [])

    return (
        <>
        </>
    );
};

export default Logout;
