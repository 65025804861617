import { useContext } from "react";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { WalletAndBankDetailsContext } from "../core/WalletAndBankDetailsProvider";

interface TabInterface {
  imgPath?: any;
  title?: string;
  handleTab?: any;
}

const Tab: React.FC<TabInterface> = ({ imgPath, title, handleTab }) => {
  const layout = useContext(WalletAndBankDetailsContext);
  const isTabActive = layout.isTabActive;
  const isTitle = isTabActive === title;

  return (
    <>
      <div
        className={`${
          isTitle ? "bg-opacity-100 z-[1]" : "bg-opacity-60"
        } single-tab lg:px-4 px-2.5 py-[.5625rem] lg2:max-w-[14.9375rem] min-w-[10.625rem lg:whitespace-normal whitespace-nowrap
        ] flex justify-between items-center bg-white rounded-t cursor-pointer mr-[1px] w-full gap-2`}
        onClick={() => handleTab()}
      >
        <h4
          className={`lg:text-sm15 text-sm14 font-semibold ${
            isTitle && "text-indigo-500"
          }`}
        >
          {title}
        </h4>
        <IKTSVG
          className={`123 flex items-center justify-center fill-${
            isTitle ? "indigo" : "violet"
          }-500 transition`}
          path={imgPath}
          svgClassName={"w-4 h-4"}
        />
      </div>
    </>
  );
};

export default Tab;
