const PPPIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15.5"
      height="15.5"
      viewBox="0 0 15.5 15.5"
      {...props}
    >
      <path
        id="Subtraction_81"
        data-name="Subtraction 81"
        d="M7.75,15.5A7.75,7.75,0,1,1,15.5,7.75,7.759,7.759,0,0,1,7.75,15.5ZM10.462,4.134,7.75,7.75H9.688v3.617h1.55V7.75h1.937ZM2.326,7.75l2.713,3.616L7.75,7.75H5.813V4.134H4.263V7.75Z"
        fill=""
      />
    </svg>
  );
};

export default PPPIcon;
