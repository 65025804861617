import { map } from "lodash";
import { useContext } from "react";
import { SYMBOLS } from "../../../helpers/const";
import { TxAccountContext } from "../core/TxAccountProvider";
import FilterCountBox from "./FilterCountBox";
import { decimalNumber } from "../../../helpers/Functions";

const InvoicesFilterCountBoxsWrapper = ({
  handleOnchange,
  invoiceOverViewDetails,
  loading,
  baseCurrency,
}: any) => {
  const layout = useContext(TxAccountContext);
  let statusArr = map(layout.invoicesFilter?.invoice_status, "id");
  return (
    <>
      <FilterCountBox
        value={`${SYMBOLS[baseCurrency]}${
          invoiceOverViewDetails?.overview?.total_invoice_value
            ? decimalNumber(
                invoiceOverViewDetails?.overview?.total_invoice_value
              )
            : 0
        }
        `}
        label="Invoice value"
        isCheckbox={false}
        loading={loading}
      />
      <FilterCountBox
        value={
          invoiceOverViewDetails?.overview?.total_invoice_count
            ? invoiceOverViewDetails?.overview?.total_invoice_count
            : 0
        }
        label="Invoices"
        isCheckbox={false}
        loading={loading}
      />
      <FilterCountBox
        value={
          invoiceOverViewDetails?.overview?.total_invoice_draft
            ? invoiceOverViewDetails?.overview?.total_invoice_draft
            : 0
        }
        label="Draft"
        isCheckbox={
          invoiceOverViewDetails?.overview?.total_invoice_draft === 0
            ? false
            : true
        }
        handleOnchange={handleOnchange}
        isChecked={statusArr?.includes("Draft") ? true : false}
        loading={loading}
      />
      <FilterCountBox
        value={
          invoiceOverViewDetails?.overview?.total_invoice_awaiting
            ? invoiceOverViewDetails?.overview?.total_invoice_awaiting
            : 0
        }
        label="Awaiting payment"
        isCheckbox={
          invoiceOverViewDetails?.overview?.total_invoice_awaiting === 0
            ? false
            : true
        }
        handleOnchange={handleOnchange}
        isChecked={statusArr?.includes("Awaiting Payment") ? true : false}
        loading={loading}
      />
      <FilterCountBox
        value={
          invoiceOverViewDetails?.overview?.total_invoice_paid
            ? invoiceOverViewDetails?.overview?.total_invoice_paid
            : 0
        }
        label="Paid"
        isCheckbox={
          invoiceOverViewDetails?.overview?.total_invoice_paid === 0
            ? false
            : true
        }
        handleOnchange={handleOnchange}
        isChecked={statusArr?.includes("Paid") ? true : false}
        loading={loading}
      />
    </>
  );
};

export default InvoicesFilterCountBoxsWrapper;
