import { useContext } from "react";
import { components } from "react-select";
import CalendarSVG from "../../../../assets/media/icons/standard_icons/calendar.svg";
import ClockOutlineSVG from "../../../../assets/media/icons/standard_icons/clock-outline.svg";
import GEOSVG from "../../../../assets/media/icons/standard_icons/geo_icon.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { AddInventoryContext } from "../../core/AddInventoryProvider";
import { convertDate, convertTime } from "../../core/_functions";

const Options = ({ children, ...props }: any) => {
  const layout = useContext(AddInventoryContext);
  if (props?.selectProps?.name === "tableSearchField") {
    return (
      <>
        <components.Option {...props}>
          <ul className="p-0 list-none">
            <li>
              <span className="search__item mb-2.5 flex flex-wrap m-1 border rounded-sm duration-150">
                <span className="search__item__title px-2 py-1 text-xs first:w-full first:border-r-0 first:border-b border-r">
                  {props.data.name}
                </span>
                <span className="inline-flex items-center px-2 py-1 text-xs border-r text-xxs">
                  <IKTSVG path={CalendarSVG} className="pr-2" />
                  <span className="">
                    {props.data.event_date_local
                      ? convertDate(props.data.event_date_local)
                      : "N/A"}{" "}
                  </span>
                </span>
                <span className="inline-flex items-center px-2 py-1 text-xs sm:border-r border-r-0 text-xxs sm:flex-none flex-1 ">
                  <IKTSVG path={ClockOutlineSVG} className="pr-2" />
                  <span className="">
                    {props.data.event_date_local
                      ? convertTime(props.data.event_date_local)
                      : "N/A"}
                  </span>
                </span>
                <span className="inline-flex items-center flex-auto px-2 py-1 text-xs border-r-0 text-xxs sm:border-t-0 border-t sm:w-auto w-full md:flex-1 ellips-text">
                  <IKTSVG path={GEOSVG} className="pr-2 " />
                  {/* <span className="">{props.data.address}</span> */}
                  <span className="md:text-ellipsis md:overflow-hidden" title={props.data.venue_name}>
                    {props.data.venue_name}
                  </span>
                </span>
              </span>
            </li>
          </ul>
        </components.Option>
      </>
    );
  } else {
    return (
      <>
        <components.Option {...props}>
          <ul className="p-0 list-none">
            {/* {(layout.eventOrPerformer === "Event" || name==='inventory') ? ( */}
            {layout.eventOrPerformer === "Event" ? (
              <li>
                <span className="search__item mb-2.5 flex flex-wrap m-1 border rounded-sm duration-150">
                  <span className="search__item__title px-2 py-1 text-xs first:w-full first:border-r-0 first:border-b border-r">
                    {props.data.name}
                  </span>
                  <span className="inline-flex items-center px-2 py-1 text-xs border-r text-xxs">
                    <IKTSVG path={CalendarSVG} className="pr-2" />
                    <span className="">
                      {props.data.event_date_local
                        ? convertDate(props.data.event_date_local)
                        : "N/A"}{" "}
                    </span>
                  </span>
                  <span className="inline-flex items-center px-2 py-1 text-xs sm:border-r border-r-0 text-xxs sm:flex-none flex-1 ">
                    <IKTSVG path={ClockOutlineSVG} className="pr-2" />
                    <span className="">
                      {props.data.event_date_local
                        ? convertTime(props.data.event_date_local)
                        : "N/A"}
                    </span>
                  </span>
                  <span className="inline-flex items-center flex-auto px-2 py-1 text-xs border-r-0 text-xxs sm:border-t-0 border-t sm:w-auto w-full md:flex-1 ellips-text">
                    <IKTSVG path={GEOSVG} className="pr-2 " />
                    {/* <span className="">{props.data.address}</span> */}
                    <span className="md:text-ellipsis md:overflow-hidden">
                      {props.data.venue_name}
                    </span>
                  </span>
                </span>
              </li>
            ) : (
              <li>
                <span className="search__item mb-2.5 flex flex-wrap m-1 border rounded-sm duration-150">
                  <span className="search__item__title px-2 py-1 text-xs first:w-full first:border-r-0 border-r">
                    {props.data.name}
                  </span>
                </span>
              </li>
            )}
          </ul>
        </components.Option>
      </>
    );
  }
};

export default Options;
