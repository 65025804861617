import { useContext, useEffect } from "react";
import { LayoutContext } from "../../components/core/LayoutProvider";

type props = {
  title: string;
};

const PageTitle = ({ title }: props) => {
  const layout = useContext(LayoutContext);
  useEffect(() => {
    layout.setTitle(title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layout.allowedAccess]);

  return <></>;
};

export default PageTitle;
