const Header = (data: any, className = "") => {
  return (
    <div className="evt_detail flex items-center border-b">
      <h5 className="ellips-text text-sm14 font-semibold max-w-[18.75rem] pl-5 p-[.9375rem] leading-5">
        {data?.title}
      </h5>
    </div>
  );
};

export default Header;
