import React, { useContext } from "react";
import AngleDownSVG from "../../../../assets/media/icons/other_icons/down-angle.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { InventoryContext } from "../../core/InventoryProvider";

type Props = {
  title: any;
  arrow: boolean;
  order: any;
  event: any;
  postKey: any;
  minWidth?: any;
  handleSortAction?: any;
  setSortingCurrentID?: any;
  SortingCurrentID?: any;
  selectedIndex?: any;
  mobWidth?: any;
};

const EventHeaderCell: React.FC<Props> = ({
  title,
  arrow,
  order,
  event,
  postKey,
  minWidth,
  handleSortAction,
  setSortingCurrentID,
  SortingCurrentID,
  selectedIndex,
  mobWidth = false,
}) => {
  const layout = useContext(InventoryContext);

  const handleSort = (id: any) => {
    layout.setOrderState((preValue: any) => {
      handleSortAction &&
        handleSortAction({
          id: id,
          sort: postKey,
          order:
            preValue &&
            Object.keys(preValue).length > 0 &&
            preValue.order === "desc"
              ? "asc"
              : "desc",
        });

      return {
        id: id,
        sort: postKey,
        order:
          preValue &&
          Object.keys(preValue).length > 0 &&
          preValue.order === "desc"
            ? "asc"
            : "desc",
      };
    });
  };
  return (
    <div
      className={`px-1.5 py-3 w-full group font-medium whitespace-nowrap min-w-[${minWidth}] ${mobWidth}`}
      key={selectedIndex}
    >
      {arrow ? (
        <div
          className={`w-full pr-3 relative group-hover:text-indigo-500 ${
            // @ts-ignore
            SortingCurrentID === event.id && order?.sort === postKey
              ? "text-violet-800"
              : ""
          } `}
        >
          {title}
          <button
            type="button"
            onClick={() => {
              setSortingCurrentID(event.id);
              handleSort(event.id);
            }}
          >
            <IKTSVG
              path={AngleDownSVG}
              className={`fill-gray-400 group-hover:fill-indigo-500 transition absolute top-1/2 right-1 -translate-y-2/4 ${
                // @ts-ignore
                SortingCurrentID === event.id &&
                order?.sort === postKey &&
                order?.order === "asc"
                  ? "-rotate-180"
                  : ""
              } ${
                // @ts-ignore
                SortingCurrentID === event.id && order?.sort === postKey
                  ? "fill-violet-800"
                  : ""
              }
              `}
              svgClassName=""
            />
          </button>
        </div>
      ) : (
        title
      )}
    </div>
  );
};

export default EventHeaderCell;
