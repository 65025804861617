import { useContext } from "react";
import { Tooltip } from "react-tooltip";
import downAngleSVG from "../../../../assets/media/icons/other_icons/down-angle.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { ConnectorContext } from "../../core/ConnectorProvider";
// import {  } from "../../core/ReportsProvider";

const HeaderCell = ({
  title,
  width,
  arrow,
  postKey,
  padding,
  uniqueKey,
  TableType = "client",
  children,
}: any) => {
  const layout = useContext(ConnectorContext);
  let order =
    TableType === "client" ? layout.orderState : layout.eventsOrderState;
  const handleSort = (sortedKey: any) => {
    if (TableType === "client") {
      layout.setOrderState((preValue: any) => {
        return {
          order_by: sortedKey,
          sort_order:
            preValue &&
            Object.keys(preValue).length > 0 &&
            preValue.order_by !== sortedKey
              ? "desc"
              : preValue.sort_order === "desc"
              ? "asc"
              : preValue.sort_order === "asc"
              ? undefined
              : "desc",
        };
      });
    } else if (TableType === "events") {
      layout.setEventsOrderState((preValue: any) => {
        return {
          order_by: sortedKey,
          sort_order:
            preValue &&
            Object.keys(preValue).length > 0 &&
            preValue.order_by !== sortedKey
              ? "desc"
              : preValue.sort_order === "desc"
              ? "asc"
              : preValue.sort_order === "asc"
              ? undefined
              : "desc",
        };
      });
    }
  };
  return (
    <th
      className={`px-1.5 py-3 font-medium whitespace-nowrap w-[${width}] ${padding}`}
      key={uniqueKey}
    >
      {children}
      {arrow ? (
        <div className="w-full pr-3 relative">
          {title}
          <button type="button" onClick={() => handleSort(postKey)}>
            <IKTSVG
              path={downAngleSVG}
              className={`fill-gray-400 group-hover:fill-white transition absolute top-1/2 right-1 -translate-y-2/4 ${
                // @ts-ignore
                order?.order_by === postKey && order?.sort_order === "desc"
                  ? "rotate-180 !fill-violet-500"
                  : order?.order_by === postKey && order?.sort_order === "asc"
                  ? " !fill-violet-500"
                  : "fill-gray-400"
              }
                  `}
            />
          </button>
        </div>
      ) : (
        title
      )}
      <Tooltip
        anchorId={`pricePerExchange-tooltip${title}`}
        content="Price per exchange"
        place="top"
        variant="light"
        className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium z-10"
      />
    </th>
  );
};
export default HeaderCell;
