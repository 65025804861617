import _, { size } from "lodash";
import moment from "moment";
import axios from "../../../../axois/SetupAxios";

// OPTIONS
const getFilterOptions = async (): Promise<any> => {
  const response = await axios.post(`subscribers/options`);
  return response.data;
};

// ACTION
const userStutusAction = async (data: any): Promise<any> => {
  const payload = {
    ...(data?.listData?.id && { team_id: data?.listData?.id }),
    ...(data?.action && { action: data?.action?.id }),
  };
  const response = await axios.post(`subscribers/action`, payload);
  return response.data;
};

// GET LISTING
const getListings = async (data: any): Promise<any> => {
  let teams = size(data?.teams) > 0 && _.map(data?.teams, "id");
  let risk_levels =
    size(data?.risk_levels) > 0 && _.map(data?.risk_levels, "id");
  let statuses = size(data?.statuses) > 0 && _.map(data?.statuses, "id");
  let query = data?.query;
  let per_page = data?.per_page;
  let page = data?.page;
  let from_register_date = data?.register_date
    ? moment(data?.register_date?.startDate).format("YYYY-MM-DD")
    : undefined;
  let to_register_date = data?.register_date
    ? moment(data?.register_date?.endDate).format("YYYY-MM-DD")
    : undefined;
  let order_by = data?.order_by;
  let sort_order = data?.sort_order;

  let payload = {
    page,
    per_page,
    ...(teams && { teams: teams }),
    ...(risk_levels && { risk_levels: risk_levels }),
    ...(statuses && { statuses: statuses }),
    ...(query && { query }),
    ...(from_register_date &&
      to_register_date && { from_register_date, to_register_date }),
    ...(order_by && sort_order && { order_by, sort_order }),
  };
  const [response1, response2] = await Promise.all([
    axios.post("subscribers/listing", payload),
    axios.post("subscribers/listing", { ...payload, counts: true }),
  ]);

  return {
    ...response1.data,
    counts: response2.data?.data,
  };
};

// GET FORM DATA
const getUserFormData = async (data: any): Promise<any> => {
  const response = await axios.get(`subscribers/edit/${data}`);
  return response.data;
};

// UPDATE FORM DATA
const updateUserFormData = async (id: any, data: any): Promise<any> => {
  const response = await axios.post(`subscribers/edit/${id}`, data);
  return response.data;
};

export {
  getFilterOptions,
  getListings,
  getUserFormData,
  updateUserFormData,
  userStutusAction,
};
