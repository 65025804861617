/* eslint-disable @typescript-eslint/no-unused-vars */
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import useDebounce from "../../add_inventory/core/useDebounce";
import { AddInventoryContext } from "../core/AddInventoryProvider";

const DraggableColumns = ({ refetch, columnUpdate }: any) => {
  const layout = useContext(AddInventoryContext);
  const coreLayout = useContext(LayoutContext);
  const isGodAdmin = coreLayout?.allowedAccess?.account_info?.is_god_admin;
  let isMobile = coreLayout?.isMobile;
  const [columnItems, setColumnItems] = useState<any>(layout.columnItems);

  useEffect(() => {
    columnUpdate(columnItems);
  }, [columnItems]);

  useEffect(() => {
    setColumnItems(layout.columnItems);
  }, [layout.columnItems]);

  let columnItemsMemo = useMemo(() => columnItems, [columnItems]);
  let columnItemsData: any = useDebounce(columnItemsMemo, 600);

  useEffect(() => {
    setColumnItems(layout.columnItems);
  }, [layout.columnItems]);

  useEffect(() => {
    // saveInventoryTableColumn();
    layout.setColumnItems(columnItems);
  }, [columnItemsData]);

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items: any = reorder(columnItems, source.index, destination.index);
      setColumnItems([...items]);
      let index = items.findIndex((item: any) => item.id === 1);
      layout.setDraggedColumns(items);

      setTimeout(() => {
        refetch();
      }, 400);
    }
  };

  //Function For Filter Checkbox Handle
  const handleChange = useCallback((e: any) => {
    setColumnItems((prev: any) => {
      return (
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name === e.target.name) {
            return {
              ...item,
              checked: e.target.checked,
            };
          } else {
            return item;
          }
        })
      );
    });
    if (e.target?.name === "Proceed price" && e.target.checked === false) {
      layout.setProceedPriceToggle(false);
    }
    setTimeout(() => {
      refetch();
    }, 400);
  }, []);

  // SAVE DRAGGABLE FILTER DATA TO LOCAL STORAGE
  const saveAddInventoryTableColumn = () => {
    localStorage.setItem(
      "saveAddInventoryTableColumn",
      JSON.stringify(columnItems)
    );
  };

  //Function For Handling Search
  const handleFilterSearch = (e: any) => {
    let searchResult = columnItems.filter((element: any) =>
      element.name.toLowerCase().includes(e.target.value.toLowerCase())
    );
    setColumnItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return {
              ...item,
              searched: true,
            };
          } else {
            return {
              ...item,
              searched: false,
            };
          }
        });
      return updatedArray;
    });
  };

  let columnItemsLength: any;
  columnItemsLength = columnItems.length * 30;

  return (
    <>
      {/* <div className="ba_search p-2.5 border-b">
        <input
          type="text"
          placeholder="Search columns 0009"
          id="search"
          className="rounded bg-violet-100/5 border-violet-100/20 w-full h-[1.875rem] text-sm12 px-2.5 py-2"
          onChange={handleFilterSearch}
        />
      </div> */}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable1" key="droppable1">
          {(provided: any, snapshot: any) => (
            <div
              className="cb-block border-b px-2.5 py-2 cursor-auto"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <ul className={`min-h-[${columnItemsLength}px]`}>
                {columnItems.map((item: any, index: any) => (
                  <li className="font-medium group " key={`li-${item.id}`}>
                    {item.searched && (
                      <Draggable
                        draggableId={item.name}
                        key={`drag-${item.id}`}
                        index={index}
                        isDragDisabled={true}
                      >
                        {(provided: any, snapshot: any) => (
                          <div
                            className={`singe_cb flex justify-between items-center ${
                              index === layout.columnItems.length - 1
                                ? ""
                                : "mb-3"
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                          >
                            <div
                              className="inline-flex items-center text-violet-800transition font-medium rounded"
                              {...provided.dragHandleProps}
                            >
                              <label
                                htmlFor={`check-${item.id}`}
                                className="ml-2 text-sm12 font-medium dark:text-gray-300"
                              >
                                {" "}
                                {item.name}
                              </label>
                            </div>
                            <div className="fixed_top flex items-center ">
                              {item.name === "Proceed price" &&
                                item.checked &&
                                !isMobile && (
                                  <div className="flex gap-1.5 items-center  bg-gray-100 px-[.3125rem] py-0.5 rounded">
                                    <span className="text-xxs font-medium dark:text-gray-300">
                                      Fix to right
                                    </span>
                                    <div className="flex">
                                      <label
                                        className={`relative inline-flex items-center cursor-pointer  [&.disabled>div:after]:bg-gray-400 [&.disabled]:pointer-events-none `}
                                      >
                                        <input
                                          type="checkbox"
                                          name="addAllEvents"
                                          value=""
                                          className="sr-only peer"
                                          checked={layout.proceedPriceToggle}
                                          onChange={(e) => {
                                            layout.setProceedPriceToggle(
                                              e.target.checked
                                            );
                                            layout.setColumnItems(
                                              (pre: any) => {
                                                return pre?.map((obj: any) => {
                                                  if (
                                                    obj?.name ===
                                                    "Proceed price"
                                                  ) {
                                                    return {
                                                      ...obj,
                                                      toggle: e.target.checked,
                                                    };
                                                  } else {
                                                    return {
                                                      ...obj,
                                                    };
                                                  }
                                                });
                                              }
                                            );
                                            setColumnItems((pre: any) => {
                                              let final = pre?.map(
                                                (obj: any) => {
                                                  if (
                                                    obj?.name ===
                                                    "Proceed price"
                                                  ) {
                                                    return {
                                                      ...obj,
                                                      toggle: e.target.checked,
                                                    };
                                                  } else {
                                                    return {
                                                      ...obj,
                                                    };
                                                  }
                                                }
                                              );

                                              columnUpdate(final);

                                              return final;
                                            });

                                            setTimeout(() => {
                                              refetch();
                                            }, 400);
                                          }}
                                        />
                                        <div
                                          className={`${
                                            false || false
                                              ? "pointer-events-none bg-gray-100 peer-checked:after:bg-gray-400 after:bg-gray-400"
                                              : "bg-gray-200 peer-checked:bg-indigo-500 peer-checked:after:bg-indigo-500  peer-checked:bg-opacity-50 after:bg-violet-500"
                                          } w-6 h-[.8125rem]  rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full  after:content-[''] after:absolute after:-top-0 after:-left-0  after:rounded-full after:h-[.8125rem] after:w-[.8125rem] after:transition-all after:shadow-md peer-checked:bg-100  after:bg-center after:bg-no-repeat after:bg-8`}
                                        ></div>
                                      </label>
                                    </div>
                                  </div>
                                )}
                              {item?.checkBox && (
                                <input
                                  type="checkbox"
                                  className="w-3 h-3 cursor-pointer text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                  checked={item.checked}
                                  id={`check-${item.id}`}
                                  name={item.name}
                                  onChange={handleChange}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default DraggableColumns;
