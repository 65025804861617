const MarketInsightGreen = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      {...props}
    >
      <path
        id="target-variant"
        d="M15,7.853H13.705V3.295H9.154V2h-1.3V3.295H3.295V7.853H2v1.3H3.295v4.551H7.853V15h1.3V13.705h4.551V9.154H15v-1.3M12.4,12.4H9.154V11.1h-1.3v1.3H4.59V9.154H5.9v-1.3H4.59V4.59H7.853V5.9h1.3V4.59H12.4V7.853H11.1v1.3h1.3V12.4m-3.25-3.9a.647.647,0,0,1-.647.647.651.651,0,1,1,.647-.647Z"
        transform="translate(-2 -2)"
      />
    </svg>
  );
};

export default MarketInsightGreen;
