import { useContext, useEffect, useState } from "react";
import { useQuery } from "react-query";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { storeFields } from "../../../components/core/requests";
import {
  columnsOutSideClick,
  outSideClick,
} from "../../../helpers/AssetHelpers";
import { existInLocalstorage } from "../../../helpers/Functions";
import { QUERIES } from "../../../helpers/const";
import { InventoryContext } from "../core/InventoryProvider";
import Bulk from "./Bulk";
import DraggableColumns from "./DraggableColumns";
import DraggableFilters from "./DraggableFilters";

const HeaderfilterContainer = ({ list, setList, isTableDisabled }: any) => {
  const layout = useContext(InventoryContext);
  const globalLayout = useContext(LayoutContext);
  const [column, setColumn] = useState<any>();
  // console.log(globalLayout?.headerDraggableFields);

  let isFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveInventoryFilter;

  let isColumnFilterExistInDB =
    globalLayout?.allowedAccess?.ui_field_settings?.saveInventoryTableColumn;

  let payload: any = [
    {
      key: `saveInventoryFilter`,
      value: layout.filterItems,
    },
    {
      key: `saveInventoryTableColumn`,
      value: column,
    },
  ];

  //ADDING & REMOVING LISTENERS FOR OUTSIDE CLICK FOR DROPDOWNS
  useEffect(() => {
    document.addEventListener("mousedown", (e: any) =>
      outSideClick(e, "saveInventoryFilter")
    );
    document.addEventListener("mousedown", (e: any) =>
      columnsOutSideClick(e, "saveInventoryTableColumn")
    );
    document.addEventListener("mousedown", (e: any) =>
      columnsOutSideClick(e, "bulk")
    );
    return () => {
      document.removeEventListener("mousedown", (e: any) =>
        outSideClick(e, "saveInventoryFilter")
      );
      document.removeEventListener("mousedown", (e: any) =>
        columnsOutSideClick(e, "saveInventoryTableColumn")
      );
      document.removeEventListener("mousedown", (e: any) =>
        columnsOutSideClick(e, "bulk")
      );
    };
  }, []);

  //   STORE FILTER ARRAY
  const { refetch } = useQuery(
    [`${QUERIES.STORE_FIELD}`],
    () => storeFields(payload),
    {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      enabled: false,
      onError: (err) => {},
      onSettled(data, error) {
        // console.log(data);
      },
    }
  );
  useEffect(() => {
    if (layout.filterItems && existInLocalstorage("saveInventoryFilter")) {
      layout.setFilterItems(existInLocalstorage("saveInventoryFilter"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("saveInventoryFilter");
      }, 1000);
    }

    if (layout.columnItems && existInLocalstorage("saveInventoryTableColumn")) {
      layout.setColumnItems(existInLocalstorage("saveInventoryTableColumn"));
      setTimeout(() => {
        refetch();
        localStorage.removeItem("saveInventoryTableColumn");
      }, 1000);
    }
  }, []);

  useEffect(() => {
    if (isFilterExistInDB) {
      setTimeout(() => {
        layout.setFilterItems(isFilterExistInDB);
        layout.setDraggedItems(isFilterExistInDB);
      }, 1000);
    }
    if (isColumnFilterExistInDB) {
      setTimeout(() => {
        layout.setColumnItems(isColumnFilterExistInDB);
        layout.setDraggedColumns(isColumnFilterExistInDB);
        const value = isColumnFilterExistInDB?.find(
          (obj: any) => obj?.name === "Proceed price"
        )?.toggle;
        layout.setProceedPriceToggle(value);
      }, 1000);
    }
  }, [isFilterExistInDB, isColumnFilterExistInDB]);

  return (
    <div
      className={`flex flex-wrap ${
        isTableDisabled ? "pointer-events-none" : ""
      }`}
      id="filters"
    >
      <div className="ba_item relative max-md:hidden">
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#saveInventoryFilter"
          aria-expanded="false"
          aria-controls="saveInventoryFilter"
        >
          Filters
        </span>
        <div
          id="saveInventoryFilter"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden"
          data-te-collapse-item
          aria-labelledby="saveInventoryFilter"
          data-te-parent="#filters"
        >
          <DraggableFilters refetch={refetch} />
        </div>
      </div>

      <div className="ba_item relative">
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#saveInventoryTableColumn"
          aria-expanded="false"
          aria-controls="saveInventoryTableColumn"
        >
          Columns
        </span>
        <div
          id="saveInventoryTableColumn"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden cursor-pointer max-h-[calc(100dvh_-_150px)] overflow-auto scrollbar-thin scrollbar-thumb-rounded scrollbar-thumb-violet-800"
          data-te-collapse-item
          aria-labelledby="saveInventoryTableColumn"
          data-te-parent="#filters"
        >
          <DraggableColumns refetch={refetch} columnUpdate={setColumn} />
        </div>
      </div>

      <div className="ba_item relative z-20">
        <span
          className="sel_item block py-0.5 pr-6 pl-3 text-sm13 font-medium [&[aria-expanded='true']]:text-indigo-500 cursor-pointer"
          data-te-collapse-init
          data-te-collapse-collapsed
          data-te-target="#bulk"
          aria-expanded="false"
          aria-controls="bulk"
        >
          Bulk actions
        </span>
        <div
          id="bulk"
          className="!visible ba_dd absolute top-full z-10 bg-white rounded shadow-lt left-0 min-w-[15rem] w-full hidden"
          data-te-collapse-item
          aria-labelledby="bulk"
        >
          <Bulk list={list} setList={setList} />
        </div>
      </div>
    </div>
  );
};

export default HeaderfilterContainer;
