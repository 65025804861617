const DetailInfo = ({
  header,
  headerClassName = "text-gray-400 text-sm12 mb-2",
  value,
  valueClassName = "text-sm12 break-words",
  children,
}: any) => {
  return (
    <div className="md:w-1/2 md:p-2 py-2 w-full">
      <div className={headerClassName}>{header}</div>
      <div className={valueClassName}>
        {value}
        {children}
      </div>
    </div>
  );
};

export default DetailInfo;
