import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import ClockSVG from "../../assets/media/icons/standard_icons/clock-outline.svg";
import { IKTSVG } from "../ui/IKTSVG";

const TimePicker = ({
  name,
  value,
  placeholder,
  handleOnChange,
  children,
  height = "h-8",
  width = "",
  disabledKeyboardNavigation,
}: any) => {
  const [focus, setFocus] = useState(false);

  return (
    <>
      <div className={`date_h relative group ${focus && `dp-focused`} w-full`}>
        <span
          className={`table-date-icon ${height} ${width} absolute left-0 top-0 flex items-center justify-center fill-violet-500 rounded-l border-r border-gray-300 w-8  pointer-events-none group-[.dp-focused]:bg-indigo-500 group-[.dp-focused]:z-10 group-[.dp-focused]:!fill-white group-[.dp-focused]:border-indigo-500`}
        >
          <IKTSVG svgClassName="h-3 w-3" path={ClockSVG} />
        </span>
        <ReactDatePicker
          selected={value ? value : null}
          onChange={(date) => handleOnChange({ name: name, value: date })}
          name={name}
          className={`picker-input block px-3.5 font-medium w-full max-w-[6.875rem] ${height} text-xs transition bg-transparent border border-gray-300 appearance-none rounded focus:outline-none focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 cursor-pointer`}
          popperClassName="timePicker"
          onCalendarOpen={() => setFocus(true)}
          onCalendarClose={() => setFocus(false)}
          showTimeSelect
          showTimeSelectOnly
          disabledKeyboardNavigation={disabledKeyboardNavigation}
          placeholderText={placeholder}
          timeIntervals={30}
          timeCaption=""
          dateFormat="h:mm aa"
        />
        {children}
      </div>
    </>
  );
};

export default TimePicker;
