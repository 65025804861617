import { PolicyContent } from "../../tx_trade/component/PolicyContent";

const TxTradeAgreeMentComponent = () => {
  return (
    <div className="">
      <h1 className="py-2 text-center text-xl mb-4">TX Trade Buyer Policy</h1>
      <PolicyContent />
    </div>
  );
};

export default TxTradeAgreeMentComponent;
