export const EditSquareIcon = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="12"
    height="12"
    viewBox="0 0 12 12"
    {...props}
  >
    <path
      id="square-edit-outline"
      d="M4.333,3A1.329,1.329,0,0,0,3,4.333v9.333A1.333,1.333,0,0,0,4.333,15h9.333A1.333,1.333,0,0,0,15,13.667V9H13.667v4.667H4.333V4.333H9V3H4.333m8.52.667a.461.461,0,0,0-.32.133l-.813.807,1.667,1.667.813-.807a.451.451,0,0,0,0-.633L13.167,3.8a.445.445,0,0,0-.313-.133M11.247,5.08,6.333,10v1.667H8l4.913-4.92Z"
      transform="translate(-3 -3)"
      fill=""
    />
  </svg>
);
