import { IKTSVG } from "../../../components/ui/IKTSVG";

// NAVIGATION BUTTON COMPONENT
const NavigationButtons = ({
  handleNavigation,
  imgPath,
  className,
  id,
  svgClassName,
  disabled,
}: any) => {
  return (
    <button
      className={`group relative p-1 md:p-1 w-6 h-6 rounded-l ${className} ${
        id === "next" && "rotate-180"
      } ${disabled ? "pointer-events-none " : ""}`}
      type="button"
      id={id}
      onClick={() => handleNavigation(id)}
    >
      <IKTSVG
        className={`flex items-center justify-center ${
          disabled ? `fill-gray-400` : ``
        }  transition group-hover:fill-indigo-500 ${svgClassName}`}
        path={imgPath}
        svgClassName="w-1.5 h-[.5625rem]"
      />
    </button>
  );
};
export default NavigationButtons;
