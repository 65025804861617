import { useRef, useState } from "react";
import Collapse from "../../../assets/media/icons/other_icons/Collapse.svg";
import AddSVG from "../../../assets/media/icons/other_icons/add_icon.svg";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import TooltipPortal from "../../tx_trade/table/component/TooltipPortal";
import HeaderfilterContainer from "./HeaderFilterContainer";

const HeaderFilterWrapper = ({ handleOpenForm, handleToggle }: any) => {
  const heightRef1: any = useRef();
  const [isCollapseAccordion, setIsCollapseAccordion] = useState<boolean>(
    false
  );

  return (
    <div className="top-accor shadow-op2">
      {/* HEADER */}
      <div className="accordion font-medium" id="heightRef1" ref={heightRef1}>
        <div className=" !rounded-none bg-white accordion-item">
          <div
            className="flex  justify-between accordion-header mb-0"
            id="headingOne"
          >
            <div className="flex flex-wrap justify-between w-full pl-2 pr-5 py-2">
              {/* HEADER FILTER */}
              <HeaderfilterContainer />
            </div>
            <div className="pt-2 pr-2">
              <button
                type="button"
                className={`group inline-flex group items-center text-white hover:text-white bg-violet-500 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 `}
                onClick={() => handleOpenForm(false, {})}
              >
                <IKTSVG
                  className={`flex items-center justify-center fill-white transition mr-2 `}
                  path={AddSVG}
                  svgClassName="w-3.5 h-3"
                />
                Add
              </button>
            </div>
            <div>
              <button
                className="group relative flex items-center py-4 px-[.9375rem] text-base text-gray-800 text-left bg-white border-l  rounded-tr transition focus:outline-none group h-full md:h-auto [&[aria-expanded='false'] > .icon ]:rotate-180"
                type="button"
                data-te-collapse-init
                data-te-toggle="collapse"
                data-te-target="#inventoryaccordion"
                aria-expanded="true"
                aria-controls="inventoryaccordion"
                id="instruction-letter-accordion-btn"
                data-tooltip-id="instruction-letter-accordion"
                onClick={() => {
                  setIsCollapseAccordion((current: boolean) => {
                    let isCollapse: any = document
                      .getElementById("instruction-letter-accordion-btn")
                      ?.getAttribute("aria-expanded");
                    let toggleCondition = isCollapse === "false" ? true : false;
                    handleToggle(toggleCondition);
                    return toggleCondition;
                  });
                }}
              >
                <TooltipPortal
                  id={`instruction-letter-accordion`}
                  content={`${isCollapseAccordion ? "Expand" : "Collapse"}`}
                  positionStrategy="fixed"
                  place="left"
                  variant="light"
                  className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
                />
                <IKTSVG
                  className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 group-hover:fill-indigo-500 transition group-[[data-te-collapse-collapsed]]:rotate-180"
                  path={Collapse}
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderFilterWrapper;
