const TableHeader = ({ width, title, className, isArrow = false }: any) => {
  return (
    <>
      <th
        style={{ width: width }}
        className="px-2.5 py-3 font-medium whitespace-nowrap"
      >
        {isArrow ? (
          <div className="w-full pr-3 table-head-select">{title}</div>
        ) : (
          title
        )}
      </th>
    </>
  );
};

export default TableHeader;
