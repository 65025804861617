/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import SVG from "react-inlinesvg";
import { Tooltip } from "react-tooltip";
import dragSVG from "../../../assets/media/icons/other_icons/dragg.svg";
import restoreSVG from "../../../assets/media/icons/other_icons/restore.svg";
import CrossSVG from "../../../assets/media/icons/standard_icons/cross.svg";
import { LayoutContext } from "../../../components/core/LayoutProvider";
import { IKTSVG } from "../../../components/ui/IKTSVG";
import {
  SALES_FILTERS_ADMIN,
  SALES_FILTER_ITEMS,
} from "../../../helpers/const";
import { SalesContext } from "../core/SalesProvider";

const DraggableFilters = ({ refetch }: any) => {
  const layout = useContext(SalesContext);
  const globalLayout = useContext(LayoutContext);
  const isGodAdmin = globalLayout?.allowedAccess?.account_info?.is_god_admin;
  const access = globalLayout.allowedAccess?.allowed_access;
  const userSpecific = access?.some(
    (item: any) => item.id === "user-specific-data" && item.status === true
  );
  const filterRef: any = useRef<any>();

  // a little function to help us with reordering the result
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }
    if (source.droppableId === destination.droppableId) {
      const items: any = reorder(
        layout.filterItems,
        source.index,
        destination.index
      );
      layout.setFilterItems([...items]);
      layout.setDraggedItems(items);
      setTimeout(() => {
        refetch();
      }, 100);
    }
  };

  //Function For Filter Checkbox Handle
  const handleChange = (e: any) => {
    layout.setFilterItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name === e.target.name) {
            return {
              ...item,
              checked: e.target.checked,
            };
          } else {
            return item;
          }
        });
      return updatedArray;
    });
  };

  //Function For Handling Search
  const handleFilterSearch = (e: any) => {
    layout.setFilterItems((prev: any) => {
      let updatedArray =
        Array.isArray(prev) &&
        prev.map((item: any) => {
          if (item.name.toLowerCase().includes(e.target.value.toLowerCase())) {
            return {
              ...item,
              searched: true,
            };
          } else {
            return {
              ...item,
              searched: false,
            };
          }
        });
      return updatedArray;
    });
  };

  // Save Draggable Filter Data to Local Storage
  const saveSalesFilter = () => {
    localStorage.setItem("saveSalesFilter", JSON.stringify(layout.filterItems));
  };

  useEffect(() => {
    // saveSalesFilter();
  }, [layout.filterItems]);

  let filterItemsLength: any;
  filterItemsLength = layout.filterItems.length * 30;

  const handleResetKeyboardItems = () => {
    filterRef.current.value = "";
    layout.setDraggedItems([]);
    layout.setFilterItems(
      isGodAdmin
        ? SALES_FILTER_ITEMS
        : userSpecific
        ? SALES_FILTER_ITEMS?.filter(
            (obj: any) =>
              obj.name !== "All teams" &&
              obj.name !== "Team member" &&
              obj.name !== "Internal replacement status" &&
              obj?.name !== "Internal order status" &&
              obj.name !== "Stores"
          )
        : SALES_FILTER_ITEMS?.filter(
            (obj: any) =>
              obj.name !== "All teams" &&
              obj.name !== "Marketplaces" &&
              obj.name !== "Internal replacement status" &&
              obj?.name !== "Internal order status" &&
              obj.name !== "Stores"
          )
    );

    setTimeout(() => {
      refetch();
    }, 100);
  };

  const allSearchedTrue = layout.filterItems.every(
    (item: any) => item.searched === false
  );
  const getFilterValue = isGodAdmin
    ? layout.filterItems
    : layout.filterItems?.filter(
        (obj: any) => !SALES_FILTERS_ADMIN?.includes(obj?.name)
      );
  return (
    <>
      <div className="ba_search p-2.5 border-b">
        <div className="relative">
          <input
            type="text"
            placeholder="Search filters"
            id="search"
            ref={filterRef}
            className="rounded  bg-violet-100/5 border-violet-100/20 w-full h-[1.875rem] text-sm12 px-2.5 py-2"
            onChange={handleFilterSearch}
          />
          {filterRef?.current?.value && (
            <button
              id="keyboard_items_clear"
              type="button"
              tabIndex={-1}
              className={` w-5 h-5 absolute top-1/2 -translate-y-1/2 right-2.5 flex items-center justify-center fill-violet-500 group-hover:fill-white bg-white rounded-full   hover:fill-white cursor-pointer hover:bg-violet-500  transition-all`}
              onClick={() => {
                if (filterRef?.current) {
                  filterRef.current.value = "";
                  handleResetKeyboardItems();
                }
              }}
            >
              <Tooltip
                anchorId={`keyboard_items_clear`}
                content={`Clear`}
                positionStrategy="fixed"
                place="top"
                variant="light"
                className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xs font-medium  z-10"
              />
              <SVG src={CrossSVG} className={"w-2 h-2"} />
            </button>
          )}
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided: any, snapshot: any) => (
            <div
              className="cb-block border-b px-2.5 py-2"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {!allSearchedTrue ? (
                <ul className={`min-h-[${filterItemsLength}px] pb-3`}>
                  {getFilterValue?.map((item: any, index: any) => (
                    <li className="font-medium empty:min-h-0 min-h-[1.125rem] group">
                      {item.searched && (
                        <Draggable
                          draggableId={item.name}
                          key={item.id}
                          index={index}
                        >
                          {(provided: any, snapshot: any) => (
                            <div
                              className="singe_cb flex justify-between items-center mb-3"
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <div className="inline-flex items-center text-violet-800transition font-medium rounded ">
                                <div
                                  className="drag_icon"
                                  {...provided.dragHandleProps}
                                >
                                  <IKTSVG
                                    className="min-w-[0.8125rem] max-w-[0.8125rem] flex items-center justify-center fill-gray-400 transition"
                                    path={dragSVG}
                                  />
                                </div>
                                <label
                                  htmlFor={item.id}
                                  className="ml-2 text-sm12 font-medium dark:text-gray-300 !cursor-pointer group-hover:text-indigo-500"
                                >
                                  {" "}
                                  {item.name}
                                </label>
                              </div>

                              <input
                                type="checkbox"
                                className="!cursor-pointer w-3 h-3 text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500 dark:focus:ring-indigo-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                checked={item.checked}
                                id={item.id}
                                name={item.name}
                                onChange={(data: any) => {
                                  handleChange(data);
                                  setTimeout(() => {
                                    refetch();
                                  }, 100);
                                }}
                              />
                            </div>
                          )}
                        </Draggable>
                      )}
                    </li>
                  ))}
                </ul>
              ) : (
                <div className="singe_cb flex justify-center items-center anim-fade">
                  <label className="ml-2 text-sm12 font-medium dark:text-gray-300">
                    {" "}
                    No data found
                  </label>
                </div>
              )}
            </div>
          )}
        </Droppable>
        <div className="restore-btn px-2.5 py-2">
          <button
            type="button"
            className="group inline-flex group items-center text-violet-800 hover:text-white bg-gray-100 hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-0.5 false"
            onClick={() => handleResetKeyboardItems()}
          >
            <IKTSVG
              className="min-w-4 max-w-4 mr-2 flex items-center justify-center fill-violet-500 group-hover:fill-white"
              path={restoreSVG}
            />
            Restore defaults
          </button>
        </div>
      </DragDropContext>
    </>
  );
};

export default DraggableFilters;
