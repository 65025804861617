import { IKTSVG } from "../../../components/ui/IKTSVG";

// NAVIGATION BUTTON COMPONENT
const NavigationButtons = ({
  handleNavigation,
  imgPath,
  className,
  id,
  svgClassName,
  disabled,
}: any) => {
  return (
    <button
      className={`group relative p-1 md:p-1 w-6 h-6 rounded-l  ${className} ${
        id === "next" && "rotate-180"
      }`}
      type="button"
      id={id}
      onClick={() => handleNavigation(id)}
      disabled={disabled}
    >
      <IKTSVG
        className={`flex items-center justify-center  transition  ${svgClassName} ${
          disabled
            ? "fill-gray-400"
            : "fill-violet-800 group-hover:fill-indigo-500"
        }`}
        path={imgPath}
      />
    </button>
  );
};
export default NavigationButtons;
