import _, { size, uniq } from "lodash";
import { useContext } from "react";
import Plus from "../../../../assets/media/icons/other_icons/plus.svg";
import cross from "../../../../assets/media/icons/standard_icons/cross.svg";
import { IKTSVG } from "../../../../components/ui/IKTSVG";
import { showAlert } from "../../../../helpers/ShowAlert";
import TooltipPortal from "../../../tx_trade/table/component/TooltipPortal";
import { TxAccountContext } from "../../core/TxAccountProvider";
import { removeOrderInvoice } from "../../core/_request";

const RemoveOrders = ({ state, setState, executeListAPI }: any) => {
  // STATES
  const layout = useContext(TxAccountContext);

  // ON ENTER -  EXCLUDE ORDER
  const handleExcludeOrder = () => {
    let enteredIds: any = state?.removeOrder
      .split(/\s*,\s*|\s+/)
      .map((id: any) => id.trim());
    enteredIds = _.uniq(enteredIds);
    let existingIds: any =
      [...(state?.allOrderIds || []), ...state?.order_ids] || [];

    // Filter out IDs that are already in existingIds
    let uniqueIds = enteredIds.filter((id: any) => !existingIds.includes(id));

    if (uniqueIds?.length === 0) {
      showAlert("Please enter unique order ID", true);
    }

    uniqueIds.forEach((id: any, index: number) => {
      if (id.toString().length >= 4 && id.toString().length <= 36) {
        setState((current: any) => {
          let order_ids = [...current.order_ids, id];
          return {
            ...current,
            removeOrder: "",
            order_ids: order_ids,
          };
        });
      }
    });
  };

  // REMOVE ORDERS API
  const handleRemoveId = (data: any) => {
    layout.setCustomLoader(true);
    // API EXECUTION
    removeOrderInvoice({
      order_ids: data?.order_ids,
      invoice_id: data?.invoice_id,
    }).then((response: any) => {
      if (
        response?.data &&
        size(response?.data) === 0 &&
        size(response?.errors) > 0
      ) {
        showAlert(response?.errors?.[0], true);
        layout.setCustomLoader(false);
      } else if (
        response?.data &&
        size(response?.data) > 0 &&
        !response?.data?.status
      ) {
        showAlert(response?.message, true);
        layout.setCustomLoader(false);
      } else if (
        response?.data &&
        size(response?.data) > 0 &&
        response?.data?.status
      ) {
        showAlert(response?.message, false);
        layout.setCustomLoader(false);
        setState((current: any) => {
          return {
            ...current,
            order_ids: [],
            allOrderIds: current?.allOrderIds
              ? uniq([...current?.order_ids, ...current?.allOrderIds])
              : current?.order_ids,
          };
        });

        setTimeout(() => {
          executeListAPI();
        }, 300);
      } else {
        showAlert("Something went wrong!", false);
        layout.setCustomLoader(false);
      }
    });
  };

  return (
    <>
      <div className="exclude_order p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded">
        {/* <div className="block_wrap w-1/2 mb-6">
          <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
            Enter invoice ID
          </h5>
          <TextInput
            inputClassName="placeholder:truncate block px-2.5 border-gray-200 font-medium w-full text-xs transition bg-gray-100 border appearance-none rounded focus:outline-none  focus:ring-0 focus:border-indigo-500 focus:bg-violet-300/50 peer peer-focus:bg-white autofill:bg-indigo-500 form"
            type="text"
            value={state?.invoice_id}
            name={"invoice_id"}
            required={false}
            disabled={false}
            placeholder={"Invoice ID"}
            handleClearValue={(data: any) =>
              setState((current: any) => {
                return {
                  ...current,
                  invoice_id: "",
                };
              })
            }
            handleOnChange={(data: any) =>
              setState((current: any) => {
                return {
                  ...current,
                  invoice_id: data?.target.value,
                };
              })
            }
            inputHeight={"!h-7"}
            isIcon={false}
          />
        </div> */}

        <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
          Exclude orders from Invoice
        </h5>
        <div className="exclude_input relative">
          <form className="flex flex-wrap flex-col">
            <input
              type="text"
              className={`w-full border rounded   text-sm14 leading-5 px-2.5 py-1 placeholder:text-gray-400 ${
                false
                  ? "bg-gray-100 text-gray-400 border-gray-100"
                  : "bg-white border-gray-200"
              }`}
              placeholder="Enter order ID or marketplace ID"
              name="excludeOrder"
              // disabled={isDisabled}
              value={state?.removeOrder}
              onChange={(data: any) =>
                setState((current: any) => {
                  return {
                    ...current,
                    removeOrder: data.target.value,
                  };
                })
              }
              onKeyDown={(data: any) => {
                if (data?.key === "Enter") {
                  data?.preventDefault();
                  handleExcludeOrder();
                }
              }}
            />
            <button
              className={`submit_exclude absolute top-[1px] right-[1px] w-6 bottom-[1px]  pl-1 ${
                false ? "pointer-events-none " : "bg-white"
              }`}
              type="button"
              // disabled={isDisabled}
              data-tooltip-id={`add-exclude`}
              onClick={(data: any) => {
                data?.preventDefault();
                handleExcludeOrder();
              }}
            >
              <TooltipPortal
                id={`add-exclude`}
                content={"Add"}
                className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
              />
              <IKTSVG
                path={Plus}
                className="fill-gray-400 hover:fill-indigo-500"
                svgClassName="w-3 h-3"
              />
            </button>
          </form>
        </div>

        {/* FILTER CHIP */}
        {size(state?.order_ids) > 0 && (
          <div className="exclude_list flex flex-wrap gap-2.5 mt-5">
            {state?.order_ids?.map((item: any, index: number) => {
              return (
                <div className="anim-fade single_list flex items-center text-sm12 rounded-xl px-2.5 py-[.1875rem] border bg-white">
                  <span>{item}</span>

                  <button
                    className="inline-flex items-center"
                    type="button"
                    data-tooltip-id={`item-${index}`}
                    onClick={(data: any) => {
                      setState((current: any) => {
                        return {
                          ...current,
                          order_ids: current?.order_ids?.filter(
                            (excludeItem: any) => excludeItem !== item
                          ),
                        };
                      });
                    }}
                  >
                    <TooltipPortal
                      id={`item-${index}`}
                      content={"Clear"}
                      className="text-center !bg-white !opacity-100 shadow !py-0.5 !px-1.5 font-medium text-xs z-[45] max-w-[9rem] cursor-default "
                    />
                    <IKTSVG
                      path={cross}
                      className="fill-violet-500 hover:fill-indigo-500 ml-2 cursor-pointer"
                      svgClassName="w-2 h-2"
                    />
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>

      {/* BUTTONS */}
      {size(state?.order_ids) > 0 && (
        <div className="anim-fade confirm_exclude px-5 py-3.5 flex items-center flex-wrap gap-2.5 text-sm13 bg-gray-100/50 border-t rounded-b">
          <span>
            Exclude{" "}
            {size(state?.order_ids) === 1
              ? `${size(state?.order_ids)} order?`
              : `${size(state?.order_ids)} orders?`}{" "}
          </span>
          <div className="buttons gap-2.5   flex flex-wrap">
            <button
              className="border border-gray-300 hover:border-indigo-500 text-violet-800 hover:text-white 
         bg-white hover:bg-indigo-500 transition font-medium rounded text-sm13 px-2.5 py-[.0625rem] leading-5"
              type="button"
              onClick={(data: any) =>
                setState((current: any) => {
                  return {
                    ...current,
                    order_ids: [],
                  };
                })
              }
            >
              Cancel
            </button>
            <button
              type="button"
              className={`group inline-flex group items-center   transition font-medium rounded px-2.5 py-0.5 leading-5
              text-white hover:text-white bg-violet-500 hover:bg-indigo-500`}
              onClick={(data: any) => handleRemoveId(state)}
            >
              Confirm
            </button>
          </div>
        </div>
      )}

      {/* EXCLUDED */}
      {_.size(state?.allOrderIds) > 0 && (
        <div className="exclude_order p-5 pt-3.5 bg-gray-100/50 mt-[1.875rem] rounded">
          <h5 className="font-semibold text-sm14 leading-4 mb-[1.125rem]">
            Excluded orders
          </h5>
          <div className="exclude_input relative"></div>
          <div className="exclude_list flex flex-wrap gap-2.5 mt-5">
            {state?.allOrderIds?.map((id: any, key: number) => {
              return (
                <div
                  className="anim-fade single_list flex items-center text-sm12 rounded-xl px-2.5 py-[.1875rem] border bg-white"
                  key={`confired-excluded-order-${key}`}
                >
                  <span>{id}</span>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default RemoveOrders;
