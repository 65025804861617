/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { MouseEventHandler, useRef, useState } from "react";
import SVG from "react-inlinesvg";
import Select, {
  ControlProps,
  NoticeProps,
  components
} from "react-select";
import { Tooltip } from "react-tooltip";
import crossIcon from "../../assets/media/icons/standard_icons/cross-purple.svg";
import searchIcon from "../../assets/media/icons/standard_icons/search-violet.svg";
import PageTitle from "../../components/core/PageTitle";
import Checkbox from "../../components/formComponent/Checkbox";
import DropDownField from "../../components/formComponent/DropDownField";
import PriceField from "../../components/formComponent/PriceField";
import Textfield from "../../components/formComponent/Textfield";
import { KTSVG } from "../../components/ui/KTSVG";
import { AddInventorySearchingValues } from "../add_inventory/core/_models";
import OffCanvas from "../add_inventory/widgets/RequestEvent";

const AddInventory = () => {
  const ref: any = useRef();
  const tableRef: any = useRef();
  const [focus, setFocus] = useState(false);
  const [searchedValue, setSearchedValue] =
    useState<AddInventorySearchingValues>();
  let options = [
    {
      value: "Glassglow",
      label: "Harry Styles Glassglow",
      date: "Mon, 30 2023",
      time: "15:00",
      place: "Ibrox Statium, Glasgow",
    },
    {
      value: "Manchester",
      label: "Harry Styles Manchester",
      date: "Sat, 15 2020",
      time: "17:00",
      place: "Wembley Statium, London",
    },
  ];

  const customStyle = {
    option: (base: any, { data, isDisabled, isFocused, isSelected }: any) => {
      return {
        // ...base,
        // padding:'6px',
        // paddingLeft:'8px',
        // paddingRight:'8px'
        // backgroundColor: isFocused ? "#E9E9FF" : "white",
        // color: isFocused ? "black" : "black",
        // "&:hover": {
        //   color: "black",
        // },
      };
    },

    control: (base: any, state: any) => {
      return {
        ...base,
        display: "flex",
        flexDirection: "row-reverse",
        border: state.isFocused ? "2px solid #6f6cff" : "2px solid lightgrey",
        minHeight: "1px",
        height: "40px",
        borderWidth: "2px",
        borderRadius: "0.312rem",
        boxShadow: "none",
        "&:hover": {
          border: "1px solid",
          borderColor: "#6f6cff ",
          borderWidth: "2px",
        },
      };
    },
    container: (provided: any, state: any) => ({
      ...provided,
      // marginTop: 50,
    }),
    valueContainer: (provided: any, state: any) => {
      return {
        ...provided,
        overflow: "visible",
      };
    },
  };

  const Control = ({ children, ...props }: ControlProps<any, false>) => {
    // @ts-ignore
    const { onButtonClick } = props.selectProps;
    return (
      <components.Control {...props}>
        {/* Search icon button */}
        <button
          className=" search_btn"
          type="button"
          onClick={(e: any) =>
            props.selectProps.inputValue ? onButtonClick(e) : null
          }
          onMouseDown={(e: any) =>
            !props.selectProps.inputValue && onButtonClick(e)
          }
        >
          <SVG
            className="w-3.5"
            id="search_btn"
            src={props.selectProps.inputValue ? crossIcon : searchIcon}
          />
        </button>
        {/* Search icon button End */}
        {children}
        {/* Start Toggle button */}
        <div className="sm:w-auto w-full inline-flex flex-row-reverse p-0.5 mr-0.5 mb-3 !my-0 border border-indigo-500/20 rounded bg-white" onMouseDown={onButtonClick} >
          <div className="relative flex items-center sm:flex-0 flex-auto">
            <input id="search-radio-2" type="radio" name="search-radio" className="absolute hidden peer" checked />
            <label htmlFor="search-radio-2" className="py-0.5 px-1 w-full text-center rounded text-indigo-500 peer-checked:text-white peer-checked:bg-indigo-500    peer-checked:shadow transition text-xxs cursor-pointer" > {" "} Performer/venue
            </label>
          </div>
          <div className="relative flex items-center sm:flex-0 flex-auto">
            <input id="search-radio-1" type="radio" name="search-radio" className="absolute hidden peer" checked />
            <label htmlFor="search-radio-1" className="py-0.5 px-1 w-full text-center rounded text-indigo-500 peer-checked:text-white peer-checked:bg-indigo-500    peer-checked:shadow transition text-xxs cursor-pointer" > {" "} Event
            </label>
          </div>
        </div>

        {/* ---------------------------- End Toggle code */}
      </components.Control>
    );
  };

  const Option = ({ children, ...props }: any) => {
    return (
      <>
        <components.Option {...props}>
          <ul className="p-0 list-none">
            <li>
              <a href="#" className="search__item mb-4 flex flex-wrap m-1 border rounded-sm duration-150">
                <span className="search__item__title px-2 py-1 text-xs first:w-full first:border-r-0 first:border-b border-r">
                  {props.data.label}
                </span>
                <span className="inline-flex items-stretch px-2 py-1 text-xs border-r">
                  <KTSVG path="standard_icons/calendar.svg" className="pr-2" />
                  <span className="">{props.data.date} </span>
                </span>
                <span className="inline-flex items-stretch px-2 py-1 text-xs border-r">
                  <KTSVG path="standard_icons/calendar.svg" className="pr-2" />
                  <span className="">{props.data.time} </span>
                </span>
                <span className="inline-flex  px-2 py-1 text-xs border-r-0">
                  <KTSVG path="standard_icons/geo_icon.svg" className="pr-2 " />
                  <span className="">{props.data.place}</span>
                </span>
              </a>
            </li>
          </ul>
        </components.Option>
      </>
    );
  };

  const NoOptionsMessage = (props: NoticeProps) => {
    return (
      <>
        <components.NoOptionsMessage {...props}>
          <div className="bg-violet-300 h-20 flex justify-center flex-col">
            <div className="text-black text-sm15">No result</div>
            <div>
              <button type="button" className="focus:outline-none text-white bg-violet-500 hover:bg-violet-900 focus:ring-violet-700 font-medium rounded-sm text-sm13 px-2 py-0.5" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight" >
                Request an event
              </button>
            </div>
          </div>
        </components.NoOptionsMessage>
      </>
    );
  };

  const MenuList = (props: any, notice: NoticeProps) => {
    const { onButtonClick } = props.selectProps;
    if (Array.isArray(props.children)) {
      return (
        <components.MenuList {...props}>
          <div className="pr-3 overflow-auto scrollbar-thin scrollbar-thumb-violet-800 scrollbar-thumb-rounded-full rounded-b-sm max-h-[25.75rem]">
            <span className="text-xs pl-1 font-semibold">Upcoming events</span>
            {props.children}
            <button type="button" className=" pl-1 hover:underline hover:decoration-indigo-500" onClick={onButtonClick} >
              <span className="text-xs text-indigo-500">
                View all results for harry styles
              </span>
            </button>
          </div>
        </components.MenuList>
      );
    } else {
      return (
        <components.MenuList {...props}>{props.children}</components.MenuList>
      );
    }
  };

  const onClick: MouseEventHandler<any> = (e: any) => {
    if (ref.current !== undefined) {
      if (e.target.id === "Rectangle_15") {
        ref.current.clearValue();
      }
    }

    e.preventDefault();
    e.stopPropagation();
  };

  // Table horizontal scroll
  const scroll = (scrollOffset: any) => {
    tableRef.current.scrollLeft += scrollOffset;
  };

  return (
    <>
      {/* Header */}
      <PageTitle title="Add Inventory" />
      {/* Event Request (if there is not options in search field) */}
      <OffCanvas />
      {/* Header Accordion */}

      <div className="accordion mt-4 font-medium" id="accordionExample">
        <div className="accordion-item bg-white border border-gray-200 shadow-md">
          <div className="flex accordion-header mb-0" id="headingOne">
            <div className="pl-2 pr-5 py-2">
              <select name="" id="" className="sm:mr-2 !pr-4 py-0 text-sm13 font-medium border-none focus:ring-0" >
                <option value="">Keyboard</option>
                <option value="">Keyboard1</option>
                <option value="">Keyboard2</option>
                <option value="">Keyboard3</option>
              </select>
              <select name="" id="" className="!pr-4 py-0 text-sm13 font-medium border-none focus:ring-0" >
                <option value="">Columns</option>
                <option value="">Columns1</option>
                <option value="">Columns2</option>
              </select>
            </div>
            <button className="relative flex items-center py-4 px-5 text-base text-gray-800 text-left bg-white border-l  rounded-tr-lg transition focus:outline-none group  ml-auto" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              <KTSVG className="flex items-center justify-center group-[.collapsed]:rotate-180  fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Collapse.svg" />
            </button>
          </div>
          <div className={`px-5 py-4 border-t flex flex-wrap items-center`}>
            <div className={`w-full md:w-3/4 lg:w-1/2 xl:w-1/4 ${ focus && "w-2/6" } focus:transition-all ease-in-out duration-300 `} >
              <Select
                // {...props}
                // @ts-ignore
                ref={ref}
                // @ts-ignore
                onButtonClick={(e: any) => onClick(e)}
                components={{ Control, Option, NoOptionsMessage, MenuList }}
                onFocus={(data: any) => setFocus(true)}
                onBlur={(data: any) => setFocus(false)}
                isSearchable
                onChange={(data: any) => {
                  let target = { ...data, name: "upcomingEvents" };
                  setSearchedValue(target);
                }}
                placeholder={"Search event"}
                className="focus:w-1/2"
                //menuIsOpen={true}
                name="upcomingEvents"
                options={options}
                styles={customStyle}
                isClearable={true}
                classNamePrefix={"inventory_select"}
              />
            </div>
            {searchedValue && (
              <div className="lg:ml-4 text-xs mt-4 lg:mt-0">
                <span className="inline-flex items-center">
                  {searchedValue?.date && (
                    <KTSVG className="flex items-center justify-center min-w-[0.75] fill-black mr-2" path="standard_icons/calendar.svg" />
                  )}
                  {searchedValue?.date}
                </span>
                <span className="inline-flex items-center ml-4">
                  {searchedValue?.time && (
                    <KTSVG className="flex items-center justify-center min-w-[0.75] fill-black mr-2" path="standard_icons/clock-outline.svg" />
                  )}
                  {searchedValue?.time}
                </span>
                <span className="inline-flex items-center ml-4">
                  {searchedValue?.address && (
                    <KTSVG className="flex items-center justify-center min-w-[0.75] fill-black mr-2" path="standard_icons/geo_icon.svg" />
                  )}
                  {searchedValue?.address}
                </span>
              </div>
            )}
          </div>
          <div id="collapseOne" className="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample" > 
            <div className="accordion-body -mx-2.5 pt-4 pb-1 px-5 border-y">
              <div className="flex flex-wrap">
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Marketplaces" name="marketplace" handleOnChange={(data: any) => {}} />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Ticket type" name="ticketype" handleOnChange={(data: any) => {}} />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <Textfield name="quantity" type="text" label="Quantity" id="1" />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Split type" name="marketplace" handleOnChange={(data: any) => {}} />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <Textfield name="maxDisplayQuantity" type="number" label="Max Display Quantity" id="90" />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Category" name="marketplace" handleOnChange={(data: any) => {}} />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Section" name="marketplace" handleOnChange={(data: any) => {}} />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <Textfield name="Row" type="number" label="Row" id="3" />
                </div>{" "}
              </div>
              <div className="flex flex-wrap">
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <Textfield name="firstRow" type="text" label="First Row" id="9" />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <PriceField id="1" />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <PriceField id="2" />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <Checkbox label="PPE" />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <Checkbox label="Tickets in hand" tooltip="true" />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Date Field" name="datefield" handleOnChange={(data: any) => {}} />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Benefits" name="benefits" handleOnChange={(data: any) => {}} />
                </div>
                <div className="xl:w-[12.5%] lg:w-1/4 sm:w-1/2 w-full px-[0.312rem] mb-3.5">
                  <DropDownField options={options} placeholder="Restriction" name="restriction" handleOnChange={(data: any) => {}} />
                </div>
              </div>
            </div>
            <div className="flex flex-wrap items-center justify-end p-4">
              <span className="mr-4 mt-0.5 text-sm13 font-medium text-gray-400">
                Add to all events
              </span>
              <div>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input type="checkbox" value="" className="sr-only peer" />
                  <div className="w-7 h-3 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:bg-violet-500 after:content-[''] after:absolute after:-top-0.5 after:-left-0.5 after:bg-gray-400 after:rounded-full after:h-4 after:w-4 after:transition-all after:shadow-md peer-checked:bg-100"></div>
                </label>
              </div>
              <div className="ml-6">
                <button type="button" className="text-white bg-violet-500 hover:bg-indigo-500 transition font-semibold rounded text-sm13 px-2.5 py-0.5 uppercase"> {" "}
                  <i className="text-xs fas fa-solid fa-plus pr-2"></i>Add Inventory
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Main Body */}
      <div className="accordion my-4 font-medium" id="accordionMainBody">
        <div className="accordion-item bg-white border border-gray-200 shadow-md">
          <div className="flex accordion-header mb-0 event__header bg-violet-600 rounded-t text-white" id="bodyOne" >
            {/* Header */}
            <div className="flex items-center border-r border-white/20">
              <div className="min-w-[2.5rem] max-w-[2.5rem] text-center p-1">
                <input className="w-3 h-3 !bg-indigo-500 !bg-none !border-2 !border-violet-500 outline outline-2 outline-indigo-500" type="radio" name="flexRadioDefault" checked />
              </div>
            </div>
            <div className="flex md:flex-nowrap flex-wrap w-full">
              <div className="flex lg:flex-nowrap flex-wrap flex-auto md:mr-4">
                <div className="xl:max-w-[350.312rem] lg:max-w-[15.625rem] max-w-full w-full flex items-center px-4 lg:pb-3 pb-1 lg:pt-3 pt-3 text-sm md:border-r border-white/20">
                  Harry Styles Manchester
                </div>
                <div className="flex flex-wrap flex-auto">
                  <div className="lg:max-w-[9.875rem] max-w-full w-full flex items-center px-4 lg:py-3 py-1 text-xs md:border-r border-white/20">
                    <KTSVG className="flex items-center justify-center min-w-[0.75] fill-white mr-2" path="standard_icons/calendar.svg" />
                    Wed, 15 Jun 2022
                  </div>
                  <div className="lg:max-w-[5.187rem] max-w-full w-full flex items-center px-4 lg:py-3 py-1 text-xs border-r border-white/20">
                    <KTSVG className="flex items-center justify-center min-w-[0.75] fill-white mr-2" path="standard_icons/clock-outline.svg" />
                    17:00
                  </div>
                  <div className="lg:w-auto w-full flex items-center flex-auto px-4 lg:pt-3 pt-1 lg:pb-3 pb-3 text-xs lg:border-none md:border-r border-white/20">
                    <KTSVG className="flex items-center justify-center min-w-[0.75] fill-white mr-2" path="standard_icons/geo_icon.svg" />
                    Emirates Old Trafford Cricket Ground, Manchester
                  </div>
                </div>
              </div>
              <div className="flex ml-auto">
                <div className="flex items-center">
                  <div className="flex items-center lg:flex-nowrap flex-wrap">
                    <Tooltip anchorId="listing-tooltip" content="1 Listing" place="top" variant="light" className="!bg-white !opacity-100 px-3 py-1 font-medium text-xs z-10" clickable/>
                    <div id="listing-tooltip" className="flex-auto flex items-center mr-3 text-xs text-gray-400"> 
                      <KTSVG className="flex items-center justify-center min-w-[0.75] fill-gray-400 mr-1.5" path="standard_icons/listing_lines.svg" /> 
                      1
                    </div>
                    <div className="flex-auto flex items-center xl:mr-5 mr-4 text-xs">
                      <KTSVG className="flex items-center justify-center min-w-[0.75] fill-white mr-1.5" path="standard_icons/subtraction.svg" />
                      2
                    </div>
                  </div>
                </div>
                <button className="relative flex items-center py-4 lg:px-5 px-4 text-base text-gray-800 text-left border-l border-white/20
                !transition focus:outline-none group" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBodyThree" aria-expanded="true" aria-controls="collapseBodyThree" > 
                  <KTSVG className="flex items-center justify-center fill-green-600 hover:fill-indigo-500 transition" path="other_icons/Market-Insights-Green.svg" />
                </button>
                <button className="relative flex items-center py-4 lg:px-5 px-4 text-base text-gray-800 text-left border-l border-white/20 !transition focus:outline-none group" type="button" data-bs-toggle="collapse" data-bs-target="#collapseBodyOne" aria-expanded="true" aria-controls="collapseBodyOne" > 
                  <KTSVG className="flex items-center justify-center group-[.collapsed]:rotate-180 fill-white transition" path="other_icons/Collapse.svg" />
                </button>
              </div>
            </div>
          </div>

          {/* Upper filter accordion body */}
          <div id="collapseBodyThree" className="accordion-collapse collapse" aria-labelledby="bodyThree"
            // data-bs-parent="#accordionMainBody"
          >
            <div className="accordion-body ">
              <section className="flex  h-14 text-sm border-b">
                <div className="inline-flex items-center justify-center px-1 w-40 text-sm13 font-semibold">
                  Market Insights
                </div>
                <div className="flex w-full items-center pl-4 border-l">
                  <div className="w-[12.5%] px-[0.312rem] ">
                    <DropDownField
                      options={options}
                      placeholder="Marketplace"
                      name="marketplace"
                      handleOnChange={(data: any) => {}}
                    />
                  </div>
                  <div className="px-[0.312rem] ">Quantity</div>
                  <div className="w-[12.5%] px-[0.312rem] ">
                    <ul className="flex justify-between">
                      {[...Array(6)].map((item: any, index: any) => {
                        return (
                          <React.Fragment key={index + 1}>
                            <li className="pr-3">
                              <span>
                                <input type="checkbox" className=" font-medium text-indigo-500 bg-gray-100 border-gray-300 visually-hidden" id={index + 1} />
                                <label htmlFor={index + 1} className="cursor-pointer inline-block rounded-full  text-black border leading-normal uppercase shadow-md hover:border-indigo-500 hover:text-indigo-500 hover:bg-indigo-500/5 hover:shadow-lg focus:bg-blue-700 focus:outline-none focus:ring-0 transition duration-150 ease-in-out w-6 h-6 text-center" >
                                  {index + 1}
                                </label>
                              </span>
                            </li>
                          </React.Fragment>
                        );
                      })}
                    </ul>
                  </div>
                  <div className="w-[12.5%] px-[0.312rem] pl-6">
                    <DropDownField
                      options={options}
                      placeholder="Category"
                      name="category"
                      handleOnChange={(data: any) => {}}
                    />
                  </div>
                  <div className="px-[0.312rem] pl-4">
                    <button
                      type="button"
                      className="border group rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                      <KTSVG path="other_icons/refresh.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.312rem]" />
                    </button>
                  </div>

                  <div className="px-[0.312rem] ">
                    <button
                      type="button"
                      className="border group rounded-sm w-6 h-6 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                      <KTSVG path="other_icons/Clear.svg" className="fill-violet-500 transition" svgClassName="ml-1" />
                    </button>
                  </div>
                </div>
              </section>

              <section className="flex justify-between h-28 text-sm border-b bg-gray-100 p-6">
                {[...Array(4)].map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      <div className="border rounded w-[24%] bg-white p-2 ">
                        <div className="flex justify-between">
                          <div>
                            <p>784</p>
                            <p className="text-gray-500">Tickets</p>
                          </div>
                          <div>
                            <span className="inline-flex self-start bg-rose-500 text-white text-xxs font-semibold px-2.5 py-0.5 rounded ">
                              <KTSVG path="standard_icons/arrowDown.svg" className="pt-1 pr-1" />
                              15
                            </span>
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  );
                })}
              </section>
            </div>
          </div>

          <div id="collapseBodyOne" className="accordion-collapse collapse show" aria-labelledby="bodyOne" >
            <div className="accordion-body relative">
              <div ref={tableRef} className="inventory-table overflow-auto scrollbar-thin scrollbar-thumb-transparent scrollbar-thumb-rounded-full rounded-b pr-32" >
                <table className="w-full text-xs text-left min-w-[87.5rem]">
                  <thead className="text-xs text-gray-400">
                    <tr className="border-b">
                      <th className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] border-r p-1 text-center">
                        <input type="checkbox" className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500"/>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        Markteplaces
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          Ticket type
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          Quantity
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        Split type
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          Max display
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          Category
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          Section
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">Row</div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          First seat
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          Face value
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        <div className="w-full pr-3 table-head-select">
                          Proceed price
                        </div>
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        In hand
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        Date of Ship
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        Benefits
                      </th>
                      <th className="px-1.5 py-3 font-medium whitespace-nowrap">
                        Restrictions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="border-b">
                      <td className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] border-r p-1 text-center">
                        <input id="checkbox-all" type="checkbox"
                          className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">2 selected</option>
                          <option value="">3 selected</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <div><PriceField/></div> */}
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/> */}
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="p-1.5 font-medium">
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        <div className="px-2 py-1 border rounded bg-gray-50 text-center">
                          <input type="checkbox" className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 
                          rounded focus:ring-indigo-500" />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        datepicker
                      </td>
                    </tr>
                    <tr id="collapseBodyfour"
                      className="accordion-collapse collapse border-b"
                      aria-labelledby="bodyfour">
                      <td className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[5.625rem] border-r p-1 text-center"></td>
                      {/* Nested table  */}
                      <td className="border-r" colSpan={2}>
                        <table className="w-full ">
                          <thead>
                            <tr>
                              <td className="px-1.5 py-1 font-medium whitespace-nowrap">
                                Ticket 4 Football
                              </td>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="text-gray-300">
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Proceed price
                              </td>
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Display price
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="p-1.5 font-medium w-0">
                                <PriceField />
                              </td>
                              <td className="p-1.5 font-medium w-0">
                              <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500" placeholder="£220"/>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="border-r" colSpan={2}>
                        <table className="w-full ">
                          <thead>
                            <tr>
                              <td className="px-1.5 py-1 font-medium whitespace-nowrap">
                                Football Ticket Pad
                              </td>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="text-gray-300">
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Proceed price
                              </td>
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Display price
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="p-1.5 font-medium w-0">
                                <PriceField />
                              </td>
                              <td className="p-1.5 font-medium w-0">
                                <input type="text" placeholder="£220" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="border-r" colSpan={2}>
                        <table className="w-full ">
                          <thead>
                            <tr>
                              <td className="px-1.5 py-1 font-medium whitespace-nowrap">
                                Vivasheets
                              </td>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="text-gray-300">
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Proceed price
                              </td>
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Display price
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="p-1.5 font-medium w-0">
                                <PriceField />
                              </td>
                              <td className="p-1.5 font-medium w-0">
                                <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="border-r" colSpan={2}>
                        <table className="w-full ">
                          <thead>
                            <tr>
                              <td className="px-1.5 py-1 font-medium whitespace-nowrap">
                                Ticombo
                              </td>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="text-gray-300">
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Proceed price
                              </td>
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Display price
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="p-1.5 font-medium w-0">
                                <PriceField />
                              </td>
                              <td className="p-1.5 font-medium w-0">
                                <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                      <td className="border-r" colSpan={2}>
                        <table className="w-full ">
                          <thead>
                            <tr>
                              <td className="px-1.5 py-1 font-medium whitespace-nowrap">
                                Ticombo
                              </td>
                            </tr>
                          </thead>
                          <thead>
                            <tr className="text-gray-300">
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Proceed price
                              </td>
                              <td className="px-1.5 text-gray-400 font-medium whitespace-nowrap" colSpan={1} >
                                Display price
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="p-1.5 font-medium w-0">
                                <PriceField />
                              </td>
                              <td className="p-1.5 font-medium w-0">
                                <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500" />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr className="border-b row-indigo-light">
                      <td className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] border-r p-1 text-center">
                        <input id="checkbox-all" type="checkbox"
                          className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">2 selected</option>
                          <option value="">3 selected</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <div><PriceField/></div> */}
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/> */}
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="p-1.5 font-medium">
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        <div className="px-2 py-1 border rounded bg-gray-50 text-center">
                          <input type="checkbox" className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 
                          rounded focus:ring-indigo-500" />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        datepicker
                      </td>
                    </tr>
                    <tr className="border-b row-indigo-light -selected">
                      <td className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] border-r p-1 text-center">
                        <input id="checkbox-all" type="checkbox" className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500" />
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">2 selected</option>
                          <option value="">3 selected</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <div><PriceField/></div> */}
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/> */}
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="p-1.5 font-medium">
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        <div className="px-2 py-1 border rounded bg-gray-50 text-center">
                          <input type="checkbox" className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 
                          rounded focus:ring-indigo-500" />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        datepicker
                      </td>
                    </tr>
                    <tr className="border-b row-error">
                      <td className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] border-r p-1 text-center">
                        <input id="checkbox-all" type="checkbox"
                          className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">2 selected</option>
                          <option value="">3 selected</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 rounded focus:border-rose-500 border-rose-500 text-rose-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 rounded focus:border-rose-500 border-rose-500 text-rose-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <div><PriceField/></div> */}
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/> */}
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="p-1.5 font-medium">
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        <div className="px-2 py-1 border rounded bg-gray-50 text-center">
                          <input type="checkbox" className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 
                          rounded focus:ring-indigo-500" />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        datepicker
                      </td>
                    </tr>
                    <tr className="border-b row-disabled">
                      <td className="flex items-center justify-center min-w-[2.5rem] max-w-[2.5rem] h-[2.5rem] border-r p-1 text-center">
                        <input id="checkbox-all" type="checkbox"
                          className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 rounded-sm focus:ring-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">2 selected</option>
                          <option value="">3 selected</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        <select className="h-6 text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500">
                          <option value="">E-ticket</option>
                          <option value="">E-ticket 2</option>
                        </select>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <div><PriceField/></div> */}
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5">
                        <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/>
                      </td>
                      <td className="p-1.5 font-medium">
                        {/* <input type="text" className="h-6 max-w-[6.875rem] text-xs font-weight-500 py-0 border-gray-300 rounded focus:border-indigo-500"/> */}
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="p-1.5 font-medium">
                        <div>
                          <PriceField />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        <div className="px-2 py-1 border rounded bg-gray-50 text-center">
                          <input type="checkbox" className="w-3 h-3 font-medium text-indigo-500 bg-gray-100 border-gray-300 
                          rounded focus:ring-indigo-500" />
                        </div>
                      </td>
                      <td className="relative p-1.5 font-medium">
                        datepicker
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div className="absolute h-full right-0 top-0 max-w-2xl bg-white border-l shadow-md rounded-br-md z-20">
                  <div className="py-2 px-4 flex justify-end border-b h-[2.562rem]">
                    <button
                      className="rounded-l bg-gray-100 hover:bg-gray-200 transition p-2"
                      type="button"
                      onClick={() => scroll(-120)}>
                      <KTSVG
                        className="flex items-center justify-center fill-violet-500 transition"
                        path="standard_icons/angle_left.svg"
                      />
                    </button>
                    <button
                      className="rounded-r bg-gray-100 hover:bg-gray-200 transition p-2"
                      type="button"
                      onClick={() => scroll(120)}>
                      <KTSVG
                        className="flex items-center justify-center fill-violet-500 transition"
                        path="standard_icons/angle_right.svg"
                      />
                    </button>
                  </div>
                  <div>
                    <div className="flex">
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Tags.svg"/>
                      </button>
                      <button className="relative price_exchange_btn flex  items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b group collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseBodyfour"
                        aria-expanded="true"
                        aria-controls="collapseBodyfour">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/PPE.svg"/>
                        <KTSVG className="absolute -bottom-2 [&_svg]:w-2.5 [&_svg]:h-2.5 left-1/2 -translate-x-1/2 transition block group-[.collapsed]:hidden" path="standard_icons/select-angle-indigo.svg"/>
                      </button>
                      <button className="flex  items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Upload.svg"/>
                      </button>
                    </div>
                    <div id="collapseBodyfour"
                      className="accordion-collapse collapse w-full h-full"
                      aria-labelledby="bodyfour">
                      <div className="accordion-body relative inline-flex items-center w-full justify-evenly py-7">
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/refresh.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/Clear.svg" className="fill-violet-500 transition [&_svg]:w-5 [&_svg]:h-5" svgClassName="ml-[0.187rem]" />
                        </button>
                        <Tooltip
                          anchorId="confirm-tooltip"
                          content="Confirm"
                          place="top"
                          variant="light"
                          className="!bg-white !opacity-100 !py-0.5 !px-1.5 !text-xxs font-medium text-xs z-10"
                          clickable
                        />
                        <button id="confirm-tooltip" type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center" >
                          <KTSVG path="other_icons/check.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex row-indigo-light">
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Tags.svg"/>
                      </button>
                      <button className="price_exchange_btn flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseBody6"
                        aria-expanded="true"
                        aria-controls="collapseBody6">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/PPE.svg"/>
                      </button>
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Upload.svg"/>
                      </button>
                    </div>
                    <div id="collapseBody6"
                      className="accordion-collapse collapse w-full h-full"
                      aria-labelledby="body6">
                      <div className="accordion-body relative inline-flex items-center w-full justify-evenly py-5">
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/refresh.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/Clear.svg" className="fill-violet-500 transition" svgClassName="ml-[0.375rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/check.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex row-indigo-light">
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Tags.svg"/>
                      </button>
                      <button className="price_exchange_btn flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseBody7"
                        aria-expanded="true"
                        aria-controls="collapseBody7">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/PPE.svg"/>
                      </button>
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-b" type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Upload.svg"/>
                      </button>
                    </div>
                    <div id="collapseBody7"
                      className="accordion-collapse collapse w-full h-full"
                      aria-labelledby="body7">
                      <div className="accordion-body relative inline-flex items-center w-full justify-evenly py-5">
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/refresh.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/Clear.svg" className="fill-violet-500 transition" svgClassName="ml-[0.375rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/check.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex row-error">
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Tags.svg"/>
                      </button>
                      <button className="price_exchange_btn flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseBody8"
                        aria-expanded="true"
                        aria-controls="collapseBody8">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/PPE.svg"/>
                      </button>
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Upload.svg"/>
                      </button>
                    </div>
                    <div id="collapseBody8"
                      className="accordion-collapse collapse w-full h-full"
                      aria-labelledby="body8">
                      <div className="accordion-body relative inline-flex items-center w-full justify-evenly py-5">
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/refresh.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/Clear.svg" className="fill-violet-500 transition" svgClassName="ml-[0.375rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/check.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex row-disabled">
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Tags.svg"/>
                      </button>
                      <button className="price_exchange_btn flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-r border-b collapsed"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseBody8"
                        aria-expanded="true"
                        aria-controls="collapseBody8">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/PPE.svg"/>
                      </button>
                      <button className="flex items-center justify-center p-1 md:p-1 w-8 md:w-10 h-[2.562rem] border-b"
                        type="button">
                        <KTSVG className="flex items-center justify-center fill-violet-500 hover:fill-indigo-500 transition" path="other_icons/Upload.svg"/>
                      </button>
                    </div>
                    <div id="collapseBody8"
                      className="accordion-collapse collapse w-full h-full"
                      aria-labelledby="body8">
                      <div className="accordion-body relative inline-flex items-center w-full justify-evenly py-5">
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/refresh.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/Clear.svg" className="fill-violet-500 transition" svgClassName="ml-[0.375rem]" />
                        </button>
                        <button type="button" className="border group rounded w-7 h-7 hover:bg-indigo-500  hover:text-white hover:border-indigo-500 transition text-center">
                          <KTSVG path="other_icons/check.svg" className="fill-violet-500 group-hover:fill-white transition" svgClassName="ml-[0.437rem]" />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddInventory;
