import Cookies from "js-cookie";
import _ from "lodash";
import moment from "moment";
import axios from "../../../axois/SetupAxios";
import { LOG_TAB_KEYS } from "../../../components/ExpandableList/constants/constants";
import { getInventoryLogListing } from "../../inventory/core/_request";

const BASEURL = process.env.REACT_APP_PF_INTERNAL_API_URL;
var myHeaders = new Headers();
let cookieTtoken = Cookies.get("authTokens") as string;
if (cookieTtoken) {
  cookieTtoken = JSON.parse(cookieTtoken);
  myHeaders.append("Authorization", `Bearer ${cookieTtoken}`);
}

// SALES OPTIONS
const getSalesOptions = (): Promise<any> => {
  return axios.post(`sales/sales-options`).then((response) => response.data);
};

// Sales LISTING
const getSalesListing = (data: any): Promise<any> => {
  let marketplace_id: any = [];
  let event_id = data?.filters?.event_id?.id
    ? [data?.filters?.event_id?.id]
    : _.map(data?.filters?.event_id, "id");
  let team_id = _.map(data?.filters?.team_id, "id");
  let ticket_type_dropdown = data?.filters?.ticket_type_dropdown?.id;
  let team_member = data?.filters?.specific
    ? [data?.filters?.specific]
    : _.map(data?.filters?.team_member, "id");
  let order_exchanges = data?.filters?.order_exchanges?.id;
  let time_to_event = data?.filters?.time_to_event?.value;
  let order_statuses = _.map(data?.filters?.order_statuses, "id");
  let category_ids = _.map(data?.filters?.category_ids, "id");
  let performer_ids = _.map(data?.filters?.performer_ids, "id");
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let order_by = data?.sorting?.order_by;
  let sort_order = data?.sorting?.sort_order;
  let ticket_type = _.keys(_.pickBy(data?.filters?.ticket_type));
  let export_to_csv = data?.export_to_csv;
  let order_start_date;
  let order_end_date;
  let delivery_start_date;
  let delivery_end_date;
  let event_start_date;
  let event_end_date;
  let store_id;
  let ticket_in_hand;
  let internal_order_statuses;
  if (data?.filters?.orderDateRange) {
    order_start_date = data?.filters?.orderDateRange?.startDate;
    order_end_date = data?.filters?.orderDateRange?.endDate;
  }
  if (data?.filters?.deliveryDateRange) {
    delivery_start_date = data?.filters?.deliveryDateRange?.startDate;
    delivery_end_date = data?.filters?.deliveryDateRange?.endDate;
  }
  if (data?.filters?.event_date) {
    event_start_date = data?.filters?.event_date?.startDate;
    event_end_date = data?.filters?.event_date?.endDate;
  }
  if (_.map(data?.filters?.order_exchanges, "id")) {
    marketplace_id = _.map(data?.filters?.order_exchanges, "id");
  }

  if (_.map(data?.filters?.internal_order_statuses, "id")) {
    internal_order_statuses = _.map(
      data?.filters?.internal_order_statuses,
      "id"
    );
  }

  store_id = data?.filters?.store_id;
  ticket_in_hand = data?.filters?.ticket_in_hand;

  // console.log(ticket_in_hand);

  let payload = {
    ...data.pagination,
    ...(data?.tabValue && { order_status_list: data?.tabValue }),
    ...(data?.debouncedSearch && { keywords: data?.debouncedSearch }),
    ...(data?.filters?.query &&
      data?.export_to_csv && { keywords: data?.filters?.query }),
    ...(team_member && team_member.length > 0
      ? { team_member }
      : { team_member: "all" }),
    ...(event_id && event_id.length > 0 && { event_id }),
    ...(time_to_event && { time_to_event }),
    ...(order_statuses && order_statuses.length > 0 && { order_statuses }),
    ...(category_ids && category_ids.length > 0 && { category_ids }),
    ...(performer_ids && performer_ids.length > 0 && { performer_ids }),
    ...(ticket_type && ticket_type.length > 0 && { ticket_type: ticket_type }),
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
    ...(order_exchanges && { exchange_id: order_exchanges }),
    ...(order_start_date && {
      order_start_date: moment(order_start_date).format("YYYY-MM-DD"),
    }),
    ...(order_end_date && {
      order_end_date: moment(order_end_date).format("YYYY-MM-DD"),
    }),
    ...(delivery_start_date && {
      delivery_start_date: moment(delivery_start_date).format("YYYY-MM-DD"),
    }),
    ...(delivery_end_date && {
      delivery_end_date: moment(delivery_end_date).format("YYYY-MM-DD"),
    }),
    ...(export_to_csv && { export_to_csv: export_to_csv }),

    ...(event_start_date && {
      event_start_date: moment(event_start_date).format("YYYY-MM-DD"),
    }),
    ...(event_end_date && {
      event_end_date: moment(event_end_date).format("YYYY-MM-DD"),
    }),
    ...(_.size(team_id) > 0 && { team_id }),
    ...(marketplace_id &&
      marketplace_id?.length > 0 && { marketplace_id: marketplace_id }),

    ...(internal_order_statuses &&
      internal_order_statuses?.length > 0 && {
        internal_order_statuses: internal_order_statuses,
      }),

    ...(store_id && {
      store_id: store_id,
    }),
    ...(ticket_in_hand && {
      ticket_in_hand: ticket_in_hand,
    }),
  };
  return axios.post(`sales/listing`, payload).then((response) => response.data);
};

// Sales LISTING
const getSalesOverviewListing = (data: any): Promise<any> => {
  let marketplace_id: any = [];
  let event_id = data?.filters?.event_id?.id
    ? [data?.filters?.event_id?.id]
    : _.map(data?.filters?.event_id, "id");
  let team_id = _.map(data?.filters?.team_id, "id");
  let team_member = data?.filters?.specific
    ? [data?.filters?.specific]
    : _.map(data?.filters?.team_member, "id");
  let order_exchanges = data?.filters?.order_exchanges?.id;
  let time_to_event = data?.filters?.time_to_event?.value;
  let order_statuses = _.map(data?.filters?.order_statuses, "id");
  let page = data?.filters?.page;
  let per_page = data?.filters?.per_page;
  let order_by = data?.sorting?.order_by;
  let sort_order = data?.sorting?.sort_order;
  let ticket_type = _.keys(_.pickBy(data?.filters?.ticket_type));
  let category_ids = _.map(data?.filters?.category_ids, "id");
  let performer_ids = _.map(data?.filters?.performer_ids, "id");
  let export_to_csv = data?.export_to_csv;
  let order_start_date;
  let order_end_date;
  let delivery_start_date;
  let delivery_end_date;
  let event_start_date;
  let event_end_date;
  let store_id;
  let internal_order_statuses;

  if (data?.filters?.orderDateRange) {
    order_start_date = data?.filters?.orderDateRange?.startDate;
    order_end_date = data?.filters?.orderDateRange?.endDate;
  }
  if (data?.filters?.deliveryDateRange) {
    delivery_start_date = data?.filters?.deliveryDateRange?.startDate;
    delivery_end_date = data?.filters?.deliveryDateRange?.endDate;
  }
  if (data?.filters?.event_date) {
    event_start_date = data?.filters?.event_date?.startDate;
    event_end_date = data?.filters?.event_date?.endDate;
  }
  if (_.map(data?.filters?.order_exchanges, "id")) {
    marketplace_id = _.map(data?.filters?.order_exchanges, "id");
  }

  if (_.map(data?.filters?.internal_order_statuses, "id")) {
    internal_order_statuses = _.map(
      data?.filters?.internal_order_statuses,
      "id"
    );
  }

  store_id = data?.filters?.store_id;

  let payload = {
    ...data.pagination,
    ...(data?.tabValue && { order_status_list: data?.tabValue }),
    ...(data?.debouncedSearch && { keywords: data?.debouncedSearch }),
    ...(data?.filters?.query &&
      data?.export_to_csv && { keywords: data?.filters?.query }),
    ...(team_member && team_member.length > 0
      ? { team_member }
      : { team_member: "all" }),
    ...(event_id && event_id.length > 0 && { event_id }),
    ...(time_to_event && { time_to_event }),
    ...(order_statuses && order_statuses.length > 0 && { order_statuses }),
    ...(ticket_type && ticket_type.length > 0 && { ticket_type: ticket_type }),
    ...(page && { page }),
    ...(per_page && { per_page }),
    ...(sort_order && order_by && { order_by }),
    ...(sort_order && { sort_order }),
    ...(order_exchanges && { exchange_id: order_exchanges }),
    ...(order_start_date && {
      order_start_date: moment(order_start_date).format("YYYY-MM-DD"),
    }),
    ...(order_end_date && {
      order_end_date: moment(order_end_date).format("YYYY-MM-DD"),
    }),
    ...(delivery_start_date && {
      delivery_start_date: moment(delivery_start_date).format("YYYY-MM-DD"),
    }),
    ...(delivery_end_date && {
      delivery_end_date: moment(delivery_end_date).format("YYYY-MM-DD"),
    }),
    ...(export_to_csv && { export_to_csv: export_to_csv }),

    ...(event_start_date && {
      event_start_date: moment(event_start_date).format("YYYY-MM-DD"),
    }),
    ...(event_end_date && {
      event_end_date: moment(event_end_date).format("YYYY-MM-DD"),
    }),
    ...(_.size(team_id) > 0 && { team_id }),
    ...(marketplace_id &&
      marketplace_id?.length > 0 && { marketplace_id: marketplace_id }),

    ...(internal_order_statuses &&
      internal_order_statuses?.length > 0 && {
        internal_order_statuses: internal_order_statuses,
      }),

    ...(store_id && {
      store_id: store_id,
    }),
    ...(category_ids && category_ids.length > 0 && { category_ids }),
    ...(performer_ids && performer_ids.length > 0 && { performer_ids }),
  };
  return axios.post(`sales/counts`, payload).then((response) => response.data);
};

//sales/details
const salesDetailsView = (data: any): Promise<any> => {
  let payload = {
    ...(data?.order_id && { order_id: data?.order_id }),
    // order_status_list: data?.tabValue,
  };
  return axios.post(`sales/details`, payload).then((response) => response.data);
};

// sales/fulfilment
const salesFulfillment = (data: any): Promise<any> => {
  let payload = {
    ...data,
  };
  return axios
    .post(`sales/fulfilment`, payload)
    .then((response) => response.data);
};

// sales/fulfilment
const shippingLabel = (data: any): Promise<any> => {
  return axios
    .post(`sales/shipping-label`, data)
    .then((response) => response.data);
};

// sales/fulfilment
const updateCustomerDetails = (data: any): Promise<any> => {
  const { order_id, items, order_status_id, ...customerWithoutOrderId } = data;

  const payload = {
    order_id: data?.order_id,
    customer: customerWithoutOrderId,
    items: items,
    order_status_id,
  };

  return axios
    .post(`sales/store-customer-details`, payload)
    .then((response) => response.data);
};

// Add order note
const addOrderNote = (data: any): Promise<any> => {
  const payload = {
    history: data?.note,
    type: data?.type,
  };

  return axios
    .post(`sales/update/history/${data?.order_id}`, payload)
    .then((response) => response.data);
};

// Delete order note
const deleteOrderNote = (data: any): Promise<any> => {
  const payload = {
    index: data?.index,
  };

  return axios
    .post(`sales/delete/history/${data?.order_id}`, payload)
    .then((response) => response.data);
};

// ----------------

// ORDER NEW REPLACEMENT LISTING
const suggestListingReplacement = async (data: any): Promise<any> => {
  let order_id = data?.order_id;
  let team_id = _.map(data?.team_id, "id");
  let category_id = _.map(data?.category_id, "id");
  let ticket_type = _.map(data?.ticket_type, "id");
  let quantity = data?.quantity?.id;
  let ticket_in_hand = data?.ticket_in_hand;
  let ticket_uploaded = data?.ticket_uploaded;
  let page = data?.page;
  let per_page = data?.per_page;
  let sort_order = data?.sort_order;
  let order_by = data?.order_by;

  // PARAMS
  const params = {
    order_id: order_id,
    ...(_.size(category_id) > 0 && { category_id: category_id }),
    ...(_.size(ticket_type) > 0 && { ticket_type: ticket_type }),
    ...(_.size(team_id) > 0 && { team_id: team_id }),
    ...(ticket_in_hand && { ticket_in_hand: ticket_in_hand }),
    ...(ticket_uploaded && { ticket_uploaded: ticket_uploaded }),
    ...(quantity && { quantity: quantity }),
    ...(page && { page: page }),
    ...(per_page && { per_page: per_page }),
    ...(sort_order && { sort_order: sort_order }),
    ...(order_by && sort_order && { order_by: order_by }),
  };

  return axios
    .get(`sales/suggest-listing-replacements`, {
      params: params,
    })
    .then((response) => response.data);
};

// POST REPLACEMENT
const orderReplacement = async (data: any): Promise<any> => {
  return axios
    .post(`sales/order-replacement`, data)
    .then((response) => response.data);
};

//GET ORDER REPLACEMENT NEW TEAM LISTING PRICE
const getOrderReplacementTeamListingPrice = async (data: any): Promise<any> => {
  return axios
    .get("sales/get-replacement-order-price", {
      params: data,
    })
    .then((response) => response.data);
};

// TICKET TYPE CHANGE IN ORDER SALES
const changeTicketType = async (
  order_id: any,
  ticket_type: any
): Promise<any> => {
  return axios
    .post(`sales/change-ticket-type`, { order_id, ticket_type })
    .then((response) => response.data);
};

//GET ORDER REPLACEMENT NEW TEAM LISTING PRICE
const getInstructionLetterTemplateList = async (data: any): Promise<any> => {
  return axios
    .get("sales/instruction-letter-template/listing", {
      params: data,
    })
    .then((response) => response.data);
};

const getSalesLogListing = (params: any) => (tabKey: any) => {
  let postKey = tabKey ?? LOG_TAB_KEYS.ORDER;
  const payload: any = { [postKey]: params[postKey] };

  if (tabKey === LOG_TAB_KEYS.INVENTORY)
    return getInventoryLogListing({
      id: payload[tabKey] ?? { ...payload },
    })().then((response) => response);

  return axios
    .get("sales/order-logs", { params: payload })
    .then((response) => response.data);
};

const cancelAndRefund = async (
  order_id: any,
  refund_amount: number,
  card_refund_ledger_entry: any
): Promise<any> => {
  return axios
    .post(`sales/cancel-and-refund`, {
      order_id,
      refund_amount,
      card_refund_ledger_entry,
    })
    .then((response) => response.data);
};

export {
  addOrderNote,
  cancelAndRefund,
  changeTicketType,
  deleteOrderNote,
  getInstructionLetterTemplateList,
  getOrderReplacementTeamListingPrice,
  getSalesListing,
  getSalesLogListing,
  getSalesOptions,
  getSalesOverviewListing,
  orderReplacement,
  salesDetailsView,
  salesFulfillment,
  shippingLabel,
  suggestListingReplacement,
  updateCustomerDetails,
};
