import axios from "../../../../axois/SetupAxios";

const AUTH_USERNAME = process.env.REACT_APP_BASIC_AUTH_API_USERNAME as string;
const AUTH_PASSWORD = process.env.REACT_APP_BASIC_AUTH_API_PASSWORD as string;

const signIn = (data: any): Promise<any> => {
  return axios.post(`auth/login`, data).then((response) => response.data);
};

const getListingData = (): Promise<any> => {
  return axios.get(`auth/sign-up`).then((response) => response.data);
};

const signUp = async (data: any): Promise<any> => {
  let payload = {
    ...data,
    base_currency: data?.base_currency?.id,
    estimated_annual_revenue: data?.estimated_annual_revenue?.id,
    country_id: data?.country_id?.id,
    phone: `${data?.countryCode}${data?.phone}`,
    card_token: data?.card_token,
    provide_kyc_docs: data?.kyc,
    ...(data?.business_name && { business_name: data?.business_name }),
    ...(data?.kyc && {
      withdrawal: {
        bank_name: data?.withdrawal_bank_name,
        ac_holder_name: data?.withdrawal_ac_holder_name,
        iban: data?.withdrawal_iban,
        swift: data?.withdrawal_swift,
        ac_number: data?.withdrawal_ac_number,
        branch_code: data?.withdrawal_branch_code,
        bank_account_currency: data?.withdrawal_bank_ac_currency,
        ...(data?.withdrawal_bank_ac_country_code?.originalId && {
          bank_account_country_code:
            data?.withdrawal_bank_ac_country_code?.originalId,
        }),

        // RECIEPIET
        ...(data?.recipient_street && {
          recipient_street: data?.recipient_street,
        }),
        ...(data?.recipient_city && {
          recipient_city: data?.recipient_city,
        }),
        ...(data?.recipient_state && {
          recipient_state: data?.recipient_state,
        }),
        ...(data?.recipient_postcode && {
          recipient_postcode: data?.recipient_postcode,
        }),
        ...(data?.recipient_country_code?.originalId && {
          recipient_country_code: data?.recipient_country_code?.originalId,
        }),
      },
    }),
    ...(data?.kyc && data?.use_as_same
      ? {
          funding: {
            bank_name: data?.withdrawal_bank_name,
            ac_holder_name: data?.withdrawal_ac_holder_name,
            iban: data?.withdrawal_iban,
            swift: data?.withdrawal_swift,
            ac_number: data?.withdrawal_ac_number,
            sort_code: data?.withdrawal_branch_code,
          },
        }
      : {
          funding: {
            bank_name: data?.funding_bank_name,
            ac_holder_name: data?.funding_ac_holder_name,
            iban: data?.funding_iban,
            swift: data?.funding_swift,
            ac_number: data?.funding_ac_number,
            sort_code: data?.funding_branch_code,
          },
        }),
    ...(data?.identity_proof && { identity_proof: data?.identity_proof }),
    ...(data?.business_proof && { business_proof: data?.business_proof }),
    // package_id: data?.package_id?.id,
    exchanges: [],
    is_preferred_partner: 1,
    package_id: process.env.REACT_APP_USER_PACKAGE_ID,
  };
  delete payload.saved;
  delete payload.isPayment;
  delete payload.formData;
  delete payload.company;
  delete payload.withdrawal_bank_identifier;
  delete payload.funding_bank_identifier;

  delete payload.withdrawal_bank_name;
  delete payload.withdrawal_ac_holder_name;
  delete payload.withdrawal_iban;
  delete payload.withdrawal_swift;
  delete payload.withdrawal_ac_number;
  delete payload.withdrawal_branch_code;
  delete payload.withdrawal_bank_ac_currency;

  delete payload.funding_bank_name;
  delete payload.funding_ac_holder_name;
  delete payload.funding_iban;
  delete payload.funding_swift;
  delete payload.funding_ac_number;
  delete payload.funding_branch_code;

  const response = await axios.post(`auth/sign-up`, payload);
  return response.data;
};

const verifyExchanges = (data: any): Promise<any> => {
  let payload = {
    exchange_id: data?.id,
    username: data.username,
    password: data.password,
  };
  return axios
    .post(`auth/verify-exchange-credentials`, payload)
    .then((response) => response.data);
};

const forgetPassword = (data: any): Promise<any> => {
  return axios
    .post(`auth/forgot-password`, data)
    .then((response) => response.data);
};

// STRIP GATEWAY
const stripeGateway = (data: any): Promise<any> => {
  return axios
    .post(`auth/stripe-client-secret`, data)
    .then((response) => response.data);
};

const resetPassword = (data: any): Promise<any> => {
  return axios
    .post(`auth/reset-password`, data)
    .then((response) => response.data);
};

const contactus = (data: any): Promise<any> => {
  let payload = {
    ...data,
    location: data?.location?.name,
    countryCode: data?.countryCode?.originalId,
  };
  return axios
    .post(`contact/inquiry`, payload, {
      auth: {
        username: AUTH_USERNAME,
        password: AUTH_PASSWORD,
      },
    })
    .then((response) => response.data);
};

const checkEmail = async (data: any): Promise<any> => {
  let payload: any = {
    email: data?.email,
    username: data?.username,
  };
  const response = await axios.get(`auth/unique`, { params: payload });
  return response.data;
};

export {
  checkEmail,
  contactus,
  forgetPassword,
  getListingData,
  resetPassword,
  signIn,
  signUp,
  stripeGateway,
  verifyExchanges,
};
